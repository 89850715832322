<template>
  <div class="d-flex justify-end">
    <v-col md="12" lg="12" xl="12" cols="12" v-if="dialogPres == true">
      <loadingComponent
        @closeDialog="dialogPres = $event"
        :dialogProps="dialogPres"
        :loadingProps="loadingPres"
      />
    </v-col>
    <v-dialog v-model="dialogGraph" width="750">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ml-4 white--text"
          :color="$colors[0]"
          :disabled="isArrayEmptyRecursive(indexes)"
          v-bind="attrs"
          v-on="on"
        >
          Créer une présentation<v-icon size="20" class="ml-2"
            >mdi-file-document-outline</v-icon
          >
        </v-btn>

        <!-- comment button associe un vendeur  -->
        <v-btn
          class="ml-4 white--text"
          color="#000"
          :disabled="isArrayEmptyRecursive(indexes)"
          @click="toggleAssocie = true"
        >
          Associe un Vendeur
        </v-btn>
      </template>

      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn class="ml-4" :color="$colors[0]" dark v-bind="attrs" v-on="on">
          Graphique<v-icon size="20" class="ml-2">mdi-chart-line</v-icon>
        </v-btn>
      </template> -->

      <v-card class="d-flex flex-column justify-end" min-height="536">
        <v-card-text v-if="toggleCharts">
          <researchDate
            class="pt-0 pr-0"
            @updateCourbe="
              updateCourbe(
                charts[0].chartOptions.series,
                charts[0].urlAPI,
                $event.dateDebut,
                $event.dateFin,
                charts[0].items,
                charts[0].chartOptions.categories,
                charts[0].data,
                charts[0].updateChartsOptions
              )
            "
          />
          <chart
            id="charts"
            :optionsChart="charts[0].chartOptions"
            :seriesCharts="charts[0].chartOptions.series.value"
            :updateChartsOptions="charts[0].updateChartsOptions"
            styleProps="height:300px;"
          />
        </v-card-text>
        <v-card-text
          style="height: 420px"
          class="d-flex flex-row align-items-center justify-center"
          v-else
        >
          <h5 class="MontSerrat">GRAPHIQUE NON INCLUS DANS LA PRESENTATION</h5>
        </v-card-text>
        <v-card-actions
          id="screenshot"
          class="pb-4 d-flex flex-row justify-space-between"
        >
          <v-switch
            class="pt-4 pb-4 mt-0"
            hide-details
            v-model="toggleCharts"
            :label="`Inclure graphique`"
          ></v-switch>
          <div class="d-flex flex-row justify-end" style="width: 100%">
            <v-spacer></v-spacer>

            <v-btn
              color="error"
              text
              class="white--text mr-4"
              @click="dialogGraph = false"
            >
              Fermer
            </v-btn>

            <v-btn
              :color="$colors[0]"
              class="white--text"
              @click="captureDownloadImage('charts', 'screenshot')"
            >
              Sauvegarder
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- comment dialog associe un vendeur  -->
    <v-dialog v-model="toggleAssocie" width="750" persistent max-width="750">
      <v-card class="d-flex flex-column justify-end" min-height="300">
        <v-card-title>
          Associé un vendeur
          <v-spacer></v-spacer>
          <v-icon @click="toggleAssocie = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-row>
              <v-col cols="12" md="12">
                <v-select
                  required
                  outlined
                  multiple
                  v-model="vendeur"
                  :items="vendeurList"
                  label="Liste Vendeur"
                  item-text="userName"
                  item-value="id"
                  return-object="false"
                ></v-select>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="dateExpiration"
                  label="Date d'expiration"
                  outlined
                  type="date"
                  :rules="[rules.required(`date obligatoire`)]"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="12">
                <v-text-field
                  v-model="commentaire"
                  label="Commentaire"
                  outlined
                  :rules="[rules.required(`veillez saisir un commentaire`)]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-btn
                  color="primary"
                  @click="sendVendorData"
                  v-if="vendeur.length > 0"
                >
                  Valider
                </v-btn>
                <v-btn color="#b8b8b8" disabled v-else> Valider </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  degrees,
  PDFDocument,
  rgb,
  PDFName,
  PDFString,
  StandardFonts,
  TextAlignment,
  setFillingRgbColor,
  setFontAndSize,
  layoutMultilineText,
} from "pdf-lib";
import html2canvas from "html2canvas";
import download from "downloadjs";
import jsPDF from "jspdf";
import loadingComponent from "../../../components/ui/loading-component.vue";
import dayjs from "dayjs";
import axios from "axios";
import "jspdf-autotable";
import chart from "../../charts/components/chart.vue";
import researchDate from "../../charts/components/research-date.vue";
export default {
  name: "presentation",
  components: {
    loadingComponent,
    chart,
    researchDate,
  },
  props: {
    infosCompteurPresentationProps: null,
    parametreCompteur: null,
    donnerDesFournisseur: [],
    typeCompteur: null,
    fournisseurActuelle: null,
    indexes: [],
    numCompteur: null,
    dataEnedis: [],
    nbrMonthsEnedis: 0,
    turpeData: [],
    donnerCompteur: [],
    societe: [],
    donneDeCotation: null,
    id_historique_cotation: null,
  },
  data() {
    return {
      toggleCharts: true,
      fournisseurActuelleData: false,
      societeData: null,
      dialogGraph: false,
      graphImage: "",
      toggleAssocie: false,
      vendeur: "",
      vendeurList: [],
      commentaire: "",
      dateExpiration: "",
      charts: [
        {
          idCapture: "capture-elec",
          items: { value: [] },
          data: { value: null },
          updateChartsOptions: null,
          chartOptions: {
            chart: {
              toolbar: {
                show: false,
              },
            },
            colors: ["#000000", "#1E6CA4", "#c7c804", "#f8c954"],
            title: "Évolution Éléctricité",
            series: { value: [] },
            categories: { value: [] },
          },
          urlAPI: "getAllDataCourbeElec/",
        },
      ],
      columns: [
        {
          header: "Fournisseur",
          dataKey: "fournisseur",
          columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
        },
        {
          header: "P\n€/MWh",
          dataKey: "p",
          columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
        },
        {
          header: "HPH\n€/MWh",
          dataKey: "hph",
          columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
        },
        {
          header: "HCH\n€/MWh",
          dataKey: "hch",
          columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
        },
        {
          header: "HPE\n€/MWh",
          dataKey: "hpe",
          columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
        },
        {
          header: "HCE\n€/MWh",
          dataKey: "hce",
          columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
        },
      ],
      columns2: [
        {
          header: "P\n€/MWh",
          dataKey: "capap",
          columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
        },
        {
          header: "HPH\n€/MWh",
          dataKey: "capahph",
          columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
        },
        {
          header: "HCH\n€/MWh",
          dataKey: "capahch",
          columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
        },
        {
          header: "HPE\n€/MWh",
          dataKey: "capahpe",
          columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
        },
        {
          header: "HCE\n€/MWh",
          dataKey: "capahce",
          columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
        },
      ],
      columnsSansPointe: [
        {
          header: "Fournisseur",
          dataKey: "fournisseur",
          columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
        },

        {
          header: "HPH\n€/MWh",
          dataKey: "hph",
          columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
        },
        {
          header: "HCH\n€/MWh",
          dataKey: "hch",
          columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
        },
        {
          header: "HPE\n€/MWh",
          dataKey: "hpe",
          columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
        },
        {
          header: "HCE\n€/MWh",
          dataKey: "hce",
          columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
        },
      ],
      columnsHPHC: [
        {
          header: "Fournisseur",
          dataKey: "fournisseur",
          columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
        },

        {
          header: "HP\n€/MWh",
          dataKey: "hp",
          columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
        },
        {
          header: "HC\n€/MWh",
          dataKey: "hc",
          columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
        },
      ],
      columns2HPHC: [
        {
          header: "HP\n€/MWh",
          dataKey: "capahp",
          columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
        },
        {
          header: "HC\n€/MWh",
          dataKey: "capahc",
          columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
        },
      ],
      columns2SansPointe: [
        {
          header: "HPH\n€/MWh",
          dataKey: "capahph",
          columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
        },
        {
          header: "HCH\n€/MWh",
          dataKey: "capahch",
          columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
        },
        {
          header: "HPE\n€/MWh",
          dataKey: "capahpe",
          columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
        },
        {
          header: "HCE\n€/MWh",
          dataKey: "capahce",
          columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
        },
      ],
      columnsBASE: [
        {
          header: "Fournisseur",
          dataKey: "fournisseur",
          columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
        },
        {
          header: "BASE\n€/MWh",
          dataKey: "base",
          columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
        },
      ],
      columns2BASE: [
        {
          header: "BASE\n€/MWh",
          dataKey: "capabase",
          columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
        },
      ],
      rules: {
        isPositiveNumb: (v) =>
          /^[0-9]\d*$/.test(v) ||
          "La valeur doit etre un entier strictement supérieur à 0",
        required(fieldName) {
          return (value) => !!value || `Veuillez saisir ${fieldName}`;
        },
      },

      dialogPres: false,
      loadingPres: false,
      listDesOffre: [],
      Offres: [],
      OffreActuelleData: {},
    };
  },
  async created() {
    await this.createGraph();
    await this.getListVendor();
  },
  watch: {
    infosCompteurPresentationProps: function (newValue) {
      // console.log(newValue);
    },
  },
  methods: {
    calculerPourcentage(prixOffreActuelle, prixOffre) {
      let pourcentage =
        ((prixOffreActuelle - prixOffre) / prixOffreActuelle) * 100;

      return pourcentage;
    },

    async sendVendorData() {
      if (
        !(
          this.vendeur.length == 0 ||
          this.dateExpiration == "" ||
          this.commentaire == ""
        )
      ) {
        let data = {
          token: this.getToken("token"),
          list_allowed_compte: this.vendeur.map((item) => item.id),
          expiration_date: this.dateExpiration,
          id_historique_de_cotation: this.id_historique_cotation,
          commentaire: this.commentaire,
          cotationParCompteur: this.send_data(),
        };
        this.toggleAssocie = false;
        this.dialogPres = true;
        this.loadingPres = true;

        await axios({
          url:
            process.env.VUE_APP_URL_API_CLIENT +
            "ajouterHistoriqueDeCotationParCompteElec/",
          method: "POST",
          data: data,
        })
          .then((response) => {
            if (response.data.result == true) {
              this.dialogPres = false;
              this.loadingPres = false;
            }

            //reset the form
            this.vendeur = "";
            this.dateExpiration = "";
            this.commentaire = "";
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    capitalizeEachWord(str) {
      return str.replace(/\b\w/g, function (char) {
        return char.toUpperCase();
      });
    },
    async drawAlignedText({
      page,
      text,
      x = 0,
      y = 0,
      font,
      size,
      align,
      textColor = [0, 0, 0],
      width = 0,
      height = 0,
      borderWidth = 0,
      borderColor,
      bgColor,
      padding = 0,
    }) {
      const widthText = font.widthOfTextAtSize(text, size);
      const heightText = font.heightAtSize(size) - 2;
      // calculate center position
      let xPosition = x + width / 2;
      if (align == "center") xPosition = xPosition - widthText / 2;
      if (align == "left") xPosition = x + padding;
      if (align == "right") xPosition = x + width - widthText - padding;
      // draw rectangle
      if (width > 0 && (borderWidth > 0 || bgColor?.length == 3)) {
        let rectProps = {
          x: x,
          y: y,
          width: width,
          height: height > 0 ? height : heightText,
          borderWidth: borderWidth,
        };
        if (bgColor) rectProps.color = rgb(bgColor[0], bgColor[1], bgColor[2]);
        if (borderColor)
          rectProps.borderColor = rgb(
            borderColor[0],
            borderColor[1],
            borderColor[2]
          );
        page.drawRectangle(rectProps);
      }
      if (height > 0) y = y + height / 2 - heightText / 2;
      // draw text
      page.drawText(text, {
        x: xPosition,
        y: y,
        size: size,
        font: font,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
    },
    formatPhoneNumber(phoneNumber) {
      // Supprimer tous les caractères non numériques
      phoneNumber = phoneNumber.replace(/\D/g, "");

      // Insérer un espace après chaque paire de chiffres
      phoneNumber = phoneNumber.replace(/(\d{2})(?=\d)/g, "$1 ");

      return phoneNumber;
    },
    isArrayEmptyRecursive(arr) {
      console.log("arr", arr);
      console.log("fournisseurActuelleData", this.fournisseurActuelleData);
      console.log("fournisseurActuelle", this.fournisseurActuelle);

      if (!Array.isArray(arr)) {
        return false; // Si l'élément n'est pas un tableau, retourne false
      } else {
        for (const element of arr) {
          if (element.length >= 1) {
            return false;
          }
          /*if (!this.isArrayEmptyRecursive(element)) {
            return false; // Si un élément n'est pas vide, retourne false
          }*/
        }
      }

      if (arr.length === 0) {
        return true; // Si le tableau est vide, retourne true
      }

      // Vérifie récursivement chaque élément du tableau s'il est vide

      return true; // Si tous les éléments sont vides, retourne true
    },
    formatDate(date) {
      return [date.getDate(), date.getMonth() + 1, date.getFullYear()].join(
        "/"
      );
    },
    async getImage(link, doc) {
      const ImageLogo = await fetch(link).then((res) => res.arrayBuffer());

      var pngImage = await doc.embedPng(ImageLogo);
      return pngImage;
    },
    async createTextFieldPers(
      fontsize,
      textColor,
      multiLine,
      form,
      page,
      x,
      y,
      width,
      height,
      txt,
      name,
      bgColor
    ) {
      let rand = (Math.random() + 1).toString(36).substring(7);
      var textField = form.createTextField(name + rand);
      textField.setText(txt);
      if (multiLine == true) {
        textField.enableMultiline();
      }
      textField.setAlignment(TextAlignment.Left);
      textField.addToPage(page, {
        x: x,
        y: y,
        width: width,
        height: height,
        textColor: rgb(0.062, 0.227, 0.368),
        backgroundColor: rgb(bgColor[0], bgColor[1], bgColor[2]),
        borderWidth: 0,
      });
      textField.acroField.setDefaultAppearance(
        setFontAndSize("Courier", fontsize).toString() +
          setFillingRgbColor(
            textColor[0],
            textColor[1],
            textColor[2]
          ).toString()
      );
      var fields = form.getFields();
      fields.forEach((field) => {
        field.enableReadOnly();
      });
      form.updateFieldAppearances();
    },
    async createTextField(
      multiLine,
      form,
      page,
      x,
      y,
      width,
      height,
      txt,
      name,
      textColor,
      bgColor
    ) {
      var fontsize = 15;
      /////////////////////
      if (width / txt.length <= 3.5) {
        if (name.indexOf("value") != -1 && name.indexOf("TypeOffre") != -1) {
          if (width / txt.length <= 2) {
            fontsize = 6.5;
            height = height + 2.5;
          } else {
            fontsize = 8;
          }
        } else {
          fontsize = 8;
        }
        multiLine = true;
      }
      let rand = (Math.random() + 1).toString(36).substring(7);
      var textField = form.createTextField(name + rand);
      if (isNaN(txt)) {
        textField.setText(txt);
      } else {
        if (txt != "" && name != "pdl") {
          var temText = Number(txt).toLocaleString("fr-FR", {
            minimumFractionDigits: 1,
          });
          temText = temText.replace(/ /g, " ");
          textField.setText(temText);
        } else {
          textField.setText(txt);
        }
      }
      if (multiLine == true) {
        textField.enableMultiline();
      }
      if (name == "title8BigPourcentage") {
        textField.enableMultiline();
        textField.setAlignment(TextAlignment.Center);
        textField.addToPage(page, {
          x: x,
          y: y,
          width: width,
          height: height + 20,
          textColor: rgb(textColor[0], textColor[1], textColor[2]), //rgb(0.062, 0.227, 0.368),
          backgroundColor: rgb(bgColor[0], bgColor[1], bgColor[2]),
          borderWidth: 0,
        });
      } else {
        textField.setAlignment(TextAlignment.Center);
        textField.addToPage(page, {
          x: x,
          y: y,
          width: width,
          height: height,
          textColor: rgb(textColor[0], textColor[1], textColor[2]), //rgb(0.062, 0.227, 0.368),
          backgroundColor: rgb(bgColor[0], bgColor[1], bgColor[2]),
          borderWidth: 0,
        });
      }
      textField.acroField.setDefaultAppearance(
        setFontAndSize("Courier", fontsize).toString() +
          setFillingRgbColor(
            textColor[0],
            textColor[1],
            textColor[2]
          ).toString()
      );
      var fields = form.getFields();
      fields.forEach((field) => {
        field.enableReadOnly();
      });
      form.updateFieldAppearances();
    },
    numberWithSpaces(x) {
      var parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return parts.join(".");
    },
    async createTextFieldForInfoTable(
      multiLine,
      form,
      page,
      x,
      y,
      width,
      height,
      txt,
      name,
      textColor,
      bgColor
    ) {
      var fontsize = 9;
      /////////////////////
      if (width / txt.length <= 3.5) {
        if (name.indexOf("value") != -1 && name.indexOf("TypeOffre") != -1) {
          if (width / txt.length <= 2) {
            fontsize = 6.5;
            height = height + 2.5;
          } else {
            fontsize = 8;
          }
        } else {
          fontsize = 8;
        }
        multiLine = true;
      }
      let rand = (Math.random() + 1).toString(36).substring(7);
      var textField = form.createTextField(name + rand);
      textField.setText(txt);
      if (multiLine == true) {
        textField.enableMultiline();
      }
      if (name == "title8BigPourcentage") {
        textField.enableMultiline();
        textField.setAlignment(TextAlignment.Center);
        textField.addToPage(page, {
          x: x,
          y: y,
          width: width,
          height: height + 20,
          // textColor: rgb(0.062, 0.227, 0.368),
          textColor: rgb(textColor[0], textColor[1], textColor[2]),
          backgroundColor: rgb(bgColor[0], bgColor[1], bgColor[2]),
          borderWidth: 0,
        });
      } else {
        textField.setAlignment(TextAlignment.Center);
        textField.addToPage(page, {
          x: x,
          y: y,
          width: width,
          height: height,
          // textColor: rgb(0.062, 0.227, 0.368),
          textColor: rgb(textColor[0], textColor[1], textColor[2]),
          backgroundColor: rgb(bgColor[0], bgColor[1], bgColor[2]),
          borderWidth: 0,
        });
      }
      textField.acroField.setDefaultAppearance(
        setFontAndSize("Courier", fontsize).toString() +
          setFillingRgbColor(0.062, 0.227, 0.368).toString()
      );
      var fields = form.getFields();
      fields.forEach((field) => {
        field.enableReadOnly();
      });
      form.updateFieldAppearances();
    },

    async infoTable(form, pdfDoc, page, donner, isCenter) {
      var HelveticaBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
      var Helvetica = await pdfDoc.embedFont(StandardFonts.Helvetica);
      // page.drawText("Vos informations", {
      //   x: 200,
      //   y: 450,
      //   font: HelveticaBold,
      //   size: 20,
      //   color: rgb(0, 0, 0),
      // });
      var image = await this.getImage("/imagePres/yellow.PNG", pdfDoc);
      var typeCompteur = donner["typeCompteur"];
      var xBegin = donner["xBegin"];
      var yBegin = isCenter ? 275 : donner["yBegin"];
      for (var i = 0; i < donner["data"].length; i++) {
        var data = donner["data"];
        var width = data[i]["width"];
        var title = data[i]["title"];
        var lesTexts = data[i]["text"];
        var fieldName = data[i]["fieldName"];

        await this.drawAlignedText({
          page,
          text: title,
          x: xBegin,
          y: yBegin + 49,
          font: Helvetica,
          size: 8,
          align: "center",
          textColor: [0.062, 0.227, 0.368],
          width: width,
          height: 15,
          // bgColor:[0.96, 0.96, 0.97]
        });

        var xForm = xBegin + 10;
        for (var j = 0; j < lesTexts.length; j++) {
          var text = lesTexts[j];
          var subTitle = text["title"];
          var value = text["value"];
          var fieldName = text["fieldName"];
          if (lesTexts.length > 2) {
            var widthForm = width / lesTexts.length - 8;
          } else if (lesTexts.length == 2) {
            var widthForm = width / lesTexts.length - 12;
          } else {
            var widthForm = width - 15;
          }

          await this.drawAlignedText({
            page,
            text: subTitle,
            x: xForm,
            y: yBegin + 30,
            font: Helvetica,
            size: 8,
            align: "center",
            textColor: [0.062, 0.227, 0.368],
            width: widthForm,
            height: 15,
            // bgColor:[0.96, 0.96, 0.97]
          });

          try {
            if (
              subTitle == "Compteur" ||
              value.indexOf("/") != -1 ||
              isNaN(parseFloat(value).toFixed(2))
            ) {
              value = value;
            } else {
              value = parseFloat(value).toFixed(2);
            }
          } catch (exception) {
            value = value;
          }

          await this.drawAlignedText({
            page,
            text: this.formatNumberOrText(value,2),
            x: xForm,
            y: yBegin + 5,
            font: Helvetica,
            size: 8,
            align: "center",
            textColor: [0.062, 0.227, 0.368],
            width: widthForm,
            height: 22,
            bgColor: [0.9725, 0.7882, 0.3294],
          });
          xForm = xForm + widthForm + 5;
        }
        if (typeCompteur == "C5HP") {
          xBegin = xBegin + 20;
        }
        if (typeCompteur == "C5BASE") {
          xBegin = xBegin + 40;
        }
        xBegin = xBegin + width;
      }
      return pdfDoc;
    },
    formatNumberOrText(num, tofFixedNumber) {
      if (!isNaN(num) && num !== null && num !== undefined && num !== "") {
        // num is number
        return (
          Number(parseFloat(num).toFixed(tofFixedNumber))
            .toLocaleString("fr-FR")
            .replace(/\u202F/g, " ")
            .toString() + ""
        );
      } else {
        // num is text
        if (num !== null && num !== undefined && num !== "") {
          return num;
        }
        return "";
      }
    },
    async drawOffreActuelle(form, pdfDoc, page, donner) {
      var HelveticaBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
      var Helvetica = await pdfDoc.embedFont(StandardFonts.Helvetica);
      let bottomMargin = 35;
      var y = 455 - bottomMargin;
      page.drawText("Offre Actuelle / de renouvellement", {
        x: 55,
        y: y,
        font: Helvetica,
        size: 20,
        color: rgb(0, 0, 0),
      });
      var line = await this.getImage("/imagePres/ligne_offre_prix.png", pdfDoc);
      /*var yellow = await this.getImage("/imagePres/yellow.PNG", pdfDoc);
      var tableLine = await this.getImage(
        "/imagePres/tableYellowRecap.PNG",
        pdfDoc
      );*/

      var xBegin = donner["xBegin"];
      var yBegin = 310 - bottomMargin;
      var width = 515;
      var logo = await this.getImage(donner["logo"], pdfDoc);

      //page.drawImage(tableLine, {x: xBegin, y: yBegin+2, width: width,height: 40,})
      var smallWidth = 80;
      //page.drawImage(yellow, {x: xBegin+width, y: yBegin+2, width: smallWidth,height: 40,})
      var xForm = xBegin;
      var widthForm = 68;
      var value = "";
      var title = "";
      var subtitle = "";
      var fieldName = "";
      page.drawImage(line, {
        // ATTENTION
        x: xBegin - 70,
        y: yBegin + 80,
        width: 900,
        height: 50,
      });
      page.drawImage(logo, {
        x: xBegin - 54,
        y: yBegin + 85,
        width: 137,
        height: 40,
        // width: 120,
        // height: 35,
      });
      for (var i = 0; i < donner["data"].length; i++) {
        // ATTENTION A CHANGER INDEX
        var data = donner["data"][i];
        xForm = xBegin + data["pas"];
        //widthForm = data["width"];
        widthForm = 145;
        value = data["value"];
        /*if ("title" in data) {
          title = data["title"];
          await this.createTextField(
            true,
            form,
            page,
            xForm,
            yBegin + 56,
            widthForm + 2,
            20,
            title,
            "title" + fieldName,
            [1, 1, 1],
            [0.98, 0.98, 0.98]
          );
        }*/
        /*if ("subTitle" in data) {
          subtitle = data["subTitle"];
          await this.createTextField(
            true,
            form,
            page,
            xForm,
            yBegin + 40,
            widthForm,
            15,
            subtitle,
            "subtitle" + fieldName,
            [1, 1, 1],
            [1, 1, 1]
          );
        }*/
        fieldName = data["fieldName"];
        xForm = xBegin + data["pas"];
        try {
          if (title == "Durée" || isNaN(parseFloat(value).toFixed(2))) {
            value = value;
          } else {
            value = parseFloat(value).toFixed(2);
          }
        } catch (exception) {
          value = value;
        }

        /*page.drawImage(line, {
          x: xForm,
          y: yBegin + 5,
          width: widthForm + 5,
          height: 37,
        });*/
        await this.drawAlignedText({
          page,
          text: this.formatNumberOrText(value,2),
          x: xForm + 120,
          y: yBegin + 95,
          font: Helvetica,
          size: 12,
          align: "center",
          textColor: [0, 0, 0],
          width: widthForm - 1,
          height: 21,
          bgColor: [0.98, 0.98, 0.98],
        });

        //xBegin = xBegin + widthForm + 2 + data["pas"];
        xBegin = xBegin + widthForm + 2 + 32;
      }

      return pdfDoc;
    },
    async getListVendor() {
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getAllVendeursProspecteurs/",
        method: "POST",
        data: {
          token: this.getToken("token"),
        },
      })
        .then((response) => {
          this.vendeurList = response.data.result;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    send_data() {
      let data = {};
      for (let i = 0; i < this.donnerDesFournisseur.length; i++) {
        if (this.indexes[i].length > 0) {
          data[this.donnerDesFournisseur[i]["NumCompteur"]] = [];
          for (let j = 0; j < this.indexes[i].length; j++) {
            data[this.donnerDesFournisseur[i]["NumCompteur"]].push(
              this.donnerDesFournisseur[i]["resultat"]["result"][
                this.indexes[i][j]
              ]
            );
          }
        }
      }
      return data;
    },
    /*async drawOffreActuelle(form, pdfDoc, page, donner) {
      var HelveticaBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
      var Helvetica = await pdfDoc.embedFont(StandardFonts.Helvetica);
      var y = 400;
      page.drawText("Offre de renouvellement / actuelle", {
        x: 50,
        y: y,
        font: HelveticaBold,
        size: 15,
        color: rgb(0.062, 0.227, 0.368),
      });

      var yellow = await this.getImage("/imagePres/yellow.PNG", pdfDoc);
      var tableLine = await this.getImage("/imagePres/tableYellowRecap.PNG", pdfDoc);

      var xBegin = donner["xBegin"];
      var yBegin = 320;
      var width = 515;
      var logo = await this.getImage(donner["logo"], pdfDoc);
      page.drawImage(logo, { x: xBegin - 110, y: yBegin + 2, width: 110, height: 40 });
      page.drawImage(tableLine, { x: xBegin, y: yBegin + 2, width: width, height: 40 });
      var smallWidth = 80;
      page.drawImage(yellow, {
        x: xBegin + width,
        y: yBegin + 2,
        width: smallWidth,
        height: 40,
      });
      //newLineElectronMoyenne
      page.drawImage(yellow, {
        x: xBegin + width + smallWidth,
        y: yBegin + 2,
        width: 100,
        height: 40,
      });
      var xForm = xBegin;
      var widthForm = 68;
      var value = "";
      var title = "";
      var subtitle = "";
      var fieldName = "";
      for (var i = 0; i < donner["data"].length; i++) {
        var data = donner["data"][i];
        xForm = xBegin + data["pas"];
        widthForm = data["width"];
        value = data["value"];
        title = data["title"];
        subtitle = data["subTitle"];
        fieldName = data["fieldName"];
        await this.createTextField(
          false,
          form,
          page,
          xForm,
          yBegin + 56,
          widthForm,
          15,
          title,
          "title" + fieldName,
          [1, 1, 1]
        );
        await this.createTextField(
          false,
          form,
          page,
          xForm,
          yBegin + 40,
          widthForm,
          15,
          subtitle,
          "subtitle" + fieldName,
          [1, 1, 1]
        );
        xForm = xBegin + data["pas"];
        try {
          if (title == "Durée" || isNaN(parseFloat(value).toFixed(2))) {
            value = value;
          } else {
            value = parseFloat(value).toFixed(2);
          }
        } catch (exception) {
          value = value;
        }
        await this.createTextField(
          false,
          form,
          page,
          xForm,
          yBegin + 12,
          widthForm,
          21,
          value,
          "value" + fieldName,
          [1, 0.796, 0.207]
        );
        xBegin = xBegin + widthForm + data["pas"];
      }

      return pdfDoc;
    },*/
    addBackgroundImage(doc, imageUrl) {
      let totalPages = doc.internal.getNumberOfPages();

      for (var i = 1; i <= totalPages; i++) {
        // Ajouter une nouvelle page
        doc.setPage(i);

        // Ajouter l'image en tant que fond de page
        doc.addImage(imageUrl, "JPEG", 0, 0, 770, 433);
      }
    },
    async createPDFWithMultipleTables(dataList, PDFDoc, typeCompteur) {
      //POINTE
      // extract fournisseur of typeYears == 'precie' to years
      let dataToDisplay = []
      for(let i=0; i<dataList.length;i++){
        let item=dataList[i];
        if(item.typeYears == "precie"){
          dataToDisplay.push(item)
          Object.keys(item).filter(key => /^\d{4}$/.test(key)) // Filter keys that are 4-digit numbers (years)
            .forEach(year => {
              dataToDisplay.push({
                ...item[year],
                rowType: "year",
                year: year,
              })
            });
        }else{
          dataToDisplay.push(item)
        } 
      }
      const rowsPerPage = 5; // Nombre maximum d'éléments par tableau
      const totalPages = Math.ceil(dataToDisplay.length / rowsPerPage); // Nombre total de pages nécessaires

      for (let pageIndex = 0; pageIndex < totalPages; pageIndex++) {
        /*if (pageIndex > 0) {
          doc.addPage(); // Ajouter une nouvelle page pour chaque page supplémentaire
          startY = 10; // Réinitialiser la position Y de départ pour les nouvelles pages
        }*/
        const doc = new jsPDF({
          orientation: "l",
          unit: "px",
          format: [1025, 578],
        });

        let imageUrl = "/imagePres/background_offre_volt_white.png";
        let backgroundImage = new Image();
        backgroundImage.src = imageUrl;
        this.addBackgroundImage(doc, backgroundImage);

        let imageSeparateur = "/imagePres/separateur_tableau_white.png";
        let imageSeparateurURL = new Image();
        imageSeparateurURL.src = imageSeparateur;

        const startIndex = pageIndex * rowsPerPage;
        const endIndex = Math.min(startIndex + rowsPerPage, dataToDisplay.length);
        const currentData = dataToDisplay.slice(startIndex, endIndex);
        const data = [];
        const dataCAPA = [];
        const dataCEE = [];
        const dataABO = [];
        console.log("cuurentData:", currentData);
        let x = 50;
        for (let i = 0; i < currentData.length; i++) {
          let dataObject = {
            fournisseur: `/logoParticulier/founisseur_logos/${currentData[i]["logo"]}`,
            p: currentData[i]["unitairepointe"]
              ? currentData[i]["unitairepointe"]?.toLocaleString("fr-FR")
              : 0, // ACTUPRIX
            hph: currentData[i]["unitairehph"]?.toLocaleString("fr-FR"),
            hch: currentData[i]["unitairehch"]?.toLocaleString("fr-FR"),
            hpe: currentData[i]["unitairehpe"]?.toLocaleString("fr-FR"),

            hce: currentData[i]["unitairehce"]?.toLocaleString("fr-FR"),
            //total: Number(currentData[i]).toLocaleString("fr-FR"),
          };
          if (currentData[i]["rowType"] == 'year') {
            dataObject['year'] = currentData[i]["year"];
            dataObject['rowType'] = "year";
          }

          let capaPOINTEToAffiche = currentData[i]["capa_pointe"];
          let capaHPHToAffiche = currentData[i]["capa_hph"];
          let capaHCHToAffiche = currentData[i]["capa_hch"];
          let capaHPEToAffiche = currentData[i]["capa_hpe"];
          let capaHCEToAffiche = currentData[i]["capa_hce"];
          if (currentData[i]["budgetCapa"] == 0) {
            capaPOINTEToAffiche = "inclus";
            capaHPHToAffiche = "inclus";
            capaHCHToAffiche = "inclus";
            capaHPEToAffiche = "inclus";
            capaHCEToAffiche = "inclus";
          } else {
            if (currentData[i]["capa_pointe"] == "-") {
              capaPOINTEToAffiche = "inclus";
            }
            if (currentData[i]["capa_hph"] == "-") {
              capaHPHToAffiche = "inclus";
            }
            if (currentData[i]["capa_hch"] == "-") {
              capaHCHToAffiche = "inclus";
            }
            if (currentData[i]["capa_hpe"] == "-") {
              capaHPEToAffiche = "inclus";
            }
            if (currentData[i]["capa_hce"] == "-") {
              capaHCEToAffiche = "inclus";
            }
          }
          let dataObjectCapa = {
            capap: currentData[i]["capa_pointe"]
              ? currentData[i]["capa_pointe"]?.toLocaleString("fr-FR")
              : 0,
            capahph: currentData[i]["capa_hph"]?.toLocaleString("fr-FR"),
            capahch: currentData[i]["capa_hch"]?.toLocaleString("fr-FR"),
            capahpe: currentData[i]["capa_hpe"]?.toLocaleString("fr-FR"),
            capahce: currentData[i]["capa_hce"]?.toLocaleString("fr-FR"),
          };
          let dataObjectCEE = {
            cee: currentData[i]["cee"],
          };
          let dataObjectABO = {
            abo: currentData[i]["aboAns"],
          };
          data.push(dataObject);
          /*if (typeCompteur != "C5BASE" || typeCompteur != "C5HP") {
            delete dataObjectCapa["capap"];
          }*/
          dataCAPA.push(dataObjectCapa);
          dataCEE.push(dataObjectCEE);
          dataABO.push(dataObjectABO);
        }

        doc.setFontSize(12);
        doc.setTextColor(24, 24, 24);
        doc.text("FOURNITURE", 155, 93);
        doc.autoTable({
          margin: {
            top: 100,
            left: 10,
          },
          tableWidth: 305,
          columns: this.columns,
          body: data,
          //margin: { top: 20, left: 10, right: 10 },
          bodyStyles: {
            fillColor: "#f3f4f6", //#1a1a1a
            lineWidth: 1, // Épaisseur de la bordure
            lineColor: "#1a1a1a", //ffffff
            textColor: "#1a1a1a",
            valign: "middle",
            halign: "center",
            minCellHeight: 40,
          },
          didParseCell: function (data) {
            // Définir la taille du texte de la cellule en fonction de la ligne et de la colonne
            if (
              data.row.index >= 0 &&
              data.section == "body" &&
              data.column.index > 0
            ) {
              data.cell.styles.fontSize = 10; // Taille de police en points pour la cellule (0, 0)
            }
            if (
              data.row.index >= 0 &&
              data.column.index === 0 &&
              data.cell.section === "body"
            ) {
              data.cell.text = [];
              data.cell.styles.fillColor = "#e6e6e6";
              //data.cell.styles.fillColor = "#ffffff";
            }
          },
          didDrawCell: async function (data) {
            if (
              data.row.index >= 0 &&
              data.column.index === 0 &&
              data.cell.section === "body"
            ) {
              if (data.row.raw.rowType == "year") {
                // draw only logo + year
                try {
                  let td = data.cell.raw;
                  console.log(td)
                  var img = new Image();
                  img.src = td;
                  let cellWidth = data.cell.width;
                  let cellHeight = data.cell.height;
                  let imgHeight = 23;
                  let imgWidth = 80;
                  let x = data.cell.x + (cellWidth - imgWidth) / 2;
                  let y = data.cell.y + (cellHeight - imgHeight) / 2;
                  doc.addImage(img, "PNG", x, y+2, imgWidth, imgHeight);
                  doc.text(`${data.row.raw.year}`, x+(cellWidth/2)-5, y);
                } catch (error) {
                  console.log(error);
                }
              } else {
                // draw only logo
                try {
                  let td = data.cell.raw;
                  var img = new Image();
                  img.src = td;
                  let cellWidth = data.cell.width;
                  let cellHeight = data.cell.height;
                  let imgHeight = 23;
                  let imgWidth = 80;
                  let x = data.cell.x + (cellWidth - imgWidth) / 2;
                  let y = data.cell.y + (cellHeight - imgHeight) / 2;
                  doc.addImage(img, "PNG", x, y, imgWidth, imgHeight);
                } catch (erro) {
                  console.log(erro);
                }
              }
            }
          },
          headStyles: {
            fillColor: "#e6e6e6",
            lineWidth: 1,
            fontSize: 10,
            textColor: 0,
            lineColor: "#1a1a1a",
            cellPadding: 10,
            halign: "center",
            valign: "middle",
          },
          theme: "plain",
          columnStyles: {
            0: { cellWidth: 80 }, // Ajuster automatiquement la première colonne
            1: { cellWidth: 45 }, // Ajuster automatiquement la deuxième colonne
            2: { cellWidth: 45 }, // Ajuster automatiquement la première colonne
            3: { cellWidth: 45 }, // Ajuster automatiquement la deuxième colonne
            4: { cellWidth: 45 }, // Ajuster automatiquement la première colonne
            5: { cellWidth: 45 }, // Ajuster automatiquement la deuxième colonne
            6: { cellWidth: 45 }, // Ajuster automatiquement la deuxième colonne
          },
        });
        doc.addImage(
          imageSeparateurURL,
          "PNG",
          doc.autoTable.previous.settings.margin.left +
            doc.autoTable.previous.settings.tableWidth +
            5,
          doc.autoTable.previous.finalY / 2 +
            doc.autoTable.previous.settings.startY / 2,
          20,
          20
        );

        doc.setFontSize(12);
        doc.setTextColor(24, 24, 24);
        doc.text("CAPACITE", 450, 93);
        doc.autoTable({
          startY: 100,
          tableWidth: 250,
          margin: {
            left:
              doc.autoTable.previous.settings.margin.left +
              doc.autoTable.previous.settings.tableWidth +
              35,
          },
          columns: this.columns2,
          body: dataCAPA,
          //margin: { top: 20, left: 10, right: 10 },
          bodyStyles: {
            fillColor: "#f3f4f6", //#1a1a1a
            lineWidth: 1, // Épaisseur de la bordure
            lineColor: "#1a1a1a", //ffffff
            textColor: "#1a1a1a",
            valign: "middle",
            halign: "center",
            minCellHeight: 40,
          },
          didParseCell: function (data) {
            // Définir la taille du texte de la cellule en fonction de la ligne et de la colonne
            if (
              data.row.index >= 0 &&
              data.section == "body" &&
              data.column.index > 0
            ) {
              data.cell.styles.fontSize = 10; // Taille de police en points pour la cellule (0, 0)
            }
          },
          didDrawCell: async function (data) {
            if (
              data.row.index >= 0 &&
              data.column.index === 0 &&
              data.cell.section === "body"
            ) {
              try {
                let td = data.cell.raw;

                var img = new Image();
                img.src = td;
                let dim1 = 30;
                let dim2 = 75;
                doc.addImage(
                  img,
                  "PNG",
                  data.cell.x + 15,
                  data.cell.y + 5,
                  dim2,
                  dim1
                );
              } catch (erro) {
                console.log(erro);
              }
            }
          },
          headStyles: {
            fillColor: "#e6e6e6",
            lineWidth: 1,
            fontSize: 10,
            textColor: 0,
            lineColor: "#1a1a1a",
            cellPadding: 10,
            halign: "center",
            valign: "middle",
          },
          theme: "plain",
          columnStyles: {
            0: { cellWidth: 50 }, // Ajuster automatiquement la première colonne
            1: { cellWidth: 50 }, // Ajuster automatiquement la deuxième colonne
            2: { cellWidth: 50 }, // Ajuster automatiquement la première colonne
            3: { cellWidth: 50 }, // Ajuster automatiquement la deuxième colonne
            4: { cellWidth: 50 }, // Ajuster automatiquement la première colonne
          },
        });
        doc.addImage(
          imageSeparateurURL,
          "PNG",
          doc.autoTable.previous.settings.margin.left +
            doc.autoTable.previous.settings.tableWidth +
            5,
          doc.autoTable.previous.finalY / 2 +
            doc.autoTable.previous.settings.startY / 2,
          20,
          20
        );

        doc.autoTable({
          startY: 100,
          margin: {
            left:
              doc.autoTable.previous.settings.margin.left +
              doc.autoTable.previous.settings.tableWidth +
              35,
          },
          tableWidth: 45,
          columns: [
            {
              header: "CEE\n€/MWh",
              dataKey: "cee",
              columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
            },
          ],
          body: dataCEE,
          //margin: { top: 20, left: 10, right: 10 },
          bodyStyles: {
            fillColor: "#f3f4f6", //#1a1a1a
            lineWidth: 1, // Épaisseur de la bordure
            lineColor: "#1a1a1a", //ffffff
            textColor: "#1a1a1a",
            valign: "middle",
            halign: "center",
            minCellHeight: 40,
          },
          didParseCell: function (data) {
            // Définir la taille du texte de la cellule en fonction de la ligne et de la colonne
            if (
              data.row.index >= 0 &&
              data.section == "body" &&
              data.column.index > 0
            ) {
              data.cell.styles.fontSize = 20; // Taille de police en points pour la cellule (0, 0)
            }
          },
          headStyles: {
            fillColor: "#e6e6e6",
            lineWidth: 1,
            fontSize: 10,
            textColor: 0,
            lineColor: "#1a1a1a",
            cellPadding: 10,
            halign: "center",
            valign: "middle",
          },
          theme: "plain",
          columnStyles: {
            0: { cellWidth: 45 }, // Ajuster automatiquement la première colonne
          },
        });
        doc.addImage(
          imageSeparateurURL,
          "PNG",
          doc.autoTable.previous.settings.margin.left +
            doc.autoTable.previous.settings.tableWidth +
            5,
          doc.autoTable.previous.finalY / 2 +
            doc.autoTable.previous.settings.startY / 2,
          20,
          20
        );

        doc.autoTable({
          startY: 100,
          margin: {
            left:
              doc.autoTable.previous.settings.margin.left +
              doc.autoTable.previous.settings.tableWidth +
              35,
          },
          tableWidth: 45,
          columns: [
            {
              header: "ABO\n€/an",
              dataKey: "abo",
              columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
            },
          ],
          body: dataABO,
          //margin: { top: 20, left: 10, right: 10 },
          bodyStyles: {
            fillColor: "#f3f4f6", //#1a1a1a
            lineWidth: 1, // Épaisseur de la bordure
            lineColor: "#1a1a1a", //ffffff
            textColor: "#1a1a1a",
            valign: "middle",
            halign: "center",
            minCellHeight: 40,
          },
          didParseCell: function (data) {
            // Définir la taille du texte de la cellule en fonction de la ligne et de la colonne
            if (
              data.row.index >= 0 &&
              data.section == "body" &&
              data.column.index > 0
            ) {
              data.cell.styles.fontSize = 20; // Taille de police en points pour la cellule (0, 0)
            }
          },

          headStyles: {
            fillColor: "#e6e6e6",
            lineWidth: 1,
            fontSize: 10,
            textColor: 0,
            lineColor: "#1a1a1a",
            cellPadding: 10,
            halign: "center",
            valign: "middle",
          },
          theme: "plain",
          columnStyles: {
            0: { cellWidth: 45 }, // Ajuster automatiquement la première colonne
          },
        });
        let arrayBuffer = doc.output("arraybuffer");
        const pdfBytes = await PDFDocument.load(arrayBuffer);
        const [copiedPage] = await PDFDoc.copyPages(pdfBytes, [0]);
        copiedPage.setSize(copiedPage.getWidth(), copiedPage.getHeight());
        PDFDoc.addPage(copiedPage);
        if (endIndex === dataToDisplay.length) {
          break; // Arrêter la boucle si nous avons atteint la fin des données
        }
      }
    },
    async createPDFWithMultipleTablesSansPointe(
      dataList,
      PDFDoc,
      typeCompteur
    ) {
      //POINTE

      // extract fournisseur of typeYears == 'precie' to years
      let dataToDisplay = []
      for(let i=0; i<dataList.length;i++){
        let item=dataList[i];
        if(item.typeYears == "precie"){
          dataToDisplay.push(item)
          Object.keys(item).filter(key => /^\d{4}$/.test(key)) // Filter keys that are 4-digit numbers (years)
            .forEach(year => {
              dataToDisplay.push({
                ...item[year],
                rowType: "year",
                year: year,
              })
            });
        }else{
          dataToDisplay.push(item)
        } 
      }

      const rowsPerPage = 5; // Nombre maximum d'éléments par tableau
      const totalPages = Math.ceil(dataToDisplay.length / rowsPerPage); // Nombre total de pages nécessaires

      for (let pageIndex = 0; pageIndex < totalPages; pageIndex++) {
        /*if (pageIndex > 0) {
          doc.addPage(); // Ajouter une nouvelle page pour chaque page supplémentaire
          startY = 10; // Réinitialiser la position Y de départ pour les nouvelles pages
        }*/
        const doc = new jsPDF({
          orientation: "l",
          unit: "px",
          format: [1025, 578],
        });

        let imageUrl = "/imagePres/background_offre_volt_white.png";
        let backgroundImage = new Image();
        backgroundImage.src = imageUrl;
        this.addBackgroundImage(doc, backgroundImage);

        let imageSeparateur = "/imagePres/separateur_tableau_white.png";
        let imageSeparateurURL = new Image();
        imageSeparateurURL.src = imageSeparateur;

        const startIndex = pageIndex * rowsPerPage;
        const endIndex = Math.min(startIndex + rowsPerPage, dataToDisplay.length);
        const currentData = dataToDisplay.slice(startIndex, endIndex);
        const data = [];
        const dataCAPA = [];
        const dataCEE = [];
        const dataABO = [];
        let x = 50;
        for (let i = 0; i < currentData.length; i++) {
          let dataObject = {
            fournisseur: `/logoParticulier/founisseur_logos/${currentData[i]["logo"]}`,
            hph: currentData[i]["unitairehph"]?.toLocaleString("fr-FR"),
            hch: currentData[i]["unitairehch"]?.toLocaleString("fr-FR"),
            hpe: currentData[i]["unitairehpe"]?.toLocaleString("fr-FR"),

            hce: currentData[i]["unitairehce"]?.toLocaleString("fr-FR"),
            //total: Number(currentData[i]).toLocaleString("fr-FR"),
          };
          if (currentData[i]["rowType"] == 'year') {
            dataObject['year'] = currentData[i]["year"];
            dataObject['rowType'] = "year";
          }

          let capaHPHToAffiche = currentData[i]["capa_hph"];
          let capaHCHToAffiche = currentData[i]["capa_hch"];
          let capaHPEToAffiche = currentData[i]["capa_hpe"];
          let capaHCEToAffiche = currentData[i]["capa_hce"];
          if (currentData[i]["budgetCapa"] == 0) {
            capaHPHToAffiche = "inclus";
            capaHCHToAffiche = "inclus";
            capaHPEToAffiche = "inclus";
            capaHCEToAffiche = "inclus";
          } else {
            if (currentData[i]["capa_hph"] == "-") {
              capaHPHToAffiche = "inclus";
            }
            if (currentData[i]["capa_hch"] == "-") {
              capaHCHToAffiche = "inclus";
            }
            if (currentData[i]["capa_hpe"] == "-") {
              capaHPEToAffiche = "inclus";
            }
            if (currentData[i]["capa_hce"] == "-") {
              capaHCEToAffiche = "inclus";
            }
          }
          let dataObjectCapa = {
            capahph: capaHPHToAffiche?.toLocaleString("fr-FR"),
            capahch: capaHCHToAffiche?.toLocaleString("fr-FR"),
            capahpe: capaHPEToAffiche?.toLocaleString("fr-FR"),
            capahce: capaHCEToAffiche?.toLocaleString("fr-FR"),
          };
          let dataObjectCEE = {
            cee: currentData[i]["cee"],
          };
          let dataObjectABO = {
            abo: currentData[i]["aboAns"],
          };
          data.push(dataObject);
          /*if (typeCompteur != "C5BASE" || typeCompteur != "C5HP") {
            delete dataObjectCapa["capap"];
          }*/
          dataCAPA.push(dataObjectCapa);
          dataCEE.push(dataObjectCEE);
          dataABO.push(dataObjectABO);
        }

        doc.setFontSize(12);
        doc.setTextColor(24, 24, 24);
        doc.text("FOURNITURE", 175, 93);
        doc.autoTable({
          margin: {
            top: 100,
            left: 15,
          },
          tableWidth: 300,
          columns: this.columnsSansPointe,
          body: data,
          //margin: { top: 20, left: 10, right: 10 },
          bodyStyles: {
            fillColor: "#f3f4f6", //#1a1a1a
            lineWidth: 1, // Épaisseur de la bordure
            lineColor: "#1a1a1a", //ffffff
            textColor: "#1a1a1a",
            valign: "middle",
            halign: "center",
            minCellHeight: 40,
          },
          didParseCell: function (data) {
            // Définir la taille du texte de la cellule en fonction de la ligne et de la colonne
            if (
              data.row.index >= 0 &&
              data.section == "body" &&
              data.column.index > 0
            ) {
              data.cell.styles.fontSize = 10; // Taille de police en points pour la cellule (0, 0)
            }
            if (
              data.row.index >= 0 &&
              data.column.index === 0 &&
              data.cell.section === "body"
            ) {
              data.cell.text = [];
              data.cell.styles.fillColor = "#e6e6e6";
              //data.cell.styles.fillColor = "#ffffff";
            }
          },
          didDrawCell: async function (data) {
            if (
              data.row.index >= 0 &&
              data.column.index === 0 &&
              data.cell.section === "body"
            ) {
              if (data.row.raw.rowType == "year") {
                // draw only logo + year
                try {
                  let td = data.cell.raw;
                  console.log(td)
                  var img = new Image();
                  img.src = td;
                  let cellWidth = data.cell.width;
                  let cellHeight = data.cell.height;
                  let imgHeight = 23;
                  let imgWidth = 80;
                  let x = data.cell.x + (cellWidth - imgWidth) / 2;
                  let y = data.cell.y + (cellHeight - imgHeight) / 2;
                  doc.addImage(img, "PNG", x, y+2, imgWidth, imgHeight);
                  doc.text(`${data.row.raw.year}`, x+(cellWidth/2)-5, y);
                } catch (error) {
                  console.log(error);
                }
              } else {
                // draw only logo
                try {
                  let td = data.cell.raw;
                  var img = new Image();
                  img.src = td;
                  let cellWidth = data.cell.width;
                  let cellHeight = data.cell.height;
                  let imgHeight = 23;
                  let imgWidth = 80;
                  let x = data.cell.x + (cellWidth - imgWidth) / 2;
                  let y = data.cell.y + (cellHeight - imgHeight) / 2;
                  doc.addImage(img, "PNG", x, y, imgWidth, imgHeight);
                } catch (erro) {
                  console.log(erro);
                }
              }
            }
          },
          headStyles: {
            fillColor: "#e6e6e6",
            lineWidth: 1,
            fontSize: 10,
            textColor: 0,
            lineColor: "#1a1a1a",
            cellPadding: 10,
            halign: "center",
            valign: "middle",
          },
          theme: "plain",
          columnStyles: {
            0: { cellWidth: 80 }, // Ajuster automatiquement la première colonne
            1: { cellWidth: 55 }, // Ajuster automatiquement la deuxième colonne
            2: { cellWidth: 55 }, // Ajuster automatiquement la première colonne
            3: { cellWidth: 55 }, // Ajuster automatiquement la deuxième colonne
            4: { cellWidth: 55 }, // Ajuster automatiquement la première colonne
          },
        });
        doc.addImage(
          imageSeparateurURL,
          "PNG",
          doc.autoTable.previous.settings.margin.left +
            doc.autoTable.previous.settings.tableWidth +
            5,
          doc.autoTable.previous.finalY / 2 +
            doc.autoTable.previous.settings.startY / 2,
          20,
          20
        );
        doc.setFontSize(12);
        doc.setTextColor(24, 24, 24);
        doc.text("CAPACITE", 440, 93);
        doc.autoTable({
          startY: 100,
          tableWidth: 220, // typeCompteur == "C5HP" || typeCompteur == "C5BASE" ? 250 : 200,
          margin: {
            left:
              doc.autoTable.previous.settings.margin.left +
              doc.autoTable.previous.settings.tableWidth +
              35,
          },
          columns: this.columns2SansPointe,
          body: dataCAPA,
          //margin: { top: 20, left: 10, right: 10 },
          bodyStyles: {
            fillColor: "#f3f4f6", //#1a1a1a
            lineWidth: 1, // Épaisseur de la bordure
            lineColor: "#1a1a1a", //ffffff
            textColor: "#1a1a1a",
            valign: "middle",
            halign: "center",
            minCellHeight: 40,
          },
          didParseCell: function (data) {
            // Définir la taille du texte de la cellule en fonction de la ligne et de la colonne
            if (
              data.row.index >= 0 &&
              data.section == "body" &&
              data.column.index > 0
            ) {
              data.cell.styles.fontSize = 10; // Taille de police en points pour la cellule (0, 0)
            }
          },
          didDrawCell: async function (data) {
            if (
              data.row.index >= 0 &&
              data.column.index === 0 &&
              data.cell.section === "body"
            ) {
              try {
                let td = data.cell.raw;

                var img = new Image();
                img.src = td;
                let dim1 = 30;
                let dim2 = 75;
                doc.addImage(
                  img,
                  "PNG",
                  data.cell.x + 15,
                  data.cell.y + 5,
                  dim2,
                  dim1
                );
              } catch (erro) {
                console.log(erro);
              }
            }
          },
          headStyles: {
            fillColor: "#e6e6e6",
            lineWidth: 1,
            fontSize: 10,
            textColor: 0,
            lineColor: "#1a1a1a",
            cellPadding: 10,
            halign: "center",
            valign: "middle",
          },
          theme: "plain",
          columnStyles: {
            0: { cellWidth: 55 }, // Ajuster automatiquement la première colonne
            1: { cellWidth: 55 }, // Ajuster automatiquement la deuxième colonne
            2: { cellWidth: 55 }, // Ajuster automatiquement la première colonne
            3: { cellWidth: 55 }, // Ajuster automatiquement la deuxième colonne
          },
        });
        doc.addImage(
          imageSeparateurURL,
          "PNG",
          doc.autoTable.previous.settings.margin.left +
            doc.autoTable.previous.settings.tableWidth +
            5,
          doc.autoTable.previous.finalY / 2 +
            doc.autoTable.previous.settings.startY / 2,
          20,
          20
        );

        doc.autoTable({
          startY: 100,
          margin: {
            left:
              doc.autoTable.previous.settings.margin.left +
              doc.autoTable.previous.settings.tableWidth +
              35,
          },
          tableWidth: 55,
          columns: [
            {
              header: "CEE\n€/MWh",
              dataKey: "cee",
              columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
            },
          ],
          body: dataCEE,
          //margin: { top: 20, left: 10, right: 10 },
          bodyStyles: {
            fillColor: "#f3f4f6", //#1a1a1a
            lineWidth: 1, // Épaisseur de la bordure
            lineColor: "#1a1a1a", //ffffff
            textColor: "#1a1a1a",
            valign: "middle",
            halign: "center",
            minCellHeight: 40,
          },
          didParseCell: function (data) {
            // Définir la taille du texte de la cellule en fonction de la ligne et de la colonne
            if (
              data.row.index >= 0 &&
              data.section == "body" &&
              data.column.index > 0
            ) {
              data.cell.styles.fontSize = 20; // Taille de police en points pour la cellule (0, 0)
            }
          },
          headStyles: {
            fillColor: "#e6e6e6",
            lineWidth: 1,
            fontSize: 10,
            textColor: 0,
            lineColor: "#1a1a1a",
            cellPadding: 10,
            halign: "center",
            valign: "middle",
          },
          theme: "plain",
          columnStyles: {
            0: { cellWidth: 55 }, // Ajuster automatiquement la première colonne
          },
        });
        doc.addImage(
          imageSeparateurURL,
          "PNG",
          doc.autoTable.previous.settings.margin.left +
            doc.autoTable.previous.settings.tableWidth +
            5,
          doc.autoTable.previous.finalY / 2 +
            doc.autoTable.previous.settings.startY / 2,
          20,
          20
        );

        doc.autoTable({
          startY: 100,
          margin: {
            left:
              doc.autoTable.previous.settings.margin.left +
              doc.autoTable.previous.settings.tableWidth +
              35,
          },
          tableWidth: 55,
          columns: [
            {
              header: "ABO\n€/an",
              dataKey: "abo",
              columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
            },
          ],
          body: dataABO,
          //margin: { top: 20, left: 10, right: 10 },
          bodyStyles: {
            fillColor: "#f3f4f6", //#1a1a1a
            lineWidth: 1, // Épaisseur de la bordure
            lineColor: "#1a1a1a", //ffffff
            textColor: "#1a1a1a",
            valign: "middle",
            halign: "center",
            minCellHeight: 40,
          },
          didParseCell: function (data) {
            // Définir la taille du texte de la cellule en fonction de la ligne et de la colonne
            if (
              data.row.index >= 0 &&
              data.section == "body" &&
              data.column.index > 0
            ) {
              data.cell.styles.fontSize = 20; // Taille de police en points pour la cellule (0, 0)
            }
          },

          headStyles: {
            fillColor: "#e6e6e6",
            lineWidth: 1,
            fontSize: 10,
            textColor: 0,
            lineColor: "#1a1a1a",
            cellPadding: 10,
            halign: "center",
            valign: "middle",
          },
          theme: "plain",
          columnStyles: {
            0: { cellWidth: 55 }, // Ajuster automatiquement la première colonne
          },
        });
        let arrayBuffer = doc.output("arraybuffer");
        const pdfBytes = await PDFDocument.load(arrayBuffer);
        const [copiedPage] = await PDFDoc.copyPages(pdfBytes, [0]);
        copiedPage.setSize(copiedPage.getWidth(), copiedPage.getHeight());
        PDFDoc.addPage(copiedPage);
        if (endIndex === dataToDisplay.length) {
          break; // Arrêter la boucle si nous avons atteint la fin des données
        }
      }
    },
    async createPDFWithMultipleTablesHPHC(dataList, PDFDoc, typeCompteur) {
      //HP & HC valeur A REMPLACER

      // extract fournisseur of typeYears == 'precie' to years
      let dataToDisplay = []
      for(let i=0; i<dataList.length;i++){
        let item=dataList[i];
        if(item.typeYears == "precie"){
          dataToDisplay.push(item)
          Object.keys(item).filter(key => /^\d{4}$/.test(key)) // Filter keys that are 4-digit numbers (years)
            .forEach(year => {
              dataToDisplay.push({
                ...item[year],
                rowType: "year",
                year: year,
              })
            });
        }else{
          dataToDisplay.push(item)
        } 
      }
      const rowsPerPage = 5; // Nombre maximum d'éléments par tableau
      const totalPages = Math.ceil(dataToDisplay.length / rowsPerPage); // Nombre total de pages nécessaires

      for (let pageIndex = 0; pageIndex < totalPages; pageIndex++) {
        /*if (pageIndex > 0) {
          doc.addPage(); // Ajouter une nouvelle page pour chaque page supplémentaire
          startY = 10; // Réinitialiser la position Y de départ pour les nouvelles pages
        }*/
        const doc = new jsPDF({
          orientation: "l",
          unit: "px",
          format: [1025, 578],
        });

        let imageUrl = "/imagePres/background_offre_volt_white.png";
        let backgroundImage = new Image();
        backgroundImage.src = imageUrl;
        this.addBackgroundImage(doc, backgroundImage);

        let imageSeparateur = "/imagePres/separateur_tableau_white.png";
        let imageSeparateurURL = new Image();
        imageSeparateurURL.src = imageSeparateur;

        const startIndex = pageIndex * rowsPerPage;
        const endIndex = Math.min(startIndex + rowsPerPage, dataToDisplay.length);
        const currentData = dataToDisplay.slice(startIndex, endIndex);
        const data = [];
        const dataCAPA = [];
        const dataCEE = [];
        const dataABO = [];
        let x = 50;
        for (let i = 0; i < currentData.length; i++) {
          let dataObject = {
            fournisseur: `/logoParticulier/founisseur_logos/${currentData[i]["logo"]}`,
            hp: currentData[i]["unitairehp"]?.toLocaleString("fr-FR"),
            hc: currentData[i]["unitairehc"]?.toLocaleString("fr-FR"),
          };
          if (currentData[i]["rowType"] == 'year') {
            dataObject['year'] = currentData[i]["year"];
            dataObject['rowType'] = "year";
          }

          let capaHPToAffiche = currentData[i]["capa_hp"];
          let capaHCToAffiche = currentData[i]["capa_hc"];
          if (currentData[i]["budgetCapa"] == 0) {
            capaHPToAffiche = "inclus";
            capaHCToAffiche = "inclus";
          } else {
            if (currentData[i]["capa_hp"] == "-") {
              capaHPToAffiche = "inclus";
            }
            if (currentData[i]["capa_hc"] == "-") {
              capaHCToAffiche = "inclus";
            }
          }
          let dataObjectCapa = {
            capahp: capaHPToAffiche?.toLocaleString("fr-FR"),
            capahc: capaHCToAffiche?.toLocaleString("fr-FR"),
          };

          let dataObjectCEE = {
            cee: currentData[i]["cee"],
          };
          let dataObjectABO = {
            abo: currentData[i]["aboAns"],
          };
          data.push(dataObject);
          /*if (typeCompteur != "C5BASE" || typeCompteur != "C5HP") {
            delete dataObjectCapa["capap"];
          }*/
          dataCAPA.push(dataObjectCapa);
          dataCEE.push(dataObjectCEE);
          dataABO.push(dataObjectABO);
        }

        // Fournisseur LOGO + FOURNITURE
        doc.setFontSize(12);
        doc.setTextColor(24, 24, 24);
        doc.text("FOURNITURE", 240, 93);
        doc.autoTable({
          margin: {
            top: 100,
            left: 130,
          },
          tableWidth: 200,
          columns: this.columnsHPHC,
          body: data,
          //margin: { top: 20, left: 10, right: 10 },
          bodyStyles: {
            fillColor: "#f3f4f6", //#1a1a1a
            lineWidth: 1, // Épaisseur de la bordure
            lineColor: "#1a1a1a", //ffffff
            textColor: "#1a1a1a",
            valign: "middle",
            halign: "center",
            minCellHeight: 40,
          },
          didParseCell: function (data) {
            // Définir la taille du texte de la cellule en fonction de la ligne et de la colonne
            if (
              data.row.index >= 0 &&
              data.section == "body" &&
              data.column.index > 0
            ) {
              data.cell.styles.fontSize = 10; // Taille de police en points pour la cellule (0, 0)
            }
            if (
              data.row.index >= 0 &&
              data.column.index === 0 &&
              data.cell.section === "body"
            ) {
              data.cell.text = [];
              data.cell.styles.fillColor = "#e6e6e6";
            }
          },
          didDrawCell: async function (data) {
            if (
              data.row.index >= 0 &&
              data.column.index === 0 &&
              data.cell.section === "body"
            ) {
              if (data.row.raw.rowType == "year") {
                // draw only logo + year
                try {
                  let td = data.cell.raw;
                  console.log(td)
                  var img = new Image();
                  img.src = td;
                  let cellWidth = data.cell.width;
                  let cellHeight = data.cell.height;
                  let imgHeight = 23;
                  let imgWidth = 80;
                  let x = data.cell.x + (cellWidth - imgWidth) / 2;
                  let y = data.cell.y + (cellHeight - imgHeight) / 2;
                  doc.addImage(img, "PNG", x, y+2, imgWidth, imgHeight);
                  doc.text(`${data.row.raw.year}`, x+(cellWidth/2)-5, y);
                } catch (error) {
                  console.log(error);
                }
              } else {
                // draw only logo
                try {
                  let td = data.cell.raw;
                  var img = new Image();
                  img.src = td;
                  let cellWidth = data.cell.width;
                  let cellHeight = data.cell.height;
                  let imgHeight = 23;
                  let imgWidth = 80;
                  let x = data.cell.x + (cellWidth - imgWidth) / 2;
                  let y = data.cell.y + (cellHeight - imgHeight) / 2;
                  doc.addImage(img, "PNG", x, y, imgWidth, imgHeight);
                } catch (erro) {
                  console.log(erro);
                }
              }
            }
          },
          headStyles: {
            fillColor: "#e6e6e6",
            lineWidth: 1,
            fontSize: 10,
            textColor: 0,
            lineColor: "#1a1a1a",
            cellPadding: 10,
            halign: "center",
            valign: "middle",
          },
          theme: "plain",
          columnStyles: {
            0: { cellWidth: 80 }, // Ajuster automatiquement la première colonne
            1: { cellWidth: 60 }, // Ajuster automatiquement la deuxième colonne
            2: { cellWidth: 60 }, // Ajuster automatiquement la première colonne
          },
        });
        // plus image
        doc.addImage(
          imageSeparateurURL,
          "PNG",
          doc.autoTable.previous.settings.margin.left +
            doc.autoTable.previous.settings.tableWidth +
            5,
          doc.autoTable.previous.finalY / 2 +
            doc.autoTable.previous.settings.startY / 2,
          20,
          20
        );

        // CAPACITE
        doc.setFontSize(12);
        doc.setTextColor(24, 24, 24);
        doc.text("CAPACITE", 400, 93);
        doc.autoTable({
          startY: 100,
          tableWidth: 120, // typeCompteur == "C5HP" || typeCompteur == "C5BASE" ? 250 : 200,
          margin: {
            left:
              doc.autoTable.previous.settings.margin.left +
              doc.autoTable.previous.settings.tableWidth +
              35,
          },
          columns: this.columns2HPHC,
          body: dataCAPA,
          //margin: { top: 20, left: 10, right: 10 },
          bodyStyles: {
            fillColor: "#f3f4f6", //#1a1a1a
            lineWidth: 1, // Épaisseur de la bordure
            lineColor: "#1a1a1a", //ffffff
            textColor: "#1a1a1a",
            valign: "middle",
            halign: "center",
            minCellHeight: 40,
          },
          didParseCell: function (data) {
            // Définir la taille du texte de la cellule en fonction de la ligne et de la colonne
            if (
              data.row.index >= 0 &&
              data.section == "body" &&
              data.column.index > 0
            ) {
              data.cell.styles.fontSize = 10; // Taille de police en points pour la cellule (0, 0)
            }
          },
          didDrawCell: async function (data) {
            if (
              data.row.index >= 0 &&
              data.column.index === 0 &&
              data.cell.section === "body"
            ) {
              try {
                let td = data.cell.raw;

                var img = new Image();
                img.src = td;
                let dim1 = 30;
                let dim2 = 75;
                doc.addImage(
                  img,
                  "PNG",
                  data.cell.x + 15,
                  data.cell.y + 5,
                  dim2,
                  dim1
                );
              } catch (erro) {
                console.log(erro);
              }
            }
          },
          headStyles: {
            fillColor: "#e6e6e6",
            lineWidth: 1,
            fontSize: 10,
            textColor: 0,
            lineColor: "#1a1a1a",
            cellPadding: 10,
            halign: "center",
            valign: "middle",
          },
          theme: "plain",
          columnStyles: {
            0: { cellWidth: 60 }, // Ajuster automatiquement la première colonne
            1: { cellWidth: 60 }, // Ajuster automatiquement la deuxième colonne
          },
        });
        // plus image
        doc.addImage(
          imageSeparateurURL,
          "PNG",
          doc.autoTable.previous.settings.margin.left +
            doc.autoTable.previous.settings.tableWidth +
            5,
          doc.autoTable.previous.finalY / 2 +
            doc.autoTable.previous.settings.startY / 2,
          20,
          20
        );

        // CEE column
        doc.autoTable({
          startY: 100,
          margin: {
            left:
              doc.autoTable.previous.settings.margin.left +
              doc.autoTable.previous.settings.tableWidth +
              35,
          },
          tableWidth: 60,
          columns: [
            {
              header: "CEE\n€/MWh",
              dataKey: "cee",
              columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
            },
          ],
          body: dataCEE,
          //margin: { top: 20, left: 10, right: 10 },
          bodyStyles: {
            fillColor: "#f3f4f6", //#1a1a1a
            lineWidth: 1, // Épaisseur de la bordure
            lineColor: "#1a1a1a", //ffffff
            textColor: "#1a1a1a",
            valign: "middle",
            halign: "center",
            minCellHeight: 40,
          },
          didParseCell: function (data) {
            // Définir la taille du texte de la cellule en fonction de la ligne et de la colonne
            if (
              data.row.index >= 0 &&
              data.section == "body" &&
              data.column.index > 0
            ) {
              data.cell.styles.fontSize = 20; // Taille de police en points pour la cellule (0, 0)
            }
          },
          headStyles: {
            fillColor: "#e6e6e6",
            lineWidth: 1,
            fontSize: 10,
            textColor: 0,
            lineColor: "#1a1a1a",
            cellPadding: 10,
            halign: "center",
            valign: "middle",
          },
          theme: "plain",
          columnStyles: {
            0: { cellWidth: 60 }, // Ajuster automatiquement la première colonne
          },
        });
        // plus image
        doc.addImage(
          imageSeparateurURL,
          "PNG",
          doc.autoTable.previous.settings.margin.left +
            doc.autoTable.previous.settings.tableWidth +
            5,
          doc.autoTable.previous.finalY / 2 +
            doc.autoTable.previous.settings.startY / 2,
          20,
          20
        );

        // ABO column
        doc.autoTable({
          startY: 100,
          margin: {
            left:
              doc.autoTable.previous.settings.margin.left +
              doc.autoTable.previous.settings.tableWidth +
              35,
          },
          tableWidth: 60,
          columns: [
            {
              header: "ABO\n€/an",
              dataKey: "abo",
              columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
            },
          ],
          body: dataABO,
          //margin: { top: 20, left: 10, right: 10 },
          bodyStyles: {
            fillColor: "#f3f4f6", //#1a1a1a
            lineWidth: 1, // Épaisseur de la bordure
            lineColor: "#1a1a1a", //ffffff
            textColor: "#1a1a1a",
            valign: "middle",
            halign: "center",
            minCellHeight: 40,
          },
          didParseCell: function (data) {
            // Définir la taille du texte de la cellule en fonction de la ligne et de la colonne
            if (
              data.row.index >= 0 &&
              data.section == "body" &&
              data.column.index > 0
            ) {
              data.cell.styles.fontSize = 20; // Taille de police en points pour la cellule (0, 0)
            }
          },

          headStyles: {
            fillColor: "#e6e6e6",
            lineWidth: 1,
            fontSize: 10,
            textColor: 0,
            lineColor: "#1a1a1a",
            cellPadding: 10,
            halign: "center",
            valign: "middle",
          },
          theme: "plain",
          columnStyles: {
            0: { cellWidth: 60 }, // Ajuster automatiquement la première colonne
          },
        });
        let arrayBuffer = doc.output("arraybuffer");
        const pdfBytes = await PDFDocument.load(arrayBuffer);
        const [copiedPage] = await PDFDoc.copyPages(pdfBytes, [0]);
        copiedPage.setSize(copiedPage.getWidth(), copiedPage.getHeight());
        PDFDoc.addPage(copiedPage);
        if (endIndex === dataToDisplay.length) {
          break; // Arrêter la boucle si nous avons atteint la fin des données
        }
      }
    },
    async createPDFWithMultipleTablesBASE(dataList, PDFDoc, typeCompteur) {
      //HP & HC valeur A REMPLACER

      // extract fournisseur of typeYears == 'precie' to years
      let dataToDisplay = []
      for(let i=0; i<dataList.length;i++){
        let item=dataList[i];
        if(item.typeYears == "precie"){
          dataToDisplay.push(item)
          Object.keys(item).filter(key => /^\d{4}$/.test(key)) // Filter keys that are 4-digit numbers (years)
            .forEach(year => {
              dataToDisplay.push({
                ...item[year],
                rowType: "year",
                year: year,
              })
            });
        }else{
          dataToDisplay.push(item)
        } 
      }
      
      const rowsPerPage = 5; // Nombre maximum d'éléments par tableau
      const totalPages = Math.ceil(dataToDisplay.length / rowsPerPage); // Nombre total de pages nécessaires

      for (let pageIndex = 0; pageIndex < totalPages; pageIndex++) {
        /*if (pageIndex > 0) {
          doc.addPage(); // Ajouter une nouvelle page pour chaque page supplémentaire
          startY = 10; // Réinitialiser la position Y de départ pour les nouvelles pages
        }*/
        const doc = new jsPDF({
          orientation: "l",
          unit: "px",
          format: [1025, 578],
        });

        let imageUrl = "/imagePres/background_offre_volt_white.png";
        let backgroundImage = new Image();
        backgroundImage.src = imageUrl;
        this.addBackgroundImage(doc, backgroundImage);

        let imageSeparateur = "/imagePres/separateur_tableau_white.png";
        let imageSeparateurURL = new Image();
        imageSeparateurURL.src = imageSeparateur;

        const startIndex = pageIndex * rowsPerPage;
        const endIndex = Math.min(startIndex + rowsPerPage, dataToDisplay.length);
        const currentData = dataToDisplay.slice(startIndex, endIndex);
        const data = [];
        const dataCAPA = [];
        const dataCEE = [];
        const dataABO = [];
        let x = 50;
        for (let i = 0; i < currentData.length; i++) {
          let dataObject = {
            fournisseur: `/logoParticulier/founisseur_logos/${currentData[i]["logo"]}`,
            base: currentData[i]["unitaire"],
          };
          if (currentData[i]["rowType"] == 'year') {
            dataObject['year'] = currentData[i]["year"];
            dataObject['rowType'] = "year";
          }

          let capaBaseToAffiche = currentData[i]["capa_base"];
          if (currentData[i]["budgetCapa"] == 0) {
            capaBaseToAffiche = "inclus";
          } else if (currentData[i]["capa_base"] == "-") {
            capaBaseToAffiche = "inclus";
          }
          let dataObjectCapa = {
            capabase: capaBaseToAffiche,
          };

          let dataObjectCEE = {
            cee: currentData[i]["cee"],
          };
          let dataObjectABO = {
            abo: currentData[i]["aboAns"],
          };
          data.push(dataObject);
          /*if (typeCompteur != "C5BASE" || typeCompteur != "C5HP") {
            delete dataObjectCapa["capap"];
          }*/

          dataCAPA.push(dataObjectCapa);
          dataCEE.push(dataObjectCEE);
          dataABO.push(dataObjectABO);
        }

        // Fournisseur LOGO + FOURNITURE
        doc.setFontSize(12);
        doc.setTextColor(24, 24, 24);
        doc.text("FOURNITURE", 235, 93);
        doc.autoTable({
          margin: {
            top: 100,
            left: 145,
          },
          tableWidth: 160,
          columns: this.columnsBASE,
          body: data,
          //margin: { top: 20, left: 10, right: 10 },
          bodyStyles: {
            fillColor: "#f3f4f6", //#1a1a1a
            lineWidth: 1, // Épaisseur de la bordure
            lineColor: "#1a1a1a", //ffffff
            textColor: "#1a1a1a",
            valign: "middle",
            halign: "center",
            minCellHeight: 40,
          },
          didParseCell: function (data) {
            // Définir la taille du texte de la cellule en fonction de la ligne et de la colonne
            if (
              data.row.index >= 0 &&
              data.section == "body" &&
              data.column.index > 0
            ) {
              data.cell.styles.fontSize = 10; // Taille de police en points pour la cellule (0, 0)
            }
            if (
              data.row.index >= 0 &&
              data.column.index === 0 &&
              data.cell.section === "body"
            ) {
              data.cell.text = [];
              data.cell.styles.fillColor = "#e6e6e6";
            }
          },
          didDrawCell: async function (data) {
            if (
              data.row.index >= 0 &&
              data.column.index === 0 &&
              data.cell.section === "body"
            ) {
              if (data.row.raw.rowType == "year") {
                // draw only logo + year
                try {
                  let td = data.cell.raw;
                  console.log(td)
                  var img = new Image();
                  img.src = td;
                  let cellWidth = data.cell.width;
                  let cellHeight = data.cell.height;
                  let imgHeight = 23;
                  let imgWidth = 80;
                  let x = data.cell.x + (cellWidth - imgWidth) / 2;
                  let y = data.cell.y + (cellHeight - imgHeight) / 2;
                  doc.addImage(img, "PNG", x, y+2, imgWidth, imgHeight);
                  doc.text(`${data.row.raw.year}`, x+(cellWidth/2)-5, y);
                } catch (error) {
                  console.log(error);
                }
              } else {
                // draw only logo
                try {
                  let td = data.cell.raw;
                  var img = new Image();
                  img.src = td;
                  let cellWidth = data.cell.width;
                  let cellHeight = data.cell.height;
                  let imgHeight = 23;
                  let imgWidth = 80;
                  let x = data.cell.x + (cellWidth - imgWidth) / 2;
                  let y = data.cell.y + (cellHeight - imgHeight) / 2;
                  doc.addImage(img, "PNG", x, y, imgWidth, imgHeight);
                } catch (erro) {
                  console.log(erro);
                }
              }
            }
          },
          headStyles: {
            fillColor: "#e6e6e6",
            lineWidth: 1,
            fontSize: 10,
            textColor: 0,
            lineColor: "#1a1a1a",
            cellPadding: 10,
            halign: "center",
            valign: "middle",
          },
          theme: "plain",
          columnStyles: {
            0: { cellWidth: 80 }, // Ajuster automatiquement la première colonne
            1: { cellWidth: 80 }, // Ajuster automatiquement la deuxième colonne
          },
        });
        // plus image
        doc.addImage(
          imageSeparateurURL,
          "PNG",
          doc.autoTable.previous.settings.margin.left +
            doc.autoTable.previous.settings.tableWidth +
            5,
          doc.autoTable.previous.finalY / 2 +
            doc.autoTable.previous.settings.startY / 2,
          20,
          20
        );

        // CAPACITE
        doc.setFontSize(12);
        doc.setTextColor(24, 24, 24);
        doc.text("CAPACITE", 357, 93);
        doc.autoTable({
          startY: 100,
          tableWidth: 80, // typeCompteur == "C5HP" || typeCompteur == "C5BASE" ? 250 : 200,
          margin: {
            left:
              doc.autoTable.previous.settings.margin.left +
              doc.autoTable.previous.settings.tableWidth +
              35,
          },
          columns: this.columns2BASE,
          body: dataCAPA,
          //margin: { top: 20, left: 10, right: 10 },
          bodyStyles: {
            fillColor: "#f3f4f6", //#1a1a1a
            lineWidth: 1, // Épaisseur de la bordure
            lineColor: "#1a1a1a", //ffffff
            textColor: "#1a1a1a",
            valign: "middle",
            halign: "center",
            minCellHeight: 40,
          },
          didParseCell: function (data) {
            // Définir la taille du texte de la cellule en fonction de la ligne et de la colonne
            if (
              data.row.index >= 0 &&
              data.section == "body" &&
              data.column.index > 0
            ) {
              data.cell.styles.fontSize = 10; // Taille de police en points pour la cellule (0, 0)
            }
          },
          didDrawCell: async function (data) {
            if (
              data.row.index >= 0 &&
              data.column.index === 0 &&
              data.cell.section === "body"
            ) {
              try {
                let td = data.cell.raw;

                var img = new Image();
                img.src = td;
                let dim1 = 30;
                let dim2 = 75;
                doc.addImage(
                  img,
                  "PNG",
                  data.cell.x + 15,
                  data.cell.y + 5,
                  dim2,
                  dim1
                );
              } catch (erro) {
                console.log(erro);
              }
            }
          },
          headStyles: {
            fillColor: "#e6e6e6",
            lineWidth: 1,
            fontSize: 10,
            textColor: 0,
            lineColor: "#1a1a1a",
            cellPadding: 10,
            halign: "center",
            valign: "middle",
          },
          theme: "plain",
          columnStyles: {
            0: { cellWidth: 80 }, // Ajuster automatiquement la première colonne
          },
        });
        // plus image
        doc.addImage(
          imageSeparateurURL,
          "PNG",
          doc.autoTable.previous.settings.margin.left +
            doc.autoTable.previous.settings.tableWidth +
            5,
          doc.autoTable.previous.finalY / 2 +
            doc.autoTable.previous.settings.startY / 2,
          20,
          20
        );
        // CEE column
        doc.autoTable({
          startY: 100,
          margin: {
            left:
              doc.autoTable.previous.settings.margin.left +
              doc.autoTable.previous.settings.tableWidth +
              35,
          },
          tableWidth: 80,
          columns: [
            {
              header: "CEE\n€/MWh",
              dataKey: "cee",
              columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
            },
          ],
          body: dataCEE,
          //margin: { top: 20, left: 10, right: 10 },
          bodyStyles: {
            fillColor: "#f3f4f6", //#1a1a1a
            lineWidth: 1, // Épaisseur de la bordure
            lineColor: "#1a1a1a", //ffffff
            textColor: "#1a1a1a",
            valign: "middle",
            halign: "center",
            minCellHeight: 40,
          },
          didParseCell: function (data) {
            // Définir la taille du texte de la cellule en fonction de la ligne et de la colonne
            if (
              data.row.index >= 0 &&
              data.section == "body" &&
              data.column.index > 0
            ) {
              data.cell.styles.fontSize = 20; // Taille de police en points pour la cellule (0, 0)
            }
          },
          headStyles: {
            fillColor: "#e6e6e6",
            lineWidth: 1,
            fontSize: 10,
            textColor: 0,
            lineColor: "#1a1a1a",
            cellPadding: 10,
            halign: "center",
            valign: "middle",
          },
          theme: "plain",
          columnStyles: {
            0: { cellWidth: 80 }, // Ajuster automatiquement la première colonne
          },
        });
        // plus image
        doc.addImage(
          imageSeparateurURL,
          "PNG",
          doc.autoTable.previous.settings.margin.left +
            doc.autoTable.previous.settings.tableWidth +
            5,
          doc.autoTable.previous.finalY / 2 +
            doc.autoTable.previous.settings.startY / 2,
          20,
          20
        );
        // ABO column
        doc.autoTable({
          startY: 100,
          margin: {
            left:
              doc.autoTable.previous.settings.margin.left +
              doc.autoTable.previous.settings.tableWidth +
              35,
          },
          tableWidth: 80,
          columns: [
            {
              header: "ABO\n€/an",
              dataKey: "abo",
              columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
            },
          ],
          body: dataABO,
          //margin: { top: 20, left: 10, right: 10 },
          bodyStyles: {
            fillColor: "#f3f4f6", //#1a1a1a
            lineWidth: 1, // Épaisseur de la bordure
            lineColor: "#1a1a1a", //ffffff
            textColor: "#1a1a1a",
            valign: "middle",
            halign: "center",
            minCellHeight: 40,
          },
          didParseCell: function (data) {
            // Définir la taille du texte de la cellule en fonction de la ligne et de la colonne
            if (
              data.row.index >= 0 &&
              data.section == "body" &&
              data.column.index > 0
            ) {
              data.cell.styles.fontSize = 20; // Taille de police en points pour la cellule (0, 0)
            }
          },

          headStyles: {
            fillColor: "#e6e6e6",
            lineWidth: 1,
            fontSize: 10,
            textColor: 0,
            lineColor: "#1a1a1a",
            cellPadding: 10,
            halign: "center",
            valign: "middle",
          },
          theme: "plain",
          columnStyles: {
            0: { cellWidth: 80 }, // Ajuster automatiquement la première colonne
          },
        });
        let arrayBuffer = doc.output("arraybuffer");
        const pdfBytes = await PDFDocument.load(arrayBuffer);
        const [copiedPage] = await PDFDoc.copyPages(pdfBytes, [0]);
        copiedPage.setSize(copiedPage.getWidth(), copiedPage.getHeight());
        PDFDoc.addPage(copiedPage);
        if (endIndex === dataToDisplay.length) {
          break; // Arrêter la boucle si nous avons atteint la fin des données
        }
      }
    },
    filterArray(inputArr) {
      var found = {};
      var out = inputArr.filter(function (element) {
        return found.hasOwnProperty(element) ? false : (found[element] = true);
      });
      return out;
    },
    async drawOffres(form, pdfDoc, page, donner, fournisseurActuelle) {
      var line = await this.getImage("/imagePres/ligne_offre_prix.png", pdfDoc);
      //var yellow = await this.getImage("/imagePres/yellow.PNG", pdfDoc);
      //var tableLine = await this.getImage("/imagePres/tableYellowRecap.PNG", pdfDoc);
      //var green = await this.getImage("/imagePres/green.PNG", pdfDoc);
      //var greenLine = await this.getImage("/imagePres/tableGreenRecap.PNG", pdfDoc);
      var HelveticaBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
      var Helvetica = await pdfDoc.embedFont(StandardFonts.Helvetica);
      var y = fournisseurActuelle ? 260 : 330;
      /*page.drawText("Nos meilleures offres", {
        x: 65,
        y: y,
        font: HelveticaBold,
        size: 15,
        color: rgb(0.062, 0.227, 0.368),
      });*/
      var xBegin = donner["xBegin"];
      var yBegin = fournisseurActuelle ? 110 : 200;
      var actuelle = donner["actuelle"];
      var width = 515;

      var xForm = xBegin;
      var widthForm = 0;
      var value = "";
      var title = "";
      var subtitle = "";
      var fieldName = "";
      for (var i = 0; i < donner["titles"].length; i++) {
        //INDEX A CHANGER
        var data = donner["titles"][i];
        data["pas"] = 75;
        xForm = xBegin + data["pas"];
        widthForm = data["width"];
        value = data["value"];
        title = data["title"];
        subtitle = data["subTitle"];
        fieldName = data["fieldName"];
        await this.drawAlignedText({
          page: page,
          text: title,
          x: xForm + 35,
          y: yBegin + 220,
          font: Helvetica,
          size: 16,
          align: "center",
          textColor: [0, 0, 0],
          width: 175,
        });

        await this.drawAlignedText({
          page: page,
          text: subtitle,
          x: xForm + 35,
          y: yBegin + 205,
          font: Helvetica,
          size: 16,
          align: "center",
          textColor: [0, 0, 0],
          width: 175,
        });
        xBegin = xBegin + widthForm + 40 + data["pas"];
      }
      var xForm = xBegin;
      var widthForm = 68;
      var value = "";
      var fieldName = "";
      for (var i = 0; i < donner["data"].length; i++) {
        xBegin = donner["xBegin"];
        page.drawImage(line, {
          // ATTENTION
          x: xBegin - 80,
          y: yBegin + 140,
          width: 900,
          height: 50,
        });
        var itemData = donner["data"][i];
        var logo = await this.getImage(itemData[0]["value"], pdfDoc);
        page.drawImage(logo, {
          x: xBegin - 65,
          y: yBegin + 145,
          width: 137,
          height: 40,
          // width: 120,
          // height: 35,
        });
        /*switch (itemData[0]["value"]) {
          case "/logoParticulier/founisseur_logos/OHM_120X35.png":
            page.drawImage(logo, {
              x: xBegin - 45,
              y: yBegin + 145,
              width: 100,
              height: 40,
            });
            break;
          case "/logoParticulier/founisseur_logos/Ekwateur_120X35.png":
            page.drawImage(logo, {
              x: xBegin - 45,
              y: yBegin + 145,
              width: 100,
              height: 40,
            });
            break;
          default:
            page.drawImage(logo, {
              x: xBegin - 45,
              y: yBegin + 145,
              width: 100,
              height: 40,
            });
            break;
        }*/
        for (var j = 1; j < itemData.length - 1; j++) {
          // A CHANGER !!!!
          var data = itemData[j];
          xForm = xBegin + data["pas"];
          widthForm = 140;
          value = data["value"];
          fieldName = data["fieldName"];
          xForm = xBegin + data["pas"];
          // draw pourcentage
          if (i == 0 && j + 1 == itemData.length - 1 && fournisseurActuelle) {
            let prixOffreActuelle =
              this.OffreActuelleData.data[
                this.OffreActuelleData.data.length - 1
              ].value;
            let prixOffre = value;
            let resultatPourcentage = this.calculerPourcentage(
              prixOffreActuelle,
              prixOffre
            );

            if (resultatPourcentage > 0 ) {
              let priceOffreDiff = prixOffreActuelle - prixOffre;
              let valueEuroToDraw = `${Math.abs(Math.round(priceOffreDiff))}€`;
              let valuePercentageToDraw = `- ${Math.round(resultatPourcentage)}%`;
              page.drawText(valuePercentageToDraw, {
                x: 965 - valuePercentageToDraw.length * 7.5, //925 when "- 9%"
                y: 543,
                font: HelveticaBold,
                size: 24,
                color: rgb(1, 1, 1),
              });
              page.drawText(valueEuroToDraw, {
                x: 937 - valueEuroToDraw.length,
                y: 523,
                font: HelveticaBold,
                size: 20,
                color: rgb(1, 1, 1),
              });
              page.drawText("économisés/an", {
                x: 910,
                y: 497,
                font: HelveticaBold,
                size: 14,
                color: rgb(1, 1, 1),
              });
            }
          }
          try {
            if (
              value.indexOf("MOIS") != -1 ||
              isNaN(parseFloat(value).toFixed(2))
            ) {
              value = value;
            } else {
              value = parseFloat(value).toFixed(2);
            }
          } catch (exception) {
            value = value;
          }
          if (i == 0) {
            var smallWidth = 80;

            /*page.drawImage(green, {
              x: xForm,
              y: yBegin + 5,
              width: widthForm + 5,
              height: 37,
            });*/
            var colorBackGroundTextField = [0.662, 0.941, 0.819];
          } else {
            var smallWidth = 80;
            /*page.drawImage(yellow, {
              x: xForm,
              y: yBegin + 5,
              width: widthForm + 5,
              height: 37,
            });*/
            var colorBackGroundTextField = [1, 0.796, 0.207];
          }
          await this.drawAlignedText({
            page: page,
            text: this.formatNumberOrText(value,2),
            x: xForm + 120,
            y: yBegin + 155,
            font: Helvetica,
            size: 16,
            align: "center",
            textColor: [0, 0, 0],
            width: widthForm,
            height: 21,
          });
          
          xBegin = xBegin + widthForm + 2 + 38;
        }

        yBegin = yBegin - 68;
        // if (yBegin < 24 && i != donner["data"].length - 1) {
        //   var image = await this.getImage("/imagePres/background_offre_volt.PNG", pdfDoc);
        //   page = pdfDoc.addPage([1025, 578]);
        //   page.drawImage(image, {
        //     x: 0,
        //     y: 0,
        //     width: page.getWidth(),
        //     height: page.getHeight(),
        //   });

        //   yBegin = 240;
        // }
      }
      return [pdfDoc, page, yBegin];
    },
    /*async drawOffres(form, pdfDoc, page, donner, fournisseurActuelle) {
      var yellow = await this.getImage("/imagePres/yellow.PNG", pdfDoc);
      var tableLine = await this.getImage("/imagePres/tableYellowRecap.PNG", pdfDoc);
      var green = await this.getImage("/imagePres/green.PNG", pdfDoc);
      var greenLine = await this.getImage("/imagePres/tableGreenRecap.PNG", pdfDoc);
      var HelveticaBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
      var Helvetica = await pdfDoc.embedFont(StandardFonts.Helvetica);
      var y = fournisseurActuelle ? 290 : 350;
      page.drawText("Nos meilleures offres", {
        x: 50,
        y: y,
        font: HelveticaBold,
        size: 15,
        color: rgb(0.062, 0.227, 0.368),
      });
      var xBegin = donner["xBegin"];
      var yBegin = fournisseurActuelle ? 210 : 270;
      var actuelle = donner["actuelle"];
      var width = 515;

      var xForm = xBegin;
      var widthForm = 68;
      var value = "";
      var title = "";
      var subtitle = "";
      var fieldName = "";
      for (var i = 0; i < donner["titles"].length; i++) {
        var data = donner["titles"][i];
        xForm = xBegin + data["pas"];
        widthForm = data["width"];
        value = data["value"];
        title = data["title"];
        subtitle = data["subTitle"];
        fieldName = data["fieldName"];
        await this.createTextField(
          false,
          form,
          page,
          xForm,
          yBegin + 56,
          widthForm,
          15,
          title,
          "title" + i + fieldName,
          [1, 1, 1]
        );
        await this.createTextField(
          false,
          form,
          page,
          xForm,
          yBegin + 40,
          widthForm,
          15,
          subtitle,
          "subtitle" + i + fieldName,
          [1, 1, 1]
        );
        xBegin = xBegin + widthForm + data["pas"];
      }
      var xForm = xBegin;
      var widthForm = 68;
      var value = "";
      var fieldName = "";
      for (var i = 0; i < donner["data"].length; i++) {
        xBegin = donner["xBegin"];
        if (i == 0) {
          page.drawImage(greenLine, {
            x: xBegin,
            y: yBegin + 2,
            width: width,
            height: 40,
          });
          var smallWidth = 80;
          page.drawImage(green, {
            x: xBegin + width,
            y: yBegin + 2,
            width: smallWidth,
            height: 40,
          });
          //if(actuelle == true){
          page.drawImage(green, {
            x: xBegin + smallWidth + width + 2,
            y: yBegin + 2,
            width: smallWidth + 15,
            height: 40,
          });
          //}
          var colorBackGroundTextField = [0.662, 0.941, 0.819];
        } else {
          page.drawImage(tableLine, {
            x: xBegin,
            y: yBegin + 2,
            width: width,
            height: 40,
          });
          var smallWidth = 80;
          page.drawImage(yellow, {
            x: xBegin + width,
            y: yBegin + 2,
            width: smallWidth,
            height: 40,
          });
          //if(actuelle == true){
          page.drawImage(yellow, {
            x: xBegin + smallWidth + width + 2,
            y: yBegin,
            width: smallWidth + 15,
            height: 40,
          });
          //}
          var colorBackGroundTextField = [1, 0.796, 0.207];
        }
        var itemData = donner["data"][i];
        var logo = await this.getImage(itemData[0]["value"], pdfDoc);
        switch (itemData[0]["value"]) {
          case "/logoParticulier/founisseur_logos/OHM_120X35.png":
            page.drawImage(logo, {
              x: xBegin - 110,
              y: yBegin + 2,
              width: 110,
              height: 30,
            });
            break;
          case "/logoParticulier/founisseur_logos/Ekwateur_120X35.png":
            page.drawImage(logo, {
              x: xBegin - 110,
              y: yBegin + 2,
              width: 110,
              height: 30,
            });
            break;
          default:
            page.drawImage(logo, {
              x: xBegin - 110,
              y: yBegin + 2,
              width: 110,
              height: 40,
            });
            break;
        }
        for (var j = 1; j < itemData.length; j++) {
          var data = itemData[j];
          xForm = xBegin + data["pas"];
          widthForm = data["width"];
          value = data["value"];
          fieldName = data["fieldName"];
          xForm = xBegin + data["pas"];
          try {
            if (value.indexOf("MOIS") != -1 || isNaN(parseFloat(value).toFixed(2))) {
              value = value;
            } else {
              value = parseFloat(value).toFixed(2);
            }
          } catch (exception) {
            value = value;
          }
          await this.createTextField(
            false,
            form,
            page,
            xForm,
            yBegin + 12,
            widthForm,
            21,
            value,
            "value" + i + j + fieldName,
            colorBackGroundTextField
          );
          xBegin = xBegin + widthForm + data["pas"];
        }

        yBegin = yBegin - 45;

        if (yBegin < 45 && i != donner["data"].length - 1) {
          page = pdfDoc.addPage([842, 595]);
          yBegin = 450;
        }

        var imageBorder = await this.getImage(
          "/imagePres/border-presentation.jpg",
          pdfDoc
        );
        page.drawImage(imageBorder, { x: 742, y: 0, width: 100, height: 100 });
      }
      return [pdfDoc, page, yBegin];
    },*/
    async totalTtcParMois(
      pdfDoc,
      bestOffre,
      donnerCompteur,
      parametreCompteur
    ) {
      let result = null;
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "offreDetailles/",
        method: "POST",
        data: {
          dataCotation: donnerCompteur,
          parametreCompteur: parametreCompteur,
          bestOffre: bestOffre,
        },
      }).then((res) => {
        if (res.data.result == true) {
          result = res.data.data;
        } else {
          result = false;
        }
      });
      if (result != false) {
        const doc = new jsPDF("l", "pt", "a4");
        var tableColumn = [
          "Année",
          "Janvier",
          "Février",
          "Mars",
          "Avril",
          "Mai",
          "Juin",
          "Juillet",
          "Août",
          "Septembre",
          "Octobre",
          "Novembre",
          "Décembre",
          "Total",
        ];

        var tableRows = [];
        for (var annee in result) {
          var somme = 0;
          var result1 = result[annee];
          var line = [
            annee,
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
          ];
          for (var month in result1) {
            var value = result1[month];
            var index = tableColumn.indexOf(month);
            if (index != -1) {
              line[index] = this.numberWithSpaces(value) + " €";
            }
          }
          tableRows.push(line);
        }
        var columnStyle = {
          0: {
            halign: "center",
            valign: "middle",
            fillColor: [255, 255, 255],
            textColor: 0,
            lineColor: 0,
            fontSize: 9,
            lineWidth: 1,
            cellWidth: 50,
            minCellHeight: 35,
            fontStyle: "bold",
          },
          1: {
            halign: "center",
            valign: "middle",
            fillColor: [255, 255, 255],
            textColor: 0,
            lineColor: 0,
            fontSize: 9,
            lineWidth: 1,
            cellWidth: 55,
            minCellHeight: 35,
            fontStyle: "normal",
          },
          2: {
            halign: "center",
            valign: "middle",
            fillColor: [255, 255, 255],
            textColor: 0,
            lineColor: 0,
            fontSize: 9,
            lineWidth: 1,
            cellWidth: 55,
            minCellHeight: 35,
            fontStyle: "normal",
          },
          3: {
            halign: "center",
            valign: "middle",
            fillColor: [255, 255, 255],
            textColor: 0,
            lineColor: 0,
            fontSize: 9,
            lineWidth: 1,
            cellWidth: 53,
            minCellHeight: 35,
            fontStyle: "normal",
          },
          4: {
            halign: "center",
            valign: "middle",
            fillColor: [255, 255, 255],
            textColor: 0,
            lineColor: 0,
            fontSize: 9,
            lineWidth: 1,
            cellWidth: 55,
            minCellHeight: 35,
            fontStyle: "normal",
          },
          5: {
            halign: "center",
            valign: "middle",
            fillColor: [255, 255, 255],
            textColor: 0,
            lineColor: 0,
            fontSize: 9,
            lineWidth: 1,
            cellWidth: 53,
            minCellHeight: 35,
            fontStyle: "normal",
          },
          6: {
            halign: "center",
            valign: "middle",
            fillColor: [255, 255, 255],
            textColor: 0,
            lineColor: 0,
            fontSize: 9,
            lineWidth: 1,
            cellWidth: 55,
            minCellHeight: 35,
            fontStyle: "normal",
          },
          7: {
            halign: "center",
            valign: "middle",
            fillColor: [255, 255, 255],
            textColor: 0,
            lineColor: 0,
            fontSize: 9,
            lineWidth: 1,
            cellWidth: 60,
            minCellHeight: 35,
            fontStyle: "normal",
          },
          8: {
            halign: "center",
            valign: "middle",
            fillColor: [255, 255, 255],
            textColor: 0,
            lineColor: 0,
            fontSize: 9,
            lineWidth: 1,
            cellWidth: 58,
            minCellHeight: 35,
            fontStyle: "normal",
          },
          9: {
            halign: "center",
            valign: "middle",
            fillColor: [255, 255, 255],
            textColor: 0,
            lineColor: 0,
            fontSize: 9,
            lineWidth: 1,
            cellWidth: 62,
            minCellHeight: 35,
            fontStyle: "normal",
          },
          10: {
            halign: "center",
            valign: "middle",
            fillColor: [255, 255, 255],
            textColor: 0,
            lineColor: 0,
            fontSize: 9,
            lineWidth: 1,
            cellWidth: 60,
            minCellHeight: 35,
            fontStyle: "normal",
          },
          11: {
            halign: "center",
            valign: "middle",
            fillColor: [255, 255, 255],
            textColor: 0,
            lineColor: 0,
            fontSize: 9,
            lineWidth: 1,
            cellWidth: 60,
            minCellHeight: 35,
            fontStyle: "normal",
          },
          12: {
            halign: "center",
            valign: "middle",
            fillColor: [255, 255, 255],
            textColor: 0,
            lineColor: 0,
            fontSize: 9,
            lineWidth: 1,
            cellWidth: 60,
            minCellHeight: 35,
            fontStyle: "normal",
          },
          13: {
            halign: "center",
            valign: "middle",
            fillColor: [255, 255, 255],
            textColor: 0,
            lineColor: 0,
            fontSize: 9,
            lineWidth: 1,
            cellWidth: 55,
            minCellHeight: 35,
            fontStyle: "bold",
          },
        };
        doc.autoTable(tableColumn, tableRows, {
          startX: 5,
          startY: 190,
          columnStyles: columnStyle,
          headStyles: {
            fillColor: [210, 210, 210],
            textColor: 0,
            halign: "center",
            valign: "middle",
          },
        });

        let blob = doc.output("arraybuffer");
        return blob;
      } else {
        return false;
      }
    },
    convertToFloat(value) {
      var cleanedValue = ("" + value).replace(",", ".");
      return parseFloat(cleanedValue);
    },
    async getPrixMoyenneElectron(typeCompteur, data, donneDeCotation) {
      if (typeCompteur == "C3") {
        var prixMoyenneElectron =
          data["consommationEnergie"] /
          (this.convertToFloat(donneDeCotation["CAHPH"]) +
            this.convertToFloat(donneDeCotation["CAHCH"]) +
            this.convertToFloat(donneDeCotation["CAHCE"]) +
            this.convertToFloat(donneDeCotation["CAHPE"]) +
            this.convertToFloat(donneDeCotation["CAP"]));
      } else if (typeCompteur == "C4") {
        var prixMoyenneElectron =
          data["consommationEnergie"] /
          (this.convertToFloat(donneDeCotation["CAHPH"]) +
            this.convertToFloat(donneDeCotation["CAHCH"]) +
            this.convertToFloat(donneDeCotation["CAHCE"]) +
            this.convertToFloat(donneDeCotation["CAHPE"]));
      }else if (typeCompteur == "C5C4") {
        var prixMoyenneElectron =
          data["consommationEnergie"] /
          (this.convertToFloat(donneDeCotation["CAHPH"]) +
            this.convertToFloat(donneDeCotation["CAHCH"]) +
            this.convertToFloat(donneDeCotation["CAHCE"]) +
            this.convertToFloat(donneDeCotation["CAHPE"]));
      } else if (typeCompteur == "C5HP") {
        var prixMoyenneElectron =
          data["consommationEnergie"] /
          this.convertToFloat(donneDeCotation["CAR"]);
      } else if (typeCompteur == "C5BASE") {
        var prixMoyenneElectron =
          data["consommationEnergie"] /
          this.convertToFloat(donneDeCotation["CAR"]);
      }
      if (isNaN(prixMoyenneElectron)) {
        return 0;
      } else {
        return prixMoyenneElectron;
      }
    },
    async drawPricingTbaleC3(
      form,
      pdfDoc,
      page,
      donner,
      fournisseur,
      fournisseurActuelle
    ) {
      var HelveticaBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
      var Helvetica = await pdfDoc.embedFont(StandardFonts.Helvetica);
      var typeCompteur = donner["typeCompteur"];
      page.drawText(
        "RECAPITULATIF DE L'APPEL D'OFFRES DU " + this.formatDate(new Date()),
        {
          x: 100,
          y: 530,
          font: HelveticaBold,
          size: 25,
          color: rgb(0.062, 0.227, 0.368),
        }
      );
      var imageBorder = await this.getImage(
        "/imagePres/border-presentation.jpg",
        pdfDoc
      );
      page.drawImage(imageBorder, { x: 742, y: 0, width: 100, height: 100 });

      var c3Pricing = await this.getImage(donner["UrlPricing"], pdfDoc);
      var green = await this.getImage(donner["UrlGreen"], pdfDoc);
      var c3PriceYellow = await this.getImage(
        donner["UrlPricingYellow"],
        pdfDoc
      );
      var yellow = await this.getImage(donner["yellow"], pdfDoc);
      var yBegin = donner["yBegin"];
      var xBegin = donner["xBegin"];
      var width = "";
      var height = "";
      var titles = donner["titre"];
      var xf = xBegin;
      var xf2 = xBegin + 4;
      for (var j = 0; j < titles.length; j++) {
        var v = titles[j]["value"];
        var w = titles[j]["width"];
        var st = titles[j]["subTitle"];
        var fd = titles[j]["fieldName"];
        if (titles[j]["twoLine"] == true) {
          var h = 23;
        } else {
          var h = 18;
        }
        await this.createTextField(
          titles[j]["twoLine"],
          form,
          page,
          xf,
          yBegin + 55,
          w,
          h,
          v,
          "title" + fd + j,
          [1, 1, 1]
        );
        for (var i = 0; i < st.length; i++) {
          var width2 = w / st.length - 4;
          await this.createTextField(
            titles[j]["twoLine"],
            form,
            page,
            xf2,
            yBegin + 36,
            width2,
            18,
            st[i],
            "subtitle" + fd + i,
            [1, 1, 1]
          );
          xf2 = xf2 + width2 + 4;
        }
        xf2 = xf2 + 4;
        xf = xf + w + 4;
      }
      var nbrPage = 0;
      for (var j = 0; j < donner["data"].length; j++) {
        var line = donner["data"][j];
        try {
          var logo = await this.getImage(line[0]["logo"], pdfDoc);
          if (
            line[0]["logo"] ==
            "/logoParticulier/founisseur_logos/Ekwateur_120X35.png"
          ) {
            page.drawImage(logo, {
              x: xBegin - 90,
              y: yBegin + 3,
              width: 85,
              height: 25,
            });
          } else {
            page.drawImage(logo, {
              x: xBegin - 90,
              y: yBegin + 3,
              width: 85,
              height: 28,
            });
          }
        } catch (error) {
          page.drawText(line[0]["logo"], {
            x: xBegin - 70,
            y: yBegin + 12,
            font: Helvetica,
            size: 14,
            color: rgb(0.062, 0.227, 0.368),
          });
        }
        for (var posData = 1; posData < line.length; posData++) {
          var item = line[posData];
          width = item["image"]["width"];
          height = item["image"]["height"];
          var IndexMoinscher = 0;
          if (fournisseurActuelle == true) {
            IndexMoinscher = 1;
          }
          if (j == IndexMoinscher) {
            //firstImage for pricing
            if (item["image"]["name"] == "pricing") {
              page.drawImage(c3Pricing, {
                x: xBegin,
                y: yBegin,
                width: width,
                height: height,
              });
            } else {
              page.drawImage(green, {
                x: xBegin,
                y: yBegin - 4,
                width: width,
                height: height + 6,
              });
            }
            var xForm = xBegin;
            var widthForm = 0;
            for (var x = 0; x < item["items"].length; x++) {
              var lesValeurs = item["items"][x];
              xForm = xForm + widthForm + lesValeurs["pas"];
              var widthForm = lesValeurs["width"];
              var fieldName = lesValeurs["fieldName"];
              var value = lesValeurs["value"];
              try {
                if (isNaN(parseFloat(value).toFixed(2))) {
                  value = value;
                } else {
                  value = parseFloat(value).toFixed(2);
                }
              } catch (exception) {
                value = value;
              }
              await this.createTextField(
                false,
                form,
                page,
                xForm,
                yBegin + 5,
                widthForm,
                25,
                value,
                fieldName + j + x + nbrPage,
                [0.662, 0.941, 0.819]
              );
            }
            xBegin = xBegin + width + 4;
          } else {
            //firstImage for pricing
            if (item["image"]["name"] == "pricing") {
              page.drawImage(c3PriceYellow, {
                x: xBegin,
                y: yBegin,
                width: width,
                height: height,
              });
            } else {
              page.drawImage(yellow, {
                x: xBegin,
                y: yBegin,
                width: width,
                height: height,
              });
            }
            var xForm = xBegin;
            var widthForm = 0;
            for (var x = 0; x < item["items"].length; x++) {
              var lesValeurs = item["items"][x];
              xForm = xForm + widthForm + lesValeurs["pas"];
              var widthForm = lesValeurs["width"];
              var fieldName = lesValeurs["fieldName"];
              var value = lesValeurs["value"];
              try {
                if (isNaN(parseFloat(value).toFixed(2))) {
                  value = value;
                } else {
                  value = parseFloat(value).toFixed(2);
                }
              } catch (exception) {
                value = value;
              }
              await this.createTextField(
                false,
                form,
                page,
                xForm,
                yBegin + 5,
                widthForm,
                25,
                value,
                fieldName + j + x + nbrPage,
                [1, 0.796, 0, 207]
              );
            }
            xBegin = xBegin + width + 4;
          }
        }
        yBegin = yBegin - 38;
        xBegin = donner["xBegin"];
        if (yBegin <= 77 && donner["data"].length - 1 - j >= 1) {
          nbrPage = nbrPage + 1;
          page = pdfDoc.addPage([842, 595]);
          page.drawText(
            "RECAPITULATIF DE L'APPEL D'OFFRES DU " +
              this.formatDate(new Date()),
            {
              x: 100,
              y: 530,
              font: HelveticaBold,
              size: 25,
              color: rgb(0.062, 0.227, 0.368),
            }
          );
          var titles = donner["titre"];
          var yBegin = donner["yBegin"];
          var xBegin = donner["xBegin"];
          var width = "";
          var height = "";
          var titles = donner["titre"];
          var xf = xBegin;
          var xf2 = xBegin + 4;
          for (var index = 0; index < titles.length; index++) {
            var v = titles[index]["value"];
            var w = titles[index]["width"];
            var st = titles[index]["subTitle"];
            var fd = titles[index]["fieldName"];
            if (titles[index]["twoLine"] == true) {
              var h = 23;
            } else {
              var h = 18;
            }
            await this.createTextField(
              titles[index]["twoLine"],
              form,
              page,
              xf,
              yBegin + 55,
              w,
              h,
              v,
              "title" + fd + index + nbrPage,
              [1, 1, 1]
            );
            for (var iindex = 0; iindex < st.length; iindex++) {
              var width2 = w / st.length - 4;
              await this.createTextField(
                titles[index]["twoLine"],
                form,
                page,
                xf2,
                yBegin + 36,
                width2,
                18,
                st[iindex],
                "subtitle" + fd + iindex + nbrPage,
                [1, 1, 1]
              );
              xf2 = xf2 + width2 + 4;
            }
            xf2 = xf2 + 4;
            xf = xf + w + 4;
          }
        }
      }
      if (typeCompteur == "C5BASE" || typeCompteur == "C5HP") {
        let returnForLogo = false;
        for (let i = 0; i < donner.data.length; i++) {
          if (!donner.data[i][0].logo.startsWith("/")) {
            var imageAmpoule = await this.getImage(
              "/imagePres/ampoule.png",
              pdfDoc
            );
            page.drawImage(imageAmpoule, {
              x: 730,
              y: 250,
              width: 100,
              height: 110,
            });
            returnForLogo = true;
            break;
          }
        }
        if (!returnForLogo) {
          switch (this.listDesOffre.length) {
            case 2:
              var imageAmpoule = await this.getImage(
                "/imagePres/ampoule.png",
                pdfDoc
              );
              page.drawImage(imageAmpoule, {
                x: 750,
                y: 360,
                width: 70,
                height: 80,
              });
              break;

            case 3:
              var imageAmpoule = await this.getImage(
                "/imagePres/ampoule.png",
                pdfDoc
              );
              page.drawImage(imageAmpoule, {
                x: 730,
                y: 350,
                width: 75,
                height: 85,
              });
              break;
            case 4:
              var imageAmpoule = await this.getImage(
                "/imagePres/ampoule.png",
                pdfDoc
              );
              page.drawImage(imageAmpoule, {
                x: 730,
                y: 350,
                width: 75,
                height: 85,
              });
              break;

            default:
              var imageAmpoule = await this.getImage(
                "/imagePres/ampoule.png",
                pdfDoc
              );
              page.drawImage(imageAmpoule, {
                x: 730,
                y: 370,
                width: 40,
                height: 50,
              });
              break;
          }
        }
        ///////////////////////////////////
      }
      return pdfDoc;
    },
    async addPagesToPdf(mergedPdf, donorPdf) {
      const copiedPages = await mergedPdf.copyPages(
        donorPdf,
        donorPdf.getPageIndices()
      );
      copiedPages.forEach((page) => {
        mergedPdf.addPage(page);
      });
      return mergedPdf;
    },
    base64ToArrayBuffer(base64) {
      var binary_string = window.atob(base64);
      var len = binary_string.length;
      var bytes = new Uint8Array(len);
      for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
      }
      return bytes.buffer;
    },
    async getConsomationTotaleGraphe(
      page,
      doc,
      dataEnedis,
      typeCompteur,
      donnerCompteur
    ) {
      var result_Enedis_Horaire = false;
      var errGraph1 = false;
      var imgResult = null;
      let payload1 = {
        //jsonData est la reponse de enedis qui contient la consommation par mois
        data: dataEnedis,
        typeCompteur: typeCompteur,
        donnerCompteur: donnerCompteur,
      };

      if (dataEnedis.mesuresInfo[0].nbrMonth >= 8) {
        errGraph1 = true;

        await axios
          .post(process.env.VUE_APP_URL_API_CLIENT + "getBarGraphGEMC/", {
            ...payload1,
          })
          .then(async (res) => {
            imgResult = res.data.some;
          })
          .catch((err) => {
            errGraph1 = false;
          });
      }
      return imgResult;

      // await axios
      //   .post(process.env.VUE_APP_URL_API_CLIENT + "getSommeConsoGraphGEMC/", {
      //     ...payload1,
      //   })
      //   .then(async (res) => {
      //     this.img = res.data.some;

      //     try {
      //       var imgg = await this.getImage(this.img, doc);
      //       if (errGraph1 == true) {
      //         page.drawImage(imgg, { x: 20, y: 80, width: 350, height: 295 });
      //       } else {
      //         page.drawImage(imgg, { x: 200, y: 70, width: 350, height: 295 });
      //       }
      //     } catch (erro) {}
      //   })
      //   .catch((err) => {});
    },
    async createPres() {
      // Sort each inner array
      this.indexes = this.indexes.map(innerArray => innerArray.sort((a, b) => a - b));
      this.societeData =
        this.donnerDesFournisseur[0]["resultat"]["dataSociete"];
      /*for (let key in this.infosCompteurPresentationProps) {
        
        this.societeData = { ...this.infosCompteurPresentationProps[key].dataSociete };
        this.fournisseurActuelleData = this.infosCompteurPresentationProps[key].actuelle;
      }*/
      this.loadingPres = true;
      this.dialogPres = true;
      if (this.isArrayEmptyRecursive(this.indexes) == false) {
        var pdfDoc = await PDFDocument.create();

        /*const presentationURLPartBegin = "/pdf/Presentation-3-5.pdf";
        const presentationPartBeginBytes = await fetch(
          presentationURLPartBegin
        ).then((res) => res.arrayBuffer());*/
        const presentationURLPartBegin = "/pdf/PRES_OFFRE_CLIENT-1-3.pdf";
        const presentationPartBeginBytes = await fetch(
          presentationURLPartBegin
        ).then((res) => {
          return res.arrayBuffer();
        });
        var presentationPagePartBeginDoc = await PDFDocument.load(
          presentationPartBeginBytes
        );
        const sourcePdfPage = presentationPagePartBeginDoc.getPage(0);
        var HelveticaFont = await presentationPagePartBeginDoc.embedFont(
          StandardFonts.Helvetica
        );

        const HelveticaBoldFont = await pdfDoc.embedFont(
          StandardFonts.HelveticaBold
        );
        const TimesRomanFont = await presentationPagePartBeginDoc.embedFont(
          StandardFonts.TimesRomanBold
        );

        function getFrenchFormattedDate() {
          const today = new Date();
          const day = today.getDate().toString().padStart(2, "0");
          const month = (today.getMonth() + 1).toString().padStart(2, "0");
          const year = today.getFullYear();
          return `${day}/${month}/${year}`;
        }
        // var multiText = layoutMultilineText(this.societeData.Raison.toUpperCase(), {
        //   alignment: "center",
        //   fontSize: 11.5,
        //   font: HelveticaFont,
        //   lineHeight: 1.5,
        //   bounds: { x: 0, y: 0, width: 600, height: 900 },
        // });

        // for (let i = 0; i < multiText.lines.length; i++) {
        //   var xPosition = (900 - multiText.lines[i].width) / 2;
        //   let startingPositon = 270;
        //   sourcePdfPage.drawText(`${multiText.lines[i].text}`, {
        //     x: xPosition,
        //     y: startingPositon,
        //     size: 40,
        //     lineHeight: 90,
        //     maxWidth: 600,
        //     color: rgb(0.2, 0.2, 0.14),
        //   });
        //   // move position down
        //   startingPositon += 200;
        // }

        //Appel d'offre

        await this.drawAlignedText({
          page: sourcePdfPage,
          text: "APPEL D’OFFRE " + getFrenchFormattedDate(),
          x: 170,
          y: 368,
          font: HelveticaFont,
          size: 20,
          align: "center",
          textColor: [0, 0, 0],
          width: 700,
        });

        // ************** Raison Sociale **************
        sourcePdfPage.drawText("Raison Sociale:", {
          x: 245,
          y: 320,
          size: 14,
          font: HelveticaFont,
          color: rgb(0.2, 0.2, 0.14),
        });
        sourcePdfPage.drawText(
          `${this.donnerDesFournisseur[0]["resultat"]["dataSociete"]["Raison"]}`,
          {
            x: 370,
            y: 320,
            size: 18,
            font: HelveticaFont,
            color: rgb(0.2, 0.2, 0.14),
          }
        );
        // ************** SIRET ***********************
        sourcePdfPage.drawText("SIRET:", {
          x: 245,
          y: 290,
          size: 14,
          font: HelveticaFont,
          color: rgb(0.2, 0.2, 0.14),
        });
        sourcePdfPage.drawText(
          `${this.donnerDesFournisseur[0]["resultat"]["dataSociete"]["siret"]}`,
          {
            x: 370,
            y: 290,
            size: 18,
            font: HelveticaFont,
            color: rgb(0.2, 0.2, 0.14),
          }
        );
        // ************** Interlocuteur **************
        sourcePdfPage.drawText("Interlocuteur:", {
          x: 245,
          y: 260,
          size: 14,
          font: HelveticaFont,
          color: rgb(0.2, 0.2, 0.14),
        });
        const interlocuteurPrenom =
          this.donnerDesFournisseur[0]["resultat"]["dataSociete"]["prenom"] ||
          "";
        const interlocuteurNom =
          this.donnerDesFournisseur[0]["resultat"]["dataSociete"]["nom"] || "";
        const interlocuteurAdresseEmail =
          this.donnerDesFournisseur[0]["resultat"]["dataSociete"][
            "AdresseEmail"
          ] || "";
        const interlocuteurNumTele =
          this.donnerDesFournisseur[0]["resultat"]["dataSociete"]["numTele"] ||
          "";
        // ************** Prenom **********************
        sourcePdfPage.drawText("Prenom:", {
          x: 290,
          y: 230,
          size: 14,
          font: HelveticaFont,
          color: rgb(0.2, 0.2, 0.14),
        });

        sourcePdfPage.drawText(`${interlocuteurPrenom}`, {
          x: 370,
          y: 230,
          size: 18,
          font: HelveticaFont,
          color: rgb(0.2, 0.2, 0.14),
        });
        // ************** Nom **********************
        sourcePdfPage.drawText("Nom:", {
          x: 290,
          y: 200,
          size: 14,
          font: HelveticaFont,
          color: rgb(0.2, 0.2, 0.14),
        });
        sourcePdfPage.drawText(`${interlocuteurNom}`, {
          x: 370,
          y: 200,
          size: 18,
          font: HelveticaFont,
          color: rgb(0.2, 0.2, 0.14),
        });
        // ************** Email **********************
        sourcePdfPage.drawText("Email:", {
          x: 290,
          y: 170,
          size: 14,
          font: HelveticaFont,
          color: rgb(0.2, 0.2, 0.14),
        });
        sourcePdfPage.drawText(`${interlocuteurAdresseEmail}`, {
          x: 370,
          y: 170,
          size: 18,
          font: HelveticaFont,
          color: rgb(0.2, 0.2, 0.14),
        });
        // ************** Telephone **********************
        sourcePdfPage.drawText("Telephone:", {
          x: 290,
          y: 140,
          size: 14,
          font: HelveticaFont,
          color: rgb(0.2, 0.2, 0.14),
        });
        sourcePdfPage.drawText(`${interlocuteurNumTele}`, {
          x: 370,
          y: 140,
          size: 18,
          font: HelveticaFont,
          color: rgb(0.2, 0.2, 0.14),
        });

        //  graph page
        if (this.toggleCharts) {
          const pageGraph = presentationPagePartBeginDoc.getPage(2);
          let imageGraphPNG = await this.getImage(
            this.graphImage,
            presentationPagePartBeginDoc
          );
          pageGraph.drawImage(imageGraphPNG, {
            x: 150,
            y: 120,
            width: 750,
            height: 350,
          });
        }

        pdfDoc = await this.addPagesToPdf(pdfDoc, presentationPagePartBeginDoc);

        if (this.toggleCharts == false) {
          pdfDoc.removePage(2);
        }

        // ***********************************************
        // page graphes enedis by compteur
        // ***********************************************
        for (let [k, fournisseur] of this.donnerDesFournisseur.entries()) {
          /// les information pour chaque compteur
          let typeCompteur = fournisseur["resultat"]["typeCompteur"];
          let numCompteur = fournisseur["resultat"]["numCompteur"];
          let donnerCompteur = fournisseur["resultat"]["donnerCompteur"];
          let fournisseurActuelle = fournisseur["resultat"]["actuelle"];
          let dataEnedis = fournisseur["resultat"]["dataEnedis"];
          let nbrMonth = donnerCompteur["nbrMonth"];
          console.log("here here");
          console.log(fournisseur);
          // filter les offre sélectionnée pour ce compteur
          let listDesOffre = fournisseur["resultat"]["result"].filter(
            (item, ind) => {
              return this.indexes[k]?.includes(ind);
            }
          );
          if (listDesOffre.length != 0) {
            const presentationURLGraphEnedis = "/pdf/PRES_OFFRE_CLIENT-4.pdf";
            const presentationGraphEnedisBytes = await fetch(
              presentationURLGraphEnedis
            ).then((res) => res.arrayBuffer());
            let presentationPageGraphEnedisDoc = await PDFDocument.load(
              presentationGraphEnedisBytes
            );
            let getPagesGraphEnedis = presentationPageGraphEnedisDoc.getPages();
            const timesRomanFont =
              await presentationPageGraphEnedisDoc.embedFont(
                StandardFonts.HelveticaBold
              );
            let pageGraphEnedis = getPagesGraphEnedis[0];

            const imgGraphEnedis = await this.getConsomationTotaleGraphe(
              pageGraphEnedis,
              pdfDoc,
              dataEnedis,
              typeCompteur,
              donnerCompteur
            );

            // pageGraphEnedis.drawText(`Numero Compteur : ${numCompteur}`, {
            //   x: 200,
            //   y: 430,
            //   size: 14,
            //   font: HelveticaBoldFont,
            //   color: rgb(0.2, 0.2, 0.14),
            // });
            if (typeCompteur == "C5BASE") {
              var enteteInfoCompteur = {
                typeCompteur: "C5BASE",
                xBegin: 200,
                yBegin: 410,
                data: [
                  {
                    text: [
                      {
                        fieldName: "pdl",
                        title: "Compteur",
                        value: numCompteur + "",
                      },
                      {
                        fieldName: "dateDebut",
                        title: "Déb.contrat ",
                        value: donnerCompteur["dateDebut"],
                      },
                    ],
                    width: 200,
                    height: 32,
                    title: "",
                    fieldName: "titleInfo",
                  },
                  {
                    text: [
                      {
                        fieldName: "kvaBASE",
                        title: "BASE",
                        value: donnerCompteur["kvaBASE"] + "",
                      },
                    ],
                    width: 110,
                    height: 32,
                    title: "Puissances souscrites KVA",
                    fieldName: "titleKva",
                  },
                  {
                    text: [
                      {
                        fieldName: "carBASE",
                        title: "BASE",
                        value: donnerCompteur["CABASE"] + "",
                      },
                    ],
                    width: 110,
                    height: 32,
                    title: "Consommation MWh",
                    fieldName: "titleCar",
                  },
                  {
                    text: [
                      {
                        fieldName: "Total",
                        title: "MWh/an",
                        value: donnerCompteur["CAR"] + "",
                      },
                    ],
                    width: 90,
                    height: 32,
                    title: "Total",
                    fieldName: "titleTotal",
                  },
                ],
              };
            } else if (typeCompteur == "C5HP") {
              var enteteInfoCompteur = {
                typeCompteur: "C5HP",
                xBegin: 175,
                yBegin: 410,
                data: [
                  {
                    text: [
                      {
                        fieldName: "pdl",
                        title: "Compteur",
                        value: numCompteur + "",
                      },
                      {
                        fieldName: "dateDebut",
                        title: "Déb.contrat ",
                        value: donnerCompteur["dateDebut"],
                      },
                    ],
                    width: 200,
                    height: 32,
                    title: "",
                    fieldName: "titleInfo",
                  },
                  {
                    text: [
                      {
                        fieldName: "kvaHP",
                        title: "HP",
                        value: donnerCompteur["kvaHP"] + "",
                      },
                      {
                        fieldName: "kvaHC",
                        title: "HC",
                        value: donnerCompteur["kvaHC"] + "",
                      },
                    ],
                    width: 150,
                    height: 32,
                    title: "Puissances souscrites KVA",
                    fieldName: "titleKva",
                  },
                  {
                    text: [
                      {
                        fieldName: "carHP",
                        title: "HP",
                        value: donnerCompteur["CAHP"] + "",
                      },
                      {
                        fieldName: "carHC",
                        title: "HC",
                        value: donnerCompteur["CAHC"] + "",
                      },
                    ],
                    width: 150,
                    height: 32,
                    title: "Consommation MWh",
                    fieldName: "titleCar",
                  },
                  {
                    text: [
                      {
                        fieldName: "Total",
                        title: "MWh/an",
                        value: donnerCompteur["CAR"] + "",
                      },
                    ],
                    width: 90,
                    height: 32,
                    title: "Total",
                    fieldName: "titleTotal",
                  },
                ],
              };
            } else if (typeCompteur == "C4") {
              var enteteInfoCompteur = {
                typeCompteur: "C4",
                xBegin: 100,
                yBegin: 410,
                data: [
                  {
                    text: [
                      {
                        fieldName: "pdl",
                        title: "Compteur",
                        value: numCompteur + "",
                      },
                      {
                        fieldName: "dateDebut",
                        title: "Déb.contrat ",
                        value: donnerCompteur["dateDebut"],
                      },
                    ],
                    width: 200,
                    height: 32,
                    title: "",
                    fieldName: "titleInfo",
                  },
                  {
                    text: [
                      {
                        fieldName: "kvaHPH",
                        title: "HPH",
                        value: donnerCompteur["kvaHPH"] + "",
                      },
                      {
                        fieldName: "kvaHCH",
                        title: "HCH",
                        value: donnerCompteur["kvaHCH"] + "",
                      },
                      {
                        fieldName: "kvaHPE",
                        title: "HPE",
                        value: donnerCompteur["kvaHPE"] + "",
                      },
                      {
                        fieldName: "kvaHCE",
                        title: "HCE",
                        value: donnerCompteur["kvaHCE"] + "",
                      },
                    ],
                    width: 245,
                    height: 32,
                    title: "Puissances souscrites KVA",
                    fieldName: "titleKva",
                  },
                  {
                    text: [
                      {
                        fieldName: "carHPH",
                        title: "HPH",
                        value: donnerCompteur["CAHPH"] + "",
                      },
                      {
                        fieldName: "carHCH",
                        title: "HCH",
                        value: donnerCompteur["CAHCH"] + "",
                      },
                      {
                        fieldName: "carHPE",
                        title: "HPE",
                        value: donnerCompteur["CAHPE"] + "",
                      },
                      {
                        fieldName: "carHCE",
                        title: "HCE",
                        value: donnerCompteur["CAHCE"] + "",
                      },
                    ],
                    width: 245,
                    height: 32,
                    title: "Consommation MWh",
                    fieldName: "titleCar",
                  },
                  {
                    text: [
                      {
                        fieldName: "Total",
                        title: "MWh/an",
                        value: donnerCompteur["CAR"] + "",
                      },
                    ],
                    width: 90,
                    height: 32,
                    title: "Total",
                    fieldName: "titleTotal",
                  },
                ],
              };
            } else if (typeCompteur == "C5C4") {
              var enteteInfoCompteur = {
                typeCompteur: "C5",
                xBegin: 100,
                yBegin: 410,
                data: [
                  {
                    text: [
                      {
                        fieldName: "pdl",
                        title: "Compteur",
                        value: numCompteur + "",
                      },
                      {
                        fieldName: "dateDebut",
                        title: "Déb.contrat ",
                        value: donnerCompteur["dateDebut"],
                      },
                    ],
                    width: 200,
                    height: 32,
                    title: "",
                    fieldName: "titleInfo",
                  },
                  {
                    text: [
                      {
                        fieldName: "kvaHPH",
                        title: "HPH",
                        value: donnerCompteur["kvaHPH"] + "",
                      },
                      {
                        fieldName: "kvaHCH",
                        title: "HCH",
                        value: donnerCompteur["kvaHCH"] + "",
                      },
                      {
                        fieldName: "kvaHPE",
                        title: "HPE",
                        value: donnerCompteur["kvaHPE"] + "",
                      },
                      {
                        fieldName: "kvaHCE",
                        title: "HCE",
                        value: donnerCompteur["kvaHCE"] + "",
                      },
                    ],
                    width: 245,
                    height: 32,
                    title: "Puissances souscrites KVA",
                    fieldName: "titleKva",
                  },
                  {
                    text: [
                      {
                        fieldName: "carHPH",
                        title: "HPH",
                        value: donnerCompteur["CAHPH"] + "",
                      },
                      {
                        fieldName: "carHCH",
                        title: "HCH",
                        value: donnerCompteur["CAHCH"] + "",
                      },
                      {
                        fieldName: "carHPE",
                        title: "HPE",
                        value: donnerCompteur["CAHPE"] + "",
                      },
                      {
                        fieldName: "carHCE",
                        title: "HCE",
                        value: donnerCompteur["CAHCE"] + "",
                      },
                    ],
                    width: 245,
                    height: 32,
                    title: "Consommation MWh",
                    fieldName: "titleCar",
                  },
                  {
                    text: [
                      {
                        fieldName: "Total",
                        title: "MWh/an",
                        value: donnerCompteur["CAR"] + "",
                      },
                    ],
                    width: 90,
                    height: 32,
                    title: "Total",
                    fieldName: "titleTotal",
                  },
                ],
              };
            } else if (typeCompteur == "C3") {
              var enteteInfoCompteur = {
                typeCompteur: "C3",
                xBegin: 100,
                yBegin: 410,
                data: [
                  {
                    text: [
                      {
                        fieldName: "pdl",
                        title: "Compteur",
                        value: numCompteur + "",
                      },
                      {
                        fieldName: "dateDebut",
                        title: "Déb.contrat ",
                        value: donnerCompteur["dateDebut"],
                      },
                    ],
                    width: 200,
                    height: 32,
                    title: "",
                    fieldName: "titleInfo",
                  },
                  {
                    text: [
                      {
                        fieldName: "kvaPTE",
                        title: "PTE",
                        value: donnerCompteur["kvaPTE"] + "",
                      },
                      {
                        fieldName: "kvaHPH",
                        title: "HPH",
                        value: donnerCompteur["kvaHPH"] + "",
                      },
                      {
                        fieldName: "kvaHCH",
                        title: "HCH",
                        value: donnerCompteur["kvaHCH"] + "",
                      },
                      {
                        fieldName: "kvaHPE",
                        title: "HPE",
                        value: donnerCompteur["kvaHPE"] + "",
                      },
                      {
                        fieldName: "kvaHCE",
                        title: "HCE",
                        value: donnerCompteur["kvaHCE"] + "",
                      },
                    ],
                    width: 245,
                    height: 32,
                    title: "Puissances souscrites KVA",
                    fieldName: "titleKva",
                  },
                  {
                    text: [
                      {
                        fieldName: "carPTE",
                        title: "PTE",
                        value: donnerCompteur["CAP"] + "",
                      },
                      {
                        fieldName: "carHPH",
                        title: "HPH",
                        value: donnerCompteur["CAHPH"] + "",
                      },
                      {
                        fieldName: "carHCH",
                        title: "HCH",
                        value: donnerCompteur["CAHCH"] + "",
                      },
                      {
                        fieldName: "carHPE",
                        title: "HPE",
                        value: donnerCompteur["CAHPE"] + "",
                      },
                      {
                        fieldName: "carHCE",
                        title: "HCE",
                        value: donnerCompteur["CAHCE"] + "",
                      },
                    ],
                    width: 245,
                    height: 32,
                    title: "Consommation MWh",
                    fieldName: "titleCar",
                  },
                  {
                    text: [
                      {
                        fieldName: "Total",
                        title: "MWh/an",
                        value: donnerCompteur["CAR"] + "",
                      },
                    ],
                    width: 90,
                    height: 32,
                    title: "Total",
                    fieldName: "titleTotal",
                  },
                ],
              };
            }
            const formPageGraphEnedisDoc =
              presentationPageGraphEnedisDoc.getForm();

            // for centering the compteur row in page if no image enedis
            let isCenter = false;
            if (!imgGraphEnedis) {
              isCenter = true;
            }
            presentationPageGraphEnedisDoc = await this.infoTable(
              formPageGraphEnedisDoc,
              presentationPageGraphEnedisDoc,
              pageGraphEnedis,
              enteteInfoCompteur,
              isCenter
            );

            if (imgGraphEnedis) {
              const imageGraphEnedisPNG = await this.getImage(
                imgGraphEnedis,
                presentationPageGraphEnedisDoc
              );
              pageGraphEnedis.drawImage(imageGraphEnedisPNG, {
                x: 200,
                y: 80,
                width: 630,
                height: 300,
              });
            }
            pdfDoc = await this.addPagesToPdf(
              pdfDoc,
              presentationPageGraphEnedisDoc
            );
          }
        }
        // presentation pages after Graph Enedis
        const presentationURLAfterGraphEnedis =
          "/pdf/PRES_OFFRE_CLIENT-5-6.pdf";
        const presentationAfterGraphEnedisBytes = await fetch(
          presentationURLAfterGraphEnedis
        ).then((res) => {
          return res.arrayBuffer();
        });
        var presentationPageAfterGraphEnedisDoc = await PDFDocument.load(
          presentationAfterGraphEnedisBytes
        );
        pdfDoc = await this.addPagesToPdf(
          pdfDoc,
          presentationPageAfterGraphEnedisDoc
        );

        let fournisseurList = [];
        // loop through all compteurs and create pages
        for (let [k, fournisseur] of this.donnerDesFournisseur.entries()) {
          /// les information pour chaque compteur
          let typeCompteur = fournisseur["resultat"]["typeCompteur"];
          let numCompteur = fournisseur["resultat"]["numCompteur"];
          let donnerCompteur = fournisseur["resultat"]["donnerCompteur"];
          let fournisseurActuelle = fournisseur["resultat"]["actuelle"];
          let dataEnedis = fournisseur["resultat"]["dataEnedis"];
          /*if (
            (typeCompteur != "C5BASE" || typeCompteur != "C5HP") &&
            this.columns2[0].dataKey == "capap"
          ) {
            this.columns2.splice(0, 1);
          }*/
          // filter les offre sélectionnée pour ce compteur
          let listDesOffre = fournisseur["resultat"]["result"].filter(
            (item, ind) => {
              return this.indexes[k]?.includes(ind);
            }
          );
          if (listDesOffre.length != 0) {
            /*let pageBlanche = pdfDoc.addPage([842, 595]);
            await this.getConsomationTotaleGraphe(
              pageBlanche,
              pdfDoc,
              dataEnedis,
              typeCompteur,
              donnerCompteur
            );
            var frm = pdfDoc.getForm();
            var imageBorder = await this.getImage(
              "/imagePres/border-presentation.jpg",
              pdfDoc
            );
            pageBlanche.drawImage(imageBorder, { x: 742, y: 0, width: 100, height: 100 });
            for (var i = 0; i < this.indexes.length; i++) {
              pageBlanche.drawText("VOTRE SOCIETE", {
                x: 50,
                y: 530,
                size: 25,
                font: HelveticaBoldFont,
                color: rgb(0.1, 0.17, 0.35),
              });
              if (i == 0) {
                await this.createTextFieldPers(
                  15,
                  [0.1, 0.17, 0.35],
                  true,
                  frm,
                  pageBlanche,
                  80,
                  470,
                  750,
                  40,
                  `Raison Sociale : ${this.donnerDesFournisseur[0]["resultat"]["dataSociete"]["Raison"]}`,
                  "raisonConsommation",
                  [1, 1, 1]
                );
                await this.createTextFieldPers(
                  15,
                  [0.1, 0.17, 0.35],
                  true,
                  frm,
                  pageBlanche,
                  80,
                  450,
                  750,
                  40,
                  `SIRET : ${this.donnerDesFournisseur[0]["resultat"]["dataSociete"]["siret"]}`,
                  "siretConsommation",
                  [1, 1, 1]
                );
                if (dataEnedis.donneTechniqueInfo[0].result == "valide") {
                  await this.createTextFieldPers(
                    15,
                    [0.1, 0.17, 0.35],
                    true,
                    frm,
                    pageBlanche,
                    80,
                    430,
                    750,
                    40,
                    `Adresse de consommation : ${dataEnedis.donneTechniqueInfo[0].data.adresse}`,
                    "adresseConsommation",
                    [1, 1, 1]
                  );
                } else {
                  await this.createTextFieldPers(
                    15,
                    [0.1, 0.17, 0.35],
                    true,
                    frm,
                    pageBlanche,
                    80,
                    430,
                    750,
                    40,
                    `Adresse de consommation : ${this.donnerDesFournisseur[0]["resultat"]["dataSociete"]["AdressePostal"]}`,
                    "adresseConsommation",
                    [1, 1, 1]
                  );
                }
              }

              pageBlanche.drawText(`VOTRE HISTORIQUE DE CONSOMMATION (MWH)`, {
                x: 50,
                y: 380,
                size: 25,
                font: HelveticaBoldFont,
                color: rgb(0.1, 0.17, 0.35),
              });
            }*/

            //var page = pdfDoc.addPage([842, 595]);

            //const form = pdfDoc.getForm();
            if (typeCompteur == "C5BASE") {
              var enteteInfoCompteur = {
                typeCompteur: "C5BASE",
                xBegin: 80,
                yBegin: 430,
                data: [
                  {
                    text: [
                      {
                        fieldName: "pdl",
                        title: "Compteur",
                        value: numCompteur + "",
                      },
                      {
                        fieldName: "dateDebut",
                        title: "Déb.contrat ",
                        value: donnerCompteur["dateDebut"],
                      },
                    ],
                    width: 200,
                    height: 32,
                    title: "",
                    fieldName: "titleInfo",
                  },
                  {
                    text: [
                      {
                        fieldName: "kvaBASE",
                        title: "BASE",
                        value: donnerCompteur["kvaBASE"] + "",
                      },
                    ],
                    width: 110,
                    height: 32,
                    title: "Puissances souscrites KVA",
                    fieldName: "titleKva",
                  },
                  {
                    text: [
                      {
                        fieldName: "carBASE",
                        title: "BASE",
                        value: donnerCompteur["CABASE"] + "",
                      },
                    ],
                    width: 110,
                    height: 32,
                    title: "Consommation MWh",
                    fieldName: "titleCar",
                  },
                  {
                    text: [
                      {
                        fieldName: "Total",
                        title: "MWh/an",
                        value: donnerCompteur["CAR"] + "",
                      },
                    ],
                    width: 90,
                    height: 32,
                    title: "Total",
                    fieldName: "titleTotal",
                  },
                ],
              };
            } else if (typeCompteur == "C5HP") {
              var enteteInfoCompteur = {
                typeCompteur: "C5HP",
                xBegin: 70,
                yBegin: 430,
                data: [
                  {
                    text: [
                      {
                        fieldName: "pdl",
                        title: "Compteur",
                        value: numCompteur + "",
                      },
                      {
                        fieldName: "dateDebut",
                        title: "Déb.contrat ",
                        value: donnerCompteur["dateDebut"],
                      },
                    ],
                    width: 200,
                    height: 32,
                    title: "",
                    fieldName: "titleInfo",
                  },
                  {
                    text: [
                      {
                        fieldName: "kvaHP",
                        title: "HP",
                        value: donnerCompteur["kvaHP"] + "",
                      },
                      {
                        fieldName: "kvaHC",
                        title: "HC",
                        value: donnerCompteur["kvaHC"] + "",
                      },
                    ],
                    width: 150,
                    height: 32,
                    title: "Puissances souscrites KVA",
                    fieldName: "titleKva",
                  },
                  {
                    text: [
                      {
                        fieldName: "carHP",
                        title: "HP",
                        value: donnerCompteur["CAHP"] + "",
                      },
                      {
                        fieldName: "carHC",
                        title: "HC",
                        value: donnerCompteur["CAHC"] + "",
                      },
                    ],
                    width: 150,
                    height: 32,
                    title: "Consommation MWh",
                    fieldName: "titleCar",
                  },
                  {
                    text: [
                      {
                        fieldName: "Total",
                        title: "MWh/an",
                        value: donnerCompteur["CAR"] + "",
                      },
                    ],
                    width: 90,
                    height: 32,
                    title: "Total",
                    fieldName: "titleTotal",
                  },
                ],
              };
            } else if (typeCompteur == "C4") {
              var enteteInfoCompteur = {
                typeCompteur: "C4",
                xBegin: 20,
                yBegin: 430,
                data: [
                  {
                    text: [
                      {
                        fieldName: "pdl",
                        title: "Compteur",
                        value: numCompteur + "",
                      },
                      {
                        fieldName: "dateDebut",
                        title: "Déb.contrat ",
                        value: donnerCompteur["dateDebut"],
                      },
                    ],
                    width: 200,
                    height: 32,
                    title: "",
                    fieldName: "titleInfo",
                  },
                  {
                    text: [
                      {
                        fieldName: "kvaHPH",
                        title: "HPH",
                        value: donnerCompteur["kvaHPH"] + "",
                      },
                      {
                        fieldName: "kvaHCH",
                        title: "HCH",
                        value: donnerCompteur["kvaHCH"] + "",
                      },
                      {
                        fieldName: "kvaHPE",
                        title: "HPE",
                        value: donnerCompteur["kvaHPE"] + "",
                      },
                      {
                        fieldName: "kvaHCE",
                        title: "HCE",
                        value: donnerCompteur["kvaHCE"] + "",
                      },
                    ],
                    width: 245,
                    height: 32,
                    title: "Puissances souscrites KVA",
                    fieldName: "titleKva",
                  },
                  {
                    text: [
                      {
                        fieldName: "carHPH",
                        title: "HPH",
                        value: donnerCompteur["CAHPH"] + "",
                      },
                      {
                        fieldName: "carHCH",
                        title: "HCH",
                        value: donnerCompteur["CAHCH"] + "",
                      },
                      {
                        fieldName: "carHPE",
                        title: "HPE",
                        value: donnerCompteur["CAHPE"] + "",
                      },
                      {
                        fieldName: "carHCE",
                        title: "HCE",
                        value: donnerCompteur["CAHCE"] + "",
                      },
                    ],
                    width: 245,
                    height: 32,
                    title: "Consommation MWh",
                    fieldName: "titleCar",
                  },
                  {
                    text: [
                      {
                        fieldName: "Total",
                        title: "MWh/an",
                        value: donnerCompteur["CAR"] + "",
                      },
                    ],
                    width: 90,
                    height: 32,
                    title: "Total",
                    fieldName: "titleTotal",
                  },
                ],
              };
            } else if (typeCompteur == "C5C4") {
              var enteteInfoCompteur = {
                typeCompteur: "C5",
                xBegin: 20,
                yBegin: 430,
                data: [
                  {
                    text: [
                      {
                        fieldName: "pdl",
                        title: "Compteur",
                        value: numCompteur + "",
                      },
                      {
                        fieldName: "dateDebut",
                        title: "Déb.contrat ",
                        value: donnerCompteur["dateDebut"],
                      },
                    ],
                    width: 200,
                    height: 32,
                    title: "",
                    fieldName: "titleInfo",
                  },
                  {
                    text: [
                      {
                        fieldName: "kvaHPH",
                        title: "HPH",
                        value: donnerCompteur["kvaHPH"] + "",
                      },
                      {
                        fieldName: "kvaHCH",
                        title: "HCH",
                        value: donnerCompteur["kvaHCH"] + "",
                      },
                      {
                        fieldName: "kvaHPE",
                        title: "HPE",
                        value: donnerCompteur["kvaHPE"] + "",
                      },
                      {
                        fieldName: "kvaHCE",
                        title: "HCE",
                        value: donnerCompteur["kvaHCE"] + "",
                      },
                    ],
                    width: 245,
                    height: 32,
                    title: "Puissances souscrites KVA",
                    fieldName: "titleKva",
                  },
                  {
                    text: [
                      {
                        fieldName: "carHPH",
                        title: "HPH",
                        value: donnerCompteur["CAHPH"] + "",
                      },
                      {
                        fieldName: "carHCH",
                        title: "HCH",
                        value: donnerCompteur["CAHCH"] + "",
                      },
                      {
                        fieldName: "carHPE",
                        title: "HPE",
                        value: donnerCompteur["CAHPE"] + "",
                      },
                      {
                        fieldName: "carHCE",
                        title: "HCE",
                        value: donnerCompteur["CAHCE"] + "",
                      },
                    ],
                    width: 245,
                    height: 32,
                    title: "Consommation MWh",
                    fieldName: "titleCar",
                  },
                  {
                    text: [
                      {
                        fieldName: "Total",
                        title: "MWh/an",
                        value: donnerCompteur["CAR"] + "",
                      },
                    ],
                    width: 90,
                    height: 32,
                    title: "Total",
                    fieldName: "titleTotal",
                  },
                ],
              };
            } else if (typeCompteur == "C3") {
              var enteteInfoCompteur = {
                typeCompteur: "C3",
                xBegin: 20,
                yBegin: 430,
                data: [
                  {
                    text: [
                      {
                        fieldName: "pdl",
                        title: "Compteur",
                        value: numCompteur + "",
                      },
                      {
                        fieldName: "dateDebut",
                        title: "Déb.contrat ",
                        value: donnerCompteur["dateDebut"],
                      },
                    ],
                    width: 200,
                    height: 32,
                    title: "",
                    fieldName: "titleInfo",
                  },
                  {
                    text: [
                      {
                        fieldName: "kvaPTE",
                        title: "PTE",
                        value: donnerCompteur["kvaPTE"] + "",
                      },
                      {
                        fieldName: "kvaHPH",
                        title: "HPH",
                        value: donnerCompteur["kvaHPH"] + "",
                      },
                      {
                        fieldName: "kvaHCH",
                        title: "HCH",
                        value: donnerCompteur["kvaHCH"] + "",
                      },
                      {
                        fieldName: "kvaHPE",
                        title: "HPE",
                        value: donnerCompteur["kvaHPE"] + "",
                      },
                      {
                        fieldName: "kvaHCE",
                        title: "HCE",
                        value: donnerCompteur["kvaHCE"] + "",
                      },
                    ],
                    width: 245,
                    height: 32,
                    title: "Puissances souscrites KVA",
                    fieldName: "titleKva",
                  },
                  {
                    text: [
                      {
                        fieldName: "carPTE",
                        title: "PTE",
                        value: donnerCompteur["CAP"] + "",
                      },
                      {
                        fieldName: "carHPH",
                        title: "HPH",
                        value: donnerCompteur["CAHPH"] + "",
                      },
                      {
                        fieldName: "carHCH",
                        title: "HCH",
                        value: donnerCompteur["CAHCH"] + "",
                      },
                      {
                        fieldName: "carHPE",
                        title: "HPE",
                        value: donnerCompteur["CAHPE"] + "",
                      },
                      {
                        fieldName: "carHCE",
                        title: "HCE",
                        value: donnerCompteur["CAHCE"] + "",
                      },
                    ],
                    width: 245,
                    height: 32,
                    title: "Consommation MWh",
                    fieldName: "titleCar",
                  },
                  {
                    text: [
                      {
                        fieldName: "Total",
                        title: "MWh/an",
                        value: donnerCompteur["CAR"] + "",
                      },
                    ],
                    width: 90,
                    height: 32,
                    title: "Total",
                    fieldName: "titleTotal",
                  },
                ],
              };
            }
            //pdfDoc = await this.infoTable(form, pdfDoc, page, enteteInfoCompteur);
            const presentationURLPart2 = "/pdf/Page_APPEL_OFFRE.pdf";
            const presentationPart2Bytes = await fetch(
              presentationURLPart2
            ).then((res) => {
              return res.arrayBuffer();
            });
            var presentationPagePart2Doc = await PDFDocument.load(
              presentationPart2Bytes
            );
            var getPages = presentationPagePart2Doc.getPages();
            var formpresentationPagePart2Doc =
              presentationPagePart2Doc.getForm();
            var firstPage = getPages[0];
            this.Offres = {
              actuelle: fournisseurActuelle,
              xBegin: 140,
              yBegin: 240,
              titles: [
                {
                  title: "Fourniture",
                  subTitle: "en €/an",
                  width: 62,
                  pas: 15,
                  fieldName: "fourniture",
                },
                /*{
                  title: "Durée",
                  subTitle: "",
                  width: 62,
                  pas: 7,
                  fieldName: "BigDurre",
                },
                {
                  title: "Type d'offre",
                  subTitle: "",
                  width: 64,
                  pas: 8,
                  fieldName: "BigTypeOffre",
                },
                {
                  title: "Budget énergie",
                  subTitle: "en €/an",
                  width: 64,
                  pas: 8,
                  fieldName: "BigAchem",
                },*/
                {
                  title: "Acheminement",
                  subTitle: "en €/an",
                  width: 64,
                  pas: 15,
                  fieldName: "BigAchem",
                },
                {
                  title: "Taxes",
                  subTitle: "en €/an",
                  width: 60,
                  pas: 15,
                  fieldName: "BigTaxe",
                },
                {
                  title: "Budget HTVA",
                  subTitle: "en €/an",
                  width: 60,
                  pas: 15,
                  fieldName: "TotalAnnuel",
                } /*
                {
                  title: "TVA",
                  subTitle: "en €/an",
                  width: 64,
                  pas: 7,
                  fieldName: "tva",
                },
                {
                  title: "Total TTC",
                  subTitle: "en €/an",
                  width: 64,
                  pas: 20,
                  fieldName: "BigTTc",
                },
                {
                  title: "Prix moyen Electron",
                  subTitle: "€/MWh",
                  fieldName: "PrixMoyenElectron",
                  width: 90,
                  pas: 10,
                },*/,
              ],
              data: [],
            };
            if (fournisseurActuelle == true) {
              var OffreActuelle = {
                xBegin: 130,
                yBegin: 330,
                logo:
                  "/logoParticulier/founisseur_logos/" +
                  fournisseur["resultat"]["result"][0]["logo"],
                data: [
                  {
                    title: "Fourniture",
                    value:
                      fournisseur["resultat"]["result"][0]["budgetAbo"] + "",
                    fieldName: "fourniture",
                    subTitle: "en €/an",
                    width: 64,
                    pas: 15,
                  },
                  /*{
                    title: "Durée",
                    subTitle: "",
                    value: fournisseur["resultat"]["result"][0]["nbrMonth"] + " MOIS",
                    fieldName: "ActuDuree",
                    width: 62,
                    pas: 7,
                  },
                  {
                    title: "Type  d'offre",
                    subTitle: "",
                    value: fournisseur["resultat"]["result"][0]["type"] + "",
                    fieldName: "ActuTypeOffre",
                    width: 64,
                    pas: 8,
                  },
                  {
                    title: "Budget Energie",
                    subTitle: "en €/an HT",
                    value: fournisseur["resultat"]["result"][0]["budgetAbo"] + "",
                    fieldName: "ActuBudget",
                    width: 64,
                    pas: 8,
                  },*/
                  {
                    title: "Acheminement",
                    subTitle: "en €/an",
                    value: fournisseur["resultat"]["result"][0]["TURPE"] + "",
                    fieldName: "ActuAchm",
                    width: 64,
                    pas: 15,
                  },
                  {
                    title: "Taxes",
                    subTitle: "en €/an",
                    value:
                      fournisseur["resultat"]["result"][0]["budgetTaxe"] + "",
                    fieldName: "ActuTaxes",
                    width: 60,
                    pas: 15,
                  },
                  {
                    title: "Budget HTVA",
                    subTitle: "en €/an",
                    value: fournisseur["resultat"]["result"][0]["budget"] + "",
                    fieldName: "ActuTotalAnnuel",
                    width: 60,
                    pas: 15,
                  } /*
                  {
                    title: "TVA",
                    subTitle: "en €/an",
                    value: fournisseur["resultat"]["result"][0]["TVA"] + "",
                    fieldName: "ActuTva",
                    width: 64,
                    pas: 7,
                  },
                  {
                    title: "Total TTC1",
                    subTitle: "en €/an",
                    value: fournisseur["resultat"]["result"][0]["budget"] + "",
                    fieldName: "ActuTotalTTC",
                    width: 64,
                    pas: 15,
                  },
                  {
                    title: "Prix moyen Electron",
                    subTitle: "€/MWh",
                    value:
                      (await this.getPrixMoyenneElectron(
                        typeCompteur,
                        fournisseur["resultat"]["result"][0],
                        donnerCompteur
                      )) + "",
                    fieldName: "ActuPrixMoyenElectron",
                    width: 90,
                    pas: 10,
                  },*/,
                ],
              };
              //pdfDoc = await this.drawOffreActuelle(form, pdfDoc, page, OffreActuelle);
              this.OffreActuelleData = { ...OffreActuelle };
              presentationPagePart2Doc = await this.drawOffreActuelle(
                formpresentationPagePart2Doc,
                presentationPagePart2Doc,
                firstPage,
                OffreActuelle
              );
            }
            /////////////////////////////////////////////////////////////
            if (fournisseurActuelle == true) {
              var BeginOffres = 1;
            } else {
              var BeginOffres = 0;
            }
            for (
              var index = BeginOffres;
              index < listDesOffre.length;
              index++
            ) {
              try {
                var aboAn = listDesOffre[index]["mensuel"] * 12;
                aboAn = aboAn.toFixed(2);
              } catch (error) {
                var aboAn = 0.0;
              }
              if (listDesOffre[index]["typeYears"] == "avecSpot") {
                var item = [
                  {
                    value:
                      "/logoParticulier/founisseur_logos/" +
                      listDesOffre[index]["moyenne"]["logo"],
                    fieldName: "logo",
                    width: 64,
                    pas: 7,
                  },
                  {
                    value: listDesOffre[index]["moyenne"]["nbrMonth"] + " MOIS",
                    fieldName: "ActuDuree",
                    width: 62,
                    pas: 7,
                  },
                  {
                    value: listDesOffre[index]["moyenne"]["type"] + "",
                    fieldName: "ActuTypeOffre",
                    width: 64,
                    pas: 8,
                  },
                  {
                    title: "Budget Energie",
                    subTitle: "en €/an HT",
                    value: listDesOffre[index]["moyenne"]["budgetAbo"] + "",
                    fieldName: "ActuBudget",
                    width: 62,
                    pas: 8,
                  },
                  {
                    value: listDesOffre[index]["moyenne"]["TURPE"] + "",
                    fieldName: "ActuAchm",
                    width: 60,
                    pas: 15,
                  },
                  {
                    value: listDesOffre[index]["moyenne"]["budgetTaxe"] + "",
                    fieldName: "ActuTaxes",
                    width: 60,
                    pas: 20,
                  },
                  {
                    value: listDesOffre[index]["moyenne"]["budget"] + "",
                    fieldName: "ActuTotalAnnuel",
                    width: 60,
                    pas: 13,
                  },
                  {
                    value: listDesOffre[index]["moyenne"]["TVA"] + "",
                    fieldName: "ActuTva",
                    width: 64,
                    pas: 7,
                  },
                  {
                    value: listDesOffre[index]["moyenne"]["budget"] + "",
                    fieldName: "ActuTotalTTC",
                    width: 64,
                    pas: 15,
                  },
                  {
                    value:
                      (await this.getPrixMoyenneElectron(
                        typeCompteur,
                        listDesOffre[index]["moyenne"],
                        donnerCompteur
                      )) + "",
                    fieldName: "PrixMoyenElec",
                    width: 75,
                    pas: 14,
                  },
                ];
              } else {
                var item = [
                  {
                    value:
                      "/logoParticulier/founisseur_logos/" +
                      listDesOffre[index]["logo"],
                    fieldName: "logo",
                    width: 64,
                    pas: 2,
                  },
                  {
                    value: listDesOffre[index]["budgetAbo"] + "",
                    fieldName: "fourniture",
                    width: 60,
                    pas: 2,
                  },
                  /*{
                    value: listDesOffre[index]["nbrMonth"] + " MOIS",
                    fieldName: "ActuDuree",
                    width: 62,
                    pas: 7,
                  },
                  {
                    value: listDesOffre[index]["type"] + "",
                    fieldName: "ActuTypeOffre",
                    width: 64,
                    pas: 8,
                  },
                  {
                    title: "Budget Energie",
                    subTitle: "en €/an HT",
                    value: listDesOffre[index]["budgetAbo"] + "",
                    fieldName: "ActuBudget",
                    width: 62,
                    pas: 8,
                  },*/
                  {
                    value: listDesOffre[index]["TURPE"] + "",
                    fieldName: "ActuAchm",
                    width: 60,
                    pas: 2,
                  },
                  {
                    value: listDesOffre[index]["budgetTaxe"] + "",
                    fieldName: "ActuTaxes",
                    width: 60,
                    pas: 2,
                  },
                  {
                    value: listDesOffre[index]["budget"] + "",
                    fieldName: "ActuTotalAnnuel",
                    width: 60,
                    pas: 2,
                  } /*
                  {
                    value: listDesOffre[index]["TVA"] + "",
                    fieldName: "ActuTva",
                    width: 64,
                    pas: 7,
                  },
                  {
                    value: listDesOffre[index]["budget"] + "",
                    fieldName: "ActuTotalTTC",
                    width: 64,
                    pas: 15,
                  },
                  {
                    value:
                      (await this.getPrixMoyenneElectron(
                        typeCompteur,
                        listDesOffre[index],
                        donnerCompteur
                      )) + "",
                    fieldName: "PrixMoyenElec",
                    width: 75,
                    pas: 14,
                  },*/,
                  ,
                ];
              }
              this.Offres.data.push(item);
            }
            /*var res = await this.drawOffres(
              form,
              pdfDoc,
              page,
              this.Offres,
              fournisseurActuelle
            );

            pdfDoc = res[0];
            page = res[1];
            var yBegin = res[2];*/
            var res = await this.drawOffres(
              formpresentationPagePart2Doc,
              presentationPagePart2Doc,
              firstPage,
              this.Offres,
              fournisseurActuelle
            );
            pdfDoc = await this.addPagesToPdf(pdfDoc, res[0]);
            //var page = res[1];
            var yBegin = res[2];
            if (typeCompteur == "C5BASE") {
              await this.createPDFWithMultipleTablesBASE(
                listDesOffre,
                pdfDoc,
                typeCompteur
              );
            }
            if (typeCompteur == "C5HP") {
              await this.createPDFWithMultipleTablesHPHC(
                listDesOffre,
                pdfDoc,
                typeCompteur
              );
            }
            if (typeCompteur == "C4") {
              await this.createPDFWithMultipleTablesSansPointe(
                listDesOffre,
                pdfDoc,
                typeCompteur
              );
            }
            if (typeCompteur == "C5C4") {
              await this.createPDFWithMultipleTablesSansPointe(
                listDesOffre,
                pdfDoc,
                typeCompteur
              );
            }
            if (typeCompteur == "C3" ) {
              await this.createPDFWithMultipleTables(
                listDesOffre,
                pdfDoc,
                typeCompteur
              );
            }

            //var page = pdfDoc.addPage([842, 595]);
            var pricing = {};
            var posY = 350 + (listDesOffre.length / 2) * 40;
            if (typeCompteur == "C5BASE") {
              pricing["typeCompteur"] = "C5BASE";
              pricing["titre"] = [
                {
                  twoLine: true,
                  fieldName: "prixUnitaire",
                  value: "Prix unitaires en €/MWh",
                  width: 80,
                  subTitle: ["BASE"],
                },
                {
                  twoLine: true,
                  fieldName: "coutCapacite",
                  value: "Coût capacité en €/MWh",
                  width: 80,
                  subTitle: ["BASE"],
                },
                {
                  twoLine: false,
                  fieldName: "CEE",
                  value: "CEE",
                  width: 80,
                  subTitle: ["€/MWh"],
                },
                {
                  twoLine: false,
                  fieldName: "Abo",
                  value: "Abo",
                  width: 80,
                  subTitle: ["€/an"],
                },
                {
                  twoLine: false,
                  fieldName: "totaleEnergie",
                  value: "Budget Energie",
                  width: 90,
                  subTitle: ["€/an"],
                },
              ];
              pricing["yBegin"] = posY;
              pricing["xBegin"] = 230;
              pricing["UrlPricing"] = "/imagePres/green.PNG";
              pricing["UrlGreen"] = "/imagePres/green.PNG";
              pricing["UrlPricingYellow"] = "/imagePres/Yellow1.PNG";
              pricing["yellow"] = "/imagePres/Yellow1.PNG";
              pricing["data"] = [];

              for (var index = 0; index < listDesOffre.length; index++) {
                var et = null;

                if (listDesOffre[index]["typeYears"] == "many") {
                  et = [
                    {
                      logo:
                        "/logoParticulier/founisseur_logos/" +
                        listDesOffre[index]["logo"],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["unitaire"] + "",
                          fieldName: "prixBase",
                          width: 70,
                          pas: 7,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["capa_base"] + "",
                          fieldName: "capaBase",
                          width: 70,
                          pas: 7,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["cee"] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["aboAns"] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["budgetAbo"] + "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ],
                    },
                  ];
                  pricing["data"].push(et);
                  et = [
                    {
                      logo: "Première année",
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value:
                            listDesOffre[index]["premierAnnee"]["unitaire"] +
                            "",
                          fieldName: "prixBase",
                          width: 70,
                          pas: 7,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value:
                            listDesOffre[index]["premierAnnee"]["capa_base"] +
                            "",
                          fieldName: "capaBase",
                          width: 70,
                          pas: 7,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["cee"] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["aboAns"] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value:
                            listDesOffre[index]["premierAnnee"]["budgetAbo"] +
                            "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ],
                    },
                  ];
                  pricing["data"].push(et);
                  et = [
                    {
                      logo: "Reste D'année",
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value:
                            listDesOffre[index]["secondeAnnee"]["unitaire"] +
                            "",
                          fieldName: "prixBase",
                          width: 70,
                          pas: 7,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value:
                            listDesOffre[index]["secondeAnnee"]["capa_base"] +
                            "",
                          fieldName: "capaBase",
                          width: 70,
                          pas: 7,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["cee"] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["aboAns"] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value:
                            listDesOffre[index]["secondeAnnee"]["budgetAbo"] +
                            "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ],
                    },
                  ];
                  pricing["data"].push(et);
                } else if (listDesOffre[index]["typeYears"] == "precie") {
                  et = [
                    {
                      logo:
                        "/logoParticulier/founisseur_logos/" +
                        listDesOffre[index]["logo"],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["unitaire"] + "",
                          fieldName: "prixBase",
                          width: 70,
                          pas: 7,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["capa_base"] + "",
                          fieldName: "capaBase",
                          width: 70,
                          pas: 7,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["cee"] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["aboAns"] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["budgetAbo"] + "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ],
                    },
                  ];
                  pricing["data"].push(et);
                  var lesAnnee = [
                    "2022",
                    "2023",
                    "2024",
                    "2025",
                    "2026",
                    "2027",
                  ];
                  for (var years = 0; years < lesAnnee.length; years++) {
                    var year = lesAnnee[years];
                    if (year in listDesOffre[index]) {
                      et = [
                        {
                          logo: year,
                        },
                        {
                          image: {
                            width: 80,
                            height: 36,
                            name: "pricing",
                          },
                          items: [
                            {
                              value: listDesOffre[index][year]["unitaire"] + "",
                              fieldName: "prixBase",
                              width: 70,
                              pas: 7,
                            },
                          ],
                        },
                        {
                          image: {
                            width: 80,
                            height: 36,
                            name: "pricing",
                          },
                          items: [
                            {
                              value:
                                listDesOffre[index][year]["capa_base"] + "",
                              fieldName: "capaBase",
                              width: 70,
                              pas: 7,
                            },
                          ],
                        },
                        {
                          image: {
                            width: 80,
                            height: 36,
                            name: "color",
                          },
                          items: [
                            {
                              value: listDesOffre[index][year]["cee"] + "",
                              fieldName: "CEE",
                              width: 70,
                              pas: 5,
                            },
                          ],
                        },
                        {
                          image: {
                            width: 80,
                            height: 36,
                            name: "color",
                          },
                          items: [
                            {
                              value: listDesOffre[index][year]["aboAns"] + "",
                              fieldName: "Abo",
                              width: 70,
                              pas: 5,
                            },
                          ],
                        },
                        {
                          image: {
                            width: 90,
                            height: 36,
                            name: "color",
                          },
                          items: [
                            {
                              value:
                                listDesOffre[index][year]["budgetAbo"] + "",
                              fieldName: "totalEnergie",
                              width: 80,
                              pas: 5,
                            },
                          ],
                        },
                      ];
                      pricing["data"].push(et);
                    }
                  }
                } else if (listDesOffre[index]["typeYears"] == "single") {
                  et = [
                    {
                      logo:
                        "/logoParticulier/founisseur_logos/" +
                        listDesOffre[index]["logo"],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["unitaire"] + "",
                          fieldName: "prixBase",
                          width: 70,
                          pas: 7,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["capa_base"] + "",
                          fieldName: "capaBase",
                          width: 70,
                          pas: 7,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["cee"] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["aboAns"] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["budgetAbo"] + "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ],
                    },
                  ];
                  pricing["data"].push(et);
                }
              }
            } else if (typeCompteur == "C5HP") {
              pricing["typeCompteur"] = "C5HP";
              pricing["titre"] = [
                {
                  twoLine: false,
                  fieldName: "prixUnitaire",
                  value: "Prix unitaires en €/MWh",
                  width: 150,
                  subTitle: ["HP", "HC"],
                },
                {
                  twoLine: false,
                  fieldName: "coutCapacite",
                  value: "Coût capacité en €/MWh",
                  width: 150,
                  subTitle: ["HP", "HC"],
                },
                {
                  twoLine: false,
                  fieldName: "CEE",
                  value: "CEE",
                  width: 80,
                  subTitle: ["€/MWh"],
                },
                {
                  twoLine: false,
                  fieldName: "Abo",
                  value: "Abo",
                  width: 80,
                  subTitle: ["€/an"],
                },
                {
                  twoLine: false,
                  fieldName: "totaleEnergie",
                  value: "Budget Energie",
                  width: 90,
                  subTitle: ["€/an"],
                },
              ];
              pricing["yBegin"] = posY;
              pricing["xBegin"] = 150;
              pricing["UrlPricing"] = "/imagePres/c5HpPrice.PNG";
              pricing["UrlGreen"] = "/imagePres/green.PNG";
              pricing["UrlPricingYellow"] = "/imagePres/c5HpPriceYellow.PNG";
              pricing["yellow"] = "/imagePres/Yellow1.PNG";
              pricing["data"] = [];

              for (var index = 0; index < listDesOffre.length; index++) {
                var et = null;
                if (listDesOffre[index]["typeYears"] == "many") {
                  et = [
                    {
                      logo:
                        "/logoParticulier/founisseur_logos/" +
                        listDesOffre[index]["logo"],
                    },
                    {
                      image: {
                        width: 150,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["unitairehp"] + "",
                          fieldName: "prixHp",
                          width: 60,
                          pas: 10,
                        },
                        {
                          value: listDesOffre[index]["unitairehc"] + "",
                          fieldName: "prixHc",
                          width: 60,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 150,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["capa_hp"] + "",
                          fieldName: "capaHp",
                          width: 60,
                          pas: 10,
                        },
                        {
                          value: listDesOffre[index]["capa_hc"] + "",
                          fieldName: "capaHc",
                          width: 60,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["cee"] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["aboAns"] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["budgetAbo"] + "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ],
                    },
                  ];
                  pricing["data"].push(et);
                  et = [
                    {
                      logo: "Première année",
                    },
                    {
                      image: {
                        width: 150,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value:
                            listDesOffre[index]["premierAnnee"]["unitairehp"] +
                            "",
                          fieldName: "prixHp",
                          width: 60,
                          pas: 10,
                        },
                        {
                          value:
                            listDesOffre[index]["premierAnnee"]["unitairehc"] +
                            "",
                          fieldName: "prixHc",
                          width: 60,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 150,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value:
                            listDesOffre[index]["premierAnnee"]["capa_hp"] + "",
                          fieldName: "capaHp",
                          width: 60,
                          pas: 10,
                        },
                        {
                          value:
                            listDesOffre[index]["premierAnnee"]["capa_hc"] + "",
                          fieldName: "capaHc",
                          width: 60,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["cee"] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value:
                            listDesOffre[index]["premierAnnee"]["aboAns"] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value:
                            listDesOffre[index]["premierAnnee"]["budgetAbo"] +
                            "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ],
                    },
                  ];
                  pricing["data"].push(et);
                  et = [
                    {
                      logo: "Reste D'années",
                    },
                    {
                      image: {
                        width: 150,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value:
                            listDesOffre[index]["secondeAnnee"]["unitairehp"] +
                            "",
                          fieldName: "prixHp",
                          width: 60,
                          pas: 10,
                        },
                        {
                          value:
                            listDesOffre[index]["secondeAnnee"]["unitairehc"] +
                            "",
                          fieldName: "prixHc",
                          width: 60,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 150,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value:
                            listDesOffre[index]["secondeAnnee"]["capa_hp"] + "",
                          fieldName: "capaHp",
                          width: 60,
                          pas: 10,
                        },
                        {
                          value:
                            listDesOffre[index]["secondeAnnee"]["capa_hc"] + "",
                          fieldName: "capaHc",
                          width: 60,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["cee"] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value:
                            listDesOffre[index]["secondeAnnee"]["aboAns"] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value:
                            listDesOffre[index]["secondeAnnee"]["budgetAbo"] +
                            "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ],
                    },
                  ];
                  pricing["data"].push(et);
                } else if (listDesOffre[index]["typeYears"] == "precie") {
                  et = [
                    {
                      logo:
                        "/logoParticulier/founisseur_logos/" +
                        listDesOffre[index]["logo"],
                    },
                    {
                      image: {
                        width: 150,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["unitairehp"] + "",
                          fieldName: "prixHp",
                          width: 60,
                          pas: 10,
                        },
                        {
                          value: listDesOffre[index]["unitairehc"] + "",
                          fieldName: "prixHc",
                          width: 60,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 150,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["capa_hp"] + "",
                          fieldName: "capaHp",
                          width: 60,
                          pas: 10,
                        },
                        {
                          value: listDesOffre[index]["capa_hc"] + "",
                          fieldName: "capaHc",
                          width: 60,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["cee"] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["aboAns"] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["budgetAbo"] + "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ],
                    },
                  ];
                  pricing["data"].push(et);
                  var lesAnnee = [
                    "2022",
                    "2023",
                    "2024",
                    "2025",
                    "2026",
                    "2027",
                  ];
                  for (var years = 0; years < lesAnnee.length; years++) {
                    var year = lesAnnee[years];
                    if (year in listDesOffre[index]) {
                      et = [
                        {
                          logo: year,
                        },
                        {
                          image: {
                            width: 150,
                            height: 36,
                            name: "pricing",
                          },
                          items: [
                            {
                              value:
                                listDesOffre[index][year]["unitairehp"] + "",
                              fieldName: "prixHp",
                              width: 60,
                              pas: 10,
                            },
                            {
                              value:
                                listDesOffre[index][year]["unitairehc"] + "",
                              fieldName: "prixHc",
                              width: 60,
                              pas: 5,
                            },
                          ],
                        },
                        {
                          image: {
                            width: 150,
                            height: 36,
                            name: "pricing",
                          },
                          items: [
                            {
                              value: listDesOffre[index][year]["capa_hp"] + "",
                              fieldName: "capaHp",
                              width: 60,
                              pas: 10,
                            },
                            {
                              value: listDesOffre[index][year]["capa_hc"] + "",
                              fieldName: "capaHc",
                              width: 60,
                              pas: 5,
                            },
                          ],
                        },
                        {
                          image: {
                            width: 80,
                            height: 36,
                            name: "color",
                          },
                          items: [
                            {
                              value: listDesOffre[index][year]["cee"] + "",
                              fieldName: "CEE",
                              width: 70,
                              pas: 5,
                            },
                          ],
                        },
                        {
                          image: {
                            width: 80,
                            height: 36,
                            name: "color",
                          },
                          items: [
                            {
                              value: listDesOffre[index][year]["aboAns"] + "",
                              fieldName: "Abo",
                              width: 70,
                              pas: 5,
                            },
                          ],
                        },
                        {
                          image: {
                            width: 90,
                            height: 36,
                            name: "color",
                          },
                          items: [
                            {
                              value:
                                listDesOffre[index][year]["budgetAbo"] + "",
                              fieldName: "totalEnergie",
                              width: 80,
                              pas: 5,
                            },
                          ],
                        },
                      ];
                      pricing["data"].push(et);
                    }
                  }
                } else if (listDesOffre[index]["typeYears"] == "single") {
                  et = [
                    {
                      logo:
                        "/logoParticulier/founisseur_logos/" +
                        listDesOffre[index]["logo"],
                    },
                    {
                      image: {
                        width: 150,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["unitairehp"] + "",
                          fieldName: "prixHp",
                          width: 60,
                          pas: 10,
                        },
                        {
                          value: listDesOffre[index]["unitairehc"] + "",
                          fieldName: "prixHc",
                          width: 60,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 150,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["capa_hp"] + "",
                          fieldName: "capaHp",
                          width: 60,
                          pas: 10,
                        },
                        {
                          value: listDesOffre[index]["capa_hc"] + "",
                          fieldName: "capaHc",
                          width: 60,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["cee"] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["aboAns"] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["budgetAbo"] + "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ],
                    },
                  ];
                  pricing["data"].push(et);
                }
              }
            } else if (typeCompteur == "C4") {
              pricing["typeCompteur"] = "C4";
              pricing["titre"] = [
                {
                  twoLine: false,
                  fieldName: "prixUnitaire",
                  value: "Prix unitaires en €/MWh",
                  width: 220,
                  subTitle: ["HPH", "HCH", "HPE", "HCE"],
                },
                {
                  twoLine: false,
                  fieldName: "coutCapacite",
                  value: "Coût capacité en €/MWh",
                  width: 220,
                  subTitle: ["HPH", "HCH", "HPE", "HCE"],
                },
                {
                  twoLine: false,
                  fieldName: "CEE",
                  value: "CEE",
                  width: 80,
                  subTitle: ["€/MWh"],
                },
                {
                  twoLine: false,
                  fieldName: "Abo",
                  value: "Abo",
                  width: 80,
                  subTitle: ["€/an"],
                },
                {
                  twoLine: false,
                  fieldName: "totaleEnergie",
                  value: "Budget Energie",
                  width: 90,
                  subTitle: ["€/an"],
                },
              ];
              pricing["yBegin"] = posY;
              pricing["xBegin"] = 110;
              pricing["UrlPricing"] = "/imagePres/c4Price.PNG";
              pricing["UrlGreen"] = "/imagePres/green.PNG";
              pricing["UrlPricingYellow"] = "/imagePres/c4PriceYellow.PNG";
              pricing["yellow"] = "/imagePres/Yellow1.PNG";
              pricing["data"] = [];

              for (var index = 0; index < listDesOffre.length; index++) {
                var et = null;
                if (listDesOffre[index]["typeYears"] == "many") {
                  et = [
                    {
                      logo:
                        "/logoParticulier/founisseur_logos/" +
                        listDesOffre[index]["logo"],
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["unitairehph"] + "",
                          fieldName: "prixHph",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]["unitairehch"] + "",
                          fieldName: "prixHch",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]["unitairehpe"] + "",
                          fieldName: "prixHpe",
                          width: 48,
                          pas: 6,
                        },
                        {
                          value: listDesOffre[index]["unitairehce"] + "",
                          fieldName: "prixHce",
                          width: 48,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["capa_hph"] + "",
                          fieldName: "capaHph",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]["capa_hch"] + "",
                          fieldName: "capaHch",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]["capa_hpe"] + "",
                          fieldName: "capaHpe",
                          width: 48,
                          pas: 7,
                        },
                        {
                          value: listDesOffre[index]["capa_hce"] + "",
                          fieldName: "capaHce",
                          width: 48,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["cee"] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["aboAns"] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["budgetAbo"] + "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ],
                    },
                  ];
                  pricing["data"].push(et);
                  et = [
                    {
                      logo: "Première année",
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value:
                            listDesOffre[index]["premierAnnee"]["unitairehph"] +
                            "",
                          fieldName: "prixHph",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value:
                            listDesOffre[index]["premierAnnee"]["unitairehch"] +
                            "",
                          fieldName: "prixHch",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value:
                            listDesOffre[index]["premierAnnee"]["unitairehpe"] +
                            "",
                          fieldName: "prixHpe",
                          width: 48,
                          pas: 6,
                        },
                        {
                          value:
                            listDesOffre[index]["premierAnnee"]["unitairehce"] +
                            "",
                          fieldName: "prixHce",
                          width: 48,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value:
                            listDesOffre[index]["premierAnnee"]["capa_hph"] +
                            "",
                          fieldName: "capaHph",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value:
                            listDesOffre[index]["premierAnnee"]["capa_hch"] +
                            "",
                          fieldName: "capaHch",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value:
                            listDesOffre[index]["premierAnnee"]["capa_hpe"] +
                            "",
                          fieldName: "capaHpe",
                          width: 48,
                          pas: 7,
                        },
                        {
                          value:
                            listDesOffre[index]["premierAnnee"]["capa_hce"] +
                            "",
                          fieldName: "capaHce",
                          width: 48,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["cee"] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value:
                            listDesOffre[index]["premierAnnee"]["aboAns"] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value:
                            listDesOffre[index]["secondeAnnee"]["budgetAbo"] +
                            "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ],
                    },
                  ];
                  pricing["data"].push(et);
                  et = [
                    {
                      logo: "Reste d'annees",
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value:
                            listDesOffre[index]["secondeAnnee"]["unitairehph"] +
                            "",
                          fieldName: "prixHph",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value:
                            listDesOffre[index]["secondeAnnee"]["unitairehch"] +
                            "",
                          fieldName: "prixHch",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value:
                            listDesOffre[index]["secondeAnnee"]["unitairehpe"] +
                            "",
                          fieldName: "prixHpe",
                          width: 48,
                          pas: 6,
                        },
                        {
                          value:
                            listDesOffre[index]["secondeAnnee"]["unitairehce"] +
                            "",
                          fieldName: "prixHce",
                          width: 48,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value:
                            listDesOffre[index]["secondeAnnee"]["capa_hph"] +
                            "",
                          fieldName: "capaHph",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value:
                            listDesOffre[index]["secondeAnnee"]["capa_hch"] +
                            "",
                          fieldName: "capaHch",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value:
                            listDesOffre[index]["secondeAnnee"]["capa_hpe"] +
                            "",
                          fieldName: "capaHpe",
                          width: 48,
                          pas: 7,
                        },
                        {
                          value:
                            listDesOffre[index]["secondeAnnee"]["capa_hce"] +
                            "",
                          fieldName: "capaHce",
                          width: 48,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["cee"] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value:
                            listDesOffre[index]["secondeAnnee"]["aboAns"] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value:
                            listDesOffre[index]["secondeAnnee"]["budgetAbo"] +
                            "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ],
                    },
                  ];
                  pricing["data"].push(et);
                }
                if (listDesOffre[index]["typeYears"] == "precie") {
                  et = [
                    {
                      logo:
                        "/logoParticulier/founisseur_logos/" +
                        listDesOffre[index]["logo"],
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["unitairehph"] + "",
                          fieldName: "prixHph",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]["unitairehch"] + "",
                          fieldName: "prixHch",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]["unitairehpe"] + "",
                          fieldName: "prixHpe",
                          width: 48,
                          pas: 6,
                        },
                        {
                          value: listDesOffre[index]["unitairehce"] + "",
                          fieldName: "prixHce",
                          width: 48,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["capa_hph"] + "",
                          fieldName: "capaHph",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]["capa_hch"] + "",
                          fieldName: "capaHch",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]["capa_hpe"] + "",
                          fieldName: "capaHpe",
                          width: 48,
                          pas: 7,
                        },
                        {
                          value: listDesOffre[index]["capa_hce"] + "",
                          fieldName: "capaHce",
                          width: 48,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["cee"] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["aboAns"] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["budgetAbo"] + "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ],
                    },
                  ];
                  pricing["data"].push(et);
                  var lesAnnee = [
                    "2022",
                    "2023",
                    "2024",
                    "2025",
                    "2026",
                    "2027",
                  ];
                  for (var years = 0; years < lesAnnee.length; years++) {
                    var year = lesAnnee[years];
                    if (year in listDesOffre[index]) {
                      et = [
                        {
                          logo: year,
                        },
                        {
                          image: {
                            width: 220,
                            height: 36,
                            name: "pricing",
                          },
                          items: [
                            {
                              value:
                                listDesOffre[index][year]["unitairehph"] + "",
                              fieldName: "prixHph",
                              width: 48,
                              pas: 5,
                            },
                            {
                              value:
                                listDesOffre[index][year]["unitairehch"] + "",
                              fieldName: "prixHch",
                              width: 48,
                              pas: 5,
                            },
                            {
                              value:
                                listDesOffre[index][year]["unitairehpe"] + "",
                              fieldName: "prixHpe",
                              width: 48,
                              pas: 6,
                            },
                            {
                              value:
                                listDesOffre[index][year]["unitairehce"] + "",
                              fieldName: "prixHce",
                              width: 48,
                              pas: 5,
                            },
                          ],
                        },
                        {
                          image: {
                            width: 220,
                            height: 36,
                            name: "pricing",
                          },
                          items: [
                            {
                              value: listDesOffre[index][year]["capa_hph"] + "",
                              fieldName: "capaHph",
                              width: 48,
                              pas: 5,
                            },
                            {
                              value: listDesOffre[index][year]["capa_hch"] + "",
                              fieldName: "capaHch",
                              width: 48,
                              pas: 5,
                            },
                            {
                              value: listDesOffre[index][year]["capa_hpe"] + "",
                              fieldName: "capaHpe",
                              width: 48,
                              pas: 7,
                            },
                            {
                              value: listDesOffre[index][year]["capa_hce"] + "",
                              fieldName: "capaHce",
                              width: 48,
                              pas: 5,
                            },
                          ],
                        },
                        {
                          image: {
                            width: 80,
                            height: 36,
                            name: "color",
                          },
                          items: [
                            {
                              value: listDesOffre[index][year]["cee"] + "",
                              fieldName: "CEE",
                              width: 70,
                              pas: 5,
                            },
                          ],
                        },
                        {
                          image: {
                            width: 80,
                            height: 36,
                            name: "color",
                          },
                          items: [
                            {
                              value: listDesOffre[index][year]["aboAns"] + "",
                              fieldName: "Abo",
                              width: 70,
                              pas: 5,
                            },
                          ],
                        },
                        {
                          image: {
                            width: 90,
                            height: 36,
                            name: "color",
                          },
                          items: [
                            {
                              value:
                                listDesOffre[index][year]["budgetAbo"] + "",
                              fieldName: "totalEnergie",
                              width: 80,
                              pas: 5,
                            },
                          ],
                        },
                      ];
                      pricing["data"].push(et);
                    }
                  }
                } else if (listDesOffre[index]["typeYears"] == "single") {
                  et = [
                    {
                      logo:
                        "/logoParticulier/founisseur_logos/" +
                        listDesOffre[index]["logo"],
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["unitairehph"] + "",
                          fieldName: "prixHph",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]["unitairehch"] + "",
                          fieldName: "prixHch",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]["unitairehpe"] + "",
                          fieldName: "prixHpe",
                          width: 48,
                          pas: 6,
                        },
                        {
                          value: listDesOffre[index]["unitairehce"] + "",
                          fieldName: "prixHce",
                          width: 48,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["capa_hph"] + "",
                          fieldName: "capaHph",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]["capa_hch"] + "",
                          fieldName: "capaHch",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]["capa_hpe"] + "",
                          fieldName: "capaHpe",
                          width: 48,
                          pas: 7,
                        },
                        {
                          value: listDesOffre[index]["capa_hce"] + "",
                          fieldName: "capaHce",
                          width: 48,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["cee"] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["aboAns"] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["budgetAbo"] + "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ],
                    },
                  ];
                  pricing["data"].push(et);
                } else if (listDesOffre[index]["typeYears"] == "avecSpot") {
                  et = [
                    {
                      logo:
                        "/logoParticulier/founisseur_logos/" +
                        listDesOffre[index]["moyenne"]["logo"],
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value:
                            listDesOffre[index]["moyenne"]["unitairehph"] + "",
                          fieldName: "prixHph",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value:
                            listDesOffre[index]["moyenne"]["unitairehch"] + "",
                          fieldName: "prixHch",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value:
                            listDesOffre[index]["moyenne"]["unitairehpe"] + "",
                          fieldName: "prixHpe",
                          width: 48,
                          pas: 6,
                        },
                        {
                          value:
                            listDesOffre[index]["moyenne"]["unitairehce"] + "",
                          fieldName: "prixHce",
                          width: 48,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value:
                            listDesOffre[index]["moyenne"]["capa_hph"] + "",
                          fieldName: "capaHph",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value:
                            listDesOffre[index]["moyenne"]["capa_hch"] + "",
                          fieldName: "capaHch",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value:
                            listDesOffre[index]["moyenne"]["capa_hpe"] + "",
                          fieldName: "capaHpe",
                          width: 48,
                          pas: 7,
                        },
                        {
                          value:
                            listDesOffre[index]["moyenne"]["capa_hce"] + "",
                          fieldName: "capaHce",
                          width: 48,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["moyenne"]["cee"] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["moyenne"]["aboAns"] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value:
                            listDesOffre[index]["moyenne"]["budgetAbo"] + "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ],
                    },
                  ];
                  pricing["data"].push(et);
                  var lesAnnee = ["spot", "grille"];
                  for (var years = 0; years < lesAnnee.length; years++) {
                    var year = lesAnnee[years];
                    var logo = "";
                    if (year == "spot") {
                      logo = "8% Spot";
                    } else {
                      logo = "92% Grille";
                    }
                    if (year in listDesOffre[index]) {
                      et = [
                        {
                          logo: logo,
                        },
                        {
                          image: {
                            width: 220,
                            height: 36,
                            name: "pricing",
                          },
                          items: [
                            {
                              value:
                                listDesOffre[index][year]["unitairehph"] + "",
                              fieldName: "prixHph",
                              width: 48,
                              pas: 5,
                            },
                            {
                              value:
                                listDesOffre[index][year]["unitairehch"] + "",
                              fieldName: "prixHch",
                              width: 48,
                              pas: 5,
                            },
                            {
                              value:
                                listDesOffre[index][year]["unitairehpe"] + "",
                              fieldName: "prixHpe",
                              width: 48,
                              pas: 6,
                            },
                            {
                              value:
                                listDesOffre[index][year]["unitairehce"] + "",
                              fieldName: "prixHce",
                              width: 48,
                              pas: 5,
                            },
                          ],
                        },
                        {
                          image: {
                            width: 220,
                            height: 36,
                            name: "pricing",
                          },
                          items: [
                            {
                              value: listDesOffre[index][year]["capa_hph"] + "",
                              fieldName: "capaHph",
                              width: 48,
                              pas: 5,
                            },
                            {
                              value: listDesOffre[index][year]["capa_hch"] + "",
                              fieldName: "capaHch",
                              width: 48,
                              pas: 5,
                            },
                            {
                              value: listDesOffre[index][year]["capa_hpe"] + "",
                              fieldName: "capaHpe",
                              width: 48,
                              pas: 7,
                            },
                            {
                              value: listDesOffre[index][year]["capa_hce"] + "",
                              fieldName: "capaHce",
                              width: 48,
                              pas: 5,
                            },
                          ],
                        },
                        {
                          image: {
                            width: 80,
                            height: 36,
                            name: "color",
                          },
                          items: [
                            {
                              value: listDesOffre[index][year]["cee"] + "",
                              fieldName: "CEE",
                              width: 70,
                              pas: 5,
                            },
                          ],
                        },
                        {
                          image: {
                            width: 80,
                            height: 36,
                            name: "color",
                          },
                          items: [
                            {
                              value: listDesOffre[index][year]["aboAns"] + "",
                              fieldName: "Abo",
                              width: 70,
                              pas: 5,
                            },
                          ],
                        },
                        {
                          image: {
                            width: 90,
                            height: 36,
                            name: "color",
                          },
                          items: [
                            {
                              value:
                                listDesOffre[index][year]["budgetAbo"] + "",
                              fieldName: "totalEnergie",
                              width: 80,
                              pas: 5,
                            },
                          ],
                        },
                      ];
                      pricing["data"].push(et);
                    }
                  }
                }
              }
            } else if (typeCompteur == "C5C4") {
              pricing["typeCompteur"] = "C4";
              pricing["titre"] = [
                {
                  twoLine: false,
                  fieldName: "prixUnitaire",
                  value: "Prix unitaires en €/MWh",
                  width: 220,
                  subTitle: ["HPH", "HCH", "HPE", "HCE"],
                },
                {
                  twoLine: false,
                  fieldName: "coutCapacite",
                  value: "Coût capacité en €/MWh",
                  width: 220,
                  subTitle: ["HPH", "HCH", "HPE", "HCE"],
                },
                {
                  twoLine: false,
                  fieldName: "CEE",
                  value: "CEE",
                  width: 80,
                  subTitle: ["€/MWh"],
                },
                {
                  twoLine: false,
                  fieldName: "Abo",
                  value: "Abo",
                  width: 80,
                  subTitle: ["€/an"],
                },
                {
                  twoLine: false,
                  fieldName: "totaleEnergie",
                  value: "Budget Energie",
                  width: 90,
                  subTitle: ["€/an"],
                },
              ];
              pricing["yBegin"] = posY;
              pricing["xBegin"] = 110;
              pricing["UrlPricing"] = "/imagePres/c4Price.PNG";
              pricing["UrlGreen"] = "/imagePres/green.PNG";
              pricing["UrlPricingYellow"] = "/imagePres/c4PriceYellow.PNG";
              pricing["yellow"] = "/imagePres/Yellow1.PNG";
              pricing["data"] = [];

              for (var index = 0; index < listDesOffre.length; index++) {
                var et = null;
                if (listDesOffre[index]["typeYears"] == "many") {
                  et = [
                    {
                      logo:
                        "/logoParticulier/founisseur_logos/" +
                        listDesOffre[index]["logo"],
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["unitairehph"] + "",
                          fieldName: "prixHph",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]["unitairehch"] + "",
                          fieldName: "prixHch",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]["unitairehpe"] + "",
                          fieldName: "prixHpe",
                          width: 48,
                          pas: 6,
                        },
                        {
                          value: listDesOffre[index]["unitairehce"] + "",
                          fieldName: "prixHce",
                          width: 48,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["capa_hph"] + "",
                          fieldName: "capaHph",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]["capa_hch"] + "",
                          fieldName: "capaHch",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]["capa_hpe"] + "",
                          fieldName: "capaHpe",
                          width: 48,
                          pas: 7,
                        },
                        {
                          value: listDesOffre[index]["capa_hce"] + "",
                          fieldName: "capaHce",
                          width: 48,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["cee"] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["aboAns"] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["budgetAbo"] + "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ],
                    },
                  ];
                  pricing["data"].push(et);
                  et = [
                    {
                      logo: "Première année",
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value:
                            listDesOffre[index]["premierAnnee"]["unitairehph"] +
                            "",
                          fieldName: "prixHph",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value:
                            listDesOffre[index]["premierAnnee"]["unitairehch"] +
                            "",
                          fieldName: "prixHch",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value:
                            listDesOffre[index]["premierAnnee"]["unitairehpe"] +
                            "",
                          fieldName: "prixHpe",
                          width: 48,
                          pas: 6,
                        },
                        {
                          value:
                            listDesOffre[index]["premierAnnee"]["unitairehce"] +
                            "",
                          fieldName: "prixHce",
                          width: 48,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value:
                            listDesOffre[index]["premierAnnee"]["capa_hph"] +
                            "",
                          fieldName: "capaHph",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value:
                            listDesOffre[index]["premierAnnee"]["capa_hch"] +
                            "",
                          fieldName: "capaHch",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value:
                            listDesOffre[index]["premierAnnee"]["capa_hpe"] +
                            "",
                          fieldName: "capaHpe",
                          width: 48,
                          pas: 7,
                        },
                        {
                          value:
                            listDesOffre[index]["premierAnnee"]["capa_hce"] +
                            "",
                          fieldName: "capaHce",
                          width: 48,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["cee"] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value:
                            listDesOffre[index]["premierAnnee"]["aboAns"] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value:
                            listDesOffre[index]["secondeAnnee"]["budgetAbo"] +
                            "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ],
                    },
                  ];
                  pricing["data"].push(et);
                  et = [
                    {
                      logo: "Reste d'annees",
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value:
                            listDesOffre[index]["secondeAnnee"]["unitairehph"] +
                            "",
                          fieldName: "prixHph",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value:
                            listDesOffre[index]["secondeAnnee"]["unitairehch"] +
                            "",
                          fieldName: "prixHch",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value:
                            listDesOffre[index]["secondeAnnee"]["unitairehpe"] +
                            "",
                          fieldName: "prixHpe",
                          width: 48,
                          pas: 6,
                        },
                        {
                          value:
                            listDesOffre[index]["secondeAnnee"]["unitairehce"] +
                            "",
                          fieldName: "prixHce",
                          width: 48,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value:
                            listDesOffre[index]["secondeAnnee"]["capa_hph"] +
                            "",
                          fieldName: "capaHph",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value:
                            listDesOffre[index]["secondeAnnee"]["capa_hch"] +
                            "",
                          fieldName: "capaHch",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value:
                            listDesOffre[index]["secondeAnnee"]["capa_hpe"] +
                            "",
                          fieldName: "capaHpe",
                          width: 48,
                          pas: 7,
                        },
                        {
                          value:
                            listDesOffre[index]["secondeAnnee"]["capa_hce"] +
                            "",
                          fieldName: "capaHce",
                          width: 48,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["cee"] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value:
                            listDesOffre[index]["secondeAnnee"]["aboAns"] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value:
                            listDesOffre[index]["secondeAnnee"]["budgetAbo"] +
                            "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ],
                    },
                  ];
                  pricing["data"].push(et);
                }
                if (listDesOffre[index]["typeYears"] == "precie") {
                  et = [
                    {
                      logo:
                        "/logoParticulier/founisseur_logos/" +
                        listDesOffre[index]["logo"],
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["unitairehph"] + "",
                          fieldName: "prixHph",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]["unitairehch"] + "",
                          fieldName: "prixHch",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]["unitairehpe"] + "",
                          fieldName: "prixHpe",
                          width: 48,
                          pas: 6,
                        },
                        {
                          value: listDesOffre[index]["unitairehce"] + "",
                          fieldName: "prixHce",
                          width: 48,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["capa_hph"] + "",
                          fieldName: "capaHph",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]["capa_hch"] + "",
                          fieldName: "capaHch",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]["capa_hpe"] + "",
                          fieldName: "capaHpe",
                          width: 48,
                          pas: 7,
                        },
                        {
                          value: listDesOffre[index]["capa_hce"] + "",
                          fieldName: "capaHce",
                          width: 48,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["cee"] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["aboAns"] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["budgetAbo"] + "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ],
                    },
                  ];
                  pricing["data"].push(et);
                  var lesAnnee = [
                    "2022",
                    "2023",
                    "2024",
                    "2025",
                    "2026",
                    "2027",
                  ];
                  for (var years = 0; years < lesAnnee.length; years++) {
                    var year = lesAnnee[years];
                    if (year in listDesOffre[index]) {
                      et = [
                        {
                          logo: year,
                        },
                        {
                          image: {
                            width: 220,
                            height: 36,
                            name: "pricing",
                          },
                          items: [
                            {
                              value:
                                listDesOffre[index][year]["unitairehph"] + "",
                              fieldName: "prixHph",
                              width: 48,
                              pas: 5,
                            },
                            {
                              value:
                                listDesOffre[index][year]["unitairehch"] + "",
                              fieldName: "prixHch",
                              width: 48,
                              pas: 5,
                            },
                            {
                              value:
                                listDesOffre[index][year]["unitairehpe"] + "",
                              fieldName: "prixHpe",
                              width: 48,
                              pas: 6,
                            },
                            {
                              value:
                                listDesOffre[index][year]["unitairehce"] + "",
                              fieldName: "prixHce",
                              width: 48,
                              pas: 5,
                            },
                          ],
                        },
                        {
                          image: {
                            width: 220,
                            height: 36,
                            name: "pricing",
                          },
                          items: [
                            {
                              value: listDesOffre[index][year]["capa_hph"] + "",
                              fieldName: "capaHph",
                              width: 48,
                              pas: 5,
                            },
                            {
                              value: listDesOffre[index][year]["capa_hch"] + "",
                              fieldName: "capaHch",
                              width: 48,
                              pas: 5,
                            },
                            {
                              value: listDesOffre[index][year]["capa_hpe"] + "",
                              fieldName: "capaHpe",
                              width: 48,
                              pas: 7,
                            },
                            {
                              value: listDesOffre[index][year]["capa_hce"] + "",
                              fieldName: "capaHce",
                              width: 48,
                              pas: 5,
                            },
                          ],
                        },
                        {
                          image: {
                            width: 80,
                            height: 36,
                            name: "color",
                          },
                          items: [
                            {
                              value: listDesOffre[index][year]["cee"] + "",
                              fieldName: "CEE",
                              width: 70,
                              pas: 5,
                            },
                          ],
                        },
                        {
                          image: {
                            width: 80,
                            height: 36,
                            name: "color",
                          },
                          items: [
                            {
                              value: listDesOffre[index][year]["aboAns"] + "",
                              fieldName: "Abo",
                              width: 70,
                              pas: 5,
                            },
                          ],
                        },
                        {
                          image: {
                            width: 90,
                            height: 36,
                            name: "color",
                          },
                          items: [
                            {
                              value:
                                listDesOffre[index][year]["budgetAbo"] + "",
                              fieldName: "totalEnergie",
                              width: 80,
                              pas: 5,
                            },
                          ],
                        },
                      ];
                      pricing["data"].push(et);
                    }
                  }
                } else if (listDesOffre[index]["typeYears"] == "single") {
                  et = [
                    {
                      logo:
                        "/logoParticulier/founisseur_logos/" +
                        listDesOffre[index]["logo"],
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["unitairehph"] + "",
                          fieldName: "prixHph",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]["unitairehch"] + "",
                          fieldName: "prixHch",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]["unitairehpe"] + "",
                          fieldName: "prixHpe",
                          width: 48,
                          pas: 6,
                        },
                        {
                          value: listDesOffre[index]["unitairehce"] + "",
                          fieldName: "prixHce",
                          width: 48,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["capa_hph"] + "",
                          fieldName: "capaHph",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]["capa_hch"] + "",
                          fieldName: "capaHch",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]["capa_hpe"] + "",
                          fieldName: "capaHpe",
                          width: 48,
                          pas: 7,
                        },
                        {
                          value: listDesOffre[index]["capa_hce"] + "",
                          fieldName: "capaHce",
                          width: 48,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["cee"] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["aboAns"] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["budgetAbo"] + "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ],
                    },
                  ];
                  pricing["data"].push(et);
                } else if (listDesOffre[index]["typeYears"] == "avecSpot") {
                  et = [
                    {
                      logo:
                        "/logoParticulier/founisseur_logos/" +
                        listDesOffre[index]["moyenne"]["logo"],
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value:
                            listDesOffre[index]["moyenne"]["unitairehph"] + "",
                          fieldName: "prixHph",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value:
                            listDesOffre[index]["moyenne"]["unitairehch"] + "",
                          fieldName: "prixHch",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value:
                            listDesOffre[index]["moyenne"]["unitairehpe"] + "",
                          fieldName: "prixHpe",
                          width: 48,
                          pas: 6,
                        },
                        {
                          value:
                            listDesOffre[index]["moyenne"]["unitairehce"] + "",
                          fieldName: "prixHce",
                          width: 48,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value:
                            listDesOffre[index]["moyenne"]["capa_hph"] + "",
                          fieldName: "capaHph",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value:
                            listDesOffre[index]["moyenne"]["capa_hch"] + "",
                          fieldName: "capaHch",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value:
                            listDesOffre[index]["moyenne"]["capa_hpe"] + "",
                          fieldName: "capaHpe",
                          width: 48,
                          pas: 7,
                        },
                        {
                          value:
                            listDesOffre[index]["moyenne"]["capa_hce"] + "",
                          fieldName: "capaHce",
                          width: 48,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["moyenne"]["cee"] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["moyenne"]["aboAns"] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value:
                            listDesOffre[index]["moyenne"]["budgetAbo"] + "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ],
                    },
                  ];
                  pricing["data"].push(et);
                  var lesAnnee = ["spot", "grille"];
                  for (var years = 0; years < lesAnnee.length; years++) {
                    var year = lesAnnee[years];
                    var logo = "";
                    if (year == "spot") {
                      logo = "8% Spot";
                    } else {
                      logo = "92% Grille";
                    }
                    if (year in listDesOffre[index]) {
                      et = [
                        {
                          logo: logo,
                        },
                        {
                          image: {
                            width: 220,
                            height: 36,
                            name: "pricing",
                          },
                          items: [
                            {
                              value:
                                listDesOffre[index][year]["unitairehph"] + "",
                              fieldName: "prixHph",
                              width: 48,
                              pas: 5,
                            },
                            {
                              value:
                                listDesOffre[index][year]["unitairehch"] + "",
                              fieldName: "prixHch",
                              width: 48,
                              pas: 5,
                            },
                            {
                              value:
                                listDesOffre[index][year]["unitairehpe"] + "",
                              fieldName: "prixHpe",
                              width: 48,
                              pas: 6,
                            },
                            {
                              value:
                                listDesOffre[index][year]["unitairehce"] + "",
                              fieldName: "prixHce",
                              width: 48,
                              pas: 5,
                            },
                          ],
                        },
                        {
                          image: {
                            width: 220,
                            height: 36,
                            name: "pricing",
                          },
                          items: [
                            {
                              value: listDesOffre[index][year]["capa_hph"] + "",
                              fieldName: "capaHph",
                              width: 48,
                              pas: 5,
                            },
                            {
                              value: listDesOffre[index][year]["capa_hch"] + "",
                              fieldName: "capaHch",
                              width: 48,
                              pas: 5,
                            },
                            {
                              value: listDesOffre[index][year]["capa_hpe"] + "",
                              fieldName: "capaHpe",
                              width: 48,
                              pas: 7,
                            },
                            {
                              value: listDesOffre[index][year]["capa_hce"] + "",
                              fieldName: "capaHce",
                              width: 48,
                              pas: 5,
                            },
                          ],
                        },
                        {
                          image: {
                            width: 80,
                            height: 36,
                            name: "color",
                          },
                          items: [
                            {
                              value: listDesOffre[index][year]["cee"] + "",
                              fieldName: "CEE",
                              width: 70,
                              pas: 5,
                            },
                          ],
                        },
                        {
                          image: {
                            width: 80,
                            height: 36,
                            name: "color",
                          },
                          items: [
                            {
                              value: listDesOffre[index][year]["aboAns"] + "",
                              fieldName: "Abo",
                              width: 70,
                              pas: 5,
                            },
                          ],
                        },
                        {
                          image: {
                            width: 90,
                            height: 36,
                            name: "color",
                          },
                          items: [
                            {
                              value:
                                listDesOffre[index][year]["budgetAbo"] + "",
                              fieldName: "totalEnergie",
                              width: 80,
                              pas: 5,
                            },
                          ],
                        },
                      ];
                      pricing["data"].push(et);
                    }
                  }
                }
              }
            } else if (typeCompteur == "C3") {
              pricing["typeCompteur"] = "C3";
              pricing["titre"] = [
                {
                  twoLine: false,
                  fieldName: "prixUnitaire",
                  value: "Prix unitaires en €/MWh",
                  width: 220,
                  subTitle: ["PTE", "HPH", "HCH", "HPE", "HCE"],
                },
                {
                  twoLine: false,
                  fieldName: "coutCapacite",
                  value: "Coût capacité en €/MWh",
                  width: 220,
                  subTitle: ["PTE", "HPH", "HCH", "HPE", "HCE"],
                },
                {
                  twoLine: false,
                  fieldName: "CEE",
                  value: "CEE",
                  width: 80,
                  subTitle: ["€/MWh"],
                },
                {
                  twoLine: false,
                  fieldName: "Abo",
                  value: "Abo",
                  width: 80,
                  subTitle: ["€/an"],
                },
                {
                  twoLine: false,
                  fieldName: "totaleEnergie",
                  value: "Budget Energie",
                  width: 90,
                  subTitle: ["€/an"],
                },
              ];
              pricing["yBegin"] = posY;
              pricing["xBegin"] = 110;
              pricing["UrlPricing"] = "/imagePres/c3Price.PNG";
              pricing["UrlGreen"] = "/imagePres/green.PNG";
              pricing["UrlPricingYellow"] = "/imagePres/c3PriceYellow.PNG";
              pricing["yellow"] = "/imagePres/Yellow1.PNG";
              pricing["data"] = [];

              for (var index = 0; index < listDesOffre.length; index++) {
                var et = null;
                if (listDesOffre[index]["typeYears"] == "many") {
                  et = [
                    {
                      logo:
                        "/logoParticulier/founisseur_logos/" +
                        listDesOffre[index]["logo"],
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["unitairepointe"] + "",
                          fieldName: "prixPte",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]["unitairehph"] + "",
                          fieldName: "prixHph",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]["unitairehch"] + "",
                          fieldName: "prixHch",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]["unitairehpe"] + "",
                          fieldName: "prixHpe",
                          width: 38,
                          pas: 6,
                        },
                        {
                          value: listDesOffre[index]["unitairehce"] + "",
                          fieldName: "prixHce",
                          width: 38,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["capa_pointe"] + "",
                          fieldName: "capaPte",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]["capa_hph"] + "",
                          fieldName: "capaHph",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]["capa_hch"] + "",
                          fieldName: "capaHch",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]["capa_hpe"] + "",
                          fieldName: "capaHpe",
                          width: 38,
                          pas: 7,
                        },
                        {
                          value: listDesOffre[index]["capa_hce"] + "",
                          fieldName: "capaHce",
                          width: 38,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["cee"] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["aboAns"] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["budgetAbo"] + "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ],
                    },
                  ];
                  pricing["data"].push(et);
                  et = [
                    {
                      logo: "Première année",
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value:
                            listDesOffre[index]["premierAnnee"][
                              "unitairepointe"
                            ] + "",
                          fieldName: "prixPte",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value:
                            listDesOffre[index]["premierAnnee"]["unitairehph"] +
                            "",
                          fieldName: "prixHph",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value:
                            listDesOffre[index]["premierAnnee"]["unitairehch"] +
                            "",
                          fieldName: "prixHch",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value:
                            listDesOffre[index]["premierAnnee"]["unitairehpe"] +
                            "",
                          fieldName: "prixHpe",
                          width: 38,
                          pas: 6,
                        },
                        {
                          value:
                            listDesOffre[index]["premierAnnee"]["unitairehce"] +
                            "",
                          fieldName: "prixHce",
                          width: 38,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value:
                            listDesOffre[index]["premierAnnee"]["capa_pointe"] +
                            "",
                          fieldName: "capaPte",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value:
                            listDesOffre[index]["premierAnnee"]["capa_hph"] +
                            "",
                          fieldName: "capaHph",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value:
                            listDesOffre[index]["premierAnnee"]["capa_hch"] +
                            "",
                          fieldName: "capaHch",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value:
                            listDesOffre[index]["premierAnnee"]["capa_hpe"] +
                            "",
                          fieldName: "capaHpe",
                          width: 38,
                          pas: 7,
                        },
                        {
                          value:
                            listDesOffre[index]["premierAnnee"]["capa_hce"] +
                            "",
                          fieldName: "capaHce",
                          width: 38,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["cee"] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value:
                            listDesOffre[index]["premierAnnee"]["aboAns"] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value:
                            listDesOffre[index]["premierAnnee"]["budgetAbo"] +
                            "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ],
                    },
                  ];
                  pricing["data"].push(et);
                  et = [
                    {
                      logo: "Reste d'annees",
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value:
                            listDesOffre[index]["secondeAnnee"][
                              "unitairepointe"
                            ] + "",
                          fieldName: "prixPte",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value:
                            listDesOffre[index]["secondeAnnee"]["unitairehph"] +
                            "",
                          fieldName: "prixHph",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value:
                            listDesOffre[index]["secondeAnnee"]["unitairehch"] +
                            "",
                          fieldName: "prixHch",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value:
                            listDesOffre[index]["secondeAnnee"]["unitairehpe"] +
                            "",
                          fieldName: "prixHpe",
                          width: 38,
                          pas: 6,
                        },
                        {
                          value:
                            listDesOffre[index]["secondeAnnee"]["unitairehce"] +
                            "",
                          fieldName: "prixHce",
                          width: 38,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value:
                            listDesOffre[index]["secondeAnnee"]["capa_pointe"] +
                            "",
                          fieldName: "capaPte",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value:
                            listDesOffre[index]["secondeAnnee"]["capa_hph"] +
                            "",
                          fieldName: "capaHph",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value:
                            listDesOffre[index]["secondeAnnee"]["capa_hch"] +
                            "",
                          fieldName: "capaHch",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value:
                            listDesOffre[index]["secondeAnnee"]["capa_hpe"] +
                            "",
                          fieldName: "capaHpe",
                          width: 38,
                          pas: 7,
                        },
                        {
                          value:
                            listDesOffre[index]["secondeAnnee"]["capa_hce"] +
                            "",
                          fieldName: "capaHce",
                          width: 38,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["cee"] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value:
                            listDesOffre[index]["secondeAnnee"]["aboAns"] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value:
                            listDesOffre[index]["secondeAnnee"]["budgetAbo"] +
                            "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ],
                    },
                  ];
                  pricing["data"].push(et);
                } else if (listDesOffre[index]["typeYears"] == "precie") {
                  et = [
                    {
                      logo:
                        "/logoParticulier/founisseur_logos/" +
                        listDesOffre[index]["logo"],
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["unitairepointe"] + "",
                          fieldName: "prixPte",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]["unitairehph"] + "",
                          fieldName: "prixHph",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]["unitairehch"] + "",
                          fieldName: "prixHch",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]["unitairehpe"] + "",
                          fieldName: "prixHpe",
                          width: 38,
                          pas: 6,
                        },
                        {
                          value: listDesOffre[index]["unitairehce"] + "",
                          fieldName: "prixHce",
                          width: 38,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["capa_pointe"] + "",
                          fieldName: "capaPte",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]["capa_hph"] + "",
                          fieldName: "capaHph",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]["capa_hch"] + "",
                          fieldName: "capaHch",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]["capa_hpe"] + "",
                          fieldName: "capaHpe",
                          width: 38,
                          pas: 7,
                        },
                        {
                          value: listDesOffre[index]["capa_hce"] + "",
                          fieldName: "capaHce",
                          width: 38,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["cee"] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["aboAns"] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["budgetAbo"] + "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ],
                    },
                  ];
                  pricing["data"].push(et);
                  var lesAnnee = [
                    "2022",
                    "2023",
                    "2024",
                    "2025",
                    "2026",
                    "2027",
                  ];
                  for (var years = 0; years < lesAnnee.length; years++) {
                    var year = lesAnnee[years];
                    if (year in listDesOffre[index]) {
                      et = [
                        {
                          logo: year,
                        },
                        {
                          image: {
                            width: 220,
                            height: 36,
                            name: "pricing",
                          },
                          items: [
                            {
                              value:
                                listDesOffre[index][year]["unitairepointe"] +
                                "",
                              fieldName: "prixPte",
                              width: 38,
                              pas: 5,
                            },
                            {
                              value:
                                listDesOffre[index][year]["unitairehph"] + "",
                              fieldName: "prixHph",
                              width: 38,
                              pas: 5,
                            },
                            {
                              value:
                                listDesOffre[index][year]["unitairehch"] + "",
                              fieldName: "prixHch",
                              width: 38,
                              pas: 5,
                            },
                            {
                              value:
                                listDesOffre[index][year]["unitairehpe"] + "",
                              fieldName: "prixHpe",
                              width: 38,
                              pas: 6,
                            },
                            {
                              value:
                                listDesOffre[index][year]["unitairehce"] + "",
                              fieldName: "prixHce",
                              width: 38,
                              pas: 5,
                            },
                          ],
                        },
                        {
                          image: {
                            width: 220,
                            height: 36,
                            name: "pricing",
                          },
                          items: [
                            {
                              value:
                                listDesOffre[index][year]["capa_pointe"] + "",
                              fieldName: "capaPte",
                              width: 38,
                              pas: 5,
                            },
                            {
                              value: listDesOffre[index][year]["capa_hph"] + "",
                              fieldName: "capaHph",
                              width: 38,
                              pas: 5,
                            },
                            {
                              value: listDesOffre[index][year]["capa_hch"] + "",
                              fieldName: "capaHch",
                              width: 38,
                              pas: 5,
                            },
                            {
                              value: listDesOffre[index][year]["capa_hpe"] + "",
                              fieldName: "capaHpe",
                              width: 38,
                              pas: 7,
                            },
                            {
                              value: listDesOffre[index][year]["capa_hce"] + "",
                              fieldName: "capaHce",
                              width: 38,
                              pas: 5,
                            },
                          ],
                        },
                        {
                          image: {
                            width: 80,
                            height: 36,
                            name: "color",
                          },
                          items: [
                            {
                              value: listDesOffre[index]["cee"] + "",
                              fieldName: "CEE",
                              width: 70,
                              pas: 5,
                            },
                          ],
                        },
                        {
                          image: {
                            width: 80,
                            height: 36,
                            name: "color",
                          },
                          items: [
                            {
                              value: listDesOffre[index][year]["aboAns"] + "",
                              fieldName: "Abo",
                              width: 70,
                              pas: 5,
                            },
                          ],
                        },
                        {
                          image: {
                            width: 90,
                            height: 36,
                            name: "color",
                          },
                          items: [
                            {
                              value:
                                listDesOffre[index][year]["budgetAbo"] + "",
                              fieldName: "totalEnergie",
                              width: 80,
                              pas: 5,
                            },
                          ],
                        },
                      ];
                      pricing["data"].push(et);
                    }
                  }
                } else if (listDesOffre[index]["typeYears"] == "single") {
                  et = [
                    {
                      logo:
                        "/logoParticulier/founisseur_logos/" +
                        listDesOffre[index]["logo"],
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["unitairepointe"] + "",
                          fieldName: "prixPte",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]["unitairehph"] + "",
                          fieldName: "prixHph",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]["unitairehch"] + "",
                          fieldName: "prixHch",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]["unitairehpe"] + "",
                          fieldName: "prixHpe",
                          width: 38,
                          pas: 6,
                        },
                        {
                          value: listDesOffre[index]["unitairehce"] + "",
                          fieldName: "prixHce",
                          width: 38,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["capa_pointe"] + "",
                          fieldName: "capaPte",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]["capa_hph"] + "",
                          fieldName: "capaHph",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]["capa_hch"] + "",
                          fieldName: "capaHch",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]["capa_hpe"] + "",
                          fieldName: "capaHpe",
                          width: 38,
                          pas: 7,
                        },
                        {
                          value: listDesOffre[index]["capa_hce"] + "",
                          fieldName: "capaHce",
                          width: 38,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["cee"] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["aboAns"] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["budgetAbo"] + "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ],
                    },
                  ];
                  pricing["data"].push(et);
                } else if (listDesOffre[index]["typeYears"] == "avecSpot") {
                  et = [
                    {
                      logo:
                        "/logoParticulier/founisseur_logos/" +
                        listDesOffre[index]["moyenne"]["logo"],
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value:
                            listDesOffre[index]["moyenne"]["unitairepointe"] +
                            "",
                          fieldName: "prixPte",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value:
                            listDesOffre[index]["moyenne"]["unitairehph"] + "",
                          fieldName: "prixHph",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value:
                            listDesOffre[index]["moyenne"]["unitairehch"] + "",
                          fieldName: "prixHch",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value:
                            listDesOffre[index]["moyenne"]["unitairehpe"] + "",
                          fieldName: "prixHpe",
                          width: 38,
                          pas: 6,
                        },
                        {
                          value:
                            listDesOffre[index]["moyenne"]["unitairehce"] + "",
                          fieldName: "prixHce",
                          width: 38,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value:
                            listDesOffre[index]["moyenne"]["capa_pointe"] + "",
                          fieldName: "capaPte",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value:
                            listDesOffre[index]["moyenne"]["capa_hph"] + "",
                          fieldName: "capaHph",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value:
                            listDesOffre[index]["moyenne"]["capa_hch"] + "",
                          fieldName: "capaHch",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value:
                            listDesOffre[index]["moyenne"]["capa_hpe"] + "",
                          fieldName: "capaHpe",
                          width: 38,
                          pas: 7,
                        },
                        {
                          value:
                            listDesOffre[index]["moyenne"]["capa_hce"] + "",
                          fieldName: "capaHce",
                          width: 38,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["moyenne"]["cee"] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["moyenne"]["aboAns"] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ],
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value:
                            listDesOffre[index]["moyenne"]["budgetAbo"] + "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ],
                    },
                  ];
                  pricing["data"].push(et);
                  var lesAnnee = ["spot", "grille"];
                  for (var years = 0; years < lesAnnee.length; years++) {
                    var year = lesAnnee[years];
                    var logo = "";
                    if (year == "spot") {
                      logo = "8% Spot";
                    } else {
                      logo = "92% Grille";
                    }
                    if (year in listDesOffre[index]) {
                      et = [
                        {
                          logo: logo,
                        },
                        {
                          image: {
                            width: 220,
                            height: 36,
                            name: "pricing",
                          },
                          items: [
                            {
                              value:
                                listDesOffre[index][year]["unitairepointe"] +
                                "",
                              fieldName: "prixPte",
                              width: 38,
                              pas: 5,
                            },
                            {
                              value:
                                listDesOffre[index][year]["unitairehph"] + "",
                              fieldName: "prixHph",
                              width: 38,
                              pas: 5,
                            },
                            {
                              value:
                                listDesOffre[index][year]["unitairehch"] + "",
                              fieldName: "prixHch",
                              width: 38,
                              pas: 5,
                            },
                            {
                              value:
                                listDesOffre[index][year]["unitairehpe"] + "",
                              fieldName: "prixHpe",
                              width: 38,
                              pas: 6,
                            },
                            {
                              value:
                                listDesOffre[index][year]["unitairehce"] + "",
                              fieldName: "prixHce",
                              width: 38,
                              pas: 5,
                            },
                          ],
                        },
                        {
                          image: {
                            width: 220,
                            height: 36,
                            name: "pricing",
                          },
                          items: [
                            {
                              value:
                                listDesOffre[index][year]["capa_pointe"] + "",
                              fieldName: "capaPte",
                              width: 38,
                              pas: 5,
                            },
                            {
                              value: listDesOffre[index][year]["capa_hph"] + "",
                              fieldName: "capaHph",
                              width: 38,
                              pas: 5,
                            },
                            {
                              value: listDesOffre[index][year]["capa_hch"] + "",
                              fieldName: "capaHch",
                              width: 38,
                              pas: 5,
                            },
                            {
                              value: listDesOffre[index][year]["capa_hpe"] + "",
                              fieldName: "capaHpe",
                              width: 38,
                              pas: 7,
                            },
                            {
                              value: listDesOffre[index][year]["capa_hce"] + "",
                              fieldName: "capaHce",
                              width: 38,
                              pas: 5,
                            },
                          ],
                        },
                        {
                          image: {
                            width: 80,
                            height: 36,
                            name: "color",
                          },
                          items: [
                            {
                              value: listDesOffre[index]["cee"] + "",
                              fieldName: "CEE",
                              width: 70,
                              pas: 5,
                            },
                          ],
                        },
                        {
                          image: {
                            width: 80,
                            height: 36,
                            name: "color",
                          },
                          items: [
                            {
                              value: listDesOffre[index][year]["aboAns"] + "",
                              fieldName: "Abo",
                              width: 70,
                              pas: 5,
                            },
                          ],
                        },
                        {
                          image: {
                            width: 90,
                            height: 36,
                            name: "color",
                          },
                          items: [
                            {
                              value:
                                listDesOffre[index][year]["budgetAbo"] + "",
                              fieldName: "totalEnergie",
                              width: 80,
                              pas: 5,
                            },
                          ],
                        },
                      ];
                      pricing["data"].push(et);
                    }
                  }
                }
              }
            }
            //createPDFWithMultipleTables
            /*pdfDoc = await this.drawPricingTbaleC3(
              form,
              pdfDoc,
              page,
              pricing,
              fournisseur,
              fournisseurActuelle
            );*/
            var bestOffre = null;
            if (fournisseurActuelle == true) {
              bestOffre = listDesOffre[0];
            } else {
              bestOffre = listDesOffre[0];
            }
            donnerCompteur["resultatEnedis"] =
              fournisseur["resultat"]["dataEnedis"];
            /*var laPages = await this.totalTtcParMois(
              pdfDoc,
              bestOffre,
              donnerCompteur,
              typeCompteur
            );*/

            /*if (laPages != false) {
              let tablePagedetaille = await PDFDocument.load(laPages);
              let tablePageDocdetaille = tablePagedetaille;
              const pagesdetaille = tablePageDocdetaille.getPages();
              const firstPagedetaille = pagesdetaille[0];

              /*var logoSmart = await this.getImage(
                "images/logos/logoSmart.png",
                tablePagedetaille
              );
              
              firstPagedetaille.drawImage(logoSmart, {
                x: 600,
                y: 500,
                width: 155,
                height: 45,
              });
              var y = 525;
              var HelveticaBold = await tablePagedetaille.embedFont(
                StandardFonts.HelveticaBold
              );
              firstPagedetaille.drawText("Budget TTC mensuel détaillé", {
                x: 50,
                y: y,
                font: HelveticaBold,
                size: 25,
                color: rgb(0.062, 0.227, 0.368),
              });

              pdfDoc = await this.addPagesToPdf(pdfDoc, tablePagedetaille);
            }*/
            let fournisseurList = [];
            for (var i = 0; i < listDesOffre.length; i++) {
              fournisseurList[i] =
                listDesOffre[i]["fournisseur"].toLowerCase() + ".pdf";
            }
            if (fournisseurActuelle == true) {
              fournisseurList.push(
                fournisseur["resultat"]["result"][0][
                  "fournisseur"
                ].toLowerCase() + ".pdf"
              );
            }
          }
        }

        fournisseurList = this.filterArray(fournisseurList);
        for (var i = 0; i < fournisseurList.length; i++) {
          var name = fournisseurList[i];
          try {
            const url4 = "/newFournisseurs/" + name;
            const fournisseurPdfBytes = await fetch(url4).then((res) =>
              res.arrayBuffer()
            );

            const fournisseurPdfDoc = await PDFDocument.load(
              fournisseurPdfBytes
            );

            pdfDoc = await this.addPagesToPdf(pdfDoc, fournisseurPdfDoc);
          } catch (error) {
            console.log("Presentation Fournisseur introuvable " + name);
          }
        }
        /*const presentationURLPartFinal = "/pdf/Presentation-11-13.pdf";
        const presentationPartFinalBytes = await fetch(
          presentationURLPartFinal
        ).then((res) => res.arrayBuffer());
        var presentationPagePartFinalDoc = await PDFDocument.load(
          presentationPartFinalBytes
        );

        var getPages = presentationPagePartFinalDoc.getPages();*/

        const presentationURLContact = "/pdf/Page_Contact.pdf";
        const presentationPartContactBytes = await fetch(
          presentationURLContact
        ).then((res) => res.arrayBuffer());
        var presentationPagePartContactDoc = await PDFDocument.load(
          presentationPartContactBytes
        );

        var getPagesContact = presentationPagePartContactDoc.getPages();

        const timesRomanFont = await presentationPagePartContactDoc.embedFont(
          StandardFonts.HelveticaBold
        );
        const contactPage = getPagesContact[0];
        // var username = localStorage.getItem("email");
        // var nomPrenom = localStorage.getItem("vendeurName");
        // var Telephone = this.formatPhoneNumber(
        //   localStorage.getItem("Telephone")
        // );
        var username = "contact@volt-consulting.fr";
        var nomPrenom = "VOLT CONSULTING";
        var Telephone = "01 87 66 70 43";
        // let roleUser = localStorage.getItem("typeUser");
        const Helvetica = await presentationPagePartContactDoc.embedFont(
          StandardFonts.Helvetica
        );

        contactPage.drawText(this.capitalizeEachWord(nomPrenom), {
          x: 460,
          y: 360,
          size: 26,
          font: Helvetica,
          color: rgb(1, 1, 1),
        });
        /*contactPage.drawText(roleUser.toUpperCase(), {
          x: 460,
          y: 320,
          size: 20,
          font: Helvetica,
          color: rgb(1, 1, 1),
        });*/
        if (Telephone != null && Telephone != "null") {
          contactPage.drawText(Telephone, {
            x: 480,
            y: 275,
            size: 25,
            font: Helvetica,
            color: rgb(1, 1, 1),
          });
        }
        const createPageLinkAnnotation = (PDFPage, string) => {
          PDFPage.doc.context.register(
            contactPage.doc.context.obj({
              Type: "Annot",
              Subtype: "Link",
              Rect: [0, 30, 40, 230],
              Border: [0, 0, 2],
              C: [0, 0, 1],
              A: {
                Type: "Action",
                S: "URI",
                URI: PDFString.of(`mailto:${string}`),
              },
            })
          );
        };
        contactPage.drawText(username, {
          x: 480,
          y: 225,
          size: 20,
          font: Helvetica,
          color: rgb(1, 1, 1),
        });

        const link = createPageLinkAnnotation(contactPage, username);
        contactPage.node.set(
          PDFName.of("Annots"),
          presentationPagePartContactDoc.context.obj([link])
        );

        const presentationURLChangementSansContrainte =
          "/pdf/Page_Changement.pdf";
        const presentationURLChangementSansContraintelBytes = await fetch(
          presentationURLChangementSansContrainte
        ).then((res) => res.arrayBuffer());
        var presentationURLChangementSansContraintelBytesDoc =
          await PDFDocument.load(presentationURLChangementSansContraintelBytes);
        //pdfDoc = await this.addPagesToPdf(pdfDoc, presentationPagePartFinalDoc);
        pdfDoc = await this.addPagesToPdf(
          pdfDoc,
          presentationURLChangementSansContraintelBytesDoc
        );
        pdfDoc = await this.addPagesToPdf(
          pdfDoc,
          presentationPagePartContactDoc
        );
        const pdfBytes = await pdfDoc.save();
        this.generatedPdf = pdfBytes;
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = today.getFullYear();

        today = mm + "/" + dd + "/" + yyyy;
        // download(
        //   pdfBytes,
        //   "Presentation_" +
        //     this.donnerDesFournisseur[0]["resultat"]["dataSociete"]["siret"] +
        //     "_" +
        //     today +
        //     ".pdf",
        //   "application/pdf"
        // );

        // Download PDF: this logic download large pdf files
        try {
          const blob = new Blob([pdfBytes], { type: "application/pdf" });
          const linkHTMLElement = document.createElement("a");
          linkHTMLElement.href = window.URL.createObjectURL(blob);
          linkHTMLElement.download =
            "Presentation_" +
            this.donnerDesFournisseur[0]["resultat"]["dataSociete"]["siret"] +
            "_" +
            today +
            ".pdf";
          linkHTMLElement.click();
        } catch (error) {
          console.log("error downloading file:", error);
        }
      }
      this.loadingPres = false;
      this.dialogPres = false;
    },
    async getDataCharts(
      seriesCharts,
      urlAPI,
      dataCharts,
      categoriesCharts,
      items,
      firstDateSearch,
      lastDateSearch
    ) {
      seriesCharts.value = [];
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + urlAPI,
        method: "POST",
        data: {
          year: 2022,
          dateDebut: firstDateSearch,
          dateFin: lastDateSearch,
        },
      })
        .then((res) => {
          dataCharts = res.data.data;
          Object.keys(dataCharts).forEach((key) => {
            var d = {
              name: key,
              data: dataCharts[key]["data"],
            };
            items.value.push({
              categorie: key,
              today: dataCharts[key]["dataTable"][key]["jour"],
              week: dataCharts[key]["dataTable"][key]["semaine"],
              month: dataCharts[key]["dataTable"][key]["month"],
              year: dataCharts[key]["dataTable"][key]["years"],
            });
            if (
              categoriesCharts.value.length < dataCharts[key]["label"].length
            ) {
              categoriesCharts.value = dataCharts[key]["label"];
            }
            seriesCharts.value.push(d);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async updateCourbe(
      series,
      urlAPI,
      dateDebut,
      dateFin,
      itemsSeries,
      categories,
      dataSeries,
      updateChartsOptions
    ) {
      //this.dialog = true;
      //this.loading = true;
      series.value = [];
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + urlAPI,
        method: "POST",
        data: { year: 2022, dateDebut: dateDebut, dateFin: dateFin },
      })
        .then((res) => {
          itemsSeries.value = [];
          categories.value = [];
          dataSeries.value = res.data.data;
          Object.keys(dataSeries.value).forEach((key) => {
            var d = {
              name: key,
              data: dataSeries.value[key]["data"],
            };
            itemsSeries.value.push({
              categorie: key,
              today: dataSeries.value[key]["dataTable"][key]["jour"],
              week: dataSeries.value[key]["dataTable"][key]["semaine"],
              month: dataSeries.value[key]["dataTable"][key]["month"],
              year: dataSeries.value[key]["dataTable"][key]["years"],
            });
            if (
              categories.value.length < dataSeries.value[key]["label"].length
            ) {
              categories.value = dataSeries.value[key]["label"];
            }
            series.value.push(d);
            updateChartsOptions = {
              chart: {
                toolbar: {
                  show: false,
                  tools: {
                    download: false,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                  },
                },
              },
              series: [{ data: series.value }],
              xaxis: {
                type: "datetime",
                categories: categories.value,
                tickPlacement: "on",
                labels: {
                  formatter: function (value) {
                    return dayjs(value).format("DD-MM-YYYY");
                  },
                },
              },
            };
            switch (urlAPI) {
              case "getAllDataCourbeElec/":
                this.charts[0].updateChartsOptions = updateChartsOptions;
                break;

              case "getAllDataCourbeGaz/":
                this.charts[1].updateChartsOptions = updateChartsOptions;
                break;

              case "getAllDataCourbeSpot/":
                this.charts[2].updateChartsOptions = updateChartsOptions;
                break;

              default:
                break;
            }
            //this.dialog = false;
            //this.loading = false;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async createGraph() {
      await this.getDataCharts(
        this.charts[0].chartOptions.series,
        this.charts[0].urlAPI,
        this.charts[0].data,
        this.charts[0].chartOptions.categories,
        this.charts[0].items,
        dayjs().subtract(1, "year").format("YYYY-MM-DD"),
        dayjs().format("YYYY-MM-DD")
      );
    },
    async convertApexCartToImage(chartId) {
      const chartInstance = window.Apex._chartInstances.find(chart => chart.id === chartId);
      const base64 = await chartInstance.chart.dataURI();
      return base64.imgURI;
    },
    async captureDownloadImage(idDiv, idScreenShot) {
      this.dialogGraph = false;
      this.loadingPres = true;
      this.dialogPres = true;
      if (this.toggleCharts) {
        /*let lien;
        document.getElementById(idScreenShot).style.display = "none";
        var container = document.getElementById(idDiv);
        await html2canvas(container, { allowTaint: true, scale: 2 }).then(
          function (canvas) {
            var link = document.createElement("a");
            document.body.appendChild(link);
            idDiv == "capture-elec"
              ? (link.download = "évolution_prix_elec.jpg")
              : idDiv == "capture-gaz"
              ? (link.download = "évolution_prix_gaz.jpg")
              : (link.download = "évolution_prix_spot.jpg");

            lien = canvas.toDataURL("image/png", 1.0);
          }
        );
        document.getElementById(idScreenShot).style.display = "block";
        this.graphImage = lien;*/
        
       this.graphImage = await this.convertApexCartToImage("chatGazAndElec");
      }
      this.loadingPres = false;
      this.dialogPres = false;
      await this.createPres();
    },
  },
};
</script>
