<template>
  <v-app id="inspire">
    <Menu style="z-index: 12" v-if="tokenUser != null" />
    <v-main style="height: 100vh">
      <RouterView />
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";
import Menu from "./components-admin/Menu.vue";
export default {
  components: {
    Menu,
  },
  data: () => ({
    tokenUser: null,
  }),
  created() {
    this.tokenUser = this.getToken("token");
    this.verifyToken();
    this.test();
  },
  methods: {
    test() {
      let self = this;
      var removeItem = localStorage.removeItem;
      localStorage.removeItem = function (key) {
        var event = new Event("removeItem");
        document.dispatchEvent(event);
        removeItem.apply(this, arguments);

        if (key != "token") {
          removeItem.apply(this, arguments);
        } else {
          removeItem.apply(this, arguments);
          self.tokenUser = localStorage.getItem("token");
        }
      };
      var localStorageRemoveHandler = function (e) {
        var x = 0;
      };
      document.addEventListener("removeItem", localStorageRemoveHandler, false);
      var originalSetItem = localStorage.setItem;
      localStorage.setItem = function (key, value) {
        self.tokenUser = localStorage.getItem("token");
        var event = new Event("itemInserted");
        event.value = value; // Optional..
        event.key = key; // Optional..
        document.dispatchEvent(event);
        if (key != "token") {
          originalSetItem.apply(this, arguments);
        } else {
          originalSetItem.apply(this, arguments);
          self.tokenUser = localStorage.getItem("token");
        }
      };

      var localStorageSetHandler = function (e) {
        var x = 0;
      };

      document.addEventListener("itemInserted", localStorageSetHandler, false);
    },
    verifyToken() {
      let self = this;
      window.addEventListener("storage", () => {
        self.tokenUser = localStorage.getItem("token");
        if (self.tokenUser == null) {
          localStorage.removeItem("vendeurName");
          localStorage.removeItem("typeUser");
          self.$router.push("/login");
        }
      });
    },
  },
};
</script>
<style>
@font-face {
  font-family: "Hatton";
  src: local("Hatton"),
    url("./assets/fonts/hatton-font-family/PP_Hatton_Medium_500.otf") format("opentype");
}

@font-face {
  font-family: "Hatton-Bold";
  src: local("Hatton-Bold"),
    url("./assets/fonts/hatton-font-family/PP_Hatton_Bold_700.otf") format("opentype");
}

@font-face {
  font-family: "MontSerrat";
  src: local("MontSerrat"),
    url("./assets/fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "MontSerrat-Bold";
  src: local("MontSerrat-Bold"),
    url("./assets/fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
}

.MontSerrat {
  font-family: "MontSerrat";
}

.MontSerrat-Bold {
  font-family: "MontSerrat-Bold";
}

.Hatton {
  font-family: "Hatton";
}
.Hatton-Bold {
  font-family: "Hatton-Bold";
}

* {
  font-family: "MontSerrat";
}
</style>
<style scoped>
.boxed-container {
  max-width: 1440px;
}
</style>
