<template>
    <div v-if="Propositions?.length > 0">
      <!-- presentation pdf -->
      <presentationGazVolt> </presentationGazVolt>
      <newPresentationGazSansAssocie
        :infosCompteurPresentationProps="infosCompteurProps"
        :donnerCompteur="donnerCompteur"
        :donnerDesFournisseur="Propositions"
        :numCompteur="numCompteur"
        :indexes="selectedData"
        :fournisseurActuelle="fournisseurActuelle"
        :societe="SocieteData"
        :id_historique_cotation="id_historique_cotation"
      />
  
      <!-- liste proposition par compteur -->
      <v-expansion-panels class="my-8" multiple>
        <v-expansion-panel
          class="px-6 py-4 mt-1"
          style="
            border: 1px solid rgb(199, 204, 216);
            box-shadow: 0px 4px 16px 0px rgb(199, 204, 216);
          "
          v-for="(company, indexPDL) in Propositions"
          :key="company.name"
        >
          <!-- header -->
          <v-expansion-panel-header style="padding: 0px">
            <div class="d-flex flex-row align-items-center">
              <h6 class="Hatton mb-0">Numéro de Compteur :&nbsp;&nbsp;</h6>
              <span
                class="MontSerrat-Bold"
                style="font-size: 15px; font-weight: bold; color: #02325c"
                >{{ company.NumCompteur }}</span
              >
            </div>
          </v-expansion-panel-header>
  
          <div v-if="filteredData(company.resultat?.result).length>0">
            <v-divider class="mt-0"></v-divider>
            <!-- logo list -->
            <ul class="list-inline" style="padding: 0px">
              <li 
              class="list-inline-item" 
              v-for="(icon, index_v) in filteredData(company.resultat?.result)" 
              :key="icon.name">
                <v-tooltip bottom style="z-index:1000;">
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      style="
                        position: relative;
                        border: 2px solid #f04f4f;
                        border-radius: 10px;
                        padding: 10px;
                        background: #fff;
                      "
                      class="td-table shadow"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-img width="120px" height="40px" :src="getImageLogo(icon.logo)">
                      </v-img>
                      <v-icon
                        style="position: absolute; top: 2px; right: 2px"
                        color="#EED202"
                        >mdi-alert</v-icon
                      >
                    </div>
                  </template>
                  <p style="color: white">{{ icon.message }}</p>
                </v-tooltip>
              </li>
            </ul>
          </div>
  
          <!-- propositions list -->
          <v-expansion-panel-content>
            <v-card
              class="my-8 pa-4"
              style="
                border: 1px solid rgb(199, 204, 216);
                box-shadow: 0px 4px 16px 0px rgb(199, 204, 216);
              "
              v-for="(compteurGaz, index) in company.resultat.result"
              :key="indexPDL + index"
              v-if="
                'message' in compteurGaz == false || 'offreIdResult' in compteurGaz == true
              "
            >
              <v-row
                outlined
                no-gutters
                class="header"
                style="background: #ffffff"
                v-if="'message' in compteurGaz == false"
              >
                <!--<v-col cols="12" sm="1" md="1" lg="1" xl="1">
                      
                  </v-col>-->
                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <v-row class="d-flex flex-column flex-lg-row mt-4">
                    <v-checkbox
                      style="position: absolute; top: 0; left: 0; z-index: 3"
                      v-if="
                        company.resultat.actuelle == true &&
                        index == 0 &&
                        compteurGaz.typeFournisseur == 'Actuelle'
                      "
                      class="ma-0 pa-0"
                      input-value="true"
                      :disabled="true"
                    ></v-checkbox>
                    <v-checkbox
                      style="position: absolute; top: 0; left: 0; z-index: 3"
                      v-else
                      class="ma-0 pa-0"
                      v-model="selectedData[indexPDL]"
                      @click.capture.stop
                      :value="index"
                      :disabled="
                        selectedData[indexPDL]?.indexOf(index) === -1 &&
                        selectedData[indexPDL].length > 4
                      "
                    ></v-checkbox>
                    <v-img
                      overflow="hiden"
                      max-width="238px"
                      max-height="80px"
                      contain
                      :src="getImageLogo(compteurGaz.logo)"
                    >
                    </v-img>
                    <div
                      class="mx-5 d-flex flex--row justify-start align-start align-lg-center justify-md-center align-items-center"
                    >
                      <div
                        class="d-flex flex-column flex-sm-row"
                        v-if="
                          compteurGaz.typeFournisseur != 'AuFrn' &&
                          compteurGaz.typeFournisseur != 'Actuelle'
                        "
                      >
                        <p
                          class="d-flex flex-md-column flex-lg-row grey--text font-weight-light mb-0 ma-2 mx-xs-0"
                        >
                          Début de contrat :
                          <span
                            style="color: #1A1A1A"
                            class="text-body-1 font-weight-bold ml-2"
                            >{{ compteurGaz.dateDebut }}</span
                          >
                        </p>
                        <p
                          class="d-flex flex-md-column flex-lg-row grey--text font-weight-light mb-0 ma-2 mx-xs-0"
                        >
                          Fin de contrat :
                          <span
                            style="color: #1A1A1A"
                            class="text-body-1 font-weight-bold ml-2"
                            >{{ compteurGaz.dateFin }}</span
                          >
                        </p>
                      </div>
                      <div class="d-flex flex-column flex-sm-row">
                        <p
                          v-if="
                            !(
                              compteurGaz.typeFournisseur != 'AuFrn' &&
                              compteurGaz.typeFournisseur != 'Actuelle'
                            )
                          "
                          class="text-body-1 d-flex flex-md-column flex-lg-row grey--text font-weight-light mb-0 ma-2 mx-xs-0"
                        >
                          Durée de contrat :
                          <span
                            style="color: #1A1A1A"
                            class="text-body-1 font-weight-bold ml-2"
                            >{{ compteurGaz.DUREE }} mois</span
                          >
                        </p>
                        <p
                          class="d-flex flex-md-column flex-lg-row grey--text font-weight-light mb-0 ma-2 mx-xs-0"
                        >
                          Budget annuel :<span
                            style="color: #1A1A1A"
                            class="text-body-1 font-weight-bold ml-2"
                            >{{ "" + compteurGaz.budget?.toLocaleString() }} €</span
                          >
                        </p>
                        <p
                          class="d-flex flex-md-column flex-lg-row grey--text font-weight-light mb-0 ma-2"
                        >
                          Offre :
                          <span
                            v-if="
                              compteurGaz.typeFournisseur != 'AuFrn' &&
                              compteurGaz.typeFournisseur != 'Actuelle'
                            "
                            style="color: #1A1A1A"
                            class="text-body-1 font-weight-bold ml-2"
                          >
                            {{ compteurGaz.Offre }}
                          </span>
                          <span
                            v-else
                            style="color: #1A1A1A"
                            class="text-body-1 font-weight-bold ml-2"
                          >
                            {{ compteurGaz.type }}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div
                      v-if="compteurGaz.fournisseur == 'Ekwateur'"
                      style="position: absolute; top: 0; right: 0"
                    >
                      <EkwateurContratGAZ
                        :donner="compteurGaz"
                        :numCompteur="company.NumCompteur"
                        :societe="company.resultat.dataSociete"
                        :listCompteur="listCompteur"
                      />
                    </div>
                  </v-row>
                </v-col>
                <div
                  v-if="compteurGaz.fournisseur == 'Engie'"
                  style="position: absolute; top: 0; right: 0"
                  class="mt-1 mb-1"
                  align="right"
                >
                  <engieProGaz
                    :donner="compteurGaz"
                    :numCompteur="company.NumCompteur"
                    :societe="company.resultat.dataSociete"
                  />
                </div>
                <div
                  v-if="compteurGaz.fournisseur == 'Endesa'"
                  style="position: absolute; top: 0; right: 0"
                  class="mr-8 mt-1 mb-1"
                  align="right"
                >
                  <v-btn
                    @click="downlmoadFileEndesa(compteurGaz.offreId)"
                    depressed
                    class="ma-auto text-capitalize text-white"
                    :color="$colors[0]"
                  >
                    <v-icon :color="$colors[0]">mdi-text-box-edit</v-icon>
                  </v-btn>
                </div>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <v-divider class="mb-3"></v-divider>
                </v-col>
                <!-- infos -->
                <v-col
                  cols="12"
                  sm="4"
                  md="3"
                  lg="2"
                  xl="2"
                  align="center"
                  justify="center"
                >
                  <p class="d-flex flex-column text-body-1 ma-0">
                    <span class="text-body-1 grey--text font-weight-light"
                      >Prix de la molécule</span
                    >
                    <span style="color: #1A1A1A" class="text-body-1 font-weight-bold mr-1"
                      >{{ "" + compteurGaz.prixMolucule?.toLocaleString() }}€/MWh</span
                    >
                  </p>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="3"
                  lg="2"
                  xl="2"
                  align="center"
                  justify="center"
                >
                  <p class="d-flex flex-column text-body-1 ma-0">
                    <span class="text-body-1 grey--text font-weight-light">ARTD</span>
                    <span style="color: #1A1A1A" class="text-body-1 font-weight-bold mr-1"
                      >{{ "" + compteurGaz.ARTD?.toLocaleString() }}€/MWh</span
                    >
                  </p>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="3"
                  lg="2"
                  xl="2"
                  align="center"
                  justify="center"
                >
                  <p class="d-flex flex-column text-body-1 ma-0">
                    <span class="text-body-1 grey--text font-weight-light"
                      >Abonnement annuel</span
                    >
                    <span style="color: #1A1A1A" class="text-body-1 font-weight-bold mr-1"
                      >{{ "" + compteurGaz.ABO?.toLocaleString() }}€</span
                    >
                  </p>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="3"
                  lg="2"
                  xl="2"
                  align="center"
                  justify="center"
                >
                  <p class="d-flex flex-column text-body-1 ma-0">
                    <span class="text-body-1 grey--text font-weight-light">CTA</span>
                    <span style="color: #1A1A1A" class="text-body-1 font-weight-bold mr-1"
                      >{{ "" + compteurGaz.CTA?.toLocaleString() }}€/an</span
                    >
                  </p>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="3"
                  lg="2"
                  xl="2"
                  align="center"
                  justify="center"
                >
                  <p class="d-flex flex-column text-body-1 ma-0">
                    <span class="text-body-1 grey--text font-weight-light">TICGN</span>
                    <span
                      style="color: #1A1A1A"
                      class="text-body-1 font-weight-bold mr-1"
                      >{{ "" + compteurGaz.TICGN?.toLocaleString() + "€/MWh" }}</span
                    >
                  </p>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="3"
                  lg="2"
                  xl="2"
                  align="center"
                  justify="center"
                >
                  <p class="d-flex flex-column text-body-1 ma-0">
                    <span class="text-body-1 grey--text font-weight-light">CEE</span>
                    <span
                      style="color: #1A1A1A"
                      class="text-body-1 font-weight-bold mr-1"
                      v-if="compteurGaz.cee == 'NON SOUMIS'"
                      >NON SOUMIS</span
                    >
                    <span
                      v-else
                      style="color: #1A1A1A"
                      class="text-body-1 font-weight-bold mr-1"
                      >{{ "" + compteurGaz.cee?.toLocaleString() }}€/MWh</span
                    >
                  </p>
                </v-col>
              </v-row>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </template>

  <script>
  import EkwateurContratGAZ from "../../../views/edition-contrat/EkwateurGaz.vue";
  import engieProGaz from "../../../views/edition-contrat/engieProGaz.vue";
  import newPresentationGazSansAssocie from "../../../views/presentation/NewElecCalcul/newPresentationGazSansAssocie.vue";
  import presentationGazVolt from "../../../views/presentation/NewElecCalcul/presentationGazVolt.vue";
  import axios from "axios";
  import download from "downloadjs";
  import moment from "moment";
  import { degrees, PDFDocument, rgb, StandardFonts, layoutMultilineText } from "pdf-lib";
  export default {
    name: "ListePropositionsGazSansAssocie",
    props: {
      infosCompteurProps: null,
      Propositions: [],
      SocieteData: [],
      numCompteur: null,
      typeCompteur: null,
      panel: [],
      fournisseurActuelle: null,
      selected: [],
      listCompteur: [],
      donnerCompteur: {},
      id_historique_cotation: null,
  
    },
    components: {
      presentationGazVolt,
      newPresentationGazSansAssocie,
      EkwateurContratGAZ,
      engieProGaz,
    },
    data() {
      return {
        selectedData: [],
        dialog: false,
        loading: false,
        error: false,
        codePremio: "",
      };
    },
    mounted() {
      for (let i = 0; i < this.Propositions?.length; i++) {
        this.selectedData.splice(i, 0, []);
        if (this.Propositions[i].resultat.result.length > 0) {
          // verifie si une offre actuelle existe
          for (let j = 0; j < this.Propositions[i].resultat.result.length; j++)
            if (this.Propositions[i].resultat.result[j].typeFournisseur == "Actuelle") {
              this.selectedData[i].push(0);
            }
        }
      }
    },
    watch: {
      Propositions: function (newValue) {
        this.selectedData = []
        for (let i = 0; i < this.newValue?.length; i++) {
          this.selectedData.splice(i, 0, []);
          if (this.Propositions[i].resultat.result.length > 0) {
            // verifie si une offre actuelle existe
            for (let j = 0; j < this.Propositions[i].resultat.result.length; j++)
              if (this.Propositions[i].resultat.result[j].typeFournisseur == "Actuelle") {
                this.selectedData[i].push(0);
              }
          }
        }
      },
    },
    methods: {
      async downlmoadFileEndesa(offreId) {
        await axios({
          url: process.env.VUE_APP_URL_API_CLIENT + "downloadFilesEndesa/",
          method: "POST",
          data: { offreId: offreId, token: this.getToken("token") },
        })
          .then(async (res) => {
            if (res.data.result === "permission") {
              localStorage.setItem("vueAfficher", "particulier");
              localStorage.removeItem("vendeurName");
              localStorage.removeItem("typeUser");
              localStorage.removeItem("token");
              this.$router.push("/login");
            }
            if (res.data.result == true) {
              if (res.data.file1 != false) {
                console.log(res.data.file1);
                var aElement = document.createElement("a");
                aElement.setAttribute(
                  "download",
                  this.numCompteur + "_endesa_condition_general.pdf"
                );
                var href = res.data.file1;
                aElement.href = href;
                aElement.setAttribute("target", "_blank");
                aElement.click();
                //window.open(res.data.file1, '_blank');
              }
              if (res.data.file2 != false) {
                console.log(res.data.file2);
                var aElement = document.createElement("a");
                aElement.setAttribute(
                  "download",
                  this.numCompteur + "_endesa_condition_particulier.pdf"
                );
                var href = res.data.file2;
                aElement.href = href;
                aElement.setAttribute("target", "_blank");
                aElement.click();
                //window.open(res.data.file2, '_blank');
              }
            } else {
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      filteredData(dataArray) {
        const filtered = [];
        const seen = new Set();
        dataArray.forEach(item => {
          // Check if logo is the same and status is 'enattend'
          if (item.hasOwnProperty('message') && item.hasOwnProperty('status') && (item.status == 'error' || item.status == 'enattend') && !seen.has(item.logo)) {
            filtered.push(item);
            seen.add(item.logo);
          }
        });
        return filtered;
      },
      getImageLogo(logo) {
        return require("@/assets/logoParticulier/" + logo);
      },
    },
  };
  </script>
  
  <style scoped>
  .v-expansion-panel::before {
    box-shadow: none;
  }
  .v-expansion-panel::after {
    border: none;
  }
  .v-expansion-panel-content__wrap {
    padding: 0px !important;
  }
  ::v-deep .v-expansion-panel-content__wrap {
    padding: 0px !important;
  }
  
  @media only screen and (max-width: 600px) {
    .marginBtnPresentation {
      padding-right: 0px !important;
      padding-left: 0px !important;
    }
  }
  
  @media only screen and (max-width: 350px) {
    ::v-deep .v-expansion-panel-header {
      padding: 15px !important;
    }
  
    ::v-deep .responsiveSpan {
      font-size: 0.8rem !important;
    }
  }
  </style>
  