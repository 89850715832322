<template>
  <div style="background-color: #eee" v-if="openInfoToSheetModal">
    <loadingComponent
      @closeDialog="dialog = $event"
      :dialogProps="dialog"
      :messageDialogeProps="messageDialogeError"
      :loadingProps="loading"
      :errorProps="error"
    />
    <v-card style="overflow: hidden">
      <v-toolbar :color="$colors[0]" dark>
        <span class="text-h6 text-white">Ajouter Offres Elmy (Google Sheet)</span>
      </v-toolbar>
      <v-card-text class="my-6">
        <v-form v-if="listCompteur.length > 0" ref="formAcd" lazy-validation>
          <!-- a integrer -->
          <v-row>
            <v-col md="12">
              <v-row>
                <v-col md="6">
                  <v-autocomplete
                    dense
                    v-model="selectedCompteur"
                    :items="listCompteur"
                    :loading="isLoading"
                    hide-no-data
                    :rules="[rules.required]"
                    label="Compteur(s)"
                    placeholder="Ajouter un compteur"
                    prepend-icon="mdi-account-search-outline"
                    chips
                    item-text="numCompteur"
                    deletable-chips
                    multiple
                    return-object
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index === 0">
                        <span>{{ item.numCompteur }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text text-caption">
                        (+{{ selectedCompteur.length - 1 }}
                        {{ selectedCompteur.length - 1 == 1 ? "compteur" : "compteurs" }})
                      </span>
                    </template>
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            data.item.numCompteur
                          }}</v-list-item-title>
                          <v-list-item-subtitle>{{
                            data.item.commerciale
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="toggle">
                        <v-list-item-content>
                          <v-list-item-title
                            >Séléctionner tous les compteurs</v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                  lg="6"
                  xl="6"
                  v-if="selectedCompteur.length > 0"
                >
                  <v-btn class="text-white" :color="$colors[0]" @click="appelleEnedis"
                    >Récupérer la consommation</v-btn
                  >
                </v-col>

                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  v-if="listCompteurResult.length > 1"
                >
                  <v-form class="ma-4" ref="defaultForm" lazy-validation>
                    <v-row>
                      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <strong
                          >Appliqué comme valeurs par défaut pour toutes les
                          ventes</strong
                        >
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          dense
                          type="date"
                          outlined
                          label="DDF par défaut"
                          v-model="defaultDateDebut"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          suffix="mois"
                          dense
                          outlined
                          label="durée par défaut"
                          v-model="defaultduree"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          dense
                          outlined
                          type="date"
                          label="DFF par défaut"
                          v-model="defaultDateFin"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3" md="2" lg="2" xl="2">
                        <v-btn @click="appliquesDefaultValue">appliquées</v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  v-if="listCompteurResult.length > 0 && typeEnergie == 'ELEC'"
                >
                  <b-table
                    :per-page="perPage"
                    :current-page="currentPage"
                    id="tableau-multisite-elec"
                    class="text-center"
                    :busy="loadingTableHistorique"
                    :fields="fieldhistoriqueDeCotationELEC"
                    :items="listCompteurResult"
                    responsive
                    bordered
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Chargement...</strong>
                      </div>
                    </template>
                    <template #cell(numCompteur)="data">
                      <small>{{ data.item.numCompteur }}</small>
                    </template>
                    <template #cell(conso)="data">
                      <v-text-field
                        dense
                        outlined
                        suffix="Mwh"
                        type="number"
                        hide-details="auto"
                        step="0.01"
                        :rules="[required('une Consommation valide')]"
                        disabled
                        v-model="data.item.conso"
                      >
                      </v-text-field>
                    </template>
                    <template #cell(dateDebut)="data">
                      <v-text-field
                        dense
                        type="date"
                        outlined
                        hide-details="auto"
                        :rules="[required('une DDF valide')]"
                        v-model="data.item.dateDebut"
                      ></v-text-field>
                    </template>
                    <template #cell(marge)="data">
                      <v-text-field
                        dense
                        type="date"
                        outlined
                        hide-details="auto"
                        :rules="[required('une DDF valide')]"
                        v-model="data.item.dateRemiseOffre"
                      ></v-text-field>
                    </template>
                    <template #cell(dateFin)="data">
                      <v-text-field
                        suffix="mois"
                        dense
                        outlined
                        hide-details="auto"
                        :rules="[required('un durée valide')]"
                        v-model="data.item.durree"
                      ></v-text-field>
                    </template>
                    <template #cell(actions)="row">
                      <b-button @click="row.toggleDetails">
                        {{ row.detailsShowing ? "-" : "+" }}
                      </b-button>
                    </template>
                    <template #row-details="row">
                      <b-tr style="border: none !important">
                        <b-td style="border: none !important">
                          <v-select
                            class="pa-0"
                            v-model="row.item.optionTarifaire"
                            @change="changeOptionTarifaire(row.item)"
                            :items="optionsTarifaires"
                            :rules="[required('Option Tarifaire')]"
                            :disabled="row.item.techniqueData.result != 'notValide'"
                            outlined
                            dense
                            hide-details
                          />
                        </b-td>
                        <b-td
                          style="border: none !important"
                          v-if="
                            row.item.optionTarifaire == 'BTINFMU4' ||
                            row.item.optionTarifaire == 'BTINFCU4'
                          "
                        >
                          <v-radio-group
                            @change="
                              changeOptionCalculeBtinf(
                                row.item,
                                row.item.parametreCompteur
                              )
                            "
                            v-model="row.item.parametreCompteur"
                            row
                            class="smallradio"
                          >
                            <v-radio label="4 POST" value="C5C4"></v-radio>
                            <v-radio label="HP/HC" value="C5HP"></v-radio>
                            <v-radio label="Base" value="C5BASE"></v-radio>
                          </v-radio-group>
                        </b-td>
                        <b-td style="border: none !important">
                          <v-text-field
                            dense
                            type="number"
                            outlined
                            label="KVA"
                            :rules="[required('une KVA valide')]"
                            v-model="row.item.kva"
                          ></v-text-field>
                        </b-td>
                        <b-td style="border: none !important">
                          <v-text-field
                            dense
                            type="number"
                            outlined
                            v-if="row.item.parametreCompteur == 'C5BASE'"
                            :rules="[required('une CABASE valide')]"
                            label="BASE (MWh)"
                            @input="calculeSommeCotation(row.item)"
                            v-model="row.item.CABASE"
                          ></v-text-field>
                          <v-text-field
                            dense
                            type="number"
                            outlined
                            v-if="row.item.parametreCompteur == 'C5HP'"
                            @input="calculeSommeCotation(row.item)"
                            label="HP (MWh)"
                            :rules="[required('une CAHP valide')]"
                            v-model="row.item.CAHP"
                          ></v-text-field>
                          <v-text-field
                            dense
                            type="number"
                            outlined
                            v-if="
                              (row.item.parametreCompteur == 'C4') |
                                (row.item.parametreCompteur == 'C3') |
                                (row.item.parametreCompteur == 'C5C4')
                            "
                            @input="calculeSommeCotation(row.item)"
                            :rules="[required('une CAHPH valide')]"
                            label="HPH (MWh)"
                            v-model="row.item.CAHPH"
                          ></v-text-field>
                        </b-td>
                        <b-td
                          style="border: none !important"
                          v-if="row.item.parametreCompteur != 'C5BASE'"
                        >
                          <v-text-field
                            dense
                            type="number"
                            outlined
                            v-if="row.item.parametreCompteur == 'C5HP'"
                            @input="calculeSommeCotation(row.item)"
                            label="HC (MWh)"
                            :rules="[required('une CAHC valide')]"
                            v-model="row.item.CAHC"
                          ></v-text-field>
                          <v-text-field
                            dense
                            type="number"
                            outlined
                            v-if="
                              (row.item.parametreCompteur == 'C4') |
                                (row.item.parametreCompteur == 'C3') |
                                (row.item.parametreCompteur == 'C5C4')
                            "
                            @input="calculeSommeCotation(row.item)"
                            :rules="[required('une CAHCH valide')]"
                            label="HCH (MWh)"
                            v-model="row.item.CAHCH"
                          ></v-text-field>
                        </b-td>
                        <b-td
                          style="border: none !important"
                          v-if="
                            (row.item.parametreCompteur == 'C4') |
                              (row.item.parametreCompteur == 'C3') |
                              (row.item.parametreCompteur == 'C5C4')
                          "
                        >
                          <v-text-field
                            dense
                            type="number"
                            outlined
                            @input="calculeSommeCotation(row.item)"
                            :rules="[required('une CAHPE valide')]"
                            label="HPE (MWh)"
                            v-model="row.item.CAHPE"
                          ></v-text-field>
                        </b-td>
                        <b-td
                          style="border: none !important"
                          v-if="
                            (row.item.parametreCompteur == 'C4') |
                              (row.item.parametreCompteur == 'C3') |
                              (row.item.parametreCompteur == 'C5C4')
                          "
                        >
                          <v-text-field
                            dense
                            type="number"
                            outlined
                            @input="calculeSommeCotation(row.item)"
                            :rules="[required('une CAHCE valide')]"
                            label="HCE (MWh)"
                            v-model="row.item.CAHCE"
                          ></v-text-field>
                        </b-td>
                        <b-td
                          style="border: none !important"
                          v-if="row.item.parametreCompteur == 'C3'"
                        >
                          <v-text-field
                            dense
                            type="number"
                            outlined
                            :rules="[required('une CAPointe valide')]"
                            @input="calculeSommeCotation(row.item)"
                            label="Pointe (MWh)"
                            v-model="row.item.CAP"
                          ></v-text-field>
                        </b-td>
                      </b-tr>
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                  >
                  </b-pagination>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- ca c'est tes données -->
          <v-row v-if="listCompteurResult.length > 0 && consommationRecuperer">
            <v-col md="6">
              <v-text-field
                v-model="societe.nom"
                label="Nom client"
                :rules="[rules.required]"
                outlined
              />
            </v-col>
            <v-col md="6">
              <v-text-field
                v-model="societe.prenom"
                label="Prénom client"
                :rules="[rules.required]"
                outlined
              />
            </v-col>
            <v-col md="6">
              <v-text-field
                v-model="societe.AdresseEmail"
                label="Mail Client"
                :rules="[rules.required, ...rules.emailRules]"
                outlined
              />
            </v-col>
            <v-col md="6">
              <v-text-field
                v-model="societe.numTele"
                label="Tél Client"
                :rules="[rules.required, rules.isNumber, rules.phoneLength]"
                outlined
              />
            </v-col>

            <v-col md="6">
              <v-text-field
                v-model="societe.scoreCreditSafe"
                label="Score CS"
                :rules="[rules.required]"
                outlined
              />
            </v-col>
            <v-col md="6">
              <v-text-field
                v-model="filledData.commentaires"
                label="Commentaires"
                outlined
              />
            </v-col>
          </v-row>
          <div class="d-flex justify-content-end">
            <v-btn :color="$colors[0]" class="mt-8 mr-4 text-white" @click="addingInfo">
              Valider
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import axios from "axios";
import "jspdf-autotable";
import loadingComponent from "@/components/ui/loading-component.vue";
export default {
  name: "infoTosheet",
  props: {
    openInfoToSheetModal: {
      type: Boolean,
      required: true,
    },
    societe: {
      type: Object,
      required: true,
    },
  },
  components: {
    loadingComponent,
  },
  data() {
    return {
      consommationRecuperer: false,
      success: false,
      message: "",
      messageDialogeError: "",
      listCompteur: [],
      openModalAcdEngie: true,
      loadingTableHistorique: false,
      fieldhistoriqueDeCotationELEC: [
        { key: "numCompteur", label: "Compteur", tdClass: "tdCenter" },
        { key: "conso", label: "Consommation" },
        { key: "dateDebut", label: "Début de contrat" },
        { key: "dateFin", label: "Durée" },
        { key: "marge", label: "Date Remise Offre" },
        { key: "actions", label: "Détails" },
      ],
      fieldhistoriqueDeCotationGAZ: [
        { key: "numCompteur", label: "Compteur", tdClass: "tdCenter" },
        { key: "conso", label: "Consommation" },
        { key: "tarif", label: "tarif" },
        { key: "profile", label: "profile" },
        { key: "dateDebut", label: "Début de debut" },
        { key: "dateFin", label: "Durée" },
        { key: "marge", label: "Date Remise Offre" },
      ],
      listeProfileT1: ["P011"],
      listeProfileT2: ["P012", "P013", "P014", "P015", "P016", "P017", "P018", "P019"],
      listeProfileT3: ["P013", "P014", "P015", "P016", "P017", "P018", "P019"],
      listCompteurResult: [],
      perPage: 3,
      currentPage: 1,
      perPageGaz: 3,
      currentPageGaz: 1,
      error_enedis: true,
      dialog: false,
      loading: false,
      error: false,
      isLoading: false,
      filledData: {
        commentaires: "",
      },
      typeEnergie: "ELEC",
      optionsTarifaires: [
        "BTINFCU4",
        "BTINFCUST",
        "BTINFMU4",
        "BTINFMUDT",
        "BTINFLU",
        "BTSUPCU4",
        "BTSUPLU4",
        "HTACU5",
        "HTACUPM5",
        "HTALU5",
        "HTALUPM5",
      ],
      selectedCompteur: [],
      defaultDateDebut: "",
      defaultDateFin: "",
      defaultduree: "",
      rules: {
        required: (value) =>
          (!!value && ("" + value).length > 0) || "Ce champ est obligatoire.",
        requiredString: (value) => ("" + value).length > 8 || "Ce champ est obligatoire.",
        siretLength: (v) => v.length == 14 || "Le siret doit comporter 14 chiffres",
        isNumber: (v) =>
          v.match(/^[0-9]+$/) != null || "Ce champ n'accepte que les chiffres",
        compteurElecLength: (v) =>
          v.length == 14 || "Le RAE / PDL est une suite de 14 chiffres",
        isGazCompteur: (v) =>
          v.match(/^(GI)[0-9]{6}$/) ||
          (v.length == 14 && v.match(/^[0-9]+$/) != null) ||
          "Le numéro PCE/ PDL doit commencer par (GI) ou comporter 14 chiffres",
        phoneLength: (v) =>
          v.length == 10 || "Le numéro de téléphone est une suite de 10 chiffres",
        phoneisNumber: (v) =>
          v.match(/^[0-9]+$/) != null || "Ce champ n'accepte que les chiffres",
        margeMaxMin: (v) =>
          (v <= 25 && v >= 2) || "La marge doit être comprise entre 2 et 25",

        emailRules: [
          (v) => !!v || "E-mail obligatoire",
          (v) => /.+@.+\.+.+/.test(v) || "l'adresse mail est invalide",
        ],
      },
      required(fieldName) {
        return (value) => !!value || value >= 0 || `Veuillez saisir ${fieldName}`;
      },
      consoMaxMin(typeCompteur) {
        return (v) => {
          if (typeCompteur == "T1") {
            if (v >= 0 && v < 6) {
              return true;
            } else {
              return "consomation pour un Tarif T1 doit être comprise entre 0 et 5.99";
            }
          } else if (typeCompteur == "T2") {
            if (v >= 3 && v <= 350) {
              return true;
            } else {
              return "consomation pour un Tarif T2 doit être comprise entre 3 et 350";
            }
          } else if (typeCompteur == "T3") {
            if (v >= 100 && v < 5000) {
              return true;
            } else {
              //250 4999.99
              return "consomation pour un Tarif T3 doit être comprise entre 100 et 4999.99";
            }
          } else if (typeCompteur == "T4") {
            if (v >= 5000) {
              return true;
            } else {
              return "consomation pour un Tarif T4 doit être supérieur ou égal à 5000";
            }
          }
        };
      },
    };
  },
  computed: {
    rows() {
      return this.listCompteurResult.length;
    },
    rowsGaz() {
      return this.listCompteurResult.length;
    },
  },
  watch: {
    async openInfoToSheetModal(newValue) {
      if (newValue) {
        await this.submitContinue();
      }
    },
    /* openInfoToSheetModal(newVal, oldVal){
        this.submitContinue()
    },*/
    selectedCompteur(newVal, oldVal) {
      if (newVal.length != oldVal.length) {
        var index = -1;
        for (var i = 0; this.listCompteurResult.length; i++) {
          index = newVal.findIndex((x) => x === this.listCompteurResult[i].numCompteur);
          if (index == -1) {
            this.listCompteurResult.splice(i, 1);
            break;
          }
        }
      }
    },
    listCompteurResult(val) {
      for (var i = 0; i < this.listCompteurResult.length; i++) {
        this.listCompteurResult[i]["valeur"] = 0;
      }
    },
  },
  methods: {
    resetData() {
      this.listCompteur = [];
      this.listCompteurResult = [];
    },
    async submitContinue() {
      this.dialog = true;
      this.loading = true;
      this.typeEnergieLocked = true;
      await this.getInfoEtCompteur(this.societe.siret, this.typeEnergie);
    },
    async getInfoEtCompteur(siret, typeEnergie) {
      let self = this;
      this.success = true;
      var formData = new FormData();
      formData.append("siret", siret);
      formData.append("token", this.getToken("token"));
      formData.append("type", typeEnergie);
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getInfoEtCompteurVentePro/",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          var data = JSON.parse(JSON.stringify(res.data));
          if (data.result == false) {
            this.success = false;
            this.error = true;
            this.loading = false;
            this.messageDialogeError =
              "Il n'y a pas de numéro de compteur associé à ce siret, merci de vérifier que votre demande de cotation a bien été acceptée";
          } else if (data.result == true) {
            this.success = true;
            this.listCompteur = data.compteur;
            this.listCompteur = data.compteur.map((compteur) => ({
              ...compteur,
              dateRemiseOffre: "",
            }));
            this.error = false;
            this.loading = false;
            this.dialog = false;
          } else if (data.result == "permissionAdmin") {
            this.success = false;
            this.error = true;
            this.loading = false;
            this.messageDialogeError =
              "Il n'y a pas de numéro de compteur associé à ce siret, merci de vérifier que votre Demande de Cotation a bien été accepté";
          } else if (data.result == "permission") {
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((err) => {
          self.success = false;
        });
    },
    closeModal() {
      this.$emit("closeInfoToSheetModal");
    },
    calculeSommeCotation(donneDeCotation) {
      if (donneDeCotation.parametreCompteur == "C3") {
        var hch = 0;
        var hph = 0;
        var hce = 0;
        var hpe = 0;
        var pointe = 0;
        try {
          if (!isNaN(donneDeCotation.CAHCH)) {
            hch = parseFloat(donneDeCotation.CAHCH);
          } else {
            hch = 0;
          }
        } catch (error) {
          hch = 0;
        }
        try {
          if (!isNaN(donneDeCotation.CAHPH)) {
            hph = parseFloat(donneDeCotation.CAHPH);
          } else {
            hph = 0;
          }
        } catch (error) {
          hph = 0;
        }
        try {
          if (!isNaN(donneDeCotation.CAHPE)) {
            hpe = parseFloat(donneDeCotation.CAHPE);
          } else {
            hpe = 0;
          }
        } catch (error) {
          hpe = 0;
        }
        try {
          if (!isNaN(donneDeCotation.CAHCE)) {
            hce = parseFloat(donneDeCotation.CAHCE);
          } else {
            hce = 0;
          }
        } catch (error) {
          hce = 0;
        }
        try {
          if (!isNaN(donneDeCotation.CAP)) {
            pointe = parseFloat(donneDeCotation.CAP);
          } else {
            pointe = 0;
          }
        } catch (error) {
          pointe = 0;
        }
        donneDeCotation.HPH = hph;
        donneDeCotation.HCH = hch;
        donneDeCotation.HPE = hpe;
        donneDeCotation.HCE = hce;
        donneDeCotation.POINTE = pointe;
        donneDeCotation.conso = hch + hph + hce + hpe + pointe;
        donneDeCotation.CAR = hch + hph + hce + hpe + pointe;
        donneDeCotation.consommationTotale = hch + hph + hce + hpe + pointe;
      } else if (
        donneDeCotation.parametreCompteur == "C4" ||
        donneDeCotation.parametreCompteur == "C5C4"
      ) {
        var hch = 0;
        var hph = 0;
        var hce = 0;
        var hpe = 0;
        var pointe = 0;
        try {
          if (!isNaN(donneDeCotation.CAHCH)) {
            hch = parseFloat(donneDeCotation.CAHCH);
          } else {
            hch = 0;
          }
        } catch (error) {
          hch = 0;
        }
        try {
          if (!isNaN(donneDeCotation.CAHPH)) {
            hph = parseFloat(donneDeCotation.CAHPH);
          } else {
            hph = 0;
          }
        } catch (error) {
          hph = 0;
        }
        try {
          if (!isNaN(donneDeCotation.CAHPE)) {
            hpe = parseFloat(donneDeCotation.CAHPE);
          } else {
            hpe = 0;
          }
        } catch (error) {
          hpe = 0;
        }
        try {
          if (!isNaN(donneDeCotation.CAHCE)) {
            hce = parseFloat(donneDeCotation.CAHCE);
          } else {
            hce = 0;
          }
        } catch (error) {
          hce = 0;
        }
        donneDeCotation.HPH = hph;
        donneDeCotation.HCH = hch;
        donneDeCotation.HPE = hpe;
        donneDeCotation.HCE = hce;
        donneDeCotation.conso = hch + hph + hce + hpe;
        donneDeCotation.CAR = hch + hph + hce + hpe;
        donneDeCotation.consommationTotale = hch + hph + hce + hpe;
      } else if (donneDeCotation.parametreCompteur == "C5HP") {
        var hc = 0;
        var hp = 0;
        try {
          if (!isNaN(donneDeCotation.CAHC)) {
            hc = parseFloat(donneDeCotation.CAHC);
          } else {
            hc = 0;
          }
        } catch (error) {
          hc = 0;
        }
        try {
          if (!isNaN(donneDeCotation.CAHP)) {
            hp = parseFloat(donneDeCotation.CAHP);
          } else {
            hp = 0;
          }
        } catch (error) {
          hp = 0;
        }

        donneDeCotation.HP = hp;
        donneDeCotation.HC = hc;
        donneDeCotation.conso = hc + hp;
        donneDeCotation.CAR = hc + hp;
        donneDeCotation.consommationTotale = hc + hp;
      } else if (donneDeCotation.parametreCompteur == "C5BASE") {
        var base = 0;
        try {
          if (!isNaN(donneDeCotation.CABASE)) {
            base = parseFloat(donneDeCotation.CABASE);
          } else {
            base = 0;
          }
        } catch (error) {
          base = 0;
        }
        donneDeCotation.BASE = base;
        donneDeCotation.conso = base;
        donneDeCotation.CAR = base;
        donneDeCotation.consommationTotale = base;
      }
    },
    async getConsoAllCompteur(typeEnergie) {
      this.consommationRecuperer = false;
      if (typeEnergie == "GAZ") {
        this.dialog = true;
        this.loading = true;
        this.title =
          "veuillez patienter jusqu'à ce que nous avons obtenu les informations relatives aux numéros des compteurs!";
        this.messageDialoge =
          "veuillez patienter jusqu'à ce que nous ayons obtenu les informations relatives aux numéros des compteurs!";
        var formData = new FormData();
        formData.append("token", this.getToken("token"));
        formData.append("listCompteur", JSON.stringify(this.selectedCompteur));
        formData.append("dateDebut", "01/01/2024");
        formData.append("dateFin", "31/12/2026");
        formData.append("siret", this.societe.siret);
        await axios({
          url: process.env.VUE_APP_URL_API_CLIENT + "getAllCompteurConsoGaz/",
          method: "POST",
          data: formData,
        })
          .then((res) => {
            if (res.data.result == false) {
              this.listCompteurResult = [];
              this.consommationRecuperer = false;
              this.error = true;
              this.messageDialogeError =
                "nous n'arrivons pas à obtenir les informations relatives au Siret!";
              this.loading = false;
            } else {
              this.listCompteurResult = res.data.result;
              if (this.listCompteurResult[0].numCompteur == "undefined") {
                this.listCompteurResult[0].numCompteur = this.selectedCompteur[0].numCompteur;
              }
              this.consommationRecuperer = true;
              this.dialog = false;
              this.listCompteurResult = res.data.result;
              console.log(this.listCompteurResult);
              if (this.listCompteurResult[0].numCompteur == "undefined") {
                this.listCompteurResult[0].numCompteur = this.selectedCompteur[0].numCompteur;
              }
              this.consommationRecuperer = true;
              this.dialog = false;
            }
          })
          .catch((err) => {
            this.consommationRecuperer = false;
            this.listCompteurResult = [];
            this.error = true;
            this.messageDialogeError =
              "nous n'arrivons pas à obtenir les informations relatives à tous les numéros de compteur présents dans votre Demande de cotation!";
          });
      } else if (typeEnergie == "ELEC") {
        this.dialog = true;
        this.loading = true;
        this.title =
          "veuillez patienter jusqu'à ce que nous avons obtenu les informations relatives aux numéros des compteurs!";
        this.messageDialoge =
          "veuillez patienter jusqu'à ce que nous ayons obtenu les informations relatives aux numéros des compteurs!";
        var formData = new FormData();
        formData.append("token", this.getToken("token"));
        formData.append("listCompteur", JSON.stringify(this.selectedCompteur));
        formData.append("dateDebut", "01/01/2024");
        formData.append("dateFin", "31/12/2026");
        formData.append("siret", this.societe.siret);
        await axios({
          url: process.env.VUE_APP_URL_API_CLIENT + "getAllCompteurConso/",
          method: "POST",
          data: formData,
        })
          .then((res) => {
            if (res.data.result == false) {
              this.listCompteurResult = [];
              this.consommationRecuperer = false;
              this.error = true;
              this.messageDialogeError =
                "nous n'arrivons pas à obtenir les informations relatives au Siret!";
              this.loading = false;
            } else {
              this.listCompteurResult = res.data.result;
              if (this.listCompteurResult[0].numCompteur == "undefined") {
                this.listCompteurResult[0].numCompteur = this.selectedCompteur[0].numCompteur;
              }
              this.consommationRecuperer = true;
              this.dialog = false;
            }
          })
          .catch((err) => {
            this.listCompteurResult = [];
            this.consommationRecuperer = false;
            this.error = true;
            this.messageDialogeError =
              "nous n'arrivons pas à obtenir les informations relatives à tous les numéros de compteur présents dans votre Demande de cotation!";
          });
      }
    },
    changeOptionCalculeBtinf(obj, newParam) {
      obj.parametreCompteur = newParam;
    },
    changeOptionTarifaire(obj) {
      var optionTarifaire = obj.optionTarifaire;
      if (optionTarifaire == "BTINFMUDT") {
        obj.parametreCompteur = "C5HP";
      }
      if (optionTarifaire == "BTINFLU" || optionTarifaire == "BTINFCUST") {
        obj.parametreCompteur = "C5BASE";
      }
      if (["BTSUPCU4", "BTSUPLU4"].includes(optionTarifaire)) {
        obj.parametreCompteur = "C4";
      }
      if (optionTarifaire == "BTINFCU4") {
        obj.parametreCompteur = "C5BASE";
        obj.typeTraitementCompteur = "complex";
      }
      if (optionTarifaire == "BTINFMU4") {
        obj.parametreCompteur = "C5HP";
        obj.typeTraitementCompteur = "complex";
      }
      if (["HTACU5", "HTACUPM5", "HTALU5", "HTALUPM5"].includes(optionTarifaire)) {
        obj.parametreCompteur = "C3";
        obj.typeTraitementCompteur = "simple";
      }
    },
    handleTarifChange() {
      this.$nextTick(() => {
        this.$refs.consogaz.validate();
      });
    },

    appliquesDefaultValue() {
      for (var i = 0; i < this.listCompteurResult.length; i++) {
        if (this.defaultDateDebut != "" && this.defaultDateDebut != null) {
          this.listCompteurResult[i]["dateDebut"] = this.defaultDateDebut;
        }
        if (this.defaultduree != "" && this.defaultduree != null) {
          this.listCompteurResult[i]["durree"] = this.defaultduree;
        }
        if (this.defaultDateFin != "" && this.defaultDateFin != null) {
          this.listCompteurResult[i]["dateRemiseOffre"] = this.defaultDateFin;
        }
      }
    },
    changeCompteurSelected() {
      this.consommationRecuperer = false;
    },
    changeCompteurSelected() {
      this.consommationRecuperer = false;
    },
    async appelleEnedis() {
      this.loadingTableHistorique = true;
      this.titleDialog = "Recupération de la consommation";
      await this.getConsoAllCompteur(this.typeEnergie);
      this.loadingTableHistorique = false;
      this.dialog = false;
      this.error = false;
    },
    toggle() {
      this.selectedCompteur = [...this.listCompteur];
    },
    async addingInfo() {
      var formData = new FormData();
      if (this.$refs.formAcd.validate()) {
        formData.append("data", JSON.stringify(this.filledData));
        formData.append("compteurs", JSON.stringify(this.listCompteurResult));
        formData.append("societe", JSON.stringify(this.societe));
        formData.append("token", this.getToken("token"));
        await axios({
          url: process.env.VUE_APP_URL_API_CLIENT + "addingInfoToSheet/",
          method: "POST",
          data: formData,
        }).catch((err) => {
          console.log(err);
        });
      } else {
        console.log("Validation failed");
      }
    },
  },
};
</script>
<style>
#table-multisite-gaz.table-bordered td:first-child,
#table-multisite-elec.table-bordered td:first-child {
  vertical-align: middle !important;
}
</style>
