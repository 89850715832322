<template>
  <div class="d-flex justify-end">
    <v-col md="12" lg="12" xl="12" cols="12" v-if="dialogPres == true">
      <loadingComponent
        @closeDialog="dialogPres = $event"
        :dialogProps="dialogPres"
        :loadingProps="loadingPres"
      />
    </v-col>
    <v-dialog v-model="dialogGraph" width="750">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ml-4 white--text"
          :color="$colors[0]"
          :disabled="isArrayEmptyRecursive(indexes)"
          v-bind="attrs"
          v-on="on"
        >
          Créer une présentation<v-icon size="20" class="ml-2"
            >mdi-file-document-outline</v-icon
          >
        </v-btn>

        <!-- comment button associe un vendeur  -->

        <v-btn
          class="ml-4 white--text"
          color="#000"
          :disabled="isArrayEmptyRecursive(indexes)"
          @click="toggleAssocie = true"
        >
          Associe un Vendeur
        </v-btn>
      </template>
      <v-card class="d-flex flex-column justify-end" min-height="536">
        <v-card-text v-if="toggleCharts">
          <researchDate
            class="pt-0 pr-0"
            @updateCourbe="
              updateCourbe(
                charts[0].chartOptions.series,
                charts[0].urlAPI,
                $event.dateDebut,
                $event.dateFin,
                charts[0].items,
                charts[0].chartOptions.categories,
                charts[0].data,
                charts[0].updateChartsOptions
              )
            "
          />
          <chart
            id="charts"
            :optionsChart="charts[0].chartOptions"
            :seriesCharts="charts[0].chartOptions.series.value"
            :updateChartsOptions="charts[0].updateChartsOptions"
            styleProps="height:300px;"
          />
        </v-card-text>
        <v-card-text
          style="height: 420px"
          class="d-flex flex-row align-items-center justify-center"
          v-else
        >
          <h5 class="MontSerrat">GRAPHIQUE NON INCLUS DANS LA PRESENTATION</h5>
        </v-card-text>
        <v-card-actions
          id="screenshot"
          class="pb-4 d-flex flex-row justify-space-between"
        >
          <v-switch
            class="pt-4 pb-4 mt-0"
            hide-details
            v-model="toggleCharts"
            :label="`Inclure graphique`"
          ></v-switch>
          <div class="d-flex flex-row justify-end" style="width: 100%">
            <v-spacer></v-spacer>

            <v-btn
              color="error"
              text
              class="white--text mr-4"
              @click="dialogGraph = false"
            >
              Fermer
            </v-btn>

            <v-btn
              :color="$colors[0]"
              class="white--text"
              @click="captureDownloadImage('charts', 'screenshot')"
            >
              Télécharger
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- comment dialog associe un vendeur  -->

    <!--<v-btn
      :disabled="indexes.length <= 0"
      :color="$colors[0]"
      class="white--text mr-2"
      @click="createPres"
      ><span class="Poppins-Bold">Créer une présentation</span
      ><v-icon class="ml-6" right dark> mdi-file-document-multiple </v-icon></v-btn
    >-->
    <v-dialog v-model="toggleAssocie" width="750" persistent max-width="750">
      <v-card class="d-flex flex-column justify-end" min-height="300">
        <v-card-title>
          Associé un vendeur
          <v-spacer></v-spacer>
          <v-icon @click="toggleAssocie = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-row>
              <v-col cols="12" md="12">
                <v-select
                  required
                  outlined
                  multiple
                  v-model="vendeur"
                  :items="vendeurList"
                  label="Liste Vendeur"
                  item-text="userName"
                  item-value="id"
                  return-object="false"
                ></v-select>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="dateExpiration"
                  label="Date d'expiration"
                  outlined
                  type="date"
                  :rules="[rules.required(`date obligatoire`)]"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="12">
                <v-text-field
                  v-model="commentaire"
                  label="Commentaire"
                  outlined
                  :rules="[rules.required(`veillez saisir un commentaire`)]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-btn
                  color="primary"
                  @click="sendVendorData"
                  v-if="vendeur.length > 0"
                >
                  Valider
                </v-btn>
                <v-btn color="#b8b8b8" disabled v-else> Valider </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  degrees,
  PDFDocument,
  rgb,
  PDFName,
  PDFString,
  StandardFonts,
  TextAlignment,
  setFillingRgbColor,
  setFontAndSize,
  layoutMultilineText,
} from "pdf-lib";
import html2canvas from "html2canvas";
import download from "downloadjs";
import jsPDF from "jspdf";
import loadingComponent from "../../../components/ui/loading-component.vue";
import axios from "axios";
import dayjs from "dayjs";
import "jspdf-autotable";
import chart from "../../charts/components/chart.vue";
import researchDate from "../../charts/components/research-date.vue";
export default {
  name: "presentation",
  components: {
    loadingComponent,
    chart,
    researchDate,
  },
  props: {
    infosCompteurPresentationProps: null,
    donnerDesFournisseur: [],
    fournisseurActuelle: null,
    indexes: [],
    numCompteur: null,
    donnerCompteur: [],

    societe: [],
    id_historique_cotation: null,
  },
  data() {
    return {
      toggleCharts: true,
      dialogGraph: false,
      graphImage: "",
      toggleAssocie: false,
      dateExpiration: "",
      vendeur: "",
      commentaire: "",
      vendeurList: [],
      rules: {
        isPositiveNumb: (v) =>
          /^[0-9]\d*$/.test(v) ||
          "La valeur doit etre un entier strictement supérieur à 0",
        required(fieldName) {
          return (value) => !!value || `Veuillez saisir ${fieldName}`;
        },
      },
      charts: [
        {
          idCapture: "capture-gaz",
          items: { value: [] },
          data: { value: null },
          updateChartsOptions: null,
          chartOptions: {
            chart: {
              toolbar: {
                show: false,
              },
            },
            colors: ["#000000", "#1E6CA4", "#c7c804", "#f8c954"],
            title: "Évolution Gaz",
            series: { value: [] },
            categories: { value: [] },
          },
          urlAPI: "getAllDataCourbeGaz/",
        },
      ],
      columns: [
        {
          header: "Fournisseur",
          dataKey: "fournisseur",
          columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
        },
        {
          header: "Molecule \n€/MWh",
          dataKey: "molecule",
          columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
        },
        {
          header: "Abonnement \n€/mois",
          dataKey: "abonnement",
          columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
        },
        {
          header: "CEE \n€/MWh",
          dataKey: "cee",
          columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
        },
        {
          header: "CTA \n€/an",
          dataKey: "CTA",
          columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
        },
        {
          header: "TICGN \n€/MWh",
          dataKey: "ticgn",
          columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
        },
        {
          header: "TOTAL \n€/an",
          dataKey: "total",
          columnStyles: { lineWidth: 0.8, lineColor: "#000000" },
        },
      ],
      societeData: null,
      dialogPres: false,
      loadingPres: false,
      listDesOffre: [],
      Offres: [],
      OffreActuelleData: {},
      OffresRecap: [],
    };
  },
  async created() {
    await this.createGraph();
    await this.getListVendor();
  },
  methods: {
    to_fixed2LocaleString(num) {
      return num != null && num >= 0
        ? Number(parseFloat(num).toFixed(2))
            .toLocaleString("fr-FR")
            .replace(/\u202F/g, " ")
            .toString()
        : "";
    },
    calculerPourcentage(prixOffreActuelle, prixOffre) {
      let pourcentage =
        ((prixOffreActuelle - prixOffre) / prixOffreActuelle) * 100;
      return pourcentage;
    },
    capitalizeEachWord(str) {
      return str.replace(/\b\w/g, function (char) {
        return char.toUpperCase();
      });
    },
    isArrayEmptyRecursive(arr) {
      if (!Array.isArray(arr)) {
        return false; // Si l'élément n'est pas un tableau, retourne false
      } else {
        for (const element of arr) {
          if (element.length >= 1) {
            return false;
          }
          /*if (!this.isArrayEmptyRecursive(element)) {
            return false; // Si un élément n'est pas vide, retourne false
          }*/
        }
      }

      if (arr.length === 0) {
        return true; // Si le tableau est vide, retourne true
      }

      // Vérifie récursivement chaque élément du tableau s'il est vide

      return true; // Si tous les éléments sont vides, retourne true
    },
    formatPhoneNumber(phoneNumber) {
      // Supprimer tous les caractères non numériques
      phoneNumber = phoneNumber.replace(/\D/g, "");

      // Insérer un espace après chaque paire de chiffres
      phoneNumber = phoneNumber.replace(/(\d{2})(?=\d)/g, "$1 ");

      return phoneNumber;
    },
    async drawAlignedText({
      page,
      text,
      x = 0,
      y = 0,
      font,
      size,
      align,
      textColor = [0, 0, 0],
      width = 0,
      height = 0,
      borderWidth = 0,
      borderColor = [0, 0, 0],
      bgColor,
      padding = 0,
    }) {
      const widthText = font.widthOfTextAtSize(text, size);
      const heightText = font.heightAtSize(size) - 2;
      // calculate center position
      let xPosition = x + width / 2;
      if (align == "center") xPosition = xPosition - widthText / 2;
      if (align == "left") xPosition = x + padding;
      if (align == "right") xPosition = x + width - widthText - padding;
      // draw rectangle
      if (width > 0 && (borderWidth > 0 || bgColor?.length == 3)) {
        let rectProps = {
          x: x,
          y: y,
          width: width,
          height: height > 0 ? height : heightText,
          borderWidth: borderWidth,
        };
        if (bgColor) rectProps.color = rgb(bgColor[0], bgColor[1], bgColor[2]);
        if (borderColor)
          rectProps.borderColor = rgb(
            borderColor[0],
            borderColor[1],
            borderColor[2]
          );
        page.drawRectangle(rectProps);
      }
      if (height > 0) y = y + height / 2 - heightText / 2;
      // draw text
      page.drawText(text, {
        x: xPosition,
        y: y,
        size: size,
        font: font,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
    },
    drawRoundedRectangle({ page, x, y, width, height, cornerRadius=0, borderWidth=1, borderColor = [0, 0, 0], bgColor, opacity = 1 }) {
      // Start the path at the top-left corner, right after the first rounded corner
      const svgPath = [
        `M ${cornerRadius} 0`, // Start path
        `L ${width - cornerRadius}, 0`, // Top horizontal line
        `C ${width - cornerRadius / 2} 0, ${width} ${cornerRadius / 2}, ${width} ${cornerRadius}`, // Top-right corner
        `L ${width},${height - cornerRadius}`, // Right vertical line
        `C ${width} ${height - cornerRadius / 2}, ${width - cornerRadius / 2} ${height}, ${width - cornerRadius} ${height}`, // Bottom-right corner
        `L ${cornerRadius},${height}`, // Bottom vertical line
        `C ${cornerRadius / 2} ${height}, 0 ${height - cornerRadius / 2}, 0 ${height - cornerRadius}`, // Bottom-left corner
        `L 0,${cornerRadius}`, // Left vertical line
        `C 0,${cornerRadius / 2} ${cornerRadius / 2},0, ${cornerRadius},0`, // Top-left corner
        `Z` // Close path
      ].join(' ');

      // Draw path as black line
      page.drawSvgPath(svgPath, { 
        x: x, 
        y: y,
        borderWidth: borderWidth,
        borderColor: rgb(borderColor[0], borderColor[1], borderColor[2]),
        color: rgb(bgColor[0], bgColor[1], bgColor[2]),
        opacity: opacity
      })
    },
    async drawCell({
      page,
      text,
      x = 0,
      y = 0,
      font,
      size,
      align,
      textColor = [0, 0, 0],
      width = 0,
      height = 0,
      borderWidth = 0,
      borderRadius = 0,
      borderColor = [0, 0, 0],
      bgColor,
      padding = 0,
    }) {
      const widthText = font.widthOfTextAtSize(text, size);
      const heightText = font.heightAtSize(size) - 2;
      let currentHeight = height > 0 ? height : heightText;
      // calculate center position
      let xText = x + width / 2;
      let yText = y;
      let xRect = x;
      let yRect = y + currentHeight;
      if (height > 0) yText = yText + height / 2 - heightText / 2;

      if (align == "center") xText = xText - widthText / 2;
      if (align == "left") xText = x + padding;
      if (align == "right") xText = x + width - widthText - padding;

      // draw rectangle
      if (width > 0 && (borderWidth > 0 || bgColor?.length == 3)) {
        let rectProps = {
          page: page, 
          x: xRect,
          y: yRect,
          width: width,
          height: currentHeight,
          borderWidth: borderWidth,
        };
        if (borderColor) rectProps.borderColor = borderColor;
        if (borderRadius) rectProps.cornerRadius = borderRadius;
        if (bgColor) rectProps.bgColor = bgColor;
        this.drawRoundedRectangle(rectProps);
      }
      
      // draw text
      page.drawText(text, {
        x: xText,
        y: yText,
        size: size,
        font: font,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
    },
    addBackgroundImage(doc, imageUrl) {
      let totalPages = doc.internal.getNumberOfPages();

      for (var i = 1; i <= totalPages; i++) {
        // Ajouter une nouvelle page
        doc.setPage(i);

        // Ajouter l'image en tant que fond de page
        doc.addImage(imageUrl, "JPEG", 0, 0, 770, 433);
      }
    },
    async createPDFWithMultipleTables(dataList, PDFDoc) {
      const rowsPerPage = 5; // Nombre maximum d'éléments par tableau
      const totalPages = Math.ceil(dataList.length / rowsPerPage); // Nombre total de pages nécessaires

      for (let pageIndex = 0; pageIndex < totalPages; pageIndex++) {
        /*if (pageIndex > 0) {
          doc.addPage(); // Ajouter une nouvelle page pour chaque page supplémentaire
          startY = 10; // Réinitialiser la position Y de départ pour les nouvelles pages
        }*/
        const doc = new jsPDF({
          orientation: "l",
          unit: "px",
          format: [1025, 578],
        });

        let imageUrl = "/imagePres/background_offre_volt_white.png";
        let backgroundImage = new Image();
        backgroundImage.src = imageUrl;
        this.addBackgroundImage(doc, backgroundImage);

        const startIndex = pageIndex * rowsPerPage;
        const endIndex = Math.min(startIndex + rowsPerPage, dataList.length);
        const currentData = dataList.slice(startIndex, endIndex);
        const data = [];
        for (let i = 0; i < currentData.length; i++) {
          let dataObject = {
            fournisseur: `/logoParticulier/founisseur_logos/${currentData[i]["logo"]}`,
            molecule: this.to_fixed2LocaleString(currentData[i].prixMolucule), // ACTUPRIX
            abonnement: this.to_fixed2LocaleString(currentData[i].ABO / 12),
            cee: currentData[i].cee,
            CTA: this.to_fixed2LocaleString(currentData[i].CTA),
            ticgn: this.to_fixed2LocaleString(currentData[i].TICGN),
            total: this.to_fixed2LocaleString(currentData[i].budget),
          };
          data.push(dataObject);
        }

        doc.autoTable({
          margin: {
            top: 90,
            left: 10,
          },
          columns: this.columns,
          body: data,
          //margin: { top: 20, left: 10, right: 10 },
          bodyStyles: {
            fillColor: "#f3f4f6", //#1a1a1a
            lineWidth: 1, // Épaisseur de la bordure
            lineColor: "#1a1a1a", //ffffff
            textColor: "#1a1a1a",
            valign: "middle",
            halign: "center",
            minCellHeight: 45,
          },
          didParseCell: function (data) {
            // Définir la taille du texte de la cellule en fonction de la ligne et de la colonne
            if (
              data.row.index >= 0 &&
              data.section == "body" &&
              data.column.index > 0
            ) {
              data.cell.styles.fontSize = 20; // Taille de police en points pour la cellule (0, 0)
            }
            if (
              data.row.index >= 0 &&
              data.column.index === 0 &&
              data.cell.section === "body"
            ) {
              data.cell.text = [];
              data.cell.styles.fillColor = "#e6e6e6";
              //data.cell.styles.fillColor = "#ffffff";
            }
          },
          didDrawCell: async function (data) {
            if (
              data.row.index >= 0 &&
              data.column.index === 0 &&
              data.cell.section === "body"
            ) {
              try {
                let td = data.cell.raw;

                var img = new Image();
                img.src = td;
                let cellWidth = data.cell.width;
                let cellHeight = data.cell.height;
                let imgHeight = 23;
                let imgWidth = 80;
                let x = data.cell.x + (cellWidth - imgWidth) / 2;
                let y = data.cell.y + (cellHeight - imgHeight) / 2;
                doc.addImage(img, "PNG", x, y, imgWidth, imgHeight);
              } catch (erro) {
                console.log(erro);
              }
            }
          },
          headStyles: {
            fillColor: "#e6e6e6",
            lineWidth: 1,
            fontSize: 15,
            textColor: 0,
            lineColor: "#1a1a1a",
            cellPadding: 10,
            halign: "center",
            valign: "middle",
          },
          theme: "plain",
          columnStyles: {
            0: { cellWidth: 107 }, // Ajuster automatiquement la première colonne
            1: { cellWidth: 107 }, // Ajuster automatiquement la deuxième colonne
            2: { cellWidth: 107 }, // Ajuster automatiquement la première colonne
            3: { cellWidth: 107 }, // Ajuster automatiquement la deuxième colonne
            4: { cellWidth: 107 }, // Ajuster automatiquement la première colonne
            5: { cellWidth: 107 }, // Ajuster automatiquement la deuxième colonne
            6: { cellWidth: 107 }, // Ajuster automatiquement la deuxième colonne
          },
        });

        let arrayBuffer = doc.output("arraybuffer");
        const pdfBytes = await PDFDocument.load(arrayBuffer);
        const [copiedPage] = await PDFDoc.copyPages(pdfBytes, [0]);
        copiedPage.setSize(copiedPage.getWidth(), copiedPage.getHeight());
        PDFDoc.addPage(copiedPage);
        if (endIndex === dataList.length) {
          break; // Arrêter la boucle si nous avons atteint la fin des données
        }
        /*if (endIndex < data.length) {
          // Si nous avons plus d'éléments, ajouter une nouvelle page pour un nouveau tableau
          doc.addPage();
        }*/
      }
    },
    async getListVendor() {
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getAllVendeursProspecteurs/",
        method: "POST",
        data: {
          token: this.getToken("token"),
        },
      })
        .then((response) => {
          this.vendeurList = response.data.result;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    send_data() {
      let data = {};
      for (let i = 0; i < this.donnerDesFournisseur.length; i++) {
        if (this.indexes[i].length > 0) {
          data[this.donnerDesFournisseur[i]["NumCompteur"]] = [];
          for (let j = 0; j < this.indexes[i].length; j++) {
            data[this.donnerDesFournisseur[i]["NumCompteur"]].push(
              this.donnerDesFournisseur[i]["resultat"]["result"][
                this.indexes[i][j]
              ]
            );
          }
        }
      }
      return data;
    },

    async sendVendorData() {
      // check required fields
      if (
        !(
          this.vendeur.length == 0 ||
          this.dateExpiration == "" ||
          this.commentaire == ""
        )
      ) {
        let data = {
          token: this.getToken("token"),
          list_allowed_compte: this.vendeur.map((item) => item.id),
          expiration_date: this.dateExpiration,
          id_historique_de_cotation: this.id_historique_cotation,
          commentaire: this.commentaire,
          cotationParCompteur: this.send_data(),
        };
        this.toggleAssocie = false;
        this.dialogPres = true;
        this.loadingPres = true;

        await axios({
          url:
            process.env.VUE_APP_URL_API_CLIENT +
            "ajouterHistoriqueDeCotationParCompteGaz/",
          method: "POST",
          data: data,
        })
          .then((response) => {
            if (response.data.result == true) {
              this.dialogPres = false;
              this.loadingPres = false;
            }

            // reset data
            this.vendeur = "";
            this.dateExpiration = "";
            this.commentaire = "";
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        alert("veillez remplir tous les champs");
      }
    },

    async getCodePostale(adresse) {
      var myRe = new RegExp("\\d\\d\\d\\d\\d", "");
      var myArray = myRe.exec(adresse);
      var voie = "";
      var codePostale = "";
      var commune = "";
      if (myArray != null) {
        codePostale = myArray[0];
        if (adresse.split(codePostale).length >= 2) {
          voie = adresse.split(codePostale)[0];
          commune = adresse.split(codePostale)[1];
        } else {
          commune = "";
          voie = adresse;
        }
      } else {
        voie = adresse;
      }
      return [codePostale, voie, commune];
    },
    getToutLesOffre() {
      for (var i = 0; i < this.indexes.length; i++) {
        let arrayResult = [];
        for (let j = 0; j < this.indexes[i].length; j++) {
          arrayResult.push(
            this.listDesOffre[i].resultat.result[this.indexes[i][j]]
          );
        }
        this.listDesOffre[i].resultat.result = [...arrayResult];
      }
      this.listDesOffre = this.listDesOffre.filter(
        (offre) => offre.resultat.result.length > 0
      );
    },
    formatDate(date) {
      return [date.getDate(), date.getMonth() + 1, date.getFullYear()].join(
        "/"
      );
    },
    getDateFormatSlach(date) {
      if (date.indexOf("-") != -1) {
        return (
          date.split("-")[2] +
          "/" +
          date.split("-")[1] +
          "/" +
          date.split("-")[0]
        );
      } else return date;
    },
    async getImage(link, doc) {
      const ImageLogo = await fetch(link).then((res) => res.arrayBuffer());
      var pngImage = await doc.embedPng(ImageLogo);
      return pngImage;
    },
    async createTextField(
      multiLine,
      form,
      page,
      x,
      y,
      width,
      height,
      txt,
      name,
      textColor,
      bgColor
    ) {
      var fontsize = 15;
      /////////////////////
      if (width / txt.length <= 3.5) {
        if (name.indexOf("value") != -1 && name.indexOf("TypeOffre") != -1) {
          if (width / txt.length <= 2) {
            fontsize = 6.5;
            height = height + 2.5;
          } else {
            fontsize = 8;
          }
        } else {
          fontsize = 8;
        }
        multiLine = true;
      }
      let rand = (Math.random() + 1).toString(36).substring(7);
      var textField = form.createTextField(name + rand);
      if (isNaN(txt)) {
        textField.setText(txt);
      } else {
        if (txt != "" && name != "pdl") {
          var temText = Number(txt).toLocaleString("fr-FR", {
            minimumFractionDigits: 1,
          });
          temText = temText.replace(/ /g, " ");
          textField.setText(temText);
        } else {
          textField.setText(txt);
        }
      }
      if (multiLine == true) {
        textField.enableMultiline();
      }
      if (name == "title8BigPourcentage") {
        textField.enableMultiline();
        textField.setAlignment(TextAlignment.Center);
        textField.addToPage(page, {
          x: x,
          y: y,
          width: width,
          height: height + 20,
          textColor: rgb(textColor[0], textColor[1], textColor[2]), //rgb(0.062, 0.227, 0.368),
          backgroundColor: rgb(bgColor[0], bgColor[1], bgColor[2]),
          borderWidth: 0,
        });
      } else {
        textField.setAlignment(TextAlignment.Center);
        textField.addToPage(page, {
          x: x,
          y: y,
          width: width,
          height: height,
          textColor: rgb(textColor[0], textColor[1], textColor[2]), //rgb(0.062, 0.227, 0.368),
          backgroundColor: rgb(bgColor[0], bgColor[1], bgColor[2]),
          borderWidth: 0,
        });
      }
      textField.acroField.setDefaultAppearance(
        setFontAndSize("Courier", fontsize).toString() +
          setFillingRgbColor(
            textColor[0],
            textColor[1],
            textColor[2]
          ).toString()
      );
      var fields = form.getFields();
      fields.forEach((field) => {
        field.enableReadOnly();
      });
      form.updateFieldAppearances();
    },

    async infoTable(form, pdfDoc, page, donner) {
      var HelveticaBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
      var Helvetica = await pdfDoc.embedFont(StandardFonts.Helvetica);
      page.drawText(
        "RESULTATS DE L'APPEL D'OFFRES DU " + this.formatDate(new Date()),
        {
          x: 175,
          y: 545,
          font: HelveticaBold,
          size: 20,
          color: rgb(0.062, 0.227, 0.368),
        }
      );
      page.drawText("Informations Compteur", {
        x: 65,
        y: 515,
        font: HelveticaBold,
        size: 15,
        color: rgb(0.062, 0.227, 0.368),
      });
      var image = await this.getImage("/imagePres/yellow.PNG", pdfDoc);
      var xBegin = donner["xBegin"];
      var yBegin = donner["yBegin"];
      for (var i = 0; i < donner["data"].length; i++) {
        var data = donner["data"];
        var width = data[i]["width"];
        //var title = data[i]['title']
        var lesTexts = data[i]["text"];
        var fieldName = data[i]["fieldName"];

        page.drawImage(image, {
          x: xBegin,
          y: yBegin,
          width: width,
          height: 32,
        });
        //await this.createTextField(false, form, page, xBegin, yBegin+49, width, 15, title, "title"+fieldName, [1, 1,1])
        var xForm = xBegin + 10;
        for (var j = 0; j < lesTexts.length; j++) {
          var text = lesTexts[j];
          var subTitle = text["title"];
          var value = text["value"];
          var fieldName = text["fieldName"];
          if (lesTexts.length > 2) {
            var widthForm = width / lesTexts.length - 8;
          } else if (lesTexts.length == 2) {
            var widthForm = width / lesTexts.length - 12;
          } else {
            var widthForm = width - 15;
          }
          await this.createTextField(
            false,
            form,
            page,
            xForm,
            yBegin + 30,
            widthForm,
            15,
            subTitle,
            "subTitle" + fieldName,
            [1, 1, 1]
          );

          try {
            if (
              subTitle == "Compteur" ||
              value.indexOf("/") != -1 ||
              isNaN(parseFloat(value).toFixed(2))
            ) {
              value = value;
            } else {
              value = parseFloat(value).toFixed(2);
            }
          } catch (exception) {
            value = value;
          }
          await this.createTextField(
            false,
            form,
            page,
            xForm,
            yBegin + 5,
            widthForm,
            22,
            value,
            fieldName,
            [1, 0.796, 0.207]
          );
          xForm = xForm + widthForm + 5;
        }
        xBegin = xBegin + width;
      }
      return pdfDoc;
    },
    formatNumberOrText(num, tofFixedNumber) {
      if (!isNaN(num) && num !== null && num !== undefined && num !== "") {
        // num is number
        return (
          Number(parseFloat(num).toFixed(tofFixedNumber))
            .toLocaleString("fr-FR")
            .replace(/\u202F/g, " ")
            .toString() + ""
        );
      } else {
        // num is text
        if (num !== null && num !== undefined && num !== "") {
          return num;
        }
        return "";
      }
    },
    async drawOffreActuelle(form, pdfDoc, page, donner) {
      var HelveticaBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
      var Helvetica = await pdfDoc.embedFont(StandardFonts.Helvetica);
      var y = 365;
      var xBegin = donner["xBegin"];
      var yBegin = 225;
      page.drawText("Offre Actuelle / de renouvellement", {
        x: 65,
        y: y,
        font: Helvetica,
        size: 12,
        color: rgb(0, 0, 0),
      });
      
      var logo = await this.getImage(donner["logo"], pdfDoc);
      var xForm = xBegin;
      var value = "";
      var title = "";
      var subtitle = "";
      var fieldName = "";
      const widthOfCell = 100;
      const heightOfCell = 36;
      var widthOfLogo = 120;
      var heightOfLogo = 35;
      var xLogo = xBegin - 75;
      var yLogo = yBegin + 85;
      xBegin = xLogo + widthOfLogo + 10;
      yBegin = yBegin + 85;
      this.drawRoundedRectangle({
        page,
        x: xLogo,
        y: yLogo + heightOfLogo,
        width: widthOfLogo,
        height: heightOfLogo + 2,
        cornerRadius: (heightOfLogo + 2)/2,
        borderWidth: 2,
        borderColor: [0.76, 0.76, 0.76],
        bgColor: [1,1,1],
      })
      page.drawImage(logo, {
        x: xLogo,
        y: yLogo,
        width: widthOfLogo,
        height: heightOfLogo,
      });
      for (var i = 0; i < 7; i++) {
        // ATTENTION A CHANGER INDEX
        var data = donner["data"][i];
        value = data["value"];
        fieldName = data["fieldName"];
        try {
          if (title == "Durée" || isNaN(parseFloat(value).toFixed(2))) {
            value = value;
          } else {
            value = parseFloat(value).toFixed(2);
          }
        } catch (exception) {
          value = value;
        }

        // draw value
        await this.drawCell({
          page: page,
          text: value>0 ? this.formatNumberOrText(value,2) : "-",
          x: xBegin,
          y: yBegin,
          font: Helvetica,
          size: 12,
          align: "center",
          textColor: [0, 0, 0],
          width: widthOfCell,
          height: heightOfCell,
          borderWidth: 2,
          borderRadius: heightOfCell/2,
          borderColor: [0.76,0.77,0.77],
          bgColor: [1,1,1],
        });
        xBegin = xBegin + widthOfCell + 10;
      }

      return pdfDoc;
    },
    filterArray(inputArr) {
      var found = {};
      var out = inputArr.filter(function (element) {
        return found.hasOwnProperty(element) ? false : (found[element] = true);
      });
      return out;
    },
    async drawOffres(
      form,
      pdfDoc,
      page,
      donner,
      fournisseurActuelle,
      fournisseur
    ) {
      
      var HelveticaBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
      var Helvetica = await pdfDoc.embedFont(StandardFonts.Helvetica);
      var xBegin = donner["xBegin"] - 20;
      var yBegin = fournisseurActuelle ? 60 : 140;
      var yBeginInfoCompteur = 400;

      var value = "";
      var title = "";
      var subtitle = "";
      var fieldName = "";

      const widthOfCell = 100;
      const heightOfCell = 36;
      var xTitles = xBegin + 75;
      var xValues = xBegin + 75;

      // titles
      for (var i = 0; i < 7; i++) {
        var data = donner["titles"][i];
        data["pas"] = 75;
        value = data["value"];
        title = data["title"];
        subtitle = data["subTitle"];
        fieldName = data["fieldName"];

        await this.drawAlignedText({
          page: page,
          text: title,
          x: xTitles,
          y: yBegin + 220,
          font: Helvetica,
          size: 10,
          align: "center",
          textColor: [0, 0, 0],
          width: widthOfCell,
        });
        await this.drawAlignedText({
          page: page,
          text: subtitle,
          x: xTitles,
          y: yBegin + 205,
          font: Helvetica,
          size: 10,
          align: "center",
          textColor: [0, 0, 0],
          width: widthOfCell,
        });
        xTitles = xTitles + widthOfCell + 10;
      }

      var value = "";
      var fieldName = "";

      for (var i = 0; i < donner["data"].length; i++) {
        xBegin = donner["xBegin"];
        // Info Compteur
        if (i == 0) {
          await this.drawCell({
            page: page,
            text: fournisseur.donnerCompteur.numCompteur,
            x: xBegin - 75,
            y: yBeginInfoCompteur,
            font: Helvetica,
            size: 12,
            align: "center",
            textColor: [0, 0, 0],
            width: 200,
            height: 36,
            borderWidth: 2,
            borderRadius: 18,
            borderColor: [0.76,0.77,0.77],
            bgColor: [1,1,1],
          });
          await this.drawCell({
            page: page,
            text: fournisseur.donnerCompteur.typeCompteur,
            x: xBegin + 180,
            y: yBeginInfoCompteur,
            font: Helvetica,
            size: 12,
            align: "center",
            textColor: [0, 0, 0],
            width: 150,
            height: 36,
            borderWidth: 2,
            borderRadius: 18,
            borderColor: [0.76,0.77,0.77],
            bgColor: [1,1,1],
          });

          await this.drawCell({
            page: page,
            text: fournisseur.donnerCompteur.profilCompteur,
            x: xBegin + 355,
            y: yBeginInfoCompteur,
            font: Helvetica,
            size: 12,
            align: "center",
            textColor: [0, 0, 0],
            width: 150,
            height: 36,
            borderWidth: 2,
            borderRadius: 18,
            borderColor: [0.76,0.77,0.77],
            bgColor: [1,1,1],
          });

          let stringCA = `${fournisseur.donnerCompteur.CA}`;
          await this.drawCell({
            page: page,
            text: stringCA,
            x: xBegin + 540 - stringCA.length,
            y: yBeginInfoCompteur,
            font: Helvetica,
            size: 12,
            align: "center",
            textColor: [0, 0, 0],
            width: 150,
            height: 36,
            borderWidth: 2,
            borderRadius: 18,
            borderColor: [0.76,0.77,0.77],
            bgColor: [1,1,1],
          });
        }

        var itemData = donner["data"][i];
        var logo = await this.getImage(itemData[0]["value"], pdfDoc);
        // draw logo fournisseur
        // var widthOfLogo = 137;
        // var heightOfLogo = 40;
        var widthOfLogo = 120;
        var heightOfLogo = 35;
        var xLogo = xBegin - 75;
        var yLogo = yBegin + 155;
        this.drawRoundedRectangle({
          page,
          x: xLogo,
          y: yLogo + heightOfLogo,
          width: widthOfLogo,
          height: heightOfLogo + 2,
          cornerRadius: (heightOfLogo + 2)/2,
          borderWidth: 2,
          borderColor: [0.76, 0.76, 0.76],
          bgColor: [1,1,1],
        })
        page.drawImage(logo, {
          x: xLogo,
          y: yLogo,
          width: widthOfLogo,
          height: heightOfLogo,
        });

        var yCellValue = yBegin + 155;
        xBegin = xValues;
        for (var j = 1; j < 8; j++) {
          var data = itemData[j];
          value = data["value"];
          fieldName = data["fieldName"];
          // percentage
          if (i == 0 && j + 1 == itemData.length - 1 && fournisseurActuelle) {
            let prixOffreActuelle = this.OffreActuelleData.data[this.OffreActuelleData.data.length - 1].value;
            let prixOffre = value;
            let resultatPourcentage = this.calculerPourcentage(
              prixOffreActuelle,
              prixOffre
            );

            if (resultatPourcentage > 0) {
              let priceOffreDiff = prixOffreActuelle - prixOffre;
              let valueEuroToDraw = `${Math.abs(Math.round(priceOffreDiff))}€`;
              let valuePercentageToDraw = `- ${Math.round(
                resultatPourcentage
              )}%`;
              page.drawText(valuePercentageToDraw, {
                x: 965 - valuePercentageToDraw.length * 7.5, //925 when "- 9%"
                y: 543,
                font: HelveticaBold,
                size: 24,
                color: rgb(1, 1, 1),
              });
              page.drawText(valueEuroToDraw, {
                x: 937 - valueEuroToDraw.length,
                y: 523,
                font: HelveticaBold,
                size: 20,
                color: rgb(1, 1, 1),
              });
              page.drawText("économisés/an", {
                x: 910,
                y: 497,
                font: HelveticaBold,
                size: 14,
                color: rgb(1, 1, 1),
              });
            }
          }
          // value change
          try {
            if (
              value.indexOf("MOIS") != -1 ||
              isNaN(parseFloat(value).toFixed(2))
            ) {
              value = value;
            } else {
              value = parseFloat(value).toFixed(2);
            }
          } catch (exception) {
            value = value;
          }
          // draw value
          await this.drawCell({
            page: page,
            text: value>0 ? this.formatNumberOrText(value,2) : "-",
            x: xBegin,
            y: yCellValue,
            font: Helvetica,
            size: 12,
            align: "center",
            textColor: [0, 0, 0],
            width: widthOfCell,
            height: heightOfCell,
            borderWidth: 2,
            borderRadius: heightOfCell/2,
            borderColor: [0.76,0.77,0.77],
            bgColor: [1,1,1],
          });

          xBegin = xBegin + widthOfCell + 10;
        }

        yBegin = yBegin - heightOfCell - 12;
        if (yBegin < -110 && i != donner["data"].length - 1) {
          var image = await this.getImage(
            "/imagePres/background_offre_volt.PNG",
            pdfDoc
          );
          page = pdfDoc.addPage([1025, 578]);
          page.drawImage(image, {
            x: 0,
            y: 0,
            width: page.getWidth(),
            height: page.getHeight(),
          });

          yBegin = 240;
        }
      }
      return [pdfDoc, page, yBegin];
    },
    /*async drawOffres(form, pdfDoc, page, donner, fournisseurActuelle) {
      var line = await this.getImage("/imagePres/ligne_offre_prix.PNG", pdfDoc);
      //var yellow = await this.getImage("/imagePres/yellow.PNG", pdfDoc);
      //var tableLine = await this.getImage("/imagePres/tableYellowRecap.PNG", pdfDoc);
      //ar green = await this.getImage("/imagePres/green.PNG", pdfDoc);
      //var greenLine = await this.getImage("/imagePres/tableGreenRecap.PNG", pdfDoc);
      var HelveticaBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
      var Helvetica = await pdfDoc.embedFont(StandardFonts.Helvetica);
      var y = fournisseurActuelle ? 260 : 330;
      page.drawText("Nos meilleures offres", {
        x: 65,
        y: y,
        font: HelveticaBold,
        size: 15,
        color: rgb(0.062, 0.227, 0.368),
      });
      var xBegin = donner["xBegin"];
      var yBegin = fournisseurActuelle ? 178 : 240;
      var actuelle = donner["actuelle"];
      var width = 515;

      var xForm = xBegin;
      var widthForm = 0;
      var value = "";
      var title = "";
      var subtitle = "";
      var fieldName = "";
      for (var i = 0; i < donner["titles"].length; i++) {
        var data = donner["titles"][i];
        xForm = xBegin + data["pas"];
        widthForm = data["width"];
        value = data["value"];
        title = data["title"];
        subtitle = data["subTitle"];
        fieldName = data["fieldName"];
        xForm = xBegin + data["pas"];
        await this.createTextField(
          false,
          form,
          page,
          xForm,
          yBegin + 61,
          widthForm,
          20,
          title,
          "title" + i + fieldName,
          [1, 1, 1]
        );
        await this.createTextField(
          false,
          form,
          page,
          xForm,
          yBegin + 44,
          widthForm,
          15,
          subtitle,
          "subtitle" + i + fieldName,
          [1, 1, 1]
        );
        xBegin = xBegin + widthForm + 2 + data["pas"];
      }
      var xForm = xBegin;
      var widthForm = 68;
      var value = "";
      var fieldName = "";

      for (var i = 0; i < donner["data"].length; i++) {
        xBegin = donner["xBegin"];
        var itemData = donner["data"][i];
        page.drawImage(line, {
          // ATTENTION
          x: xBegin - 80,
          y: yBegin + 130,
          width: 900,
          height: 70,
        });
        var logo = await this.getImage(itemData[0]["value"], pdfDoc);
        switch (itemData[0]["value"]) {
          case "/logoParticulier/founisseur_logos/OHM_120X35.png":
            page.drawImage(logo, {
              x: xBegin - 50,
              y: yBegin + 145,
              width: 115,
              height: 35,
            });
            break;
          case "/logoParticulier/founisseur_logos/Ekwateur_120X35.png":
            page.drawImage(logo, {
              x: xBegin - 50,
              y: yBegin + 145,
              width: 115,
              height: 33,
            });
            break;
          default:
            page.drawImage(logo, {
              x: xBegin - 50,
              y: yBegin + 145,
              width: 115,
              height: 35,
            });
            break;
        }

        for (var j = 1; j < itemData.length; j++) {
          var data = itemData[j];
          xForm = xBegin + data["pas"];
          widthForm = data["width"];
          value = data["value"];
          fieldName = data["fieldName"];
          xForm = xBegin + data["pas"];
          try {
            if (value.indexOf("MOIS") != -1 || isNaN(parseFloat(value).toFixed(2))) {
              value = value;
            } else {
              value = parseFloat(value).toFixed(2);
            }
          } catch (exception) {
            value = value;
          }
          if (i == 0) {
            var smallWidth = 80;

            /*page.drawImage(line, {
              // ATTENTION
              x: xForm,
              y: yBegin + 5,
              width: widthForm + 5,
              height: 37,
            });
            var colorBackGroundTextField = [0.662, 0.941, 0.819];
          } else {
            var smallWidth = 80;
            /*page.drawImage(line, {
              // ATTENTION
              x: xForm,
              y: yBegin + 5,
              width: widthForm + 5,
              height: 37,
            });
            var colorBackGroundTextField = [1, 0.796, 0.207];
          }
          await this.createTextField(
            false,
            form,
            page,
            xForm + 3,
            yBegin + 12,
            widthForm - 1,
            21,
            value,
            "value" + i + j + fieldName,
            colorBackGroundTextField
          );
          xBegin = xBegin + widthForm + 2 + data["pas"];
        }

        yBegin = yBegin - 70;

        if (yBegin < 45 && i != donner["data"].length - 1) {
          page = pdfDoc.addPage([842, 595]);
          yBegin = 450;
        }

        /*var imageBorder = await this.getImage(
          "/imagePres/border-presentation.jpg",
          pdfDoc
        );
        page.drawImage(imageBorder, { x: 742, y: 0, width: 100, height: 100 });
      }
      return [pdfDoc, page, yBegin];
    },*/

    async drawOffresRecap(form, pdfDoc, page, donner, fournisseurActuelle) {
      page.drawText(
        "RECAPITULATIF DE L'APPEL D'OFFRES DU " + this.formatDate(new Date()),
        {
          x: 115,
          y: 490,
          font: HelveticaBold,
          size: 25,
          color: rgb(0.062, 0.227, 0.368),
        }
      );
      var yellow = await this.getImage("/imagePres/yellow.PNG", pdfDoc);
      var tableLine = await this.getImage(
        "/imagePres/tableYellowRecap.PNG",
        pdfDoc
      );
      var green = await this.getImage("/imagePres/green.PNG", pdfDoc);
      var greenLine = await this.getImage(
        "/imagePres/tableGreenRecap.PNG",
        pdfDoc
      );
      var HelveticaBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
      var Helvetica = await pdfDoc.embedFont(StandardFonts.Helvetica);
      var y = 320;
      var xBegin = donner["xBegin"];
      var yBegin = 330;
      var actuelle = donner["actuelle"];
      var width = 515;

      var xForm = xBegin;
      var widthForm = 0;
      var value = "";
      var title = "";
      var subtitle = "";
      var fieldName = "";
      for (var i = 0; i < donner["titles"].length; i++) {
        var data = donner["titles"][i];
        xForm = xBegin + data["pas"];
        widthForm = data["width"];
        value = data["value"];
        title = data["title"];
        subtitle = data["subTitle"];
        fieldName = data["fieldName"];
        xForm = xBegin + data["pas"];
        await this.createTextField(
          false,
          form,
          page,
          xForm,
          yBegin + 61,
          widthForm,
          20,
          title,
          "title" + i + fieldName,
          [1, 1, 1]
        );
        await this.createTextField(
          false,
          form,
          page,
          xForm,
          yBegin + 44,
          widthForm,
          15,
          subtitle,
          "subtitle" + i + fieldName,
          [1, 1, 1]
        );
        xBegin = xBegin + widthForm + 2 + data["pas"];
      }

      var IndexMoinscher = 0;
      if (fournisseurActuelle == true) {
        IndexMoinscher = 1;
      }
      var xForm = xBegin;
      var widthForm = 68;
      var value = "";
      var fieldName = "";
      for (var i = 0; i < donner["data"].length; i++) {
        xBegin = donner["xBegin"];
        var itemData = donner["data"][i];
        var logo = await this.getImage(itemData[0]["value"], pdfDoc);
        switch (itemData[0]["value"]) {
          case "/logoParticulier/founisseur_logos/OHM_120X35.png":
            page.drawImage(logo, {
              x: xBegin - 122,
              y: yBegin + 4,
              width: 120,
              height: 37,
            });
            break;
          case "/logoParticulier/founisseur_logos/Ekwateur_120X35.png":
            page.drawImage(logo, {
              x: xBegin - 122,
              y: yBegin + 4,
              width: 120,
              height: 33,
            });
            break;
          default:
            page.drawImage(logo, {
              x: xBegin - 122,
              y: yBegin + 4,
              width: 120,
              height: 37,
            });
            break;
        }
        for (var j = 1; j < itemData.length; j++) {
          var data = itemData[j];
          xForm = xBegin + data["pas"];
          widthForm = data["width"];
          value = data["value"];
          fieldName = data["fieldName"];
          xForm = xBegin + data["pas"];

          try {
            if (
              value.indexOf("MOIS") != -1 ||
              isNaN(parseFloat(value).toFixed(2))
            ) {
              value = value;
            } else {
              value = parseFloat(value).toFixed(2);
            }
          } catch (exception) {
            value = value;
          }
          if (i == IndexMoinscher) {
            var smallWidth = 80;

            page.drawImage(green, {
              x: xForm,
              y: yBegin + 4,
              width: widthForm + 5,
              height: 37,
            });
            var colorBackGroundTextField = [0.662, 0.941, 0.819];
          } else {
            var smallWidth = 80;
            page.drawImage(yellow, {
              x: xForm,
              y: yBegin + 4,
              width: widthForm + 5,
              height: 37,
            });
            var colorBackGroundTextField = [1, 0.796, 0.207];
          }
          await this.createTextField(
            false,
            form,
            page,
            xForm + 3,
            yBegin + 13,
            widthForm - 1,
            20,
            value,
            "value" + i + j + fieldName,
            colorBackGroundTextField
          );
          xBegin = xBegin + widthForm + 2 + data["pas"];
        }

        yBegin = yBegin - 33;

        if (yBegin < 45 && i != donner["data"].length - 1) {
          page = pdfDoc.addPage([842, 595]);
          yBegin = 450;
        }

        var imageBorder = await this.getImage(
          "/imagePres/border-presentation.jpg",
          pdfDoc
        );
        page.drawImage(imageBorder, { x: 742, y: 0, width: 100, height: 100 });
      }
      return [pdfDoc, page, yBegin];
    },
    async addPagesToPdf(mergedPdf, donorPdf) {
      const copiedPages = await mergedPdf.copyPages(
        donorPdf,
        donorPdf.getPageIndices()
      );
      copiedPages.forEach((page) => {
        mergedPdf.addPage(page);
      });
      return mergedPdf;
    },
    base64ToArrayBuffer(base64) {
      var binary_string = window.atob(base64);
      var len = binary_string.length;
      var bytes = new Uint8Array(len);
      for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
      }
      return bytes.buffer;
    },
    async createPres() {
      // Sort each inner array
      this.indexes = this.indexes.map(innerArray => innerArray.sort((a, b) => a - b));
      this.societeData =
        this.donnerDesFournisseur[0]["resultat"]["dataSociete"];
      this.loadingPres = true;
      this.dialogPres = true;
      if (this.isArrayEmptyRecursive(this.indexes) == false) {
        this.listDesOffre = JSON.parse(
          JSON.stringify(this.donnerDesFournisseur)
        );
        this.getToutLesOffre();
        var pdfDoc = await PDFDocument.create();
        const presentationURLPartBegin = "/pdf/PRES_OFFRE_CLIENT-1-6.pdf";
        const presentationPartBeginBytes = await fetch(
          presentationURLPartBegin
        ).then((res) => {
          return res.arrayBuffer();
        });
        var presentationPagePartBeginDoc = await PDFDocument.load(
          presentationPartBeginBytes
        );
        const sourcePdfPage = presentationPagePartBeginDoc.getPage(0);
        var timesRomanFont1 = await presentationPagePartBeginDoc.embedFont(
          StandardFonts.Helvetica
        );

        if (this.toggleCharts) {
          const pageGraph = presentationPagePartBeginDoc.getPage(2);
          let imageGraphPNG = await this.getImage(
            this.graphImage,
            presentationPagePartBeginDoc
          );
          pageGraph.drawImage(imageGraphPNG, {
            x: 120,
            y: 120,
            width: 872,
            height: 375,
          });
        }
        var HelveticaFont = await presentationPagePartBeginDoc.embedFont(
          StandardFonts.Helvetica
        );

        const HelveticaBoldFont = await pdfDoc.embedFont(
          StandardFonts.HelveticaBold
        );

        const TimesRomanFont = await presentationPagePartBeginDoc.embedFont(
          StandardFonts.TimesRomanBold
        );
        // var multiText = layoutMultilineText(this.societeData.Raison.toUpperCase(), {
        //   alignment: "center",
        //   fontSize: 11.5,
        //   font: HelveticaFont,
        //   lineHeight: 1.5,
        //   bounds: { x: 0, y: 0, width: 600, height: 900 },
        // });

        // for (let i = 0; i < multiText.lines.length; i++) {
        //   var xPosition = (900 - multiText.lines[i].width) / 2;
        //   let startingPositon = 270;
        //   sourcePdfPage.drawText(`${multiText.lines[i].text}`, {
        //     x: xPosition,
        //     y: startingPositon,
        //     size: 40,
        //     lineHeight: 90,
        //     maxWidth: 600,
        //     color: rgb(0.2, 0.2, 0.14),
        //   });
        //   // move position down
        //   startingPositon += 200;
        // }
        function getFrenchFormattedDate() {
          const today = new Date();
          const day = today.getDate().toString().padStart(2, "0");
          const month = (today.getMonth() + 1).toString().padStart(2, "0");
          const year = today.getFullYear();
          return `${day}/${month}/${year}`;
        }
        await this.drawAlignedText({
          page: sourcePdfPage,
          text: "APPEL D’OFFRE " + getFrenchFormattedDate(),
          x: 170,
          y: 368,
          font: HelveticaFont,
          size: 20,
          align: "center",
          textColor: [0, 0, 0],
          width: 700,
        });
        // ************** Raison Sociale **************
        sourcePdfPage.drawText("Raison Sociale:", {
          x: 245,
          y: 320,
          size: 14,
          font: HelveticaFont,
          color: rgb(0.2, 0.2, 0.14),
        });
        sourcePdfPage.drawText(
          `${this.donnerDesFournisseur[0]["resultat"]["dataSociete"]["Raison"]}`,
          {
            x: 370,
            y: 320,
            size: 18,
            font: HelveticaFont,
            color: rgb(0.2, 0.2, 0.14),
          }
        );
        // ************** SIRET ***********************
        sourcePdfPage.drawText("SIRET:", {
          x: 245,
          y: 290,
          size: 14,
          font: HelveticaFont,
          color: rgb(0.2, 0.2, 0.14),
        });
        sourcePdfPage.drawText(
          `${this.donnerDesFournisseur[0]["resultat"]["dataSociete"]["siret"]}`,
          {
            x: 370,
            y: 290,
            size: 18,
            font: HelveticaFont,
            color: rgb(0.2, 0.2, 0.14),
          }
        );
        // ************** Interlocuteur **************
        sourcePdfPage.drawText("Interlocuteur:", {
          x: 245,
          y: 260,
          size: 14,
          font: HelveticaFont,
          color: rgb(0.2, 0.2, 0.14),
        });
        const interlocuteurPrenom =
          this.donnerDesFournisseur[0]["resultat"]["dataSociete"]["prenom"] ||
          "";
        const interlocuteurNom =
          this.donnerDesFournisseur[0]["resultat"]["dataSociete"]["nom"] || "";
        const interlocuteurAdresseEmail =
          this.donnerDesFournisseur[0]["resultat"]["dataSociete"][
            "AdresseEmail"
          ] || "";
        const interlocuteurNumTele =
          this.donnerDesFournisseur[0]["resultat"]["dataSociete"]["numTele"] ||
          "";
        // ************** Prenom **********************
        sourcePdfPage.drawText("Prenom:", {
          x: 290,
          y: 230,
          size: 14,
          font: HelveticaFont,
          color: rgb(0.2, 0.2, 0.14),
        });

        sourcePdfPage.drawText(`${interlocuteurPrenom}`, {
          x: 370,
          y: 230,
          size: 18,
          font: HelveticaFont,
          color: rgb(0.2, 0.2, 0.14),
        });
        // ************** Nom **********************
        sourcePdfPage.drawText("Nom:", {
          x: 290,
          y: 200,
          size: 14,
          font: HelveticaFont,
          color: rgb(0.2, 0.2, 0.14),
        });
        sourcePdfPage.drawText(`${interlocuteurNom}`, {
          x: 370,
          y: 200,
          size: 18,
          font: HelveticaFont,
          color: rgb(0.2, 0.2, 0.14),
        });
        // ************** Email **********************
        sourcePdfPage.drawText("Email:", {
          x: 290,
          y: 170,
          size: 14,
          font: HelveticaFont,
          color: rgb(0.2, 0.2, 0.14),
        });
        sourcePdfPage.drawText(`${interlocuteurAdresseEmail}`, {
          x: 370,
          y: 170,
          size: 18,
          font: HelveticaFont,
          color: rgb(0.2, 0.2, 0.14),
        });
        // ************** Telephone **********************
        sourcePdfPage.drawText("Telephone:", {
          x: 290,
          y: 140,
          size: 14,
          font: HelveticaFont,
          color: rgb(0.2, 0.2, 0.14),
        });
        sourcePdfPage.drawText(`${interlocuteurNumTele}`, {
          x: 370,
          y: 140,
          size: 18,
          font: HelveticaFont,
          color: rgb(0.2, 0.2, 0.14),
        });

        pdfDoc = await this.addPagesToPdf(pdfDoc, presentationPagePartBeginDoc);
        pdfDoc.removePage(3);
        if (this.toggleCharts == false) {
          pdfDoc.removePage(2);
        }
        let fournisseurList = [];
        // loop through all compteurs and create pages

        for (let indice in this.listDesOffre) {
          let fournisseur = this.listDesOffre[indice];
          /// les information pour chaque compteur
          let typeCompteur = fournisseur["resultat"]["typeCompteur"];
          let numCompteur = fournisseur["resultat"]["numCompteur"];
          let donnerCompteur = fournisseur["resultat"]["donnerCompteur"];
          let fournisseurActuelle = fournisseur["resultat"]["actuelle"];
          // filter les offre sélectionnée pour ce compteur
          let listDesOffre = fournisseur["resultat"]["result"];
          //var form = pdfDoc.getForm();
          //var page = pdfDoc.addPage([842, 595]);
          /*if (listDesOffre.length == 0) {
            continue;
          }
          var page = pdfDoc.addPage([842, 595]);

          var form = pdfDoc.getForm();
          if (k == 0) {
            page.drawText("Vos informations", {
              x: 500,
              y: 515,
              size: 15,
              font: HelveticaBoldFont,
              color: rgb(0.062, 0.227, 0.368),
            });
            var adressePostal = this.donnerDesFournisseur[0]["resultat"]["dataSociete"][
              "AdressePostal"
            ].replace(
              this.donnerDesFournisseur[0]["resultat"]["dataSociete"]["Raison"],
              ""
            );
            var adresseSocieteSeparer = await this.getCodePostale(adressePostal);
            if (!adresseSocieteSeparer[0]) {
              adresseSocieteSeparer[0] = "";
            }
            if (!adresseSocieteSeparer[1]) {
              adresseSocieteSeparer[1] = "";
            }
            if (!adresseSocieteSeparer[2]) {
              adresseSocieteSeparer[2] = "";
            }
            await this.createTextFieldPers(
              11,
              [0.1, 0.17, 0.35],
              true,
              form,
              page,
              510,
              465,
              200,
              40,
              `SIRET : ${this.donnerDesFournisseur[0]["resultat"]["dataSociete"]["siret"]}`,
              "siretConsommation",
              [1, 1, 1]
            );
            await this.createTextFieldPers(
              11,
              [0.1, 0.17, 0.35],
              true,
              form,
              page,
              510,
              445,
              200,
              40,
              `Raison Sociale : ${this.donnerDesFournisseur[0]["resultat"]["dataSociete"]["Raison"]}`,
              "raisonConsommation",
              [1, 1, 1]
            );
            if (adresseSocieteSeparer[0] != "") {
              await this.createTextFieldPers(
                11,
                [0.1, 0.17, 0.35],
                true,
                form,
                page,
                510,
                415,
                250,
                40,
                `Adresse : ${adresseSocieteSeparer[1]}`,
                "Adresse",
                [1, 1, 1]
              );
              await this.createTextFieldPers(
                11,
                [0.1, 0.17, 0.35],
                true,
                form,
                page,
                510,
                385,
                250,
                40,
                `Code postal : ${adresseSocieteSeparer[0]}`,
                "Code_postal",
                [1, 1, 1]
              );
              await this.createTextFieldPers(
                11,
                [0.1, 0.17, 0.35],
                true,
                form,
                page,
                510,
                365,
                250,
                40,
                `Commune : ${adresseSocieteSeparer[2]}`,
                "Commune",
                [1, 1, 1]
              );
            } else {
              await this.createTextFieldPers(
                11,
                [0.1, 0.17, 0.35],
                true,
                form,
                page,
                510,
                435,
                250,
                40,
                `Adresse postale : ${adressePostal}`,
                "Adresse",
                [1, 1, 1]
              );
            }
          }

          //prepare l'entete de consommation
          /*var enteteInfoCompteur = {
            xBegin: 80,
            yBegin: 460,
            data: [
              {
                text: [
                  {
                    fieldName: "pdl",
                    title: "Compteur",
                    value: numCompteur + "",
                  },
                  {
                    fieldName: "dateDebut",
                    title: "Déb.contrat ",
                    value: this.getDateFormatSlach(donnerCompteur["dateDebut"]),
                  },
                ],
                width: 200,
                height: 32,
                fieldName: "titleInfo",
              },
              {
                text: [
                  {
                    fieldName: "kvaBASE",
                    title: "Tarif",
                    value: donnerCompteur["tarif"] + "",
                  },
                ],
                width: 64,
                height: 32,
                fieldName: "Tarif",
              },
              {
                text: [
                  {
                    fieldName: "carBASE",
                    title: "Profil",
                    value: donnerCompteur["profilCompteur"] + "",
                  },
                ],
                width: 64,
                height: 32,
                fieldName: "titleCar",
              },
              {
                text: [
                  {
                    fieldName: "Total",
                    title: "CAR MWh",
                    value: donnerCompteur["CA"] + "",
                  },
                ],
                width: 64,
                height: 32,
                fieldName: "titleTotal",
              },
            ],
          };
          pdfDoc = await this.infoTable(form, pdfDoc, page, enteteInfoCompteur);*/
          //preparation des titre pour les offre principal
          const presentationURLPart2 = "/pdf/Page_APPEL_OFFRE.pdf";
          const presentationPart2Bytes = await fetch(presentationURLPart2).then(
            (res) => {
              return res.arrayBuffer();
            }
          );
          var presentationPagePart2Doc = await PDFDocument.load(
            presentationPart2Bytes
          );
          var getPages = presentationPagePart2Doc.getPages();
          var formpresentationPagePart2Doc = presentationPagePart2Doc.getForm();
          var firstPage = getPages[0];
          this.Offres = {
            actuelle: fournisseurActuelle,
            xBegin: 130,
            yBegin: 140,
            titles: [
              {
                title: "Budget Énergétique",
                subTitle: "en €/an",
                width: 64,
                pas: 2,
                fieldName: "BigBudgetEnergetique",
              },
              {
                title: "Distribution",
                subTitle: "en €/an",
                width: 64,
                pas: 2,
                fieldName: "BigDistribution",
              },
              {
                title: "Taxes",
                subTitle: "en €/an",
                width: 64,
                pas: 2,
                fieldName: "BigTaxes",
              },
              {
                title: "Abonnement",
                subTitle: "en €/an",
                width: 64,
                pas: 2,
                fieldName: "BigABO",
              },
              {
                title: "CEE",
                subTitle: "en €/an",
                width: 64,
                pas: 2,
                fieldName: "BigCEE",
              },
              {
                title: "CTA",
                subTitle: "en €/an",
                width: 64,
                pas: 2,
                fieldName: "CTA",
              },
              {
                title: "Budget HTVA",
                subTitle: "en €/an",
                width: 64,
                pas: 2,
                fieldName: "BigBudgetHTVA",
              },
              /*{
                title: "Fourniture",
                subTitle: "",
                width: 64,
                pas: 2,
                fieldName: "BigDurre",
              },
              {
                title: "Acheminement",
                subTitle: "",
                width: 64,
                pas: 2,
                fieldName: "BigTypeOffre",
              },
              {
                title: "Taxes",
                subTitle: "en €/an",
                width: 64,
                pas: 2,
                fieldName: "BigPrix",
              },
              {
                title: "Budget HTVA",
                subTitle: "en €/an",
                width: 66,
                pas: 2,
                fieldName: "BigBudget",
              },*/
              /*{
                title: "CEE",
                subTitle: "en €/an",
                width: 64,
                pas: 2,
                fieldName: "BigCEE",
              },
              {
                title: "Abonnement",
                subTitle: "en €/an",
                width: 64,
                pas: 2,
                fieldName: "BigABO",
              },
              {
                title: "Taxe (TICGN)",
                subTitle: "en €/an",
                width: 64,
                pas: 2,
                fieldName: "BigTICGN",
              },
              {
                title: "CTA",
                subTitle: "en €/an",
                width: 64,
                pas: 2,
                fieldName: "BigCTA",
              },
              {
                title: "Budget Annuel",
                subTitle: "en €/an",
                width: 64,
                pas: 2,
                fieldName: "BigBudget",
              },*/
            ],
            data: [],
          };
          // create line for fournisseur actuelle
          if (fournisseurActuelle == true) {
            if (
              !isNaN(fournisseur["resultat"]["result"][0]["cee"]) &&
              !isNaN(donnerCompteur["CA"])
            ) {
              var Budgetcee =
                fournisseur["resultat"]["result"][0]["cee"] *
                donnerCompteur["CA"];
            } else {
              var Budgetcee = 0;
            }

            var OffreActuelle = {
              xBegin: 130,
              yBegin: 330,
              logo:
                "/logoParticulier/founisseur_logos/" +
                fournisseur["resultat"]["result"][0]["logo"],
              data: [
                {
                  title: "Budget Energie",
                  subTitle: "en €/MWh",
                  value:
                    fournisseur["resultat"]["result"][0]["prixMolucule"] *
                      donnerCompteur["CA"] +
                    "",
                  fieldName: "ActuPrix",
                  width: 64,
                  pas: 2,
                },
                {
                  title: "Frais de distribution",
                  subTitle: "en €/an",
                  value:
                    fournisseur["resultat"]["result"][0]["ARTD"] *
                      donnerCompteur["CA"] +
                    "",
                  fieldName: "ActuARTD",
                  width: 66,
                  pas: 2,
                },
                {
                  title: "Taxe (TICGN)",
                  subTitle: "en €/ans",
                  value:
                    fournisseur["resultat"]["result"][0]["TICGN"] *
                      donnerCompteur["CA"] +
                    "",
                  fieldName: "ActuTICGN",
                  width: 64,
                  pas: 2,
                },
                {
                  title: "Abonnement",
                  subTitle: "en €/an",
                  value: fournisseur["resultat"]["result"][0]["ABO"] + "",
                  fieldName: "ActuAbonnement",
                  width: 64,
                  pas: 2,
                },
                {
                  title: "CEE",
                  subTitle: "en €/an",
                  value: Budgetcee + "",
                  fieldName: "Actucee",
                  width: 64,
                  pas: 2,
                },
                {
                  title: "CTA",
                  subTitle: "en €/an",
                  value: fournisseur["resultat"]["result"][0]["CTA"] + "",
                  fieldName: "ActuCTA",
                  width: 64,
                  pas: 2,
                },
                {
                  title: "Budget Annuel",
                  subTitle: "en €/an",
                  value: fournisseur["resultat"]["result"][0]["budget"] + "",
                  fieldName: "Actubudget",
                  width: 64,
                  pas: 2,
                },
                /*{
                  title: "Durée",
                  subTitle: "",
                  value: fournisseur["resultat"]["result"][0]["DUREE"] + " MOIS",
                  fieldName: "ActuDuree",
                  width: 64,
                  pas: 2,
                },
                {
                  title: "Type d'offre",
                  subTitle: "",
                  value: fournisseur["resultat"]["result"][0]["Offre"] + "",
                  fieldName: "ActuTypeOffre",
                  width: 64,
                  pas: 2,
                },
                {
                  title: "Budget Energie",
                  subTitle: "en €/MWh",
                  value:
                    fournisseur["resultat"]["result"][0]["prixMolucule"] *
                      donnerCompteur["CA"] +
                    "",
                  fieldName: "ActuPrix",
                  width: 64,
                  pas: 2,
                },
                {
                  title: "Frais de distribution",
                  subTitle: "en €/an",
                  value:
                    fournisseur["resultat"]["result"][0]["ARTD"] * donnerCompteur["CA"] +
                    "",
                  fieldName: "ActuARTD",
                  width: 66,
                  pas: 2,
                },
                {
                  title: "Taxe (TICGN)",
                  subTitle: "en €/ans",
                  value:
                    fournisseur["resultat"]["result"][0]["TICGN"] * donnerCompteur["CA"] +
                    "",
                  fieldName: "ActuTICGN",
                  width: 64,
                  pas: 2,
                },
                {
                  title: "Budget Annuel",
                  subTitle: "en €/an",
                  value: fournisseur["resultat"]["result"][0]["budget"] + "",
                  fieldName: "Actubudget",
                  width: 64,
                  pas: 2,
                },*/
              ],
            };
            this.OffreActuelleData = { ...OffreActuelle };
            presentationPagePart2Doc = await this.drawOffreActuelle(
              formpresentationPagePart2Doc,
              presentationPagePart2Doc,
              firstPage,
              OffreActuelle
            );
            this.Offres.titles.push({
              title: "Economies par rapport à votre offre",
              subTitle: "en %/an",
              width: 85,
              pas: 3,
              fieldName: "BigPourcentage",
            });
          }

          /////////////////////////////////////////////////////////////
          //ajouter ptout les proposition dans les offres a afficher
          var pos_debut = 0;
          if (fournisseurActuelle == true) {
            pos_debut = 1;
          }
          for (var index = pos_debut; index < listDesOffre.length; index++) {
            if (
              !isNaN(listDesOffre[index]["cee"]) &&
              !isNaN(donnerCompteur["CA"])
            ) {
              var Budgetcee = listDesOffre[index]["cee"] * donnerCompteur["CA"];
            } else {
              var Budgetcee = 0;
            }
            var item = [
              {
                value:
                  "/logoParticulier/founisseur_logos/" +
                  listDesOffre[index]["logo"],
                fieldName: "logo",
                width: 64,
                pas: 2,
              },
              /*{
                value: listDesOffre[index]["DUREE"] + " MOIS",
                fieldName: "ActuDuree",
                width: 64,
                pas: 2,
              },
              {
                value: listDesOffre[index]["Offre"] + "",
                fieldName: "ActuTypeOffre",
                width: 64,
                pas: 2,
              },*/
              {
                value:
                  listDesOffre[index]["prixMolucule"] * donnerCompteur["CA"] +
                  "",
                fieldName: "ActuPrix", // budget energie
                width: 64,
                pas: 2,
              },
              {
                value: listDesOffre[index]["ARTD"] * donnerCompteur["CA"] + "",
                fieldName: "ActuARTD", //distribution
                width: 64,
                pas: 2,
              },
              {
                value: listDesOffre[index]["TICGN"] * donnerCompteur["CA"] + "",
                fieldName: "ActuTICGN", //taxes
                width: 64,
                pas: 2,
              },
              {
                value: listDesOffre[index]["ABO"] + "",
                fieldName: "ActuAbonnement",
                width: 64,
                pas: 2,
              },
              {
                value: Budgetcee + "",
                fieldName: "Actucee",
                width: 64,
                pas: 2,
              },
              {
                value: listDesOffre[index]["CTA"] + "",
                fieldName: "ActuCTA",
                width: 64,
                pas: 2,
              },
              {
                value: listDesOffre[index]["budget"] + "",
                fieldName: "Actubudget", //budget HTVA
                width: 64,
                pas: 2,
              },
            ];
            // ajouter champ economies si ilya un fournisseur actuelle
            if (fournisseurActuelle == true) {
              try {
                var poucentage =
                  ((listDesOffre[0]["budget"] - listDesOffre[index]["budget"]) /
                    listDesOffre[0]["budget"]) *
                  100;
                poucentage = poucentage.toFixed(2);
              } catch (error) {
                var poucentage = 0.0;
              }
              item.push({
                value: poucentage < 0 ? "--" : poucentage + "",
                fieldName: "pourcentage",
                width: 70,
                pas: 5,
              });
            }
            this.Offres.data.push(item);
          }
          let resultatDrawOffres = await this.drawOffres(
            formpresentationPagePart2Doc,
            presentationPagePart2Doc,
            firstPage,
            this.Offres,
            fournisseurActuelle,
            fournisseur["resultat"]
          );

          await this.createPDFWithMultipleTables(
            listDesOffre,
            presentationPagePart2Doc
          );
          pdfDoc = await this.addPagesToPdf(pdfDoc, presentationPagePart2Doc);
          //var page = pdfDoc.addPage([842, 595]);
          //var form = pdfDoc.getForm();
          //preparation des titres pour les recap
          this.OffresRecap = {
            actuelle: fournisseurActuelle,
            xBegin: 192,
            yBegin: 140,
            titles: [
              {
                title: "Prix Molecule",
                subTitle: "en €/MWh",
                width: 70,
                pas: 2,
                fieldName: "BigPrix",
              },
              {
                title: "Frais de distribution",
                subTitle: "en €/MWh",
                width: 66,
                pas: 2,
                fieldName: "BigARTD",
              },
              {
                title: "CEE",
                subTitle: "en €/MWh",
                width: 64,
                pas: 2,
                fieldName: "BigCEE",
              },
              {
                title: "Abonnement",
                subTitle: "en €/mois",
                width: 64,
                pas: 2,
                fieldName: "BigABO",
              },
              {
                title: "Taxe (TICGN)",
                subTitle: "en €/MWh",
                width: 64,
                pas: 2,
                fieldName: "BigTICGN",
              },
              {
                title: "CTA",
                subTitle: "en €/mois",
                width: 64,
                pas: 2,
                fieldName: "BigCTA",
              },
              {
                title: "Budget Annuel",
                subTitle: "en €/an",
                width: 64,
                pas: 2,
                fieldName: "BigBudget",
              },
            ],
            data: [],
          };

          //ajouter tout les proposition dans les offres Recap a afficher
          for (var index = 0; index < listDesOffre.length; index++) {
            if (!isNaN(listDesOffre[index]["cee"])) {
              var cee = listDesOffre[index]["cee"];
            } else {
              var cee = 0;
            }
            var item = [
              {
                value:
                  "/logoParticulier/founisseur_logos/" +
                  listDesOffre[index]["logo"],
                fieldName: "logo",
                width: 70,
                pas: 2,
              },
              {
                value: listDesOffre[index]["prixMolucule"] + "",
                fieldName: "recapPrix",
                width: 70,
                pas: 2,
              },
              {
                value: listDesOffre[index]["ARTD"] + "",
                fieldName: "recapARTD",
                width: 64,
                pas: 2,
              },
              {
                value: cee + "",
                fieldName: "recapcee",
                width: 64,
                pas: 2,
              },
              {
                value: listDesOffre[index]["ABO"] / 12 + "",
                fieldName: "recapAbonnement",
                width: 64,
                pas: 2,
              },
              {
                value: listDesOffre[index]["TICGN"] + "",
                fieldName: "recapTICGN",
                width: 64,
                pas: 2,
              },
              {
                value: listDesOffre[index]["CTA"] / 12 + "",
                fieldName: "recapCTA",
                width: 64,
                pas: 2,
              },
              {
                value: listDesOffre[index]["budget"] + "",
                fieldName: "recapbudget",
                width: 64,
                pas: 2,
              },
            ];
            this.OffresRecap.data.push(item);
          }

          /*var res = await this.drawOffresRecap(
            form,
            pdfDoc,
            page,
            this.OffresRecap,
            fournisseurActuelle
          );
          pdfDoc = res[0];
          page = res[1];
          var yBegin = res[2];*/

          let fournisseurList = [];
          for (var i = 0; i < listDesOffre.length; i++) {
            fournisseurList[i] =
              listDesOffre[i]["fournisseur"].toLowerCase() + ".pdf";
          }
          if (fournisseurActuelle == true) {
            fournisseurList.push(
              fournisseur["resultat"]["result"][0][
                "fournisseur"
              ].toLowerCase() + ".pdf"
            );
          }
        }
        fournisseurList = this.filterArray(fournisseurList);
        for (var i = 0; i < fournisseurList.length; i++) {
          var name = fournisseurList[i];
          try {
            const url4 = "/newFournisseurs/" + name;
            const fournisseurPdfBytes = await fetch(url4).then((res) =>
              res.arrayBuffer()
            );

            const fournisseurPdfDoc = await PDFDocument.load(
              fournisseurPdfBytes
            );

            pdfDoc = await this.addPagesToPdf(pdfDoc, fournisseurPdfDoc);
          } catch (error) {
            console.log("Presentation Fournisseur introuvable " + name);
          }
        }
        /*const presentationURLPartFinal = "/pdf/Presentation-11-13.pdf";
        const presentationPartFinalBytes = await fetch(
          presentationURLPartFinal
        ).then((res) => res.arrayBuffer());
        var presentationPagePartFinalDoc = await PDFDocument.load(
          presentationPartFinalBytes
        );

        var getPages = presentationPagePartFinalDoc.getPages();*/

        const presentationURLContact = "/pdf/Page_Contact.pdf";
        const presentationPartContactBytes = await fetch(
          presentationURLContact
        ).then((res) => res.arrayBuffer());
        var presentationPagePartContactDoc = await PDFDocument.load(
          presentationPartContactBytes
        );

        var getPagesContact = presentationPagePartContactDoc.getPages();

        const contactPage = getPagesContact[0];
        // var username = localStorage.getItem("email");
        // var nomPrenom = localStorage.getItem("vendeurName");
        // var Telephone = this.formatPhoneNumber(
        //   localStorage.getItem("Telephone")
        // );
        var username = "contact@volt-consulting.fr";
        var nomPrenom = "VOLT CONSULTING";
        var Telephone = "01 87 66 70 43";
        // let roleUser = localStorage.getItem("typeUser");
        const Helvetica = await presentationPagePartContactDoc.embedFont(
          StandardFonts.Helvetica
        );

        contactPage.drawText(this.capitalizeEachWord(nomPrenom), {
          x: 460,
          y: 360,
          size: 26,
          font: Helvetica,
          color: rgb(1, 1, 1),
        });
        /*contactPage.drawText(roleUser.toUpperCase(), {
          x: 460,
          y: 320,
          size: 20,
          font: Helvetica,
          color: rgb(1, 1, 1),
        });*/
        if (Telephone != null && Telephone != "null") {
          contactPage.drawText(Telephone, {
            x: 480,
            y: 275,
            size: 25,
            font: Helvetica,
            color: rgb(1, 1, 1),
          });
        }
        const createPageLinkAnnotation = (PDFPage, string) => {
          PDFPage.doc.context.register(
            contactPage.doc.context.obj({
              Type: "Annot",
              Subtype: "Link",
              Rect: [0, 30, 40, 230],
              Border: [0, 0, 2],
              C: [0, 0, 1],
              A: {
                Type: "Action",
                S: "URI",
                URI: PDFString.of(`mailto:${string}`),
              },
            })
          );
        };
        contactPage.drawText(username, {
          x: 480,
          y: 225,
          size: 20,
          font: Helvetica,
          color: rgb(1, 1, 1),
        });

        const link = createPageLinkAnnotation(contactPage, username);
        contactPage.node.set(
          PDFName.of("Annots"),
          presentationPagePartContactDoc.context.obj([link])
        );

        const presentationURLChangementSansContrainte =
          "/pdf/Page_Changement.pdf";
        const presentationURLChangementSansContraintelBytes = await fetch(
          presentationURLChangementSansContrainte
        ).then((res) => res.arrayBuffer());
        var presentationURLChangementSansContraintelBytesDoc =
          await PDFDocument.load(presentationURLChangementSansContraintelBytes);
        pdfDoc = await this.addPagesToPdf(
          pdfDoc,
          presentationURLChangementSansContraintelBytesDoc
        );
        pdfDoc = await this.addPagesToPdf(
          pdfDoc,
          presentationPagePartContactDoc
        );

        const pdfBytes = await pdfDoc.save();
        this.generatedPdf = pdfBytes;
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = today.getFullYear();

        today = mm + "/" + dd + "/" + yyyy;
        // download(
        //   pdfBytes,
        //   "Presentation_" +
        //     this.donnerDesFournisseur[0]["resultat"]["dataSociete"]["siret"] +
        //     "_" +
        //     today +
        //     ".pdf",
        //   "application/pdf"
        // );

        // Download PDF: this logic download large pdf files
        try {
          const blob = new Blob([pdfBytes], { type: "application/pdf" });
          const linkHTMLElement = document.createElement("a");
          linkHTMLElement.href = window.URL.createObjectURL(blob);
          linkHTMLElement.download =
            "Presentation_" +
            this.donnerDesFournisseur[0]["resultat"]["dataSociete"]["siret"] +
            "_" +
            today +
            ".pdf";
          linkHTMLElement.click();
        } catch (error) {
          console.log("error downloading file:", error);
        }
      }
      this.loadingPres = false;
      this.dialogPres = false;
    },
    async getDataCharts(
      seriesCharts,
      urlAPI,
      dataCharts,
      categoriesCharts,
      items,
      firstDateSearch,
      lastDateSearch
    ) {
      seriesCharts.value = [];
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + urlAPI,
        method: "POST",
        data: {
          year: 2022,
          dateDebut: firstDateSearch,
          dateFin: lastDateSearch,
        },
      })
        .then((res) => {
          dataCharts = res.data.data;
          Object.keys(dataCharts).forEach((key) => {
            var d = {
              name: key,
              data: dataCharts[key]["data"],
            };
            items.value.push({
              categorie: key,
              today: dataCharts[key]["dataTable"][key]["jour"],
              week: dataCharts[key]["dataTable"][key]["semaine"],
              month: dataCharts[key]["dataTable"][key]["month"],
              year: dataCharts[key]["dataTable"][key]["years"],
            });
            if (
              categoriesCharts.value.length < dataCharts[key]["label"].length
            ) {
              categoriesCharts.value = dataCharts[key]["label"];
            }
            seriesCharts.value.push(d);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async updateCourbe(
      series,
      urlAPI,
      dateDebut,
      dateFin,
      itemsSeries,
      categories,
      dataSeries,
      updateChartsOptions
    ) {
      //this.dialog = true;
      //this.loading = true;
      series.value = [];
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + urlAPI,
        method: "POST",
        data: { year: 2022, dateDebut: dateDebut, dateFin: dateFin },
      })
        .then((res) => {
          itemsSeries.value = [];
          categories.value = [];
          dataSeries.value = res.data.data;
          Object.keys(dataSeries.value).forEach((key) => {
            var d = {
              name: key,
              data: dataSeries.value[key]["data"],
            };
            itemsSeries.value.push({
              categorie: key,
              today: dataSeries.value[key]["dataTable"][key]["jour"],
              week: dataSeries.value[key]["dataTable"][key]["semaine"],
              month: dataSeries.value[key]["dataTable"][key]["month"],
              year: dataSeries.value[key]["dataTable"][key]["years"],
            });
            if (
              categories.value.length < dataSeries.value[key]["label"].length
            ) {
              categories.value = dataSeries.value[key]["label"];
            }
            series.value.push(d);
            updateChartsOptions = {
              chart: {
                toolbar: {
                  show: false,
                  tools: {
                    download: false,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                  },
                },
              },
              series: [{ data: series.value }],
              xaxis: {
                type: "datetime",
                categories: categories.value,
                tickPlacement: "on",
                labels: {
                  formatter: function (value) {
                    return dayjs(value).format("DD-MM-YYYY");
                  },
                },
              },
            };
            switch (urlAPI) {
              case "getAllDataCourbeGaz/":
                this.charts[0].updateChartsOptions = updateChartsOptions;
                break;

              default:
                break;
            }
            //this.dialog = false;
            //this.loading = false;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async createGraph() {
      await this.getDataCharts(
        this.charts[0].chartOptions.series,
        this.charts[0].urlAPI,
        this.charts[0].data,
        this.charts[0].chartOptions.categories,
        this.charts[0].items,
        dayjs().subtract(1, "year").format("YYYY-MM-DD"),
        dayjs().format("YYYY-MM-DD")
      );
    },
    
    async convertApexCartToImage(chartId) {
      const chartInstance = window.Apex._chartInstances.find(chart => chart.id === chartId);
      const base64 = await chartInstance.chart.dataURI();
      return base64.imgURI;
    },
    async captureDownloadImage(idDiv, idScreenShot) {
      this.dialogGraph = false;
      this.loadingPres = true;
      this.dialogPres = true;
      if (this.toggleCharts) {
        /*let lien;
        document.getElementById(idScreenShot).style.display = "none";
        var container = document.getElementById(idDiv);
        await html2canvas(container, { allowTaint: true, scale: 2 }).then(
          function (canvas) {
            var link = document.createElement("a");
            document.body.appendChild(link);
            idDiv == "capture-elec"
              ? (link.download = "évolution_prix_elec.jpg")
              : idDiv == "capture-gaz"
              ? (link.download = "évolution_prix_gaz.jpg")
              : (link.download = "évolution_prix_spot.jpg");

            lien = canvas.toDataURL("image/png", 1.0);
          }
        );
        document.getElementById(idScreenShot).style.display = "block";
        this.graphImage = lien;*/
       this.graphImage = await this.convertApexCartToImage("chatGazAndElec");
      }
      this.loadingPres = false;
      this.dialogPres = false;
      await this.createPres();
    },
  },
};
</script>
