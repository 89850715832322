<template>
    <div>
        <v-dialog
            v-model="dialog"
            persistent
            max-width="500"
            >
            <v-card>
                <v-card-title class="text-h5">
                Extraction du contrat
                </v-card-title>
                <v-card-text>
                    <v-alert v-if="error==true && loading==false" type="error">
                        {{messageDialoge}}
                    </v-alert>
                    <v-alert v-if="error==false && loading==false" type="success">
                        {{messageDialoge}}
                    </v-alert>
                    <v-flex  class="text-center">
                        <v-progress-circular v-if="loading==true"
                            :size="150"
                            :width="7"
                            color="primary"
                            indeterminate
                            >
                            Patientez  ...
                        </v-progress-circular>
                    </v-flex>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="loading==false"
                    color="green darken-1"
                    text
                    @click="dialog = false"
                >
                    FERMER
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        
        <div>
            <v-btn
            @click="generatePdf"
            icon
            depressed class="ma-auto text-capitalize">
                <v-icon  :color="$colors[0]">mdi-text-box-edit</v-icon>
            </v-btn>
        </div>
    </div>
</template>
<script>
    import { degrees, PDFDocument, rgb, StandardFonts,layoutMultilineText } from 'pdf-lib'
    import download from 'downloadjs'
    import jsPDF from 'jspdf'
    import 'jspdf-autotable'
    import axios from 'axios'
    export default {
        name: 'Totale',
        props: {
            donner: null,
            numCompteur: null,
            societe: null,
            donnerEnedis: null,
        },
        data: () => ({
            
            dialog: false,
            error: false,
            loading: false,
            messageDialoge: "",
             energieVert: true,
             percent: '25',
             generatedPdf: null,
             type: '',
             positionPage1: 1,
             positionPage1: 10,
             positionPage1: 10,
             positionPage1: 10,
             positionPage1: 10,
             positionPage1: 10,
             
             data:{
                 dateAjourdhui: new Date().getDate()+"/"+new Date().getMonth()+"/"+new Date().getFullYear(),
                 dateDebut: "10/10/2020",
                 dateFin: "10/10/2022",
                 pdl: "14552022144555",
                 siret: "11111111111111", 
                 codeNaf: "1212Z", 
                 adresseSociete: "199 RUE, 12554 commune d'atte", 
                 codePostaleCompteur: '',
                 voieCompteur: '',
                 communeCompteur: '',
                 codePostaleSociete: '',
                 voieSociete: '',
                 communeSociete: '',
                 adresseCompteur: "199 RUE, 12554 commune d'atte",
                 codePostale: '',
                 nom: "chrif",
                 prenom: "slimen", 
                 email: "slimenc6@gmail", 
                 numTele: "0755448899", 
                 consommationAnuuelle: "377.20",
                 typeCompteur: "BTINFLU4",
                 kva: "100",
                 
                kvaBase: '',
                kvaHp: '',
                kvaHc: '',
                kvaPte: '',
                kvaHce: '',
                kvaHpe: '',
                kvaHch: '',
                kvaHph: '',

                 prixHPH: "9",
                 prixHCH: "8",
                 prixHPE: "7",
                 prixHCE: "4",
                 prixHC: "8",
                 prixHP: "7",
                 prixBASE: "4",
                 raison: '',
                 nbrYears: '2',
             },
        }),
        
        created() {
            //this.verifyApplicationDataSecurity()
        },
        methods: {
            async getCodeNaf(){
                this.data.dateAjourdhui= new Date().getDate()+"/"+new Date().getMonth()+1+"/"+new Date().getFullYear()
                if(this.donnerEnedis.donneTechniqueInfo[0].result == 'valide'){
                    this.data.adresseCompteur = this.donnerEnedis.donneTechniqueInfo[0].data.numVoie+' '+
                            this.donnerEnedis.donneTechniqueInfo[0].data.codePostal+' '+this.donnerEnedis.donneTechniqueInfo[0].data.commune

                }
                this.data.dateDebut = this.donner.dateDebut+""
                this.data.dateFin = this.donner.dateFin+""
                this.data.pdl = this.numCompteur+""
                this.data.siret = this.societe.siret+''
                this.data.adresseSociete = this.societe.AdressePostal+""
                this.data.nom = this.societe.nom+""
                this.data.prenom = this.societe.prenom+""
                this.data.email = this.societe.AdresseEmail+""
                this.data.numTele = this.societe.numTele+""
                this.data.consommationAnuuelle = this.donner.consomationGlobale+""
                this.data.kva = this.donnerEnedis.donneTechniqueInfo[0].data.kva+""
                this.data.raison = this.societe.Raison+""
                this.data.nbrMonth = this.donner.nbrMonth+""
                this.data.typeCompteur = this.donnerEnedis.donneTechniqueInfo[0].data.typeCompteur
                this.data.codeNaf = this.societe.codeNaf               
                this.data.codePostaleCompteur = this.donnerEnedis.donneTechniqueInfo[0].data.codePostal
                this.data.voieCompteur = this.donnerEnedis.donneTechniqueInfo[0].data.numVoie
                this.data.communeCompteur = this.donnerEnedis.donneTechniqueInfo[0].data.commune
                this.data.codePostaleSociete = this.societe.codePostal
                this.data.voieSociete = this.societe.Voie
                this.data.communeSociete = this.societe.commune
                this.data.dateAjourdhui= new Date().getDate()+"/"+new Date().getMonth()+1+"/"+new Date().getFullYear()
                
                
            },
            async getCodePostale(adresse){
                var myRe = new RegExp('\\d\\d\\d\\d\\d', '');
                var myRe2 = new RegExp('\\d+', '');
                var myArray = myRe.exec(adresse);
                var codePostale =  myArray[0]
                var voie = adresse.split(codePostale)[0]
                var commune = adresse.split(codePostale)[1]
                var myArray = myRe2.exec(voie)
                var numRue=""
                if(myArray!=null){
                    numRue = myArray[0]
                }
                return [codePostale, voie, commune, numRue]
            },
            async createContrat(){
                this.addData = false
                this.dialog= true
                this.error= false
                this.loading= true
                this.messageDialoge= ""
                
                var dataToSend = {
                    donner: this.donner,
                    donnerEnedis: this.donnerEnedis,
                    optionTarifaire: this.optionTarifaire,
                    numCompteur: this.numCompteur,
                    societe: this.societe,
                }
                await axios({
                    url: 'https://editioncontrat.agora-energie.com/totalContrat',
                    // url: 'http://127.0.0.1:3000/totalContrat',
                    method: 'POST',
                    data: dataToSend,
                    headers:{'content-type': 'application/json'}
                    
                })
                .then(async (res) => {
                    var doc = await PDFDocument.load(res.data)
                    doc = await doc.save()
                    download(doc, this.numCompteur,'_TotalClassique_'+this.societe.Raison+'_.pdf', 'application/pdf')
                }).catch((err)=>{
                    console.log(err)
                })
                this.dialog= false
                this.error= false
                this.loading= false
                this.messageDialoge= ""
            },
            async generatePdf(){
                this.dialog = true
                this.error = false
                this.loading = true
                this.messageDialoge = ""
                 await this.getCodeNaf()
                /////////////// page 1 ///////////////////////////////////////
                var url0 = '/pdf/totale/totaleContrat-1.pdf'
                const pageZero = await fetch(url0).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pageZeroDoc = await PDFDocument.load(pageZero)
                var getPages = pageZeroDoc.getPages()
                var firstPage = getPages[0]
                
                firstPage.drawText(this.data.raison, {
                    x: 90,
                    y: 643,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText(this.data.codeNaf, {
                    x: 70,
                    y: 607,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.siret, {
                    x: 240,
                    y: 607,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.nom, {
                    x: 60,
                    y: 590,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText(this.data.prenom, {
                    x: 240,
                    y: 590,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.adresseCompteur, {
                    x: 160,
                    y: 574,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                var site = await this.getCodePostale(this.data.adresseCompteur)
                
                firstPage.drawText(site[0], {
                    x: 73,
                    y: 558,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(site[2], {
                    x: 240,
                    y: 558,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.email, {
                    x: 65,
                    y: 542,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.numTele, {
                    x: 240,
                    y: 524,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText("X", {
                    x: 61,
                    y: 350,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                /////////////// page 2 ///////////////////////////////////////
                var url0 = '/pdf/totale/totaleContrat-2.pdf'
                const pageOne = await fetch(url0).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pageOneDoc = await PDFDocument.load(pageOne)
                var getPages = pageOneDoc.getPages()
                var firstPage = getPages[0]
                firstPage.drawText(this.data.pdl, {
                    x: 85,
                    y: 635,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.kva, {
                    x: 340,
                    y: 635,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.typeCompteur, {
                    x: 90,
                    y: 620,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText(this.data.dateDebut, {
                    x: 350,
                    y: 620,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                 /////////////// page 3 ///////////////////////////////////////
                var url0 = '/pdf/totale/totaleContrat-3.pdf'
                const pageTwo = await fetch(url0).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pageTwoDoc = await PDFDocument.load(pageTwo)
                var getPages = pageTwoDoc.getPages()
                var firstPage = getPages[0]
                 firstPage.drawText(this.data.raison, {
                    x: 285,
                    y: 709,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                 firstPage.drawText(this.data.siret, {
                    x: 265,
                    y: 690,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                var site = await this.getCodePostale(this.data.adresseSociete)
                
                 firstPage.drawText(site[3], {
                    x: 280,
                    y: 671,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(site[1], {
                    x: 340,
                    y: 671,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText(site[0], {
                    x: 280,
                    y: 650,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText(site[2], {
                    x: 350,
                    y: 650,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                 /////////////// page 4 ///////////////////////////////////////
                var url0 = '/pdf/totale/totaleContrat-4.pdf'
                const pageThree = await fetch(url0).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pageThreeDoc = await PDFDocument.load(pageThree)
                var getPages = pageThreeDoc.getPages()
                var firstPage = getPages[0]
                 /////////////// page 5 ///////////////////////////////////////
                var url0 = '/pdf/totale/totaleContrat-5.pdf'
                const pageFour = await fetch(url0).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pageFourDoc = await PDFDocument.load(pageFour)
                var getPages = pageFourDoc.getPages()
                var firstPage = getPages[0]
                // Create a new PDFDocument
                let mergedPdf = await PDFDocument.create()
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageZeroDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageOneDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageTwoDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageThreeDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageFourDoc)
                //fin contact
                const pdfBytes = await mergedPdf.save()
                this.generatedPdf = pdfBytes
                download(pdfBytes, this.data.pdl+'_TotalClassique_'+this.data.raison+'_.pdf', 'application/pdf')
                
                this.dialog = false
                this.error = false
                this.loading = false
                this.messageDialoge = ""
            },
            async addPagesToPdf(mergedPdf, donorPdf) {
                const copiedPages = await mergedPdf.copyPages(
                    donorPdf,
                    donorPdf.getPageIndices()
                )
                copiedPages.forEach((page) => {
                    mergedPdf.addPage(page)
                })
                return mergedPdf
            },
            verifyApplicationDataSecurity() {
                let self = this
                var formData = new FormData()
                formData.append('type', localStorage.getItem('typeUser'))
                formData.append('vueAfficher', 'pro')
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'verifyApplicationDataSecurity.php',
                    method: 'POST',
                    data: formData,
                    
                })
                .then((res) => {
                    var data = JSON.parse(JSON.stringify(res.data))
                    if (data[0].result === 'notLogin') {
                        localStorage.setItem('vueAfficher', 'particulier')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    } else if (data[0].result === 'accessDenied') {
                        localStorage.setItem('vueAfficher', 'particulier')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
            },
        },
    }
</script>