<template>
  <v-card class="mt-6 pa-0 mr-2 ml-3" elevation="10">
    <v-toolbar :color="$colors[0]" dark>
      <span class="text-h6 pl-0">Création de message</span>
    </v-toolbar>
    <v-form class="pa-4" ref="form" lazy-validation>
      <v-row>
        <v-col cols="12" md="12" lg="12" xl="12">
          <v-radio-group v-model="color" row>
            <v-radio color="green" label="vert" value="goodNews"></v-radio>
            <v-radio color="amber" label="orange" value="warningNews"></v-radio>
            <v-radio color="red" label="rouge" value="badNews"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="11" md="11" lg="11" xl="11">
          <v-textarea
            v-model="message"
            solo
            auto-grow
            label="Message"
            color="teal"
            :rules="[required('un message valide')]"
          >
          </v-textarea>
          <div>
            <emoji-picker @emoji="insert" :search="search" class="d-flex">
              <div
                class="mt-1 mr-1"
                slot="emoji-invoker"
                slot-scope="{ events: { click: clickEvent } }"
                @click.stop="clickEvent"
              >
                <button type="button">
                  <v-icon>mdi-emoticon-outline</v-icon>
                </button>
              </div>
              <div
                slot="emoji-picker"
                slot-scope="{ emojis, insert, display }"
                class="ml-2"
              >
                <div>
                  <div style="height: 150px; width: 20%; overflow-y: auto">
                    <div
                      v-for="(emojiGroup, category) in emojis"
                      :key="category"
                    >
                      <h5>{{ category }}</h5>
                      <div>
                        <span
                          style="cursor: pointer"
                          v-for="(emoji, emojiName) in emojiGroup"
                          :key="emojiName"
                          @click="insert(emoji)"
                          :title="emojiName"
                          >{{ emoji }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </emoji-picker>
          </div>
        </v-col>
        <v-col cols="12" md="12" lg="12" xl="12">
          <v-btn
            depressed
            :loading="loadingButtonAddMessage"
            class="mb-4 text-white"
            @click="ajouterMessage(message, color)"
            :color="$colors[0]"
            >Ajouter un message</v-btn
          >
          <v-snackbar v-model="snackbar" timeout="2000" color="green accent-3">
            <template v-slot:default>
              <span
                class="d-flex flex-row justify-content-center align-items-center"
              >
                <v-icon>mdi-check-circle</v-icon>
                <p class="ma-0 ml-2">Message ajouté !</p>
              </span>
            </template>
          </v-snackbar>
        </v-col>
      </v-row>
    </v-form>
    <div v-if="messages.length > 0" class="card mb-4">
      <div class="card-body pa-0" style="overflow: overlay">
        <p class="my-4 ml-2">
          <b
            ><span style="color: #0d47a1" class="font-italic me-1"
              >Liste des messages</span
            ></b
          >
        </p>
        <b-table
          hover
          id="table-controle-alert"
          class="text-center"
          responsive
          :busy="loadingTable"
          bordered
          fixed
          :fields="fieldMessage"
          :items="messages"
        >
          <template #cell(date)="data">
            <span>{{ $dayjs(data.item.date).format("DD/MM/YYYY") }}</span>
          </template>
          <template #table-busy>
            <div
              class="d-flex flex-column flex-lg-row justify-content-center text-primary my-2"
            >
              <b-spinner
                style="width: 2rem; height: 2rem"
                class="align-middle mx-4"
              ></b-spinner>
              <strong>Chargement...</strong>
            </div>
          </template>
          <template #cell(message)="data">
            <span style="width: 60%; text-align: end">{{
              data.item.message
            }}</span>
          </template>
          <template #cell(action)="data">
            <div class="d-flex justify-content-center align-items-center">
              <v-dialog
                persistent
                v-model="dialog"
                width="500"
                :retain-focus="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="newMessage = data.item"
                    class="mt-1"
                    color="grey"
                    dark
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-message-text</v-icon>
                  </v-btn>
                </template>

                <v-card>
                  <v-form>
                    <v-toolbar :color="$colors[0]" dark>
                      <v-card-title
                        class="d-flex justify-content-center align-items-center"
                      >
                        Modification de message
                      </v-card-title>
                    </v-toolbar>
                    <v-col cols="12" md="12" lg="12" xl="12">
                      <span style="font-size: large; font-weight: bold"
                        >Importance du message :</span
                      >
                      <v-radio-group v-model="newMessage.color" row>
                        <v-radio label="vert" value="goodNews"></v-radio>
                        <v-radio label="orange" value="warningNews"></v-radio>
                        <v-radio label="rouge" value="badNews"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <span
                      class="ml-4"
                      style="font-size: large; font-weight: bold"
                      >Nouveau message :</span
                    >
                    <v-textarea
                      class="ma-4"
                      ref="newMessage"
                      solo
                      auto-grow
                      :label="newMessage.message"
                      color="teal"
                    >
                    </v-textarea
                    ><!-- Maximum call stack size exceeded -> v-model message -->

                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        class="text-white"
                        @click="dialog = false"
                      >
                        Fermer
                      </v-btn>
                      <v-btn
                        :color="$colors[0]"
                        class="text-white"
                        @click="modifierMessage()"
                        :loading="loadingButtonModifyMessage"
                      >
                        Modifier
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
              <v-btn
                v-if="data.item.order > 1"
                :loading="loadingButton"
                icon
                color="blue darken-4"
                id="background-hover"
                @click="test(data.item)"
                ><v-icon>mdi-arrow-up-bold-box</v-icon></v-btn
              >
              <v-btn
                icon
                id="background-hover"
                color="error"
                @click="deleteMessage(data.item.id)"
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </v-card>
</template>
<script>
import axios from "axios";
import EmojiPicker from "vue-emoji-picker";
import dialogeModal from "../../components/ui/dialogeModal";
export default {
  name: "addRapideMessage",
  components: {
    dialogeModal: dialogeModal,
    EmojiPicker: EmojiPicker,
  },
  data() {
    return {
      snackbar: false,
      loadingButtonAddMessage: false,
      loadingTable: false,
      loadingButtonModifyMessage: false,
      loadingButton: false,
      newMessage: { color: "", message: "", id: 0 },
      dialog: false,
      message: "",
      color: "goodNews",
      search: "",
      messages: [],
      lesOrder: [],
      fieldMessage: [
        { key: "date", label: "Date d'ajout" },
        { key: "message", label: "Message" },
        { key: "color", label: "Couleur" },
        { key: "action", label: "Action" },
      ],
      required(fieldName) {
        return (value) =>
          (!!value && value >= 0) ||
          (!!value && value != "") ||
          `Veuillez saisir ${fieldName}`;
      },
    };
  },
  async created() {
    this.loadingTable = true;
    var whoIam = "";
    await this.whoIAm(this.getToken("token")).then((data) => {
      whoIam = data;
    });
    if (whoIam == "noToken") {
      localStorage.removeItem("vendeurName");
      localStorage.removeItem("typeUser");
      localStorage.removeItem("token");
      this.$router.push("/login");
    } else {
      localStorage.setItem("typeUser", whoIam);
    }
    this.getAllMessage();
    this.loadingTable = false;
  },
  computed: {},
  methods: {
    async test(item) {
      this.loadingButton = true;
      if (item.order > 1) {
        let indexElement = this.lesOrder.findIndex(
          (element) => element == item.order
        );
        if (indexElement != 0) {
          var tmp = this.lesOrder[indexElement];
          this.lesOrder[indexElement] = this.lesOrder[indexElement - 1];
          this.lesOrder[indexElement - 1] = tmp;
          var formData = new FormData();
          formData.append("token", this.getToken("token"));
          formData.append("lesOrder", this.lesOrder);
          await axios({
            url: process.env.VUE_APP_URL_API_CLIENT + "modifierLesOrdre/",
            method: "POST",
            data: formData,
          })
            .then((res) => {
              if (res.data.result == "permission") {
                localStorage.removeItem("vendeurName");
                localStorage.removeItem("typeUser");
                localStorage.removeItem("token");
                this.$router.push("/login");
              }
            })
            .catch((err) => {});
        }
      }
      await this.getAllMessage();
      this.loadingButton = false;
    },
    async modifierMessage() {
      this.loadingButtonModifyMessage = true;
      var formData = new FormData();
      this.$refs.newMessage.lazyValue =
        this.$refs.newMessage.lazyValue == null
          ? this.newMessage.message
          : this.$refs.newMessage.lazyValue;
      formData.append("message", this.$refs.newMessage.lazyValue);
      formData.append("color", this.newMessage.color);
      formData.append("id", this.newMessage.id);
      formData.append("token", this.getToken("token"));
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "modifierMessage/",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          if (res.data.result == "permission") {
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((err) => {});
      await this.getAllMessage();

      this.$refs.newMessage.reset();
      this.newMessage.id = 0;
      this.loadingButtonModifyMessage = false;
      this.dialog = false;
    },
    insert(emoji) {
      this.message += emoji;
    },
    async deleteMessage(id) {
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "deleteMessage/",
        method: "POST",
        data: {
          id: id,
          token: this.getToken("token"),
        },
      })
        .then((res) => {
          if (res.data.result == "permission") {
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((err) => {
          console.log(err);
        });
      await this.getAllMessage();
    },
    async getAllMessage() {
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getListmessageRapide/",
        method: "POST",
        data: {},
      })
        .then((res) => {
          this.messages = res.data.result;
          this.lesOrder = res.data.lesOrder;
          this.$store.commit("addMessage", this.messages);
        })
        .catch((err) => {});
    },
    async ajouterMessage(message, color, value) {
      if (this.$refs.form.validate() || value) {
        this.snackbar = true;
        this.loadingButtonAddMessage = true;
        var formData = new FormData();
        formData.append("message", message);
        formData.append("color", color);
        formData.append("token", this.getToken("token"));

        await axios({
          url: process.env.VUE_APP_URL_API_CLIENT + "ajouterMessage/",
          method: "POST",
          data: formData,
        })
          .then((res) => {
            if (res.data.result == "permission") {
              localStorage.removeItem("vendeurName");
              localStorage.removeItem("typeUser");
              localStorage.removeItem("token");
              this.$router.push("/login");
            }
          })
          .catch((err) => {});
        await this.getAllMessage();
        this.message = "";
        this.$refs.form.resetValidation();
        this.loadingButtonAddMessage = false;
      }
    },
  },
};
</script>
<style>
#table-controle-alert th {
  background-color: #1a1a1a;
  color: white;
  font-weight: bold;
}
</style>
<style scoped>
.v-radio > .v-label {
  margin-bottom: 0px;
}

@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  @import "../../components/dataTable/style.css";
  td:nth-of-type(1):before {
    content: "Date d'ajout";
  }
  td:nth-of-type(2):before {
    content: "Message";
  }
  td:nth-of-type(3):before {
    content: "Couleur";
  }
  td:nth-of-type(4):before {
    content: "Action";
  }
}
::v-deep .table-bordered thead th {
  /*class header element in b-table*/
  text-align: center !important;
}
::v-deep .table-striped tbody {
  /* class tbody element in b-table*/
  text-align: center !important;
}
</style>
