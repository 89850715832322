<template>
  <div style="background-color: #eee">
    <v-card style="overflow: hidden">
      <v-toolbar :color="$colors[0]" dark>
        <span class="text-h6 text-white">Création d'un mandat de résiliation</span>
      </v-toolbar>
      <v-card-text class="my-6">
        <v-form ref="formAcd" lazy-validation>
          <v-row>
            <v-col md="6">
              <v-text-field
                dense
                v-model="societe.Raison"
                label="Raison Sociale"
                :rules="[rules.required]"
                outlined
              />
            </v-col>
            <v-col md="6">
              <v-text-field
                dense
                v-model="societe.AdressePostal"
                label="Adresse Societe"
                :rules="[rules.required]"
                outlined
              />
            </v-col>
            <v-col md="6">
              <v-text-field
                dense
                v-model="societe.nom"
                label="Nom client"
                :rules="[rules.required]"
                outlined
              />
            </v-col>
            <v-col md="6">
              <v-text-field
                dense
                v-model="societe.prenom"
                label="Prénom client"
                :rules="[rules.required]"
                outlined
              />
            </v-col>
            <v-col md="6">
              <v-text-field
                dense
                type="date"
                v-model="dataFournisseur.dateResiliation"
                label="Date Résiliation"
                :rules="[rules.required]"
                outlined
              />
            </v-col>
            <v-col md="6">
              <v-text-field
                dense
                v-model="dataFournisseur.nomFournisseur"
                label="Nouveau Fournisseur"
                :rules="[rules.required]"
                outlined
              />
            </v-col>
            <v-col md="6">
              <v-text-field
                dense
                v-model="dataFournisseur.anciennomFournisseur"
                label="Ancien Fournisseur"
                :rules="[rules.required]"
                outlined
              />
            </v-col>
            <v-col md="6">
              <v-text-field
                dense
                v-model="dataFournisseur.adresseFournisseur"
                label="Adresse Fournisseur"
                :rules="[rules.required]"
                outlined
              />
            </v-col>
            <v-col md="6">
              <v-text-field
                dense
                v-model="dataFournisseur.compteFacturation"
                :rules="[rules.required]"
                label="Compte de facturation"
                outlined
              />
            </v-col>
            <v-col md="6">
              <v-autocomplete
                dense
                v-model="selectedCompteur"
                :items="listCompteur"
                :loading="isLoading"
                hide-no-data
                :rules="[rules.required]"
                label="Compteur(s)"
                placeholder="Ajouter un compteur"
                prepend-icon="mdi-account-search-outline"
                chips
                item-text="numCompteur"
                deletable-chips
                multiple
                return-object
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.numCompteur }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text text-caption">
                    (+{{ selectedCompteur.length - 1 }}
                    {{ selectedCompteur.length - 1 == 1 ? "compteur" : "compteurs" }})
                  </span>
                </template>
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title>{{ data.item.numCompteur }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        data.item.typeDemande
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="toggle">
                    <v-list-item-content>
                      <v-list-item-title
                        >Séléctionner tous les compteurs</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <div class="d-flex justify-content-end">
            <v-btn
              :color="$colors[0]"
              class="mt-8 mr-4 text-white"
              @click="createMandatResiliation"
            >
              Valider
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import {
  degrees,
  PDFDocument,
  rgb,
  StandardFonts,
  TextAlignment,
  setFillingRgbColor,
  setFontAndSize,
  layoutMultilineText,
} from "pdf-lib";
import download from "downloadjs";
import jsPDF from "jspdf";
import axios from "axios";
import "jspdf-autotable";
export default {
  name: "lettreDeRisiliation",
  props: { societe: "", listCompteur: [] },
  data() {
    return {
      openModalAcdEngie: true,
      isLoading: false,
      dataFournisseur: {
        nomFournisseur: "",
        anciennomFournisseur: "",
        adresseFournisseur: "",
        dateResiliation: "",
        compteFacturation: "",
      },
      selectedCompteur: [],
      rules: {
        required: (value) =>
          (value != null && ("" + value).length > 0) || "Ce champ est obligatoire.",
        requiredString: (value) => ("" + value).length > 8 || "Ce champ est obligatoire.",
        siretLength: (v) => v.length == 14 || "Le siret doit comporter 14 chiffres",
        isNumber: (v) =>
          v.match(/^[0-9]+$/) != null || "Ce champ n'accepte que les chiffres",
        compteurElecLength: (v) =>
          v.length == 14 || "Le RAE / PDL est une suite de 14 chiffres",
        isGazCompteur: (v) =>
          v.match(/^(GI)[0-9]{6}$/) ||
          (v.length == 14 && v.match(/^[0-9]+$/) != null) ||
          "Le numéro PCE/ PDL doit commencer par (GI) ou comporter 14 chiffres",
        phoneLength: (v) =>
          v.length == 10 || "Le numéro de téléphone est une suite de 10 chiffres",
        phoneisNumber: (v) =>
          v.match(/^[0-9]+$/) != null || "Ce champ n'accepte que les chiffres",
        margeMaxMin: (v) =>
          (v <= 25 && v >= 2) || "La marge doit être comprise entre 2 et 25",

        emailRules: [
          (v) => !!v || "E-mail obligatoire",
          (v) => /.+@.+\.+.+/.test(v) || "l'adresse mail est invalide",
        ],
      },
      required(fieldName) {
        return (value) =>
          !!value ||
          value >= 0 ||
          (!!value && value != "") ||
          `Veuillez saisir ${fieldName}`;
      },
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeMandatModal");
    },
    toggle() {
      this.selectedCompteur = [...this.listCompteur];
    },
    formatDate(dateStr) {
      const regex = /^\d{4}-\d{2}-\d{2}$/;
      if (!regex.test(dateStr)) {
        throw new Error('Le format de la date doit être "année-mois-jour"');
      }

      const [year, month, day] = dateStr.split("-");

      return `${day}/${month}/${year}`;
    },
    async drawTextWithBoldWords(
      page,
      text,
      wordsToFormat,
      x,
      y,
      fontSize,
      maxWidth,
      color
    ) {
      // Load the font for regular text
      const regularFont = await page.doc.embedFont(StandardFonts.Helvetica);
      // Load the font for bold text
      const boldFont = await page.doc.embedFont(StandardFonts.HelveticaBold);

      // Create a regex pattern to match any of the words to format
      const regexPattern = new RegExp(
        `(${wordsToFormat.map((word) => word.text).join("|")})`,
        "gi"
      );

      // Split the text into parts: words to format and other text
      const parts = text.split(regexPattern);

      let currentX = x;
      for (const part of parts) {
        const format = wordsToFormat.find(
          (word) => word.text.toLowerCase() === part.toLowerCase()
        );
        const isBold = format !== undefined;
        const font = isBold ? boldFont : regularFont;
        const width = font.widthOfTextAtSize(part, fontSize);

        page.drawText(part, {
          x: currentX,
          y,
          size: fontSize,
          maxWidth,
          font,
          color,
        });

        // If the part is a word to be formatted and should be underlined, draw the underline
        if (isBold && format.underline) {
          page.drawLine({
            start: { x: currentX, y: y - 2 }, // Adjust y position for underline
            end: { x: currentX + width, y: y - 2 },
            thickness: 0.5,
            color: color,
          });
        }

        currentX += width;
      }
    },
    async createMandatResiliation() {
      this.$refs.formAcd.validate();
      if (this.$refs.formAcd.validate()) {
        /////////////// page 1 ///////////////////////////////////////
        var url0 = "/pdf/resiliation/mandat_resiliation_1.pdf";
        var pageZero = await fetch(url0).then((res) => res.arrayBuffer());
        // Load a PDFDocument from each of the existing PDFs
        var pageZeroDoc = await PDFDocument.load(pageZero);

        var getPages = pageZeroDoc.getPages();
        var firstPage = getPages[0];
        var form = pageZeroDoc.getForm();
        var field = form.getTextField("textarea_societe");
        var nPointLivraison = "";
        for (var i = 0; i < this.selectedCompteur.length; i++) {
          nPointLivraison =
            nPointLivraison + this.selectedCompteur[i]["numCompteur"] + ", ";
        }
        var txt =
          `La société : ${this.societe.Raison}\n` +
          `enregistrée sous le numéro SIREN : ${this.societe.siren}\n` +
          `dont le siège social est situé à : ${this.societe.AdressePostal}\n` +
          `dont le numéro de compteur est : ${nPointLivraison}\n` +
          `représentée par : ${this.societe.nom} ${this.societe.prenom}\n` +
          "dument habilité(e) à cet effet, ci-après désigné(e) par « Le Mandant » d’une part et,\n";
        const textLines = txt.split("\n");
        const lineHeight = 15; // Adjust this value to set the line spacing
        const pageWidth = firstPage.getWidth();
        const pageHeight = firstPage.getHeight();
        let startingPositon = pageHeight - 240; // Adjust the starting Y coordinate
        const timesRomanFont = await pageZeroDoc.embedFont(StandardFonts.HelveticaBold);
        var multiText = layoutMultilineText(txt, {
          alignment: "left",
          fontSize: 12,
          font: timesRomanFont,
          lineHeight: lineHeight,
          bounds: { width: pageWidth - 80, height: pageHeight },
        });
        let listeMotGras = [
          { text: "La société", underline: true },
          { text: "SIREN", underline: true },
          { text: "siège social", underline: true },
          { text: "numéro de compteur", underline: true },
          { text: "représentée par", underline: true },
          { text: `${nPointLivraison}`, underline: false },
        ];
        for (let i = 0; i < multiText.lines.length; i++) {
          if (startingPositon - lineHeight < 30) {
            var firstPage = pageZeroDoc.addPage([pageWidth, pageHeight]);
            var newPageFirstTextY = pageHeight - 50;
            startingPositon = newPageFirstTextY - lineHeight; // Reset the Y coordinate for the new page
            firstPage.drawText(`${multiText.lines[i].text}`, {
              x: 65,
              y: startingPositon,
              size: 12,
              maxWidth: pageWidth - 80,
              color: rgb(0, 0, 0),
            });
          } else {
            await this.drawTextWithBoldWords(
              firstPage,
              multiText.lines[i].text,
              listeMotGras,
              58,
              startingPositon,
              11,
              pageWidth - 80,
              rgb(0, 0, 0)
            );
          }
          // move position down
          startingPositon = startingPositon - lineHeight;
        }
        var field = form.getTextField("textarea_mandat");
        var textPT1 =
          `Par le présent mandat spécial, le Mandant donne pouvoir au Mandataire, et à lui seul,\n` +
          `d’effectuer, en son nom et pour son compte, les démarches nécessaires auprès de son\n` +
          `fournisseur actuel : ${this.dataFournisseur.anciennomFournisseur} pour résilier son contrat à la date d’échéance de\n` +
          `celui-ci soit le : ${this.formatDate(
            this.dataFournisseur.dateResiliation
          )},\n`;
        var textPT2 =
          `J’atteste avoir vérifier en amont la date de fin de contrat au dos de la facture ou le cas\n` +
          `échéant sur le contrat. Je désengage de toute responsabilité le fournisseur ${this.dataFournisseur.nomFournisseur}\n` +
          `et le courtier Volt Consulting en ce qui concerne les frais de pénalité en cas de non-\nrespect du préavis de résiliation.`;

        const textLinesFinalPT1 = textPT1.split("\n");
        console.log(textLinesFinalPT1);
        const lineHeightFinal = 10; // Adjust this value to set the line spacing
        const pageWidthFinal = firstPage.getWidth();
        const pageHeightFinal = firstPage.getHeight();
        let startingPositionFinal = pageHeightFinal - 470; // Adjust the starting Y coordinate
        var multiTextPT1 = layoutMultilineText(textPT1, {
          alignment: "left",
          fontSize: 12,
          font: timesRomanFont,
          lineHeight: lineHeightFinal,
          bounds: { width: pageWidth - 80, height: lineHeightFinal },
        });
        let listeMotGrasPT1 = [
          { text: "fournisseur actuel", underline: true, sameLine: true },
          { text: "date d'échéance", underline: true, sameLine: true },
        ];
        for (let i = 0; i < multiTextPT1.lines.length; i++) {
          if (startingPositionFinal - lineHeight < 30) {
            var firstPage = pageZeroDoc.addPage([pageWidthFinal, pageHeightFinal]);
            var newPageFirstTextY = pageHeightFinal - 50;
            startingPositionFinal = newPageFirstTextY - lineHeight; // Reset the Y coordinate for the new page
            firstPage.drawText(`${multiTextPT1.lines[i].text}`, {
              x: 65,
              y: startingPositionFinal,
              size: 11.2,
              maxWidth: pageWidthFinal - 80,
              color: rgb(0, 0, 0),
            });
          } else {
            await this.drawTextWithBoldWords(
              firstPage,
              multiTextPT1.lines[i].text,
              listeMotGrasPT1,
              58,
              startingPositionFinal,
              12,
              pageWidth - 80,
              rgb(0, 0, 0)
            );
          }
          // move position down
          startingPositionFinal = startingPositionFinal - lineHeight;
        }

        const textLinesFinalPT2 = textPT2.split("\n");
        console.log(textLinesFinalPT2);

        startingPositionFinal = startingPositionFinal + 10; // Adjust the starting Y coordinate
        var multiTextPT2 = layoutMultilineText(textPT2, {
          alignment: "left",
          fontSize: 12,
          font: timesRomanFont,
          lineHeight: lineHeightFinal,
          bounds: { width: pageWidth - 80, height: pageHeight },
        });
        let listeMotGrasPT2 = [{ text: "le fournisseur", underline: true }];
        for (let i = 0; i < multiTextPT2.lines.length; i++) {
          if (startingPositionFinal - lineHeight < 30) {
            var firstPage = pageZeroDoc.addPage([pageWidthFinal, pageHeightFinal]);
            var newPageFirstTextY = pageHeightFinal - 50;
            startingPositionFinal = newPageFirstTextY - lineHeight; // Reset the Y coordinate for the new page
            firstPage.drawText(`${multiTextPT2.lines[i].text}`, {
              x: 65,
              y: startingPositionFinal,
              size: 11.2,
              maxWidth: pageWidthFinal - 80,
              color: rgb(0, 0, 0),
            });
          } else {
            await this.drawTextWithBoldWords(
              firstPage,
              multiTextPT2.lines[i].text,
              listeMotGrasPT2,
              58,
              startingPositionFinal,
              12,
              pageWidth - 80,
              rgb(0, 0, 0)
            );
          }
          // move position down
          startingPositionFinal = startingPositionFinal - lineHeight;
        }

        var field = form.getTextField("text_date");
        var DateAujourdhui = new Date();
        DateAujourdhui =
          DateAujourdhui.getDate() +
          "/" +
          (DateAujourdhui.getMonth() + 1) +
          "/" +
          DateAujourdhui.getFullYear();
        field.setText(DateAujourdhui);
        field.setFontSize(12);

        var fields = form.getFields();
        fields.forEach((field) => {
          field.enableReadOnly();
        });
        form.updateFieldAppearances();
        let mergedPdf = await PDFDocument.create();
        mergedPdf = await this.addPagesToPdf(mergedPdf, pageZeroDoc);

        const pdfBytes = await mergedPdf.save();
        this.generatedPdf = pdfBytes;
        download(pdfBytes, "Mandat_résiliation.pdf", "application/pdf");
      }
    },
    dateFormatSlach(date) {
      try {
        return date.split("-").reverse().join("/");
      } catch (err) {
        return date;
      }
    },
    async addPagesToPdf(mergedPdf, donorPdf) {
      const copiedPages = await mergedPdf.copyPages(donorPdf, donorPdf.getPageIndices());
      copiedPages.forEach((page) => {
        mergedPdf.addPage(page);
      });
      return mergedPdf;
    },
  },
};
</script>
