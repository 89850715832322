<template>
  <div class="login">
    <img
      class="mb-4"
      @click="$router.push('/')"
      src="../assets/logo-volt-consulting-transparent.png"
      :width="235"
      alt="Logo"
    />
    <div class="login-area">
      <h2 class="textprimary">Se Connecter</h2>
      <form @submit.prevent="login">
        <div class="form-group">
          <label for="email"><strong class="textprimary">ADRESSE MAIL</strong></label>
          <input
            type="email"
            placeholder="ADRESSE MAIL"
            v-model="user.email"
            class="input-field"
            required
          />
        </div>
        <div class="form-group">
          <label for="password"><strong class="textprimary">MOT DE PASSE</strong></label>
          <input
            type="password"
            placeholder="MOT DE PASSE"
            v-model="user.password"
            class="input-field"
            required
          />
        </div>

        <div class="form-group">
          <button class="login-btn" type="submit" :disabled="loading">
            {{ loading ? "Patientez ..." : "Login" }}
          </button>
        </div>
        <div class="form-group">
          <p style="color: red">
            {{ errorData ? "Email et/ou mot de passe incorrect(s)" : "" }}
          </p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Login",

  data() {
    // test si la session contient des valeur
    if (this.getToken("token") != null) {
      this.$router.push("/demande-cotation-elec-pro");
    } else {
      this.$router.push("/login");
    }
    return {
      loading: false,
      errorData: false,

      user: {
        email: null,
        password: null,
      },
    };
  },

  methods: {
    login() {
      this.errorData = false;
      this.loading = true;
      axios
        .post(process.env.VUE_APP_URL_API_CLIENT + "logIn/", {
          email: this.user.email,
          password: this.user.password,
          isAuthenticated: true,
        })
        .then((res) => {
          if (res.data.login == true) {
            //set the vueAfficher to pro or particulier to know wich view we will open first
            //type of user for the header
            localStorage.setItem("email", this.user.email);
            localStorage.setItem("typeUser", res.data.type);
            localStorage.setItem("Telephone", res.data.Telephone);
            //localStorage.setItem('token', res.data.token)
            this.setToken("token", res.data.token, 8 * 60 * 60);
            var whoIam = res.data.type;
            localStorage.setItem("vendeurName", res.data.nom + " " + res.data.prenom);
            if (
              localStorage.getItem("lastLink") != "notFound" &&
              localStorage.getItem("lastLink") != "login" &&
              localStorage.getItem("lastLink") != "/"
            ) {
              if (whoIam == "fournisseur") {
                if (
                  localStorage.getItem("lastLink").indexOf("ajouterNouvelleOffre") != -1
                ) {
                  this.$router.push(localStorage.getItem("lastLink"));
                } else {
                  this.$router.push("/mes-offres-externes");
                }
              } else {
                this.$router.push("/demande-cotation-elec-pro");
              }
            } else {
              this.$router.push("/demande-cotation-elec-pro");
            }
            this.loading = false;
          } else {
            this.errorData = true;
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.login {
  height: 100%;
  max-width: 1180px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-area {
  width: 400px;
  margin: 0 auto;
  border: 2px solid #000000;
  border-radius: 6px;
  padding: 20px 30px 30px 20px;
  text-align: left;
}

.textprimary {
  color: #070707;
}

h2 {
  text-align: center;
}

.form-group {
  margin-top: 20px;
}

.input-field {
  width: 100%;
  height: 30px;
  outline: none;
  border: 1px solid #0c0c0c;
  border-radius: 4px;
  margin-top: 5px;
}

.login-btn {
  width: 100%;
  height: 35px;
  border: none;
  border: 1px solid #0f0f0f;
  color: white;
  background: #0f0f0f;
  border-radius: 4px;
}

.login-btn:hover {
  cursor: pointer;
}
</style>
