<template>
  <div class="" style="box-shadow: unset" :style="styleProps">
    <apexchart
      height="100%"
      type="line"
      :options="chartOptions"
      :series="seriesCharts"
    ></apexchart>
  </div>
</template>
<script>
import dayjs from "dayjs";
import VueApexCharts from "vue-apexcharts";
export default {
  name: "chart",
  components: {
    apexchart: VueApexCharts,
  },
  props: ["optionsChart", "seriesCharts", "styleProps", "updateChartsOptions"],
  data() {
    return {
      chartOptions: {
        noData: {
          text: "Aucunes données disponibles affichées",
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: "#000000",
            fontSize: "14px",
            fontFamily: "Helvetica",
          },
        },
        colors: this.optionsChart.colors, //["#0D47A1", "#1E6CA4", "#FFCB35"],
        chart: {
          id: "chatGazAndElec",
          height: 350,
          type: "line",
          toolbar: {
            tools: {
              pan: false,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 2,
          curve: "straight",
        },
        title: {
          text: this.optionsChart.title,
        },
        legend: {
          height: 50,
          horizontalAlign: "center",
          tooltipHoverFormatter: function (val, opts) {
            return (
              val +
              " - " +
              opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
              " €/MWh"
            );
          },
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return `${val.toFixed(2)} €`;
            },
          },
          title: {
            text: "Prix €/MWh",
          },
        },
        xaxis: {
          type: "datetime",
          categories: this.optionsChart.categories.value,
          labels: {
            formatter: function (value) {
              return dayjs(value).format("DD-MM-YYYY");
            },
          },
        },

        tooltip: {
          theme: "apexcharts-tooltip",
          shared: false,
          y: {
            formatter: function (val) {
              return "";
            },
            title: {
              formatter: function (val, opts) {
                return (
                  val +
                  " " +
                  opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
                  " €/MWh"
                );
              },
            },
          },
          x: {
            show: true,
            formatter: function (val) {
              return dayjs(val).format("DD/MM/YYYY");
            },
          },
        },
        grid: {
          borderColor: "#f1f1f1",
        },
        series: this.seriesCharts,
      },
    };
  },
  watch: {
    updateChartsOptions: function (newValue, oldValue) {
      if (newValue != oldValue) {
        this.chartOptions = newValue;
      }
      console.log(this.chartOptions);
    },
  },
};
</script>
<style>
.apexcharts-tooltip {
  background: white;
  color: #1a1a1a;
}
</style>
