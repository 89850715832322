<style>
    .td-valeur{
        align-items:center;
        flex-direction:column
    }

    .td-statut{
        align-items:center;
        flex-direction:row;
    }
    .td-table, .td-valeur, .td-statut{
        display:flex;
        justify-content:center;
    }
    .d1{
        display: flex;
        align-items:center;
        width:100%;
        justify-content:flex-end
    }
</style>
<template>
    <div>
        <v-dialog persistent v-model="ouvrireDialogeRefuser" max-width="620px">
            <v-card style="overflow: hidden;">
                <v-toolbar
                    :color="$colors[0]"
                    dark
                    >
                    <v-card-title class="d-flex justify-content-center align-items-center">Suppression de l'équipe {{demandeTraiter?.nomEquipe}}</v-card-title>
                </v-toolbar>
                <v-card-text style="display:block" class="text-h6 text-center font-weight-light my-8">Êtes-vous sûr de bien vouloir supprimer cette équipe ?</v-card-text>
                    <div class="d-flex justify-content-end mb-2">
                        <v-btn text class="mx-2" color="error" @click="fermerDialogesupprimerEquipe"
                            >Annuler</v-btn
                        >
                        <v-btn class="text-white mr-2" :color="$colors[0]"  @click="supprimerEquipe"
                            >Supprimer</v-btn
                        >
                    </div>
            </v-card>
        </v-dialog>
        <ajouterEquipe v-if="ajouterEquipe==true"  @ajouterEquipeMethod="getEtatajouterEquipe"/>
        <v-dialog persistent v-model="modifierEquipe" max-width="900px" max-height='800px'>
            <modifierEquipe :equipeId="equipeIdModifier" @modifierEquipe="getEtatModifierEquipe" v-if="modifierEquipe==true" />
        </v-dialog>
        <v-btn :color='$colors[0]' class="mt-lg-8 mx-lg-8 mx-4 my-4 text-white" @click="ajouterEquipe=true" v-if="ajouterEquipe==false">Ajouter une equipe<v-icon class="ml-2 mb-1">mdi-account-group</v-icon></v-btn>
        <datatable id="table-list-equipe" class="ma-lg-8 mx-4" :columns="columns" :sortKey="sortKey" :sortOrders="sortOrders" @sort="sortBy"  @search="rechercheData">
            
            <template v-slot:body>
                <tbody v-if="lesEquipes?.length > 0 && loadingTable == false">
                    <tr class="text-center" v-for="( equipe, index) in lesEquipes" :key="equipe.id">
                        <td class="px-1">
                            <div class="td-table">
                                {{equipe.date}}
                            </div>
                        </td>
                        <td>
                            <div class="td-table">
                                {{equipe.nomEquipe}} 
                            </div>
                        </td>
                        <td>
                            <div class="td-table">
                                {{equipe.ADV.userName}} 
                            </div>
                        </td>
                        <td>
                            <div class="td-table" v-if="equipe.chefEquipe">
                                {{equipe.chefEquipe.userName}} 
                            </div>
                        </td>
                        <td class="text-left">
                                <v-chip-group
                                    v-if="equipe.Vendeurs.length>0"
                                    column
                                 >
                                 <v-chip
                                        data-toggle="collapse" :data-target="'#multiCollapseVendeur'+index" aria-expanded="false" :aria-controls="'multiCollapseVendeur'+index"
                                    >{{equipe.Vendeurs[0].userName}}
                                        <v-icon
                                            v-if="equipe.Vendeurs.length>1"
                                            right
                                            >
                                            mdi-menu-down
                                        </v-icon>
                                    </v-chip>
                                    <div class="collapse multi-collapse" :id="'multiCollapseVendeur'+index">
                                        <div >
                                            <v-chip v-for="vendeur, indexV in equipe.Vendeurs.slice(1,equipe.Vendeurs.length)" :key="indexV">{{vendeur.userName}}</v-chip>  
                                        </div>
                                    </div>
                                </v-chip-group>
                                <v-chip-group v-else>
                                    <span>aucun vendeur</span>
                                </v-chip-group>
                        </td>
                        <td>
                            <div>
                                <v-chip-group
                                    v-if="equipe.prospecteur.length>0"
                                    column
                                 >
                                    <v-chip>{{equipe.prospecteur[0].userName}}
                                        <v-icon
                                            v-if="equipe.prospecteur.length>1"
                                            data-toggle="collapse" :data-target="'#multiCollapseprospecteur'+index" aria-expanded="false" :aria-controls="'multiCollapseprospecteur'+index"
                                            right
                                            >
                                            mdi-menu-down
                                        </v-icon>
                                    </v-chip>
                                    <div class="collapse multi-collapse" :id="'multiCollapseprospecteur'+index">
                                        <div >
                                            <v-chip v-for="prospecteur, indexV in equipe.prospecteur.slice(1,equipe.prospecteur.length)" :key="indexp">{{prospecteur.userName}}</v-chip>  
                                        </div>
                                    </div>
                                </v-chip-group>
                                <v-chip-group v-else>
                                    <span>aucun prospecteur</span>
                                </v-chip-group>
                            </div>
                        </td>
                        <td>
                            <div  class="btn-group td-statut">
                                <div class="d-flex flex-row justify-content-start align-items-center">
                                        <v-btn
                                            @click="ouvrireDialogeModifier(equipe)"
                                            color='blue darken-4'
                                            icon
                                            dark>
                                            <v-icon>mdi-account-edit</v-icon>
                                        </v-btn>
                                        <v-btn
                                            @click="ouvrireDialogesupprimerEquipe(equipe)"
                                            color='error'
                                            icon
                                            dark>
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <noDataTable class="ma-4" v-if="lesEquipes.length == 0 && loadingTable == false"/>
                <progressCircular class="ma-8" v-if="loadingTable == true"/>
            </template>
        </datatable>


                <pagination v-if="lesEquipes?.length > 0" :pagination="pagination"
                    @selectNumberOfRecords="rechercheData"
                    @first="getData(pagination.firstPage)"
                    @last="getData(pagination.lastPage)"
                    @prev="getData(pagination.prevPageUrl)"
                    @current="getData(pagination.currentPage)"
                    @next="(totalElements == lesEquipes.length) ? getData(pagination.currentPage) : getData(pagination.nextPageUrl)">
                </pagination>
    </div>
</template>

<script>
    import "jquery/dist/jquery.min.js";
    import "bootstrap/dist/css/bootstrap.min.css";
    import "bootstrap/dist/css/bootstrap.css";
    import "bootstrap/dist/js/bootstrap.bundle";
    import "datatables.net-dt/js/dataTables.dataTables";
    import "datatables.net-dt/css/jquery.dataTables.min.css";

    import $ from 'jquery'
    import jQuery from 'jquery'

    import Datatable from '../../components/dataTable/Datatable.vue'
    import Pagination from '../../components/dataTable/Pagination.vue'
    import ajouterEquipe from './ajouterEquipe.vue'
    import modifierEquipe from './modifierEquipe.vue'
    import noDataTable from "../../components/no-data-table.vue"
    import progressCircular from "../../components/progress-circular.vue"
    import axios from 'axios'
    export default{
        name: 'listeEquipeAdmin',
        components: {datatable:Datatable, pagination:Pagination, ajouterEquipe:ajouterEquipe, modifierEquipe:modifierEquipe, noDataTable, progressCircular},
        data(){
            let sortOrders={};
            let columns = [
                {width:'14vw',label:'Date', name:'date', sortable:true},
                {width:'14vw', label:'Equipe', name:'Equipe', sortable:true},
                {width:'14vw', label:'ADV', name:'ADV', sortable:true},
                {width:'14vw', label:"Chef d'equipe", name:'Chef équipe', sortable:false},
                {width:'14vw', label:'Vendeurs', name:'Vendeurs', sortable:false},
                {width:'14vw', label:'Prospecteurs', name:'Prospecteurs', sortable:false},
                {width:'14vw', label:'Actions', name:'status', sortable:true},
            ];
            columns.forEach((column)=>{
                sortOrders[column.name] = -1;
            });
            return{
                loadingTable:false,
                totalElements:0,
                equipeIdModifier: 33,
                ajouterEquipe: false,
                modifierEquipe: false,
                dialog: false,
                loading: false,
                error: false,
                commentRefuser:'',
                messageDialoge: '',
                ouvrireDialogeRefuser: false,
                lesEquipes: [],
                columns: columns,
                sortKey: 'date',
                sortOrders: sortOrders,
                perPage: ['15', '15', '30'],
                tableData: {
                    draw: 0,
                    length: 15,
                    search: '',
                    column: 0,
                    dir: 'desc',
                },
                demandeTraiter: null,
                pagination: {
                    perPage: ['10', '15', '30'],
                    firstPage: 1,
                    lastPage: '',
                    currentPage: '',
                    total: '',
                    lastPageUrl: '',
                    nextPageUrl: '',
                    prevPageUrl: '',
                    from: '',
                    to: ''
                },

                 rules: {
                    required(finPhrase) {
                    return (value) => !!value || `Veuillez saisir ${finPhrase}`
                    },
                },
            }
        },
        async created() {
            this.loadingTable = true;
            var whoIam = ""
            await this.whoIAm(this.getToken('token')).then((data)=>{whoIam=data});
            if(whoIam == "noToken"){
                localStorage.removeItem('vendeurName')
                localStorage.removeItem('typeUser') 
                localStorage.removeItem('token')
                this.$router.push('/login')
            }else{
                localStorage.setItem('typeUser', whoIam)
            }
            await this.getData();
            this.loadingTable = false;
        },
        methods: {
            rechercheData({type, key}) {
                if(type=='search'){
                    var pageNumber = 0
                    this.tableData.search = key
                }else if(type=="nbrRecords")
                {
                    this.tableData.length = key
                }
                var pageNumber = 0
                this.tableData.search = key
                this.tableData.draw++;
                axios({url:process.env.VUE_APP_URL_API_CLIENT + 'getAllEquipeServerSide/',
                    method: 'POST',
                    data: {'dataTable': this.tableData,
                        "pageNumber": pageNumber,
                        'token': this.getToken('token')},
                    }).then(response => {
                       if(response.data.result == true){
                        let data = response.data.data;
                        if (this.tableData.draw == data.draw) {
                            this.lesEquipes = data.data.data;
                            this.configPagination(data.data);
                        }
                    }else{
                        alert('permission refusée')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                })
                .catch(errors => {
                    console.log(errors);
                });
            },
            supprimerEquipe(){
                //fermer modal comment
                this.ouvrireDialogeRefuser = false
                //open modal loading
                this.dialog = true
                this.loading = true
                this.error = false
                this.messageDialoge = ''

                let self = this
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                formData.append('idEquipe', this.demandeTraiter.id)
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT + 'supprimerEquipe/',
                    method: 'POST',
                    data: formData,
                    
                }).then((res) => {
                    this.fermerDialogesupprimerEquipe()
                    this.getData()
                }).catch((err)=>{
                    this.fermerDialogesupprimerEquipe()
                    this.getData()
                })
            },
            ouvrireDialogesupprimerEquipe(item){
                this.demandeTraiter = item
                this.ouvrireDialogeRefuser = true
            },
            fermerDialogesupprimerEquipe(){
                this.demandeTraiter = ''
                this.ouvrireDialogeRefuser = false
            },
            getEtatModifierEquipe(varr){
                this.modifierEquipe=varr
                this.getData()
            },
            getEtatajouterEquipe(varr){
                this.ajouterEquipe = varr
                this.getData()
            },
            ouvrireDialogeModifier(item){
                this.modifierEquipe = true
                this.equipeIdModifier = item.id
            },
           async getData(pageNumber = 0) {
                this.tableData.draw++;
               await axios({url:process.env.VUE_APP_URL_API_CLIENT + 'getAllEquipeServerSide/',
                    method: 'POST',
                    data: {'dataTable': this.tableData,
                        "pageNumber": pageNumber,
                        'token': this.getToken('token')},
                }).then(response => {
                    if(response.data.result == true){
                        let data = response.data.data;
                        if (this.tableData.draw == data.draw) {
                            this.lesEquipes = data.data.data;
                            this.configPagination(data.data);
                        }
                    }else{
                        alert('permission refusée')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                })
                .catch(errors => {
                    console.log(errors);
                });
            },
            configPagination(data) {
                this.pagination.lastPage = data.last_page;
                this.pagination.currentPage = data.current_page;
                this.pagination.total = data.total;
                this.pagination.lastPageUrl = data.last_page_url;
                this.pagination.nextPageUrl = data.next_page_url;
                this.pagination.prevPageUrl = data.prev_page_url;
                this.pagination.from = data.from;
                this.pagination.to = data.to;
            },
            sortBy(key) {
                this.sortKey = key;
                this.sortOrders[key] = this.sortOrders[key] * -1;
                this.tableData.column = this.getIndex(this.columns, 'name', key);
                this.tableData.dir = this.sortOrders[key] === 1 ? 'asc' : 'desc';
                this.getData();
            },
            getIndex(array, key, value) {
                return array.findIndex(i => i[key] == value)
            },
        }
    }
</script>
<style>
#table-list-equipe th{
    background-color:#1A1A1A;
        color:white;
        font-weight:bold;
}
  @media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {
    @import "../../components/dataTable/style.css";
    #table-list-equipe td:nth-of-type(1):before { content: "Date"; }
	#table-list-equipe td:nth-of-type(2):before { content: "Equipe"; }
	#table-list-equipe td:nth-of-type(3):before { content: "ADV"; }
	#table-list-equipe td:nth-of-type(4):before { content: "chef d'equipe"; }
	#table-list-equipe td:nth-of-type(5):before { content: "Vendeurs"; }
	#table-list-equipe td:nth-of-type(6):before { content: "Prospecteurs"; }
	#table-list-equipe td:nth-of-type(7):before { content: "Statut"; }
}
</style>

