<template>
    <div>
        <v-dialog
            v-model="dialog"
            persistent
            max-width="500"
            >
            <v-card>
                <v-card-title class="text-h5">
                Extraction du contrat
                </v-card-title>
                <v-card-text>
                    <v-alert v-if="error==true && loading==false" type="error">
                        {{messageDialoge}}
                    </v-alert>
                    <v-alert v-if="error==false && loading==false" type="success">
                        {{messageDialoge}}
                    </v-alert>
                    <v-flex  class="text-center">
                        <v-progress-circular v-if="loading==true"
                            :size="150"
                            :width="7"
                            color="primary"
                            indeterminate
                            >
                            Patientez  ...
                        </v-progress-circular>
                    </v-flex>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="loading==false"
                    color="green darken-1"
                    text
                    @click="dialog = false"
                >
                    FERMER
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
        v-model="addData"
        max-width="850"
        persistent
        >
            <v-card>
                <v-card-title class="text-h5">
                    Plus d'informations
                    
                </v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-form ref="form"  lazy-validation class="justify-left">
                    <v-row  class="justify-left">
                        <v-col cols="10" md="5" sm="5" lg="5" xl="5">
                        <span>Méthode de Facturation </span>
                            <v-radio-group
                                v-model="typeFacture"
                                row
                                >
                                <v-radio
                                    label="Électronique"
                                    value="ELEC"
                                ></v-radio>
                                <v-radio
                                    label="Courrier"
                                    value="COUR"
                                ></v-radio>
                                </v-radio-group>
                        </v-col>
                        
                        <v-col cols="10" md="5" sm="5" lg="5" xl="5">
                            <span>Ajouter une adresse de facturation</span>
                            <v-radio-group
                                v-model="addAdrsFact"
                                row
                                >
                                <v-radio
                                    label="oui"
                                    :value="true"
                                ></v-radio>
                                <v-radio
                                    label="non"
                                    :value="false"
                                ></v-radio>
                                </v-radio-group>
                        </v-col>
                        <v-col cols="10" md="5" sm="5" lg="5" xl="5" v-if="addAdrsFact==true && typeFacture=='COUR'">
                        
                            <v-text-field
                                outlined
                                v-model="raisonRecus"
                                :rules="[rules.required]"
                                label="Raison sociale de facturation"
                            ></v-text-field>
                            <v-text-field
                                outlined
                                v-model="adreseeRecus"
                                :rules="[rules.required]"
                                label="Adresse de facturation"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="10" md="5" sm="5" lg="5" xl="5" v-if="addAdrsFact==true && typeFacture=='ELEC'">
                        
                            <v-text-field
                                outlined
                                v-model="adresseEmailRecus"
                                :rules="[rules.required,rules.hasWhiteSpace,rules.emailRules]"
                                label="Adresse e-mail de facturation"
                            ></v-text-field>
                        </v-col>
                        
                        <v-col cols="10" md="10" sm="10" lg="10" xl="10">
                            <v-btn v-if="loading==false"
                                color="primary"
                                @click="createContrat"
                            >
                                Confirmer
                            </v-btn>
                            <v-btn v-if="loading==false"
                                color="error"
                                text
                                @click="addData=false"
                            >
                                Annuler
                            </v-btn>
                        </v-col>
                    </v-row>
                    </v-form>   
                    </v-card-actions>
            </v-card>
        </v-dialog>
        

        <div>
            <v-btn
            @click="replierForm"
            icon
            depressed class="ma-auto text-capitalize">
                <v-icon  :color="$colors[0]">mdi-text-box-edit</v-icon>
            </v-btn>
        </div>
        
    </div>
</template>
<script>
    import { degrees, PDFDocument, rgb, StandardFonts,layoutMultilineText } from 'pdf-lib'
    import download from 'downloadjs'
    import jsPDF from 'jspdf'
    import 'jspdf-autotable'
    import axios from 'axios'
    export default {
        name: 'TotalHorizon',
        props: {
            donner: null,
            numCompteur: null,
            societe: null,
            donnerEnedis: null,
        },
        data: () => ({
            addData : false,
            dialog: false,
            error: false,
            loading: false,
            messageDialoge: "",
            addAdrsFact: false,
            typeFacture: 'COUR',
            raisonRecus: '',
            adreseeRecus: '',
            adresseEmailRecus: '',
             data:{
                dateAjourdhui: new Date().getDate()+"/"+new Date().getMonth()+"/"+new Date().getFullYear(),
                dateDebut: "",
                dateFin: "",
                pdl: "",
                siret: "", 
                codeNaf: "", 
                adresseSociete: "", 
                adresseCompteur: "",
                 codePostaleCompteur: '',
                 voieCompteur: '',
                 communeCompteur: '',
                 codePostaleSociete: '',
                 voieSociete: '',
                 communeSociete: '',
                codePostale: '',
                nom: "",
                prenom: "", 
                email: "", 
                numTele: "", 
                consommationAnuuelle: "",
                typeCompteur: "",
                kva: "",
                 
                coefBase: '',
                coefHp: '',
                coefHc: '',
                prixHC: "",
                prixHP: "",
                prixBASE: "",
                raison: '',
                nbrYears: '',
             },
rules: {
                required: value => !!value  || 'Ce champ est obligatoire.',
                hasWhiteSpace: value => !(/\s/.test(value)) || "Ce champ ne doit pas être constitué d'espace",

                emailRules:v => !!v && /.+@.+\.+.+/.test(v) || 'E-mail invalide',
                    

            }
        }),
        
        created() {
            //this.verifyApplicationDataSecurity()
        },
        methods:{
            async createContrat(){
                this.$refs.form.validate();
                if(this.$refs.form.validate()){

                    this.addData = false
                this.dialog= true
                this.error= false
                this.loading= true
                this.messageDialoge= ""
                var autreDonner = {}
                autreDonner.addAdrsFact = this.addAdrsFact
                autreDonner.raisonFact = this.raisonRecus
                autreDonner.adresseFact = this.adreseeRecus
                autreDonner.typeFacture = this.typeFacture
                autreDonner.adresseEmailFact = this.adresseEmailRecus
                var dataToSend = {
                    donner: this.donner,
                    donnerEnedis: this.donnerEnedis,
                    optionTarifaire: this.optionTarifaire,
                    numCompteur: this.numCompteur,
                    societe: this.societe,
                    autreDonner: autreDonner,
                }
                await axios({
                    url: 'https://editioncontrat.agora-energie.com/totalHorizonContrat',
                    // url: 'http://127.0.0.1:3000/totalHorizonContrat',
                    method: 'POST',
                    data: dataToSend,
                    headers:{'content-type': 'application/json'}
                    
                })
                .then(async (res) => {
                    var doc = await PDFDocument.load(res.data)
                    doc = await doc.save()
                    download(doc, this.numCompteur+'TotalHorizon_'+this.societe.Raison+'_.pdf', 'application/pdf')
                }).catch((err)=>{
                    console.log(err)
                })
                this.dialog= false
                this.error= false
                this.loading= false
                this.messageDialoge= ""
                }
            },
            async replierForm(){
                this.addData = true
            },
            async getCodeNaf(){
                this.data.dateAjourdhui= new Date().getDate()+"/"+new Date().getMonth()+1+"/"+new Date().getFullYear()
                if(this.donnerEnedis.donneTechniqueInfo[0].result == 'valide'){
                    this.data.adresseCompteur = this.donnerEnedis.donneTechniqueInfo[0].data.numVoie+' '+
                            this.donnerEnedis.donneTechniqueInfo[0].data.codePostal+' '+this.donnerEnedis.donneTechniqueInfo[0].data.commune

                }
                this.data.dateDebut = this.donner.dateDebut+""
                this.data.dateFin = this.donner.dateFin+""
                this.data.pdl = this.numCompteur+""
                this.data.siret = this.societe.siret+''
                this.data.adresseSociete = this.societe.AdressePostal+""
                this.data.nom = this.societe.nom+""
                this.data.prenom = this.societe.prenom+""
                this.data.email = this.societe.AdresseEmail+""
                this.data.numTele = this.societe.numTele+""
                this.data.consommationAnuuelle = this.donner.consomationGlobale+""
                this.data.kva = this.donnerEnedis.donneTechniqueInfo[0].data.kva+""
                this.data.raison = this.societe.Raison+""
                this.data.nbrMonth = this.donner.nbrMonth+""
                this.data.typeCompteur = this.donnerEnedis.donneTechniqueInfo[0].data.typeCompteur
                this.data.codeNaf = this.societe.codeNaf               
                this.data.codePostaleCompteur = this.donnerEnedis.donneTechniqueInfo[0].data.codePostal
                this.data.voieCompteur = this.donnerEnedis.donneTechniqueInfo[0].data.numVoie
                this.data.communeCompteur = this.donnerEnedis.donneTechniqueInfo[0].data.commune
                this.data.codePostaleSociete = this.societe.codePostal
                this.data.voieSociete = this.societe.Voie
                this.data.communeSociete = this.societe.commune
                this.data.dateAjourdhui= new Date().getDate()+"/"+new Date().getMonth()+1+"/"+new Date().getFullYear()
                
                this.data.coefBase= this.donner.coefBase+""
                this.data.coefHp= this.donner.coefHp+""
                this.data.coefHc= this.donner.coefHc+""
                this.data.prixHC= this.donner.unitairehc+""
                this.data.prixHP= this.donner.unitairehp+""
                this.data.prixBASE= this.donner.unitaire+""
                this.data.nbrYears= this.donner.nbrYears+""
            },

            async getCodePostale(adresse){
                var myRe = new RegExp('\\d\\d\\d\\d\\d', '');
                var myRe2 = new RegExp('\\d+', '');
                var myArray = myRe.exec(adresse);
                var codePostale =  myArray[0]
                var voie = adresse.split(codePostale)[0]
                var commune = adresse.split(codePostale)[1]
                var myArray = myRe2.exec(voie)
                var numRue=""
                if(myArray!=null){
                    numRue = myArray[0]
                }
                return [codePostale, voie, commune, numRue]
            },
            
            async generatePdf(){
                
                this.addData = false
                this.dialog = true
                this.error = false
                this.loading = true
                this.messageDialoge = ""
                await this.getCodeNaf()
                /////////////// page 1 ///////////////////////////////////////
                var url0 = '/pdf/totale/HORIZON/totalHorizon1.pdf'
                const pageZero = await fetch(url0).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pageZeroDoc = await PDFDocument.load(pageZero)
                var getPages = pageZeroDoc.getPages()
                var firstPage = getPages[0]
                const timesRomanFont = await pageZeroDoc.embedFont(StandardFonts.HelveticaBold)

                firstPage.drawText(this.data.raison, {
                    x: 98,
                    y: 665,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText(this.data.siret, {
                    x: 95,
                    y: 643,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.nom, {
                    x: 70,
                    y: 620,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText(this.data.prenom, {
                    x: 335,
                    y: 620,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.email, {
                    x: 70,
                    y: 595,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.numTele, {
                    x: 95,
                    y: 572,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                if(this.typeFacture == 'ELEC'){
                    firstPage.drawText('X', {
                        x: 132,
                        y: 507,
                        size: 12,
                        color: rgb(0, 0, 0),
                    })
                }else if(this.typeFacture == 'COUR'){
                    firstPage.drawText('X', {
                        x: 414,
                        y: 507,
                        size: 12,
                        color: rgb(0, 0, 0),
                    })
                }
                
                if(this.typeFacture == 'COUR' && this.addAdrsFact==true){
                    firstPage.drawText(this.raisonRecus, {
                        x: 381,
                        y: 472,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                    var multiText = layoutMultilineText(this.adreseeRecus, {
                        alignment: 'left', 
                        fontSize: 9,   
                        font: timesRomanFont,
                        lineHeight: 9,
                        bounds: { width: 300 - 100, height: 1000  }
                    })
                    
                    var startingPositon=458
                    for(let i = 0; i < multiText.lines.length; i++) {
                        
                        firstPage.drawText(`${multiText.lines[i].text}`, {
                            x: 371,
                            y: startingPositon,
                            size: 9,
                            maxWidth: 320 - 100,
                            color: rgb(0, 0, 0),
                        })
                        // move position down
                        startingPositon = startingPositon - (13)
                    }
                    var donnerDesAdresse = await this.getCodePostale(this.adreseeRecus)
                    firstPage.drawText(donnerDesAdresse[0], {
                        x: 374,
                        y: 428,
                        size: 11.50,
                        color: rgb(0, 0, 0),
                    })
                    firstPage.drawText(donnerDesAdresse[2], {
                        x: 450,
                        y: 428,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }
                if(this.typeFacture == 'ELEC' && this.addAdrsFact==true){
                    firstPage.drawText(this.adresseEmailRecus, {
                        x: 50,
                        y: 442,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }
                firstPage.drawText(this.data.pdl, {
                    x: 165,
                    y: 180,
                    size: 10,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.adresseCompteur, {
                    x: 175,
                    y: 160,
                    size: 10,
                    color: rgb(0, 0, 0),
                })
                var donnerDesAdresse = await this.getCodePostale(this.data.adresseCompteur)
                firstPage.drawText(donnerDesAdresse[0], {
                    x: 100,
                    y: 140,
                    size: 11.50,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(donnerDesAdresse[2], {
                    x: 175,
                    y: 140,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.dateDebut, {
                    x: 190,
                    y: 120,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                if(this.data.nbrYears=='12'){
                    firstPage.drawText('X', {
                        x: 84,
                        y: 97,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })

                }else if(this.data.nbrYears=='24'){

                    firstPage.drawText('X', {
                        x: 142,
                        y: 97,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }else if(this.data.nbrYears=='136'){
                    firstPage.drawText('X', {
                        x: 202,
                        y: 97,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }
                
                /////////////// page 2 ///////////////////////////////////////
                url0 = '/pdf/totale/HORIZON/totalHorizon2.pdf'
                const pageOne = await fetch(url0).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pageOneDoc = await PDFDocument.load(pageOne)
                var getPages = pageOneDoc.getPages()
                var firstPage = getPages[0]
                console.log(this.data.typeCompteur)
                if(this.data.typeCompteur == 'CUST'){
                    
                    firstPage.drawText("X", {
                        x: 198,
                        y: 787,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }else if(this.data.typeCompteur == 'MUDT'){
                    firstPage.drawText("X", {
                        x: 247,
                        y: 787,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }else if(this.data.typeCompteur == 'CU4'){
                    firstPage.drawText("X", {
                        x: 299,
                        y: 787,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }else if(this.data.typeCompteur == 'MU4'){
                    firstPage.drawText("X", {
                        x: 344,
                        y: 787,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })

                }
                
                
                firstPage.drawText(this.data.kva, {
                    x: 178,
                    y: 767,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                if(this.data.prixBASE != ''){

                    firstPage.drawText("X", {
                        x: 156,
                        y: 747,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                    firstPage.drawText(this.data.prixBASE, {
                        x: 200,
                        y: 670,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                    firstPage.drawText(this.data.coefBase, {
                        x: 300,
                        y: 615,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }else{
                    firstPage.drawText("X", {
                        x: 200,
                        y: 747,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                    firstPage.drawText(this.data.prixHP, {
                        x: 450,
                        y: 670,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                    firstPage.drawText(this.data.prixHC, {
                        x: 500,
                        y: 670,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                    firstPage.drawText(this.data.coefHp, {
                        x: 355,
                        y: 615,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                    
                    firstPage.drawText(this.data.coefHc, {
                        x: 410,
                        y: 615,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                
                }
                
                firstPage.drawText(this.data.dateAjourdhui, {
                    x: 280,
                    y: 370,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText("18  00", {
                    x: 353,
                    y: 370,
                    size: 10,
                    color: rgb(0, 0, 0),
                })
                /////////////// page 3 ///////////////////////////////////////
                url0 = '/pdf/totale/HORIZON/totalHorizon3.pdf'
                const pageTwo = await fetch(url0).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pageTwoDoc = await PDFDocument.load(pageTwo)
                var getPages = pageTwoDoc.getPages()

                /////////////// page 4 ///////////////////////////////////////
                url0 = '/pdf/totale/HORIZON/totalHorizon4.pdf'
                const pageThree = await fetch(url0).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pageThreeDoc = await PDFDocument.load(pageThree)
                var getPages = pageThreeDoc.getPages()
                var firstPage = getPages[0]

                firstPage.drawText(this.data.raison, {
                    x: 290,
                    y: 709,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText(this.data.siret, {
                    x: 270,
                    y: 689,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                var donnerDesAdresse = await this.getCodePostale(this.data.adresseSociete)
                firstPage.drawText(donnerDesAdresse[3], {
                    x: 278,
                    y: 669,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(donnerDesAdresse[1], {
                    x: 360,
                    y: 669,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(donnerDesAdresse[0], {
                    x: 280,
                    y: 649,
                    size: 11.50,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(donnerDesAdresse[2], {
                    x: 360,
                    y: 649,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                // Create a new PDFDocument
                let mergedPdf = await PDFDocument.create()
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageZeroDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageOneDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageTwoDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageThreeDoc)
                //fin contact
                const pdfBytes = await mergedPdf.save()
                this.generatedPdf = pdfBytes
                download(pdfBytes, this.data.pdl+'TDE_Horizon_'+this.data.raison+'_.pdf', 'application/pdf')
                
                this.dialog = false
                this.error = false
                this.loading = false
                this.messageDialoge = ""
            },
            async addPagesToPdf(mergedPdf, donorPdf) {
                const copiedPages = await mergedPdf.copyPages(
                    donorPdf,
                    donorPdf.getPageIndices()
                )
                copiedPages.forEach((page) => {
                    mergedPdf.addPage(page)
                })
                return mergedPdf
            },
            verifyApplicationDataSecurity() {
                let self = this
                var formData = new FormData()
                formData.append('type', localStorage.getItem('typeUser'))
                formData.append('vueAfficher', 'pro')
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'verifyApplicationDataSecurity.php',
                    method: 'POST',
                    data: formData,
                    
                })
                .then((res) => {
                    var data = JSON.parse(JSON.stringify(res.data))
                    if (data[0].result === 'notLogin') {
                        localStorage.setItem('vueAfficher', 'particulier')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    } else if (data[0].result === 'accessDenied') {
                        localStorage.setItem('vueAfficher', 'particulier')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
            },
        }
    }
</script>
<style scoped>
::v-deep .v-input--selection-controls__input + .v-label{
   margin-bottom:0px !important;
}
</style>