<template>
  <div>
    <dialogeModal
      v-if="dialog == true"
      :error="error"
      :dialog="dialog"
      :loading="loading"
      @closeDialog="dialog = false"
      titre="Extraction du contrat"
      :messageDialogeSucces="this.messageDialogeSucces"
      :messageDialogeError="this.messageDialogeError"
    />
    <!-- form add plus data -->
    <v-dialog v-model="addData" max-width="850" persistent>
      <v-card>
        <v-toolbar :color="$colors[0]" dark>
          <v-card-title class="text-h6"> Informations complémentaires </v-card-title>
        </v-toolbar>
        <v-card-actions class="pa-8">
          <v-spacer></v-spacer>
          <v-row>
            <v-col cols="12" md="6" sm="6" lg="6" xl="6">
              <p class="font-weight-bold">Energie verte ?</p>
              <v-radio-group v-model="energieVert" class="mt-2" row>
                <v-radio class="mb-0 mx-2" label="Oui" :value="true"></v-radio>
                <v-radio label="Non" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" lg="6" xl="6">
              <span class="font-weight-bold">Utilisation de Gaz :</span>
              <v-radio-group class="mt-2" v-model="Utilisation" row>
                <v-radio class="mb-0 mx-2" label="chauffage" value="chauffage"></v-radio>
                <v-radio class="mb-0 mr-2" label="process" value="process"></v-radio>
                <v-radio label="mixte" value="mixte"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" lg="6" xl="6">
              <span class="font-weight-bold">Débit du Compteur :</span>
              <v-radio-group class="mt-2" v-model="Debit" column>
                <div class="d-flex flex-row">
                  <v-radio class="mb-0" label="6 m3/h" value="6"></v-radio>
                  <v-radio style="margin-left: 14px" label="10 m3/h" value="10"></v-radio>
                </div>
                <div class="d-flex flex-row">
                  <v-radio class="mb-0 mr-2" label="16 m3/h" value="16"></v-radio>
                  <v-radio label="25 m3/h" value="25"></v-radio>
                </div>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" lg="6" xl="6">
              <span class="font-weight-bold">Ajouter une adresse de facturation</span>
              <v-radio-group class="mt-2" v-model="addAdrsFact" row>
                <v-radio label="oui" class="mb-0 mx-2" :value="true"></v-radio>
                <v-radio label="non" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col
              class="d-flex flex-column align-end"
              v-if="addAdrsFact == true"
              cols="12"
              md="12"
              sm="12"
              lg="12"
              xl="12"
            >
              <v-text-field
                class="align-self-end"
                style="width: 50%"
                outlined
                v-model="adresseFacturation"
                label="Adresse de facturation"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12" lg="12" xl="12" v-if="typeCompteur != 'c4'">
              <span class="font-weight-bold">Type de paiement :</span>
              <v-radio-group class="mt-2" v-model="typePayement" row>
                <v-radio
                  label="Je choisis d’être mensualisé et prélevé automatiquement"
                  value="month"
                ></v-radio>
                <v-radio label="Autre mode de paiement et de Facturation" value="autre">
                </v-radio>
              </v-radio-group>
            </v-col>

            <v-col
              v-if="typePayement == 'month' && typeCompteur != 'c4'"
              cols="12"
              md="12"
              sm="12"
              lg="12"
              xl="12"
            >
              <span class="font-weight-bold">Type d'équipement :</span>
              <v-radio-group v-model="equipement">
                <v-radio
                  label="Mon local n’est pas équipé d’un compteur communicant LinkyTM ou Gazpar"
                  value="non"
                ></v-radio>
                <v-radio
                  label="Mon local est équipé d’un compteur communicant LinkyTM ou Gazpar"
                  value="oui"
                >
                </v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12" md="6" sm="6" lg="6" xl="6" v-if="typePayement == 'autre'">
              <span class="font-weight-bold">Mode de paiement :</span>
              <v-radio-group class="mt-2" v-model="modePayement" column>
                <v-radio label="Prélèvement automatique" value="Prel"></v-radio>
                <v-radio label="TIP/Chèque" value="TIP"> </v-radio>
              </v-radio-group>
            </v-col>
            <v-col
              cols="12"
              md="6"
              sm="6"
              lg="6"
              xl="6"
              v-if="
                (typePayement == 'month' && equipement == 'non') ||
                typePayement == 'autre'
              "
            >
              <span class="font-weight-bold">Type de facture :</span>
              <v-radio-group v-model="typeFacture" column>
                <v-radio label="Papier" value="papier"></v-radio>
                <v-radio label="Électronique" value="elec"> </v-radio>
              </v-radio-group>
            </v-col>

            <v-col align="end" cols="12" md="12" sm="12" lg="12" xl="12">
              <v-btn v-if="loading == false" color="error" text @click="addData = false">
                Annuler
              </v-btn>
              <v-btn
                v-if="loading == false"
                class="white--text"
                :color="$colors[0]"
                @click="createContrat"
              >
                Confirmer
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div>
      <v-btn @click="replierForm" icon depressed class="ma-auto text-capitalize">
        <v-icon :color="$colors[0]">mdi-text-box-edit</v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
import { degrees, PDFDocument, rgb, StandardFonts, layoutMultilineText } from "pdf-lib";
import download from "downloadjs";
import jsPDF from "jspdf";
import "jspdf-autotable";

import dialogeModal from "../../components/ui/dialogeModal";
import axios from "axios";
export default {
  name: "engieProGaz",
  components: { dialogeModal: dialogeModal },
  props: {
    donner: null,
    numCompteur: null,
    societe: null,
  },
  data: () => ({
    addData: false,
    addAdrsFact: false,
    typePayement: "autre",
    equipement: "oui",
    typeFacture: "elec",
    modePayement: "Prel",
    adresseFacturation: "",
    energieVert: true,
    percent: "25",
    Debit: "6",
    Utilisation: "chauffage",
    dialog: false,
    error: false,
    loading: false,
    messageDialogeError: "",
    messageDialogeSucces: "",
    generatedPdf: null,
    type: "",
    positionPage1: 1,
    positionPage1: 10,
    positionPage1: 10,
    positionPage1: 10,
    positionPage1: 10,
    positionPage1: 10,

    data: {
      dateAjourdhui:
        new Date().getDate() +
        "/" +
        (new Date().getMonth() + 1) +
        "/" +
        new Date().getFullYear(),
      dateDebut: "",
      dateFin: "",
      pdl: "",
      siret: "",
      codeNaf: "",
      adresseSociete: "",
      adresseCompteur: "",
      codePostaleCompteur: "",
      voieCompteur: "",
      communeCompteur: "",
      codePostaleSociete: "",
      voieSociete: "",
      communeSociete: "",
      codePostale: "",
      nom: "",
      prenom: "",
      email: "",
      numTele: "",
      consommationAnuuelle: "",
      typeCompteur: "",
      kva: "",
      kvaBase: "",
      kvaHp: "",
      kvaHc: "",
      kvaPte: "",
      kvaHce: "",
      kvaHpe: "",
      kvaHch: "",
      kvaHph: "",

      prixHPH: "",
      prixHCH: "",
      prixHPE: "",
      prixHCE: "",
      prixHC: "",
      prixHP: "",
      prixBASE: "",
      raison: "",
      nbrYears: "2",
    },
  }),
  created() {},
  methods: {
    async replierForm() {
      this.addData = true;
    },
    async createContrat() {
      this.addData = false;
      this.dialog = true;
      this.error = false;
      this.loading = true;
      this.messageDialogeError = "";
      this.messageDialogeSucces = "";

      var autreDonner = {};
      autreDonner.addAdrsFact = this.addAdrsFact;
      autreDonner.typePayement = this.typePayement;
      autreDonner.equipement = this.equipement;
      autreDonner.typeFacture = this.typeFacture;
      autreDonner.modePayement = this.modePayement;
      autreDonner.adresseFacturation = this.adresseFacturation;
      autreDonner.energieVert = this.energieVert;
      autreDonner.Debit = this.Debit;
      autreDonner.Utilisation = this.Utilisation;
      var dataToSend = {
        donner: this.donner,
        numCompteur: this.numCompteur,
        societe: this.societe,
        autreDonner: autreDonner,
      };
      await axios({
        url: "https://editioncontrat.agora-energie.com/engieContratGaz",
        // url: 'http://127.0.0.1:3000/engieContratGaz',
        method: "POST",
        data: dataToSend,
        headers: { "content-type": "application/json" },
      })
        .then(async (res) => {
          var doc = await PDFDocument.load(res.data);
          doc = await doc.save();
          download(
            doc,
            this.numCompteur + "_EngiePro_" + this.societe.Raison + "_.pdf",
            "application/pdf"
          );
        })
        .catch((err) => {
          console.log(err);
        });
      this.dialog = false;
      this.error = false;
      this.loading = false;
      this.messageDialogeError = "";
      this.messageDialogeSucces = "";
    },
  },
};
</script>
<style>
.v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  margin: 0px;
}
</style>
