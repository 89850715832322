<style>
    .td-valeur{
        align-items:center;
        flex-direction:column
    }

    .td-statut{
        align-items:center;
        flex-direction:row;
    }
    .td-table, .td-valeur, .td-statut{
        display:flex;
        justify-content:center;
    }
    .d1{
        display: flex;
        align-items:center;
        width:100%;
        justify-content:flex-end
    }
</style>
<template>
    <div>
        <v-dialog v-model="ouvrireDialogeSupprimer" max-width="700px">
            <v-card style="overflow: hidden;">
                <v-card-title style="display:block" class="text-h5 text-center font-weight-light">Êtes-vous sûr de bien vouloir supprimer cette équipe ?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-row>
                    <v-col class="d-flex justify-content-center" cols="12" sm="12" >
                        <v-btn color="blue darken-1" text @click="fermerDialogesupprimerEquipe"
                            >Annuler</v-btn
                        >
                        <v-btn color="blue darken-1" text @click="supprimerEquipe"
                            >Confirmer</v-btn
                        >
                    </v-col>
                </v-row>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <ajouterEquipeAdv v-if="ajouterEquipe==true" @ajouterEquipeMethod="getEtatajouterEquipe"/>
        <modifierEquipeAdv :equipeId="equipeIdModifier" @modifierEquipe="getEtatModifierEquipe" v-if="modifierEquipe==true" />
        <v-btn class="mt-8 ml-8 mr-8" @click="ajouterEquipe=true" v-if="ajouterEquipe==false">Ajouter equipe</v-btn>
        <datatable class="ma-8" :columns="columns" :sortKey="sortKey" :sortOrders="sortOrders" @sort="sortBy"  @search="rechercheData">
            <template v-slot:body>
                <tbody>
                    <tr  v-for="( equipe, index) in lesEquipes" :key="equipe.id">
                        <td>
                            <div class="td-table">
                                {{equipe.date}}
                            </div>
                        </td>
                        <td>
                            <div class="td-table">
                                {{equipe.nomEquipe}} 
                            </div>
                        </td>
                        <td>
                            <div class="td-table">
                                {{equipe.ADV.userName}} 
                            </div>
                        </td>
                        <td>
                            <div class="td-table" v-if="equipe.chefEquipe">
                                {{equipe.chefEquipe.userName}} 
                            </div>
                        </td>
                        <td>
                            <div class="td-table">
                                <v-chip-group
                                    v-if="equipe.Vendeurs.length>0"
                                    column
                                 >
                                    <v-chip>{{equipe.Vendeurs[0].userName}}
                                        <v-icon
                                            v-if="equipe.Vendeurs.length>1"
                                            large
                                            data-toggle="collapse" :data-target="'#multiCollapseVendeur'+index" aria-expanded="false" :aria-controls="'multiCollapseVendeur'+index"
                                            right
                                            >
                                            mdi-menu-down
                                        </v-icon>
                                    </v-chip>
                                    <div class="collapse multi-collapse" :id="'multiCollapseVendeur'+index">
                                        <div >
                                            <v-chip v-for="vendeur, indexV in equipe.Vendeurs.slice(1,equipe.Vendeurs.length)" :key="indexV">{{vendeur.userName}}</v-chip>  
                                        </div>
                                    </div>
                                </v-chip-group>
                                <v-chip-group v-else>
                                    <span>cette equipe ne contient aucune vendeur</span>
                                </v-chip-group>
                            </div>
                        </td>
                        <td>
                            <div class="td-table">
                                <v-chip-group
                                    v-if="equipe.prospecteur.length>0"
                                    column
                                 >
                                    <v-chip>{{equipe.prospecteur[0].userName}}
                                        <v-icon
                                            v-if="equipe.prospecteur.length>1"
                                            large
                                            data-toggle="collapse" :data-target="'#multiCollapseprospecteur'+index" aria-expanded="false" :aria-controls="'multiCollapseprospecteur'+index"
                                            right
                                            >
                                            mdi-menu-down
                                        </v-icon>
                                    </v-chip>
                                    <div class="collapse multi-collapse" :id="'multiCollapseprospecteur'+index">
                                        <div >
                                            <v-chip v-for="prospecteur, indexp in equipe.prospecteur.slice(1,equipe.prospecteur.length)" :key="indexp">{{prospecteur.userName}}</v-chip>  
                                        </div>
                                    </div>
                                </v-chip-group>
                                <v-chip-group v-else>
                                    <span>cette equipe ne contient aucune Prospecteur</span>
                                </v-chip-group>
                            </div>
                        </td>
                        <td>
                            <div  class="btn-group td-statut">
                                <div class="d-flex">
                                    <v-btn
                                        @click="ouvrireDialogesupprimerEquipe(equipe)"
                                        class="mx-2"
                                        color='grey lighten-1'
                                        small
                                        dark>Supprimer
                                    </v-btn>
                                    <v-btn
                                        @click="ouvrireDialogeModifier(equipe)"
                                        class="mx-2"
                                        color='grey lighten-1'
                                        small
                                        dark>modifier
                                    </v-btn>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </template>
        </datatable>
        <v-row >
            <v-col >
                <pagination :pagination="pagination"
                    @selectNumberOfRecords="rechercheData"
                    @first="getData(pagination.firstPage)"
                    @last="getData(pagination.lastPage)"
                    @prev="getData(pagination.prevPageUrl)"
                    @current="getData(pagination.currentPage)"
                    @next="getData(pagination.nextPageUrl)">
                </pagination>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import "jquery/dist/jquery.min.js";
    import "bootstrap/dist/css/bootstrap.min.css";
    import "bootstrap/dist/css/bootstrap.css";
    import "bootstrap/dist/js/bootstrap.bundle";
    import "datatables.net-dt/js/dataTables.dataTables";
    import "datatables.net-dt/css/jquery.dataTables.min.css";

    import $ from 'jquery'
    import jQuery from 'jquery'

    import Datatable from '../../components/dataTable/Datatable.vue'
    import Pagination from '../../components/dataTable/Pagination.vue'
    import ajouterEquipeAdv from './ajouterEquipeAdv.vue'
    import modifierEquipeAdv from './modifierEquipeAdv.vue'
    import axios from 'axios'
    export default{
        name: 'listeEquipeAdv',
        components: {datatable:Datatable, pagination:Pagination, ajouterEquipeAdv:ajouterEquipeAdv, modifierEquipeAdv:modifierEquipeAdv},
        data(){
            let sortOrders={};
            let columns = [
               {width:'8vw', label:'Date', name:'date', sortable:true},
                {width:'10vw', label:'Equipe', name:'Equipe', sortable:true},
                {width:'10vw', label:'ADV', name:'ADV', sortable:true},
                {width:'10vw', label:'chefEquipe', name:'Chef équipe', sortable:false},
                {width:'22vw', label:'Vendeurs', name:'Vendeurs', sortable:false},
                {width:'22vw', label:'Prospecteurs', name:'Prospecteurs', sortable:false},
                {width:'10vw', label:'Statut', name:'status', sortable:true},
            ];
            columns.forEach((column)=>{
                sortOrders[column.name] = -1;
            });
            return{
                ajouterEquipe: false,
                modifierEquipe: false,
                dialog: false,
                loading: false,
                error: false,
                commentRefuser:'',
                messageDialoge: '',
                ouvrireDialogeSupprimer: false,
                ouvrireDialogeAccepter: false,
                lesEquipes: [],
                columns: columns,
                sortKey: 'date',
                sortOrders: sortOrders,
                perPage: ['15', '15', '30'],
                tableData: {
                    draw: 0,
                    length: 15,
                    search: '',
                    column: 0,
                    dir: 'desc',
                },
                demandeTraiter: null,
                pagination: {
                    firstPage: 0,
                    lastPage: '',
                    currentPage: '',
                    total: '',
                    lastPageUrl: '',
                    nextPageUrl: '',
                    prevPageUrl: '',
                    from: '',
                    to: ''
                },

                 rules: {
                    required(finPhrase) {
                    return (value) => !!value || `Veuillez saisir ${finPhrase}`
                    },
                },
            }
        },
        async created() {
            var whoIam = ""
            await this.whoIAm(this.getToken('token')).then((data)=>{whoIam=data});
            if(whoIam == "noToken"){
                localStorage.removeItem('vendeurName')
                localStorage.removeItem('typeUser') 
                localStorage.removeItem('token')
                this.$router.push('/login')
            }else{
                localStorage.setItem('typeUser', whoIam)
            }
            this.getData();
        },
        methods: {
            getEtatModifierEquipe(varr){
                this.modifierEquipe=varr
                this.getData()
            },
            getEtatajouterEquipe(varr){
                this.ajouterEquipe = varr
                this.getData()
            },
            ouvrireDialogeModifier(item){
                this.modifierEquipe = true
                this.equipeIdModifier = item.id
            },
            rechercheData({type, key}) {
                if(type=='search'){
                    var pageNumber = 0
                    this.tableData.search = key
                }else if(type=="nbrRecords")
                {
                    this.tableData.length = key
                }
                var pageNumber = 0
                this.tableData.search = key
                this.tableData.draw++;
                axios({url:process.env.VUE_APP_URL_API_CLIENT + 'getAllEquipeServerSide/',
                    method: 'POST',
                    data: {'dataTable': this.tableData,
                        "pageNumber": pageNumber,
                        'token': this.getToken('token')},
                    }).then(response => {
                       if(response.data.result == true){
                        let data = response.data.data;
                        if (this.tableData.draw == data.draw) {
                            this.lesEquipes = data.data.data;
                            this.configPagination(data.data);
                        }
                    }else{
                        alert('permission refusée')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                })
                .catch(errors => {
                    console.log(errors);
                });
            },
            supprimerEquipe(){
                //fermer modal comment
                this.ouvrireDialogeSupprimer = false
                //open modal loading
                this.dialog = true
                this.loading = true
                this.error = false
                this.messageDialoge = ''

                let self = this
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                formData.append('idEquipe', this.demandeTraiter.id)
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT + 'supprimerEquipe/',
                    method: 'POST',
                    data: formData,
                    
                }).then((res) => {
                    this.fermerDialogesupprimerEquipe()
                    this.getData()
                }).catch((err)=>{
                    this.fermerDialogesupprimerEquipe()
                    this.getData()
                })
            },
            ouvrireDialogesupprimerEquipe(item){
                this.demandeTraiter = item
                this.ouvrireDialogeSupprimer = true
            },
            fermerDialogesupprimerEquipe(){
                this.demandeTraiter = ''
                this.ouvrireDialogeSupprimer = false
            },
            getData(pageNumber = 0) {
                this.tableData.draw++;
                axios({url:process.env.VUE_APP_URL_API_CLIENT + 'getAllEquipeServerSide/',
                    method: 'POST',
                    data: {'dataTable': this.tableData,
                        "pageNumber": pageNumber,
                        'token': this.getToken('token')},
                }).then(response => {
                    if(response.data.result == true){
                        let data = response.data.data;
                        if (this.tableData.draw == data.draw) {
                            this.lesEquipes = data.data.data;
                            this.configPagination(data.data);
                        }
                    }else{
                        alert('permission refusée')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                })
                .catch(errors => {
                    console.log(errors);
                });
            },
            configPagination(data) {
                this.pagination.lastPage = data.last_page;
                this.pagination.currentPage = data.current_page;
                this.pagination.total = data.total;
                this.pagination.lastPageUrl = data.last_page_url;
                this.pagination.nextPageUrl = data.next_page_url;
                this.pagination.prevPageUrl = data.prev_page_url;
                this.pagination.from = data.from;
                this.pagination.to = data.to;
            },
            sortBy(key) {
                this.sortKey = key;
                this.sortOrders[key] = this.sortOrders[key] * -1;
                this.tableData.column = this.getIndex(this.columns, 'name', key);
                this.tableData.dir = this.sortOrders[key] === 1 ? 'asc' : 'desc';
                this.getData();
            },
            getIndex(array, key, value) {
                return array.findIndex(i => i[key] == value)
            },

            
        }
    }
</script>
