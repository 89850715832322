<template>
  <div>
    <!-- create dialoge has on titre and button -->
    <v-dialog v-model="changed" max-width="890" persistent>
     
        <!-- <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          @click="dialog = !dialog"
        >
          <v-icon>mdi-plus</v-icon>
          <span>Ajouter</span>
        </v-btn> -->
      
      <v-card>
        <v-card-title class="headline">Changer Les Vendeurs</v-card-title>
    
        <v-card-text>
          <h4>
            Vous êtes sur le point de changer les vendeurs associés à cette cotation.
          </h4>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="changed = false">Annuler</v-btn>
          <v-btn color="blue darken-1" text @click="changeVendeurSubmit">Enregistrer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="expirationModal" max-width="890" persistent>
     
     <!-- <v-btn
       color="primary"
       dark
       v-bind="attrs"
       v-on="on"
       @click="dialog = !dialog"
     >
       <v-icon>mdi-plus</v-icon>
       <span>Ajouter</span>
     </v-btn> -->
   
   <v-card>
     <v-card-title class="headline">Changer Le Date Expiration</v-card-title>
 
     <v-card-text>
       <h4>
         Vous êtes sur le point de changer Le Date Expiration.
       </h4>
     </v-card-text>
     <v-card-actions>
       <v-spacer></v-spacer>
       <v-btn color="blue darken-1" text @click="expirationModal = false">Annuler</v-btn>
       <v-btn color="blue darken-1" text @click="saveChangeModalDate">Enregistrer</v-btn>
     </v-card-actions>
   </v-card>
    </v-dialog>


    <datatable id="dataTable" class="ma-8" :columns="columns">
      <template v-slot:body>
        <tbody v-if="historiqueCotationItems.length > 0">
          <tr class="text-center" v-for="(item, indexFor) in historiqueCotationItems">
            <td>{{ item.id }}</td>
            <td>
              <v-tooltip v-if="item.Societe.Raison.length >= 15" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <small class="td-table" v-bind="attrs" v-on="on">
                    <router-link
                      :to="{
                        path: '/les-profils-societes',
                        query: { id: item.Societe.id },
                      }"
                      >{{ item.Societe.Raison.substring(0, 20) + "..." }}</router-link
                    >
                  </small>
                  <small>{{ item.Societe.siret }}</small>
                </template>
                <span>{{ item.Societe.Raison }}</span>
              </v-tooltip>
              <small class="td-table" v-else>
                <router-link
                  :to="{
                    path: '/les-profils-societes',
                    query: { id: item.Societe.id },
                  }"
                  >{{ item.Societe.Raison }}</router-link
                >
                <small>{{ item.Societe.siret }}</small>
              </small>
            </td>
            <td>{{ item.date }}</td>
            <td>
              <v-text-field
                
                outlined
                dense
                hide-details="auto"
                v-model="item.dateExpiration"
                @input="openModalDate(item.dateExpiration, item.id)"
                :disabled="userRole === 'Vendeur' || userRole ==='Prospecteur'"
                type="date"
                label="Picker in dialog"
                prepend-inner-icon="mdi-calendar"
              ></v-text-field>
            </td>
            <td>
              <router-link
                :to="{
                  path: '/new-historiqueCotationPC',
                  query: { id: item.id },
                }"
                target="_blank"
                v-if="item.status == 'new'"
                ><small>Historique de Cotation PC {{ item.id }}</small></router-link
              >
            </td>
            <td>{{ item.message }}</td>
            <td v-if="userRole !== 'Vendeur' && userRole !=='Prospecteur'">
              <v-select
                    required
                    outlined
                    multiple
                    v-model="item.allowedComptes"
                    :items="listVendeurs"
                    label="Liste Vendeur"
                    item-text="userName"
                    item-value="id"
                    @blur="changeVendeur(item)"
              >
              
              </v-select>
              

            </td>
          
          </tr>
        </tbody>
        <progressCircular class="ma-8" v-else-if="loadingTable == true" />
          <noDataTable
            style="z-index: 2"
            class="ma-4"
            v-else-if="historiqueCotationItems?.length == 0 && loadingTable == false"
          />
      </template>
    </datatable>
    <pagination
        v-if="historiqueCotationItems.length > 0"
        :pagination="pagination"
        @first="getData(pagination.firstPage)"
        @last="getData(pagination.lastPage)"
        @prev="getData(pagination.prevPageUrl)"
        @current="getData(pagination.currentPage)"
        @next="getData(pagination.nextPageUrl)"
      />
  </div>
</template>
<script>
import Datatable from "../components/dataTable/Datatable.vue";
import Pagination from "../components/dataTable/Pagination.vue";
import noDataTable from "../components/no-data-table.vue";
import progressCircular from "../components/progress-circular.vue";

import dayjs from "dayjs";
import axios from "axios";
export default {
  name: "historiqueCotationCpElec",
  props: {
    societe: Number,

  },
  components: {
    datatable: Datatable,
    pagination: Pagination,
    noDataTable,
    progressCircular,
  },
  data() {
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      modalDate: false,
      expirationModal: false,
      loadingTable: false,
      filterStatus: "tout",
      newEpirationDate: null,
      idCotation: null,
      newEpirationDate: null,
      changed: false,
      historiqueCotationItems: [],
      histoDeCotation: [],
      userRole: "",
      
      columns: [
          { width: "20vw", label: "ID", name: "id", sortable: true },
          { width: "20vw", label: "Raison Sociale", name: "raison", sortable: true },
          { width: "20vw", label: "Date d'ajout", name: "dateAjout", sortable: true },
          { width: "20vw", label: "Date de validité", name: "DateValide", sortable: true },
          { width: "20vw", label: "Historique", name: "Historique", sortable: true },
          { width: "20vw", label: "Commentaire", name: "comment", sortable: true },
          ...( !["Vendeur","Prospecteur"].includes(localStorage.getItem("typeUser")) ? [{ width: "20vw", label: "Commercial", name: "Commerciale", sortable: true }] : [])

      ],
      listVendeurs: [],
      selectedListVendeurs: [],
      selectedHistoriqueCotationCp:null,
      vendeur:"",
      itemsVendeurs: [],
      tableData: {
          statusFilter: "",
          draw: 0,
          length: 15,
          search: "",
          column: 0,
          dir: "desc",
        },
        pagination: {
        perPage: ["15", "20", "30"],
        firstPage: 1,
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
      },
    };
  },
  async created() {
    //this.historiqueCotationItems = [...this.histoDeCotation];
    //await this.getData();
    await this.getAllHistoryCotationBySociete();
    await this.getListVendor();
    console.log(localStorage.getItem("typeUser"))
    this.userRole = localStorage.getItem("typeUser");
  },
  methods: {
    saveChangeModalDate() {
      let data = {
        token: this.getToken("token"),
        id: this.idCotation,
        new_date: this.newEpirationDate,
      };
      axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "changeDateExpirationHistoriqueDeCotation/",
        method: "POST",
        data: data,
      })
        .then((res) => {
          if (res.data.result == true) {
            this.expirationModal = false;
            this.$toast.success("Date d'expiration modifiée avec succès");
          } else {
            this.$toast.error("Erreur lors de la modification de la date d'expiration");
          }
        })
        .catch((err) => {
          console.log(err);
        });

    },
    openModalDate(date, id) {
      this.expirationModal = true;
      this.newEpirationDate = date;
      this.idCotation = id;
    },
    changeVendeursAssocie(listVendeursAssocié, cotation) {
      if (listVendeursAssocié.length > 0) {
        console.log(listVendeursAssocié, cotation.id);
      } else {
        console.log("liste vendeur vide");
      }
    },
    getDiffDate(dateValidite) {
      const [day, month, year] = dateValidite.split("/");
      // changement format dateValidite afin d'empecher l'interpretation du format américain
      const isoDateString = `${year}-${month}-${day}`;
      const date1 = new Date(); // Date actuelle
      const date2 = new Date(isoDateString); // Date de validité

      const diffTime = date2 - date1; // Différence en millisecondes
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Nombre de jours restants

      let diffYears = 0;

      // Calcul du nombre d'années restantes si les jours restants sont supérieurs à 365
      if (diffDays > 365) {
        diffYears = Math.floor(diffDays / 365);
      }

      const remainingDays = diffDays % 365;

      //console.log(diffTime);
      if (diffYears !== 0) {
        // utilisation du terme année s'il reste plus que 365 jours entre les 2 dates
        //console.log("Années restantes:", diffYears);
      }
      //console.log("Jours restants:", remainingDays);
      return remainingDays; // retourne le nombre de jours restants
    },
    async getAllHistoryCotationBySociete(pageNumber = 0) {
      this.tableData.draw++;
      this.vueAfficher = "historiqueDeCotation";
      let data = {
          token: this.getToken("token"),
          idSociete: this.societe,
          dataTable: this.tableData,
          pageNumber: pageNumber,
        };
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getAllHistoryCotationParCompteBySociete/",
        method: "POST",
        data: data,
      })
        .then((res) => {
          if (res.data.result == true) {
            this.vueAfficher = "historiqueDeCotation";
            if(this.tableData.draw == res.data.histoDeCotation.draw){
                this.historiqueCotationItems = res.data.histoDeCotation.data.data;
                this.configPagination(res.data.histoDeCotation.data);
              }
            this.historiqueCotationItems.forEach((item) => {
              const [day, month, year] = item.dateFin.split("/");
              item.dateFin = `${year}-${month}-${day}`;
            });
            this.demandeDeCotationItems = res.data.DC;
          } else if (res.data.result == false) {
            alert("Une erreur est survenue ! Veuillez réessayer");
          } else if (res.data.result == "permission") {
            alert("permission refusée");
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((err) => {
          
        });
    },
    async changeVendeurSubmit() {
     let historique = this.selectedHistoriqueCotationCp;
     let data={
      "token":this.getToken("token"),
      "id":historique.id,
      "list_allowed_compte": historique.allowedComptes

     }
     await axios({
      url:process.env.VUE_APP_URL_API_CLIENT + "changeAllowedCompteHistoriqueDeCotation/",
      method: "POST",
      data:data
      }).then((response) => {
      if(response.data.result==true){
        this.changed = false;
        this.$toast.success("Vendeur modifié avec succès");
        
      }else{
        this.$toast.error("Erreur lors de la modification du vendeur");
      }
      }).catch((error) => {
      console.log(error)
      })
    
    },
    changeVendeur(item) {
     this.changed = true;
     this.selectedHistoriqueCotationCp = item;
    
    },
    configPagination(data) {
      this.pagination.lastPage = data.last_page;
      this.pagination.currentPage = data.current_page;
      this.pagination.total = data.total;
      this.pagination.lastPageUrl = data.last_page_url;
      this.pagination.nextPageUrl = data.next_page_url;
      this.pagination.prevPageUrl = data.prev_page_url;
      this.pagination.from = data.from;
      this.pagination.to = data.to;
    },
    async getListVendor() {
      await axios({
      url:process.env.VUE_APP_URL_API_CLIENT + "getAllVendeurs/",
      method: "POST",
      data:{
          "token":this.getToken("token")

      }
      }).then((response) => {
      
      this.listVendeurs = response.data.result;

      }).catch((error) => {
      console.log(error)
      })
      },
  },
};
</script>
<style scoped>
::v-deep #dataTable th {
  background-color: #1a1a1a;
  color: white;
  font-weight: bold;
}
</style>
